@use 'var';
@use 'utility';
@use 'mixins';


// ################ Miscellaneous ################

// This should be moved to _room.scss or _roomDetail.scss when either Room.jsx is used or class based styles are added to RoomDetail.jsx
// .MuiButton-root.room-addentity-table-button,
// .MuiButton-root.room-addentity-sensor-button {
//     margin-bottom: 16px;
//     margin-right: 16px;
// }


// ################ AddEntityCard ################

$baseEntityCardWd: 396px;
$baseEntityCardPX: 20px;

.MuiBox-root.base-entity-card-box,
.MuiButton-root.add-entity-card-button {
    align-items: center;
    background-color: var.$primary-200;
    border: 1px solid var.$primary-800;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    // flex-grow: 0;
    // flex-shrink: 0;
    height: 214px;
    justify-content: center;
    padding: 40px $baseEntityCardPX;
    text-transform: none;
    // width: $baseEntityCardWd;

    &:hover{
        cursor: pointer;
        background-color: var.$primary-200;
    }
}

.MuiSvgIcon-root.add-entity-card-icon {
    // color: var.$primary-800;
    fill: none;
    height: 24px;
    width: 24px;
    path {
        stroke: var.$primary-800;
    }
}

.MuiTypography-root.add-entity-card-typo {
    @include utility.typoBase(var.$primary-800, 0.875rem, 500, null, 1.5, 16px, null)
}

    // DARK THEME

.MuiBox-root.base-entity-card-box.#{var.$darkThemeClass},
.MuiButton-root.add-entity-card-button.#{var.$darkThemeClass} {
    border: 1px solid var.$primary-600;

    @include utility.theme(('bgColor': var.$dark-100));
}

.MuiSvgIcon-root.add-entity-card-icon.#{var.$darkThemeClass} {
    fill: none;
    @include utility.theme(('color': var.$primary-600));

    path {
        stroke: var.$primary-600;
    }

}

.MuiTypography-root.add-entity-card-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));    
}


// ################ BaseEntityCard ################

.MuiBox-root.base-entity-card-box { // Other details in AddEntityCard
    &:hover {
        cursor: default;
    }
} 

    // DARK THEME
// .MuiBox-root.base-entity-card-box.#{var.$darkThemeClass} {} // Same as AddEntityCard


// ################ SensorCard ################

.MuiBox-root.sensor-card-baseentitycard-box {
    // margin-right: 16px;
    // margin-bottom: 16px;
    position: relative;
}

.MuiBox-root.sensor-card-action-button-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    width: 100%;

    &.sensor-card-added-table {
        justify-content: center;
    }
}

.MuiButton-root.sensor-card-attach-table-button {
    background-color: transparent;
    // border: 1px solid var.$primary-800;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: none;

    @include utility.typoBase(var.$primary-800, 0.875rem, 400, null, 1.5);

    &:hover {
        background-color: transparent;
    }
}

.MuiButton-root.sensor-card-edit-details-button {
    background-color: transparent;
    // border: 1px solid var.$primary-800;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: none;

    @include utility.typoBase(var.$primary-800, 0.875rem, 400, null, 1.5);
    
    &:hover {
        background-color: transparent;
    }
}

.MuiBox-root.sensor-card-info-box {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 20px auto 0;
}

.MuiSvgIcon-root.sensor-card-edit-icon,
.MuiSvgIcon-root.sensor-card-attach-table-icon {
    height: 16px;
    width: 16px;

    path {
        fill: none;
        stroke: var.$primary-800;
    }
}

// .MuiSvgIcon-root.sensor-card-edit-icon {
//     path {
//         stroke: var.$primary-800;
//     }
// }

// .MuiSvgIcon-root.sensor-card-attach-table-icon {
//     path {
//         stroke: var.$primary-800;
//     }
// }

.MuiSvgIcon-root.sensor-card-dot-separator-icon {
    height: 5px;
    margin-left: 5px;
    margin-right: 5px;
    width: 5px;
    
    circle {
        fill: var.$primary-800;
    }
}

.MuiTypography-root.sensor-card-types-typo,
.MuiTypography-root.sensor-card-room-typo,
.MuiTypography-root.sensor-card-mac-typo {
    text-align: center;

    @include utility.typoBase(var.$primary-800, 0.875rem, 500, null, 1.5);
}

.MuiTypography-root.sensor-card-types-typo {
    // width: calc($baseEntityCardWd - 2 * $baseEntityCardPX);
    width: calc(100% - 2 * $baseEntityCardPX);
}

.MuiTypography-root.sensor-card-title-typo {
    @include utility.typoBase(var.$primary-800, 1.5625rem, 500, null, 1.16);
}

.MuiButton-root.sensor-card-del-button {
    min-width: fit-content;
    padding: 0;
    position: absolute;
    right: 8px;
    top: 16px;
    
    &:hover {
        background-color: transparent;
    }

    path {
        fill: var.$primary-800;
    }
}

    // DARK THEME

.MuiBox-root.sensor-card-baseentitycard-box.#{var.$darkThemeClass} {
    border-color: transparent;
}

.MuiButton-root.sensor-card-attach-table-button.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));
}

.MuiSvgIcon-root.sensor-card-edit-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$grey-400;
    }
}

.MuiButton-root.sensor-card-edit-details-button.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

// .MuiBox-root.sensor-card-info-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.sensor-card-types-typo.#{var.$darkThemeClass},
.MuiTypography-root.sensor-card-room-typo.#{var.$darkThemeClass},
.MuiTypography-root.sensor-card-mac-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

// .MuiTypography-root.sensor-card-room-typo.#{var.$darkThemeClass} {

// }

.MuiTypography-root.sensor-card-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-100));
}

.MuiSvgIcon-root.sensor-card-dot-separator-icon.#{var.$darkThemeClass} {
    circle {
        fill: var.$grey-400;
    }
}

// .MuiTypography-root.sensor-card-types-typo.#{var.$darkThemeClass} {

// }
.MuiButton-root.sensor-card-del-button.#{var.$darkThemeClass} {
    path {
        fill: var.$grey-400;
    }
}

// ################ RoomTableCard ################


.MuiBox-root.roomtable-card-baseentitycard-box {
    // margin-right: 16px;
    // margin-bottom: 16px;
    position: relative;
}

.MuiTypography-root.roomtable-card-name-typo {
    @include utility.typoBase(var.$primary-800, 1.5625rem, 500, null, 1.16);
}

.MuiBox-root.roomtable-card-info-box {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 20px auto 0;
}

.MuiTypography-root.roomtable-card-info-typo {
    text-align: center;

    @include utility.typoBase(var.$primary-800, 0.875rem, 500, null, 1.5);
}

.MuiSvgIcon-root.roomtable-card-dot-sep-icon {
    height: 5px;
    margin-left: 5px;
    margin-right: 5px;
    width: 5px;
    
    circle {
        fill: var.$primary-800;
    }
}

.MuiSvgIcon-root.roomtable-card-edit-icon {
    height: 16px;
    width: 16px;

    path {
        fill: none;
        stroke: var.$primary-800;
    }
}

.MuiButton-root.roomtable-card-edit-button {
    background-color: transparent;
    // border: 1px solid var.$primary-800;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: none;

    @include utility.typoBase(var.$primary-800, 0.875rem, 400, null, 1.5, auto);
    
    &:hover {
        background-color: transparent;
    }
}

.MuiButton-root.roomtable-card-del-button {
    min-width: fit-content;
    padding: 0;
    position: absolute;
    right: 8px;
    top: 16px;
    
    &:hover {
        background-color: transparent;
    }

    path {
        fill: var.$primary-800;
    }
}

// #################### DARK THEME

.MuiBox-root.roomtable-card-baseentitycard-box.#{var.$darkThemeClass} {
    border-color: transparent;
}

.MuiTypography-root.roomtable-card-name-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-100));
}

// .MuiBox-root.roomtable-card-info-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.roomtable-card-info-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiSvgIcon-root.roomtable-card-dot-sep-icon.#{var.$darkThemeClass} {
    circle {
        fill: var.$grey-400;
    }
}

.MuiSvgIcon-root.roomtable-card-edit-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$grey-400;
    }
}

.MuiButton-root.roomtable-card-edit-button.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiButton-root.roomtable-card-del-button.#{var.$darkThemeClass} {
    path {
        fill: var.$grey-400;
    }
}


// ################ CropSpecCard ################


.MuiBox-root.crop-spec-card-box {
    align-items: center;
    background-color: var.$primary-400; // prev to figma
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px; // Actual: 40px 80px

    @include utility.transition(background-color, false, false);
}

.MuiSvgIcon-root.crop-spec-card-icon {
    height: 24px;
    width: 24px;
    path {
        // fill: var.$primary-800; // prev to figma
        fill: var.$grey-700; // self assumed
    }
}

.MuiTypography-root.crop-spec-card-title-typo {
    max-width: 118px;
    text-align: center;
    
    // @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.28, 22px, null); // prev to figma
    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.28, 22px, null);
}

.MuiTypography-root.crop-spec-card-value-typo {
    text-align: center;
    text-transform: uppercase;

    // @include utility.typoBase(var.$primary-800, 1.5625rem, 700, null, 1.16, 24px, null); // prev to figma
    @include utility.typoBase(var.$grey-700, 1.5625rem, 700, null, 1.16, 24px, null);

}

// ################ DARK THEME

.MuiBox-root.crop-spec-card-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-400));
}

.MuiSvgIcon-root.crop-spec-card-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$grey-300;
    }
}

.MuiTypography-root.crop-spec-card-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiTypography-root.crop-spec-card-value-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}


// ################ CropLinkCard ################


.MuiBox-root.crop-link-card-box {
    align-items: flex-start;
    background-color: var.$primary-400; // a/c to figma
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;

    @include utility.transition(background-color, false, false);
}

.MuiBox-root.crop-link-card-title-box {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.MuiTypography-root.crop-link-card-title-key-typo {
    // @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.28, null, null); // prev to figma
    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.28, null, null);
}

.MuiTypography-root.crop-link-card-title-value-typo {
    // @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.28, 5px, null); // prev to figma
    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.28, 5px, null);
}

.MuiBox-root.crop-link-card-link {
    text-decoration: none;

    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.28, null, null); // self assuemd

    // &:hover {
    //     background-color: transparent;
    // }
}

.MuiButton-root.crop-link-card-link-button {
    align-items: center;
    justify-content: center;
    padding: 0;
    text-transform: none;

    // @include utility.typoBase(var.$primary-700, 1rem, 500, null, 1.28, null, null); // prev to figma
    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.28, null, null); // self assuemd

    &:hover {
        background-color: transparent;
    }
}

.MuiSvgIcon-root.crop-link-card-link-icon {
    height: 16px;
    vertical-align: top;
    width: 16px;

    path {
        fill: none;
        // stroke: var.$primary-700; // prev to figma
        stroke: var.$grey-700; // self assumed
    }
}

// ################ DARK THEME

.MuiBox-root.crop-link-card-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-400));
}

// .MuiBox-root.crop-link-card-title-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.crop-link-card-title-key-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiTypography-root.crop-link-card-title-value-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiBox-root.crop-link-card-link.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-700));
}

.MuiButton-root.crop-link-card-link-button.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-700));
}

.MuiSvgIcon-root.crop-link-card-link-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$primary-700;
    }
}


// ################ RoomDetailInfoCard ################


.MuiPaper-root.room-detail-info-card-paper {
    margin-bottom: 16px;
    padding: 25px 28px;
    width: 100%;
}

.MuiPaper-root.room-detail-info-card-title-paper {
    display: flex;
    justify-content: space-between;
}

.MuiTypography-root.room-detail-info-card-title-typo {
    color: var.$grey-700;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.16;
}

.MuiSvgIcon-root.room-detail-info-card-edit-icon {
    height: 24px;
    width: 24px;
}

.MuiButton-root.room-detail-info-card-edit-button {
    color: var.$grey-700;
    font-size: 16px;
    font-weight: 500;
    flex-shrink: 0;
    line-height: 1.56;
    padding: 0;
    text-transform: none;
}

.MuiTypography-root.room-detail-info-card-entry-typo {
    color: var.$grey-700;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.56;
    // margin-top: 16px;
}

// ################ DARK THEME

.MuiPaper-root.room-detail-info-card-paper.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100));
}

.MuiPaper-root.room-detail-info-card-title-paper.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': transparent));
}

.MuiTypography-root.room-detail-info-card-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiSvgIcon-root.room-detail-info-card-edit-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$primary-600;
    }
}

.MuiButton-root.room-detail-info-card-edit-button.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));
}

.MuiTypography-root.room-detail-info-card-entry-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-300));
}

// ################ RoomDetailItemInfoCard ################


.MuiPaper-root.room-detail-item-info-card-paper {
    background-color: var.$primary-400;
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: 16px;
    padding: 46px 28px;
    width: 100%;
    
    &:hover {
        cursor: pointer;
    }
}

// ################ DARK THEME

.MuiPaper-root.room-detail-item-info-card-paper.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$grey-400));
}

// ################ MetrcRoomDragCard ################

.MuiBox-root.metrc-room-drag-card-box {
    background-color: var.$primary-400;
    border-bottom: 1px solid var.$primary-700;
    display: flex;
    padding: 24px 12px 23px;
    width: 100%;

    &:hover {
        cursor: pointer;
    }
}

.MuiSvgIcon-root.metrc-room-drag-card-icon {
    height: 24px;
    width: 24px;
    
    path {
        fill: none;
        stroke: var.$grey-900;
    }

    &.#{var.$darkThemeClass} {
        path {
            fill: none;
        }
    }
}

.MuiTypography-root.metrc-room-drag-card-typo {
    color: var.$grey-900;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.45;
    margin-left: 16px;
}

// ################ DARK THEME

.MuiBox-root.metrc-room-drag-card-box.#{var.$darkThemeClass} {
    border-color: var.$primary-900;
    @include utility.theme(('bgColor': var.$grey-400));
}

// .MuiSvgIcon-root.metrc-room-drag-card-icon.#{var.$darkThemeClass} {

// }

.MuiTypography-root.metrc-room-drag-card-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-900));
}


// ################ ExternalCard ################

.MuiBox-root.external-card-box {
    background-color: var.$primary-200;
    border-bottom: 1px solid var.$primary-800;
    display: flex;
    margin-bottom: 16px;
    margin-right: 16px;    

    &:hover {
        cursor: pointer;
    }
}

// img.external-card-logo-image {}

// ################ DARK THEME

.MuiBox-root.external-card-box.#{var.$darkThemeClass} {
    border-bottom: none;
    @include utility.theme(('bgColor': var.$dark-100));
}

// ################ FarmCard ################


.MuiPaper-root.farm-card-paper {
    border-bottom: 1px solid;
    border-color: var.$primary-800;
    flex-shrink: 0;
    padding: 36px 28px; 
    
    &:hover {
        cursor: pointer;
    }
}

.MuiSvgIcon-root.farm-card-icon {
    height: 48px;
    width: 48px;
}

.MuiTypography-root.farm-card-name-typo {
    @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, 32px, 100%);
}

.MuiTypography-root.farm-card-count-typo {
    @include utility.typoBase(var.$primary-800, 0.875rem, 500, null, 1.5, 8px, null);
}

.MuiSvgIcon-root.farm-card-room-icon {
    height: 16px;
    margin-right: 4px;
    vertical-align: middle;
    width: 16px;

    path {
        fill: none;
        stroke: var.$primary-800;
        stroke-width: 4;
    }
}

// ################ DARK THEME

.MuiPaper-root.farm-card-paper.#{var.$darkThemeClass} {
    border-color: var.$primary-600;

    @include utility.theme(('bgColor': var.$dark-100));
}

.MuiSvgIcon-root.farm-card-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$primary-600;
    }
}

.MuiTypography-root.farm-card-name-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));
}

.MuiTypography-root.farm-card-count-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));
}

.MuiSvgIcon-root.farm-card-room-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$primary-600;
    }
}


// ################ RoomCard ################


.MuiPaper-root.room-card-paper {
    border-bottom: 1px solid var.$primary-800;
    flex-shrink: 0;
    padding: 36px 28px;

    &:hover {
        cursor: pointer;
    }
}

.MuiSvgIcon-root.room-card-icon {
    height: 48px;
    width: 48px;
        
    & path {
        fill: none;
        stroke: var.$primary-800;
    }
}

.MuiTypography-root.room-card-name-typo {
    @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, 32px, 100%);
}

.MuiTypography-root.room-card-mapping-typo {
    @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, 8px, null);
}

.MuiSvgIcon-root.room-card-mapping-icon {
    height: 24px;
    margin-right: 4px;
    vertical-align: middle;
    width: 24px;
}

// ################ DARK THEME

.MuiPaper-root.room-card-paper.#{var.$darkThemeClass} {
    border-color: var.$primary-600;

    @include utility.theme(('bgColor': var.$dark-100));
}

.MuiSvgIcon-root.room-card-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$primary-600;
    }
}

.MuiTypography-root.room-card-name-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));
}

.MuiTypography-root.room-card-mapping-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));
}

.MuiSvgIcon-root.room-card-mapping-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$primary-600;
    }
}


// ################ FarmRoomDetailCard ################


.MuiPaper-root.farm-room-detail-card-paper {
    border-bottom: 1px solid var.$primary-800;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 36px 28px;

    &:hover {
        cursor: pointer;
    }
}

.MuiBox-root.farm-room-detail-card-upper-info-box {

    &.#{var.$darkThemeClass} {

    }
}

.MuiSvgIcon-root.farm-room-detail-card-icon {
    height: 48px;
    width: 48px;

    path {
        fill: none;
    }
}

.MuiTypography-root.farm-room-detail-card-name-typo {
    @include utility.typoBase(var.$primary-800, 1.5625rem, 500, null, 1.16, 32px, null);
}

.MuiTypography-root.farm-room-detail-card-mapping-typo,
.MuiTypography-root.farm-room-detail-card-size-typo,
.MuiTypography-root.farm-room-detail-card-lights-typo,
.MuiTypography-root.farm-room-detail-card-sensors-typo {
    @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, 4px, null);
}

.MuiBox-root.farm-room-detail-card-lower-info-box {

    &.#{var.$darkThemeClass} {
        
    }
}

.MuiTypography-root.farm-room-detail-card-size-typo {
    // @include utility.typoBase(var.$primary-800, 1rem, 400, null, null, 24px, null);
    margin-top: 24px;
}

.MuiTypography-root.farm-room-detail-card-lights-typo,
.MuiTypography-root.farm-room-detail-card-sensors-typo {
    // @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, 16px, null);
    margin-top: 16px;
}

.MuiSvgIcon-root.farm-room-detail-card-mapping-icon,
.MuiSvgIcon-root.farm-room-detail-card-size-icon,
.MuiSvgIcon-root.farm-room-detail-card-lights-icon,    
.MuiSvgIcon-root.farm-room-detail-card-sensors-icon {
    height: 24px;
    margin-right: 4px;
    vertical-align: middle;
    width: 24px;
}


// ################ DARK THEME


.MuiPaper-root.farm-room-detail-card-paper.#{var.$darkThemeClass} {
    border: 1px solid var.$grey-600;

    @include utility.theme(('bgColor': var.$dark-100));
}

.MuiSvgIcon-root.farm-room-detail-card-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$grey-200;
    }
}

.MuiTypography-root.farm-room-detail-card-name-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiTypography-root.farm-room-detail-card-mapping-typo.#{var.$darkThemeClass},
.MuiTypography-root.farm-room-detail-card-size-typo.#{var.$darkThemeClass},
.MuiTypography-root.farm-room-detail-card-lights-typo.#{var.$darkThemeClass},
.MuiTypography-root.farm-room-detail-card-sensors-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}
    

.MuiSvgIcon-root.farm-room-detail-card-mapping-icon.#{var.$darkThemeClass},
.MuiSvgIcon-root.farm-room-detail-card-size-icon.#{var.$darkThemeClass},
.MuiSvgIcon-root.farm-room-detail-card-lights-icon.#{var.$darkThemeClass}, 
.MuiSvgIcon-root.farm-room-detail-card-sensors-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$grey-400;
    }
}

   
// ################ PlanCard ################


.MuiBox-root.plan-card-box {
    background-color: var.$primary-200;
    border-bottom: 1px solid var.$primary-800;
    height: 275px;
    padding: 36px 28px;

    &:hover {
        cursor: pointer;
    }
}

.MuiBox-root.plan-card-header-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.MuiSvgIcon-root.plan-card-icon {
    height: 48px;
    width: 48px;

    path {
        fill: var.$primary-800;
    }
}

.MuiTypography-root.plan-card-label-typo {
    border: 1px solid var.$primary-800;
    border-radius: 4px;
    padding: 4px 8px;
    text-transform: uppercase;
    
    @include utility.typoBase(var.$primary-800, 0.875rem, 500, null, 1.5, null, null);
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$primary-600));
        border-color: var.$primary-600;
    }

}

.MuiTypography-root.plan-card-title-typo {
    @include utility.typoBase(var.$primary-800, 1.5625rem, 500, null, 1.16, 32px, null);
}

.MuiTypography-root.plan-card-desc-typo {
    max-height: 36%;
    overflow: auto;
    
    @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.5, 24px, null);

}


// ################ DARK THEME


.MuiBox-root.plan-card-box.#{var.$darkThemeClass} {
    border-color: var.$primary-600;

    @include utility.theme(('bgColor': var.$dark-100));
}

.MuiSvgIcon-root.plan-card-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$primary-600;
    }
}

.MuiTypography-root.plan-card-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));
}
    
.MuiTypography-root.plan-card-desc-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));
}


// ################ DetailInfoCard ################


.MuiPaper-root.detail-info-card-paper {
    padding: 25px 28px;
    width: 100%;
}

.MuiPaper-root.detail-info-card-title-paper {
    display: flex;
    justify-content: space-between;
}

.MuiTypography-root.detail-info-card-title-typo {
    @include utility.typoBase(var.$grey-700, 1.5625rem, 700, null, 1.16, null, null);
}

.MuiSvgIcon-root.detail-info-card-edit-icon {
    height: 24px;
    vertical-align: middle;
    width: 24px;
}

.MuiButton-root.detail-info-card-edit-button {
    // flexShrink: 0
    padding: 0;
    text-transform: none;

    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.56, null, null);
}

.MuiTypography-root.detail-info-card-entry-typo {
    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.56, null, null);
}

// .MuiBox-root.detail-info-card-entries-box {
    
// }


// ################ DARK THEME

.MuiPaper-root.detail-info-card-paper.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100));
}

.MuiPaper-root.detail-info-card-title-paper.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': transparent));
}

.MuiTypography-root.detail-info-card-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiSvgIcon-root.detail-info-card-edit-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$primary-600;
    }
}

.MuiButton-root.detail-info-card-edit-button.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));
}

.MuiTypography-root.detail-info-card-entry-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-300));
}


// ################ ExpandCard ################


.MuiAccordion-root.expand-card-accordion {
    // background-color: var.$primary-400;
    // border-bottom: 1px solid var.$primary-700;
    // padding: 24px 20px;
    // // transition: `background-color ${transitionDuration}ms ease-in-out;

    // &::before {
    //     content: none;
    // }
    
    // &.Mui-expanded {
    //     background-color: var.$primary-100;
    //     margin-bottom: 0;
    //     margin-top: 0;
    // }

    // // @include utility.transition(list(background-color var.$baseTransitionTime ease-in-out), false, false);
    // @include utility.transition(background-color, false, false);
    
    @include mixins.accordion;
}

.MuiAccordionSummary-root.expand-card-accordion-summary {
    // min-height: 0;
    // padding-left: 0;
    // padding-right: 0;
    
    // // &.MuiButtonBase-root:hover {
    // &:hover {
    //     cursor: default;
    // }

    // &.Mui-expanded {
    //     min-height: 0;
    // }

    // & .MuiAccordionSummary-content {
    //     flex-direction: column;
    //     margin-bottom: 0;
    //     margin-top: 0;
    // }
    @include mixins.accordion-summary;
}

.MuiBox-root.expand-card-title-box {
    // align-items: center;
    // display: flex;
    // justify-content: space-between;
    // width: 100%;
    @include mixins.accordion-title-box;
}

.MuiTypography-root.expand-card-title-typo {
    // @include utility.typoBase(var.$grey-900, 1.25rem, 500, null, 1.45, null, null);
    @include mixins.accordion-title-typo;

    @include utility.textEllipsis(16ch);
}

.MuiSvgIcon-root.expand-card-title-crop-icon {
    // height: 24px;
    // margin-right: 18px;
    // vertical-align: sub;
    // width: 24px;

    // path {
    //     fill: var.$grey-900;
    // }
    @include mixins.accordion-title-icon;

    margin-right: 8px;
}

.MuiTypography-root.expand-card-title-qp-typo {
    @include utility.typoBase(var.$grey-900, 0.875rem, 400, null, 1.5, null, null);

    @include utility.textEllipsis(20ch);
}

.MuiBox-root.expand-card-action-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    width: 100%;
    
    &.Mui-disabled {
        justify-content: flex-end;
    }
}

.MuiSvgIcon-root.expand-card-action-button-icon {
    height: 20px;
    margin-left: 6px;
    width: 20px;

    path {
        fill: var.$grey-900;
    }
}

.MuiSvgIcon-root.expand-card-see-more-icon {
    height: 24px;
    transform: none;
    width: 24px;
    
    path {
        fill: var.$grey-900;
    }

    &.expand-card-expanded-state {
        transform: rotateZ(180deg);
    }
    
    // transition: transform ${collapseTransition}ms ease-in-out
    // @include utility.transition([[transform, var.$baseCollapseTime, ease-in-out]], false, false); // vscode syntax issues
    @include utility.transition(transform, false, false);
}

.MuiButton-root.expand-card-action-button {
    align-items: center;
    border: none;
    // margin-right: 24px;
    min-width: 0;
    padding: 0;
    text-transform: none;

    &.Mui-disabled .MuiSvgIcon-root.expand-card-action-button-icon path {
        fill: var.$disabled-icon-100;
    }

    @include utility.typoBase(var.$grey-900, 1rem, 500, null, 1.5, null, null);
}

.MuiButton-root.expand-card-action-see-close-button {
    align-items: center;
    border: none;
    min-width: 0;
    padding: 0;
    text-transform: none;

    .MuiButton-endIcon {
        margin-left: 7px;
    }

    @include utility.typoBase(var.$grey-900, 1rem, 500, null, 1.5, null, null);
}

.MuiBox-root.expand-card-detail-box {
    display: flex;
    border-bottom: 1px solid var.$grey-200;
    flex-direction: column;
    margin-top: 24px;
    padding-bottom: 16px;
}

.MuiBox-root.expand-card-detail-entry-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
        
    &:last-of-type {
        margin-bottom: 0
    }
}

.MuiTypography-root.expand-card-detail-entry-key-typo {
    @include utility.typoBase(var.$grey-900, 1rem, 500, null, 1.56, null, null);
}

.MuiTypography-root.expand-card-detail-entry-value-typo {
    @include utility.typoBase(var.$grey-600, 1rem, 400, null, 1.56, null, null);
}


// ################ PlansDetailInfoCard ################


.MuiBox-root.plans-detail-info-card-box {
    background-color: var.$primary-200;
    min-width: fit-content;
    padding: 24px 28px;
    width: 100%;
}

.MuiBox-root.plans-detail-info-card-header-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.MuiTypography-root.plans-detail-info-card-header-typo {
    // whiteSpace: nowrap;
    @include utility.typoBase(var.$grey-700, 1.5625rem, 700, null, 1.16, null, null, true);
}

.MuiSvgIcon-root.plans-detail-info-card-edit-icon {
    height: 24px;
    width: 24px;
}

.MuiButton-root.plans-detail-info-card-edit-button {
    background-color: transparent;
    text-transform: none;
    // whiteSpace: nowrap;
        
    &:hover {
        background-color: transparent;
    }

    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.56, null, null, true);
}

.MuiBox-root.plans-detail-info-card-content-box {
    display: flex;
    flex-direction: column;
}

.MuiBox-root.plans-detail-info-card-entry-box {
    margin-top: 16px;
}

.MuiTypography-root.plans-detail-info-card-entry-key-typo {
    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.56, null, null);
}

.MuiTypography-root.plans-detail-info-card-entry-value-typo {
    @include utility.typoBase(var.$grey-500, 1rem, 500, null, 1.56, null, null, true);
}

.MuiSvgIcon-root.plans-detail-info-card-entry-icon {
    height: 24px;
    margin-right: 10px;
    width: 24px;
    vertical-align: middle;

    // path {
    //     fill: var.$grey-500;
    //     stroke: var.$grey-500;
    // }
}


// ################ DARK THEME


.MuiBox-root.plans-detail-info-card-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100));
}

// .MuiBox-root.plans-detail-info-card-header-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.plans-detail-info-card-header-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiSvgIcon-root.plans-detail-info-card-edit-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$primary-600;
    }
}

.MuiButton-root.plans-detail-info-card-edit-button.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));
}

// .MuiBox-root.plans-detail-info-card-content-box.#{var.$darkThemeClass} {

// }

// .MuiBox-root.plans-detail-info-card-entry-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.plans-detail-info-card-entry-key-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-300));
}

.MuiTypography-root.plans-detail-info-card-entry-value-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

// .MuiSvgIcon-root.plans-detail-info-card-entry-icon.#{var.$darkThemeClass} {
// }
    

// ################ DragCard ################


.MuiBox-root.drag-card-box {
    align-items: center;
    background-color: var.$primary-400;
    border-bottom: 1px solid var.$primary-700;
    display: flex;
    padding: 24px 12px 23px;
    width: 100%;

    &:hover {
        cursor: pointer;
    }

    &.#{var.$darkThemeClass} {
        border-color: var.$primary-900;

        @include utility.theme(('bgColor': var.$grey-400));
    }
}

.MuiSvgIcon-root.drag-card-icon {
    height: 24px;
    width: 24px;

    // path {
    //     fill: none;
    // }

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTypography-root.drag-card-typo {
    margin-left: 16px;

    @include utility.typoBase(var.$grey-900, 1.25rem, 500, null, 1.45, null, null);
    
    // &.#{var.$darkThemeClass} {

    // }
}


// ################ DetailAccordion ################
// TODO: Detail Accordion has same component structure as Expand Card; Only content is different; Adapt ExpandCard and remove DetailAccordion 


.MuiAccordion-root.detail-accordion-accordion {
    // background-color: var.$primary-400;
    // border-bottom: 1px solid var.$primary-700;
    // padding: 24px 28px;

    // &::before {
    //     content: none;
    // }

    // &.Mui-expanded {
    //     background-color: var.$primary-100;
    //     margin-bottom: 0;
    //     margin-top: 0;
    // }

    // &.#{var.$darkThemeClass} {
    //     border-color: var.$grey-400;

    //     @include utility.theme(('bgColor': var.$grey-300));
        
    //     &.Mui-expanded {
    //         @include utility.theme(('bgColor': var.$light-grey-200));
    //     }    
    // }

    @include mixins.accordion;

    padding-bottom: 28px;
    padding-top: 28px;
}

.MuiAccordionSummary-root.detail-accordion-accordion-summary {
      @include mixins.accordion-summary;
}

.MuiBox-root.detail-accordion-title-box {
    @include mixins.accordion-title-box;
}

.MuiTypography-root.detail-accordion-title-typo {
    @include mixins.accordion-title-typo;
}

.MuiSvgIcon-root.detail-accordion-title-crop-icon {
   @include mixins.accordion-title-icon;
}

.MuiTypography-root.detail-accordion-title-qp-typo {
    @include utility.typoBase(var.$grey-900, 0.875rem, 400, null, 1.5, null, null);
}

.MuiBox-root.detail-accordion-title-action-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    width: 100%;
    
    &.Mui-disabled {
        justify-content: flex-end;
    }
}

.MuiSvgIcon-root.detail-accordion-action-button-icon {
    height: 20px;
    margin-left: 6px;
    width: 20px;

    path {
        fill: var.$grey-900;
    }
}

.MuiSvgIcon-root.detail-accordion-see-more-icon {
    height: 24px;
    transform: none;
    width: 24px;
    
    path {
        fill: var.$grey-900;
    }

    &.expanded {
        transform: rotateZ(180deg);
    }
    
    @include utility.transition(transform, false, false);
}

.MuiButton-root.detail-accordion-action-edit-button,
.MuiButton-root.detail-accordion-action-unlink-button {
    align-items: center;
    border: none;
    margin-right: 24px;
    min-width: 0;
    padding: 0;
    text-transform: none;

    &.Mui-disabled .MuiSvgIcon-root.expand-card-action-button-icon path {
        fill: var.$disabled-icon-100;
    }

    @include utility.typoBase(var.$grey-900, 1rem, 500, null, 1.5, null, null);
}

.MuiButton-root.detail-accordion-action-unlink-button {
    margin-right: auto;
}

.MuiButton-root.detail-accordion-action-see-close-button {
    align-items: center;
    border: none;
    min-width: 0;
    padding: 0;
    text-transform: none;

    .MuiButton-endIcon {
        margin-left: 7px;
    }

    @include utility.typoBase(var.$grey-900, 1rem, 500, null, 1.5, null, null);
}

.MuiBox-root.detail-accordion-detail-box {
    display: flex;
    border-bottom: 1px solid var.$grey-200;
    flex-direction: column;
    margin-top: 24px;
    padding-bottom: 16px;
}

.MuiBox-root.detail-accordion-detail-entry-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
        
    &:last-of-type {
        margin-bottom: 0
    }
}

.MuiTypography-root.detail-accordion-detail-entry-key-typo {
    @include utility.typoBase(var.$grey-900, 1rem, 500, null, 1.56, null, null);
}

.MuiTypography-root.detail-accordion-detail-entry-value-typo {
    @include utility.typoBase(var.$grey-600, 1rem, 400, null, 1.56, null, null);
}


// ################ CropRunCard ################


.MuiBox-root.crop-run-card-box {
    background-color: var.$primary-200;
    border-bottom: 1px solid var.$primary-800;
    height: 328px;
    padding: 36px 28px;
        
    &:hover {
        cursor: pointer;
    }

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-100));

        border-color: var.$primary-600;
    }
}

.MuiBox-root.crop-run-card-header-box {
    align-items: center;
    display: flex;
    justify-content: space-between;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTypography-root.crop-run-card-date-typo,
.MuiTypography-root.crop-run-card-status-typo {
    text-transform: uppercase;
    
    @include utility.typoBase(var.$primary-800, 0.875rem, 500, null, 1.5, null, null);
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$primary-600));
    }
}

.MuiTypography-root.crop-run-card-status-typo {
    border: 1px solid var.$primary-800;
    border-radius: 4px;
    padding: 4px 8px;

    &.#{var.$darkThemeClass} {
        border-color: var.$primary-600;
    }
}

.MuiTypography-root.crop-run-card-title-typo {

    @include utility.typoBase(var.$primary-800, 1.5625rem, 500, null, 1.16, 32px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$primary-600));
    }
}

.MuiTypography-root.crop-run-card-desc-typo {
    // These styles will now be provided via otherDescClasses
    // max-height: 2em;
    // overflow: auto;

    // @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, 24px, null);
    @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.5, 24px, null); // reduced line-height to be consistent with other cards

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$primary-600));
    }
}


// ################ InProdInvCard ################


.MuiBox-root.in-production-inv-card-box {
    background-color: var.$light-yellow-100;
    display: flex;
    flex-direction: column;
    padding: 24px 6px;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$grey-400));
    }
}

.MuiTypography-root.in-production-inv-card-value-typo {
    @include utility.typoBase(var.$main-900, 1.875rem, 500, -0.01em, 1.13, null, null);
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-800));
    }
}

.MuiTypography-root.in-production-inv-card-title-typo {
    @include utility.typoBase(var.$main-900, 1.125rem, 500, null, 1.5, 2px, null);
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-800));
    }
}

.MuiBox-root.in-production-inv-card-footer-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    &.#{var.$darkThemeClass} {
        
    }
}

.MuiTypography-root.in-production-inv-card-actuals-typo,
.MuiTypography-root.in-production-inv-card-exp-typo {
    @include utility.typoBase(var.$main-900, 1.125rem, 500, null, 1.5, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-800));
    }
}

.MuiTypography-root.in-production-inv-card-actuals-typo {
    // margin-left: 24px;

    &.#{var.$darkThemeClass} {

    }
}

.MuiSvgIcon-root.in-production-inv-card-icon {
    fill: none;
    height: 24px;
    margin-right: 4px;
    vertical-align: text-bottom;
    width: 24px;

    path {
        stroke: var.$main-900;
    }
    
    &.#{var.$darkThemeClass} {
        path {
            stroke: var.$black-000;
        }
    }

}

// ################ DragAccordionCard ################

.MuiAccordion-root.drag-accordion-card-accordion {
    @include mixins.accordion;
}

.MuiAccordionSummary-root.drag-accordion-card-accordion-summary {
    @include mixins.accordion-summary;
}

.MuiBox-root.drag-accordion-card-box {
    @include mixins.accordion-title-box;
}

.MuiBox-root.drag-accordion-card-title-box {
    align-items: center;
    display: flex;

    &.#{var.$darkThemeClass} {

    }
}

.MuiSvgIcon-root.drag-accordion-card-icon {
    @include mixins.accordion-title-icon;

    path {
        fill: none;
    }
}

.MuiTypography-root.drag-accordion-card-typo {
    @include utility.typoBase(var.$grey-900, 0.875rem, 400, null, 1.5, null, null);

    @include utility.textEllipsis(90%);
}

.MuiButton-root.drag-accordion-card-see-more-button {
    align-items: center;
    border: none;
    min-width: 0;
    padding: 0;
    text-transform: none;

    .MuiButton-endIcon {
        margin-left: 7px;
    }

    @include utility.typoBase(var.$grey-900, 0.875rem, 500, null, 1.5, null, null);

    &.#{var.$darkThemeClass} {

    }
}

.MuiSvgIcon-root.drag-accordion-card-see-more-icon {
    height: 24px;
    transform: none;
    width: 24px;
    
    path {
        fill: var.$grey-900;
    }

    &.drag-accordion-card-expanded {
        transform: rotateZ(180deg);
    }
    
    @include utility.transition(transform, false, false);


    &.#{var.$darkThemeClass} {

    }
}

// ################ Strain Accordion ################
.MuiAccordion-root.strain-accordion-accordion {

    &::before {
        content: none;
    }

    border: 1px solid var.$primary-700;
    padding: 0;
    margin: 8px 0;

    &.#{var.$darkThemeClass} {
        border-color: var.$grey-400;
        color: var.$grey-300;
        @include utility.theme(('bgColor': var.$grey-800))
    }
}

.MuiAccordionSummary-root.strain-accordion-accordion-summary {
    
    & .MuiAccordionSummary-content {
        justify-content: space-between;
    }
}

.MuiSvgIcon-root.strain-accordion-expand-icon.#{var.$darkThemeClass} {
    & path{
        fill: var.$grey-400;
    }
}

.MuiTableHead-root.strain-table-header {
    opacity: 0.8;
    background: linear-gradient(180deg, #6B9219 0%, #223102 100%);
}

.MuiTableCell-root.strain-table-title-cell{
    color: var.$main-000;
}

.MuiTextField-root.strain-textfield {
    @include mixins.textfield-outlined;

    &.#{var.$darkThemeClass} {
        @include mixins.textfield-dark;
    }

    margin-top: 0;
    // width: 50%;
}

// ################ ################ ################ ################ ################



// ################ Card ################


// ################ DARK THEME

