@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 

.MuiBox-root.setup-outline-page-box {
    background-color: var.$primary-100;
    // display: flex;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    
    &.setup-outline-page-hide-image {
        min-height: 100vh;
        grid-template-columns: repeat(1, 1fr);
    }

    @include utility.transition((background-color));

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-700));
    }
}

// .MuiBox-root.setup-outline-page-bg-image-box {
//     // flex-basis: 50%;
    
//     &.#{var.$darkThemeClass} {
        
//     }
// }

img.setup-outline-page-bg-image {
    float: left;
    height: 100vh;
    min-height: 100%; 
    width: 100%;
}

.MuiBox-root.setup-outline-page-content-box {
    // flex-basis: 50%;
    // padding-left: 138px;
    // padding-left: 138px;
    // padding-right: 138px;
    padding-top: 37px;
    
    // &.setup-outline-page-hide-image {
    //     // flex-basis: 100%;
    //     // padding-left: 32px;
    // }

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiBox-root.setup-outline-page-logo-box {

    &.setup-outline-page-allow-add-farm {
        margin-left: auto;
        margin-right: auto;
        width: var.$maxSetupFormWd;
    }

    // &.#{var.$darkThemeClass} {

    // }
}

// .MuiBox-root.setup-outline-page-logo-image-box {

//     // &.#{var.$darkThemeClass} {

//     // }
// }

.MuiTypography-root.setup-outline-page-brand-typo {
    @include utility.typoBase(var.$grey-900, 0.875rem, 700, null, 1.29, 8px, null);
    
    &:hover {
        cursor: pointer;
    }

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$primary-600));
    }
}
