@use 'var';
@use 'utility';
@use 'mixins';

// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~ 

.MuiBox-root.base-new-and-detail-box {
    @include mixins.main-content-wrapper;

    @include utility.transition((background-color));
}

.MuiBox-root.base-new-and-detail-header-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.MuiBox-root.base-new-and-detail-title-box {
    background-color: transparent;
    display: flex;
    flex-direction: column;
}

.MuiTypography-root.base-new-and-detail-title-typo {
    margin-bottom: 16px;

    @include utility.typoBase(var.$grey-700, 2.25rem, 700, -0.01em, 1.08, null, null, true);
}

.MuiSvgIcon-root.base-new-and-detail-breadcrumb-sep-icon {
    height: 16px;
    width: 16px;
}

.MuiBreadcrumbs-root.base-new-and-detail-breadcrumbs {
    line-height: 18px;
        
    .MuiBreadcrumbs-separator {
        margin-left: 4px;
        margin-right: 4px;
    }
}

.MuiTypography-root.base-new-and-detail-breadcrumb-link-typo {

    &:hover {
        cursor: default;
    }

    @include utility.typoBase(var.$grey-600, 0.875rem, 400, -0.05em, 1.13, null, null, true);
}

.MuiTab-root.base-new-and-detail-tab {
    
    text-transform: none;
        
    &.Mui-selected {
        color: var.$grey-700;
        font-weight: 500;
    }

    @include utility.typoBase(var.$grey-500, 1rem, 400, null, 1.56, null, null);
}

.MuiCircularProgress-root.base-new-and-detail-circular-progress {
    color: var.$grey-500;
}

.MuiButton-root.base-new-and-detail-save-button {
    background-color: var.$primary-700;
    border-radius: 0;
    box-shadow: none;
    padding-bottom: 16px;
    padding-top: 16px;
    text-transform: none;
    
    &:hover {
        background-color: var.$primary-700;
    }

    @include utility.typoBase(var.$primary-100, 1rem, 500, -0.05em, 1.25, null, var.$maxBtnWd);
}

.MuiTabs-root.base-new-and-detail-tabs {

    .MuiTabs-indicator {
        background-color: var.$grey-700;
    }
}

.MuiDivider-root.base-new-and-detail-divider {
    background-color: var.$grey-400;
}


// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.base-new-and-detail-box.#{var.$darkThemeClass} {
    @include mixins.main-content-wrapper-dark;
}

// .MuiBox-root.base-new-and-detail-header-box.#{var.$darkThemeClass} {

// }

// .MuiBox-root.base-new-and-detail-title-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.base-new-and-detail-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiSvgIcon-root.base-new-and-detail-breadcrumb-sep-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$grey-400;
    }
}

.MuiBreadcrumbs-root.base-new-and-detail-breadcrumbs.#{var.$darkThemeClass} {
    li span {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiTypography-root.base-new-and-detail-breadcrumb-link-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiTab-root.base-new-and-detail-tab.#{var.$darkThemeClass} {
    &.Mui-selected {
        color: var.$grey-200;
    }
}

.MuiButton-root.base-new-and-detail-save-button.#{var.$darkThemeClass} {
    @include mixins.save-button-contained-dark;
}

.MuiTabs-root.base-new-and-detail-tabs.#{var.$darkThemeClass} {
    & .MuiTabs-indicator {
        background-color: var.$grey-200;
    }
}

.MuiDivider-root.base-new-and-detail-divider.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$grey-500));
}
    