@use 'var';
@use 'utility';
@use 'mixins';

// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~

.MuiBox-root.command-center-root-container {
  @include mixins.main-content-wrapper;

  @include utility.transition((background-color));

  padding: 0;
}

.MuiBox-root.command-center-header-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.MuiBox-root.command-center-title-box {
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.MuiTypography-root.command-center-title-typo {
  margin-bottom: 16px;

  @include utility.typoBase(var.$grey-700, 2.25rem, 700, -0.01em, 1.08, null, null, true);
}

.MuiButton-root.command-center-save-button {
  background-color: var.$primary-700;
  border-radius: 0;
  box-shadow: none;
  padding-bottom: 16px;
  padding-top: 16px;
  text-transform: none;

  &:hover {
    background-color: var.$primary-700;
  }

  @include utility.typoBase(var.$primary-100, 1rem, 500, -0.05em, 1.25, null, var.$maxBtnWd);
}

.MuiCircularProgress-root.command-center-circular-progress {
  color: var.$grey-500;
}

.MuiSvgIcon-root.command-center-icon {
  height: 24px;
  width: 24px;
  fill: none;
}

.MuiPaper-root.command-center-modal-container {
  background-color: var.$primary-100;
  max-height: 100vh;
  overflow: auto;
  position: relative;
  width: 100%;
  max-width: none
}

// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~

.MuiBox-root.command-center-root-container.#{var.$darkThemeClass} {
  @include mixins.main-content-wrapper-dark;
}

.MuiTypography-root.command-center-title-typo.#{var.$darkThemeClass} {
  @include utility.theme(
    (
      'color': var.$grey-200,
    )
  );
}

.MuiButton-root.command-center-save-button.#{var.$darkThemeClass} {
  @include mixins.save-button-contained-dark;
}

.Mui-selected .command-center-icon {
  path {
    stroke: var.$primary-600;
  }
}

.MuiPaper-root.command-center-modal-container.#{var.$darkThemeClass} {
  @include utility.theme(('bgColor': var.$dark-900));
}