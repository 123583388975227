@use 'var';
@use 'utility';
@use 'mixins';

// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~ 

// .MuiBox-root.phase-plan-overview-wrapper-box {

// }

.MuiBox-root.phase-plan-bar-box {
    display: flex;
    margin-top: 20px;
    width: 100%;
}

.MuiBox-root.phase-plan-bar-entry-box {
    align-items: center;
    background-color: var.$primary-200;
    border: 1px solid var.$grey-200;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    height: 36px;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
}

.MuiTypography-root.phase-plan-bar-day-typo {
    font-size: 0.875rem;
}

.MuiTypography-root.phase-plan-bar-day-typo,
.MuiTypography-root.phase-plan-bar-type-typo {
    color: var.$grey-900;
}

.MuiBox-root.phase-plan-overview-box {
    // display: grid;
    // gap: 16px;
    // grid-auto-rows: auto;
    // grid-template-areas: "a c"
    //                      "b c";
    // grid-template-columns: repeat(2, 1fr);
    // margin-top: 36px;
    // max-width: var.$maxTableWd;
    // width: 100%;
    @include mixins.overview-cards-grid;
}

// Not using below since switched to grid
// .MuiBox-root.phase-plan-overview-left-box {
//     display: flex;
//     flex-direction: column;
//     flex-basis: 50%;
//     flex-grow: 1;
//     margin-right: 16px;
// }

// .MuiBox-root.phase-plan-overview-left-inner-box {
//     flex-basis: 50%;
//     flex-grow: 1;
// }

// .MuiBox-root.phase-plan-overview-right-box {
//     flex-basis: 50%;
//     flex-grow: 1;
// }

.MuiTypography-root.phase-plan-overview-info-typo {
    @include utility.typoBase(var.$grey-500, 0.875rem, 500, null, 1.56, 4px, null, true);
}

.MuiSvgIcon-root.phase-plan-overview-info-icon {
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
    width: 20px;
        
    path {
        fill: var.$primary-800;
    }
}

.MuiTextField-root.phase-plan-textfield {
    width: var.$maxTextFieldWd;

    @include mixins.textfield;
}

.MuiBox-root.phase-plan-gen-info-box {
    display: flex;
    flex-direction: column;
    margin-top: 56px;
    width: 100%;
}

.MuiTypography-root.phase-plan-gen-info-title-typo {
    @include utility.typoBase(var.$grey-900, 1.25rem, 500, null, 1.45, null, null);
}

.MuiBox-root.phase-plan-sub-phase-box {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    // padding-bottom: 60px;
    width: 100%;
}

.MuiBox-root.phase-plan-sub-phase-title-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
}

.MuiSvgIcon-root.phase-plan-add-sub-phase-icon {
    &.#{var.$darkThemeClass} {
        path {
            fill: var.$primary-900;
        }
    }
}

.MuiButton-root.phase-plan-add-sub-phase-button {
    @include mixins.save-button-contained-1;
}

// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~ 

// .MuiBox-root.phase-plan-bar-box.#{var.$darkThemeClass} {
// }

.MuiBox-root.phase-plan-bar-entry-box.#{var.$darkThemeClass} {
    border-color: var.$grey-400;
    @include utility.theme(('bgColor': var.$dark-100));
}

.MuiTypography-root.phase-plan-bar-type-typo.#{var.$darkThemeClass},
.MuiTypography-root.phase-plan-bar-day-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-300));
}

// .MuiBox-root.phase-plan-overview-box.#{var.$darkThemeClass} {

// }

// .MuiBox-root.phase-plan-overview-left-box.#{var.$darkThemeClass} {

// }

// .MuiBox-root.phase-plan-overview-left-inner-box.#{var.$darkThemeClass} {

// }

// .MuiBox-root.phase-plan-overview-right-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.phase-plan-overview-info-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiSvgIcon-root.phase-plan-overview-info-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$primary-600;
    }
}

.MuiTextField-root.phase-plan-textfield.#{var.$darkThemeClass} {
    @include mixins.textfield-dark;
}

// .MuiBox-root.phase-plan-gen-info-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.phase-plan-gen-info-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

// .MuiBox-root.phase-plan-sub-phase-box.#{var.$darkThemeClass} {

// }
