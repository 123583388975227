@use 'var';
@use 'utility';
@use 'mixins';

.MuiTimeline-root.entity-detail-timeline {
    padding: 0;
    padding-left: 10px;
}

.MuiTimelineItem-root.entity-detail-timeline-item {
    &:before {
        content: none;
    }
}

.MuiTimelineSeparator-root.entity-detail-timeline-separator{
   
}

.MuiTimelineDot-root.entity-detail-timeline-dot {
    position: relative;

    &:before {
        width: calc(100% + 170%);
        height: calc(100% + 170%);
        content: '';
        left: -85%;
        top: -85%;
        position: absolute;
        border: 1px dashed var.$grey-400;
        border-radius: 50%;
    }

    &.entity-detail-level-completed-timeline-dot{
        background-color: var.$primary-600;

        &:before {
            width: calc(100% + 120%);
            height: calc(100% + 120%);
            content: '';
            left: -60%;
            top: -60%;
            position: absolute;
            border: 1px solid var.$primary-600;
            border-radius: 50%;
        }
    }
}

.MuiTimelineDot-root.entity-detail-default-expanded-timeline-dot {
    background-color: var.$grey-200;
    position: relative;

    &:before{
        width: calc(100% + 250%);
        height: calc(100% + 250%);
        content: '';
        left: -125%;
        top: -125%;
        position: absolute;
        border: 1px dashed var.$grey-200;
        border-radius: 50%;
    }
}

.MuiTimelineConnector-root.entity-detail-timeline-connector {

    background: none;
    width: 1px;
    border: 1px dashed var.$grey-500;

    &.entity-detail-level-completed-timeline-connector{
        background-color: var.$primary-600;
        border: none;
    }
}

.MuiAccordionSummary-root.entity-detail-timeline-accordion-summary {
    background-color: var.$primary-100;
    padding: 0 16px;
    &.#{var.$darkThemeClass} {
        background-color: var.$grey-700;
        color: var.$grey-300;
    }
}

.MuiAccordionDetails-root.entity-detail-timeline-accordion-detail {
    &.#{var.$darkThemeClass} {
        background-color: var.$grey-900;
        color: var.$grey-200;
    }
}