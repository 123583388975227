@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 

.MuiBox-root.login-page-box {
    margin: 108px auto 0;
    width: var.$maxSetupFormWd;

    &.#{var.$darkThemeClass} {

    }
}

.MuiTypography-root.login-page-info-typo {    
    @include utility.typoBase(var.$grey-900, 1rem, 400, null, 1.56, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiTypography-root.login-page-action-typo {
    @include utility.typoBase(var.$grey-900, 1.875rem, 700, -0.01em, 1.13, 12px, null);
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiTextField-root.login-page-textfield {
    @include mixins.textfield-outlined;
    
    &.#{var.$darkThemeClass} {

    }
}

.MuiButton-root.login-page-proceed-button {
    @include mixins.save-button-contained-2;

    margin-bottom: 20px;
    width: 100%;
}

.MuiButton-root.specific-popup-proceed-button {
    @include mixins.save-button-contained-2;

    margin-bottom: 20px;
    width: 100%;
}
