@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.profile-box {
    @include mixins.content-wrapper;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTypography-root.profile-welcome-typo {
    @include utility.typoBase(var.$grey-700, 2.25rem, 700, -0.01em, 1.08, 12px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiBox-root.profile-form-box {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.profile-form-content-box {
    // display: flex;
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 16px;

    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.profile-input-section-box {
    // flex-basis: 70%;
    // flex-shrink: 0;
    // min-width: var.$maxTextFieldWd;
    // max-width: var.$maxTextFieldWd;
    // margin-right: 16px;

    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.profile-section-box {
    display: flex;

    &.#{var.$darkThemeClass} {

    }
}

.MuiTextField-root.profile-textfield {
    @include mixins.textfield-outlined;


    &.#{var.$darkThemeClass} {
        input.Mui-disabled {
            -webkit-text-fill-color: var.$grey-500;
        }
    }
}

.MuiBox-root.profile-photo-section-box {
    align-items: center;
    display: flex;
    justify-content: center;
    // flex-basis: 30%;
    // flex-grow: 0;
    min-width: 244px;
    max-width: 244px;
    margin-left: auto;

    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.profile-photo-box {
    background-color: var.$grey-200;
    border-radius: 50%;
    height: 244px;
    width: 100%;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-100));
    }
}

.MuiBox-root.profile-content-footer-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 48px;

    &.#{var.$darkThemeClass} {

    }
}

.MuiTypography-root.profile-role-typo {    
    @include utility.typoBase(null, 1.125rem, 500, null, 1.5, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiButton-root.profile-save-button {
    @include mixins.save-button-contained-1;
}
