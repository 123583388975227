@use 'var';
@use 'utility';
@use 'mixins';


// ################ DownloadingMetrcDataMsg ################

.MuiBox-root.download-metrc-data-msg-box {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: var.$maxSetupFormWd;
    padding-top: 72px;
    width: 100%;
}

.MuiBox-root.download-metrc-data-msg-download-box {
    height: 48px;
    padding: 4px;
    width: 48px;
}

.MuiTypography-root.download-metrc-data-msg-title-typo {
    @include utility.typoBase(var.$grey-900, 1.875rem, 700, -0.01em, 1.13, 36px, null);
}

.MuiTypography-root.download-metrc-data-msg-time-typo {
    @include utility.typoBase(var.$grey-900, 1rem, 400, null, 1.56, 12px, null);
}


// ################ DARK THEME

.MuiBox-root.download-metrc-data-msg-download-box.#{var.$darkThemeClass} {

    & svg.MuiCircularProgress-svg circle {
        stroke: var.$grey-500;
    }
}

.MuiTypography-root.download-metrc-data-msg-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-300))
}

.MuiTypography-root.download-metrc-data-msg-time-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400))
}


// ################ TaskStatusSnackbar ################

.MuiButton-root.task-status-snackbar-action-button {

    &.#{var.$darkThemeClass} {

    }
}

.MuiSnackbar-root.task-status-snackbar-snackbar {

    &.#{var.$darkThemeClass} {

    }
}

.MuiAlert-root.task-status-snackbar-alert {

    &.#{var.$darkThemeClass} {
        
    }
}