@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.org-setup-outline-box {
    background-color: var.$primary-100;
    display: flex;
    min-height: 100vh;
    width: 100%;
    
    @include utility.transition((background-color));
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-700));
    }
    
}

.MuiBox-root.org-setup-outline-content-box {
    flex-basis: 100%;
    padding-left: 32px;
    padding-top: 37px;
    
    &.#{var.$darkThemeClass} {

    }
}

// .MuiBox-root.org-setup-outline-logo-box {

//     &.#{var.$darkThemeClass} {

//     }
// }

// .MuiBox-root.org-setup-outline-logo-image-box {

//     &.#{var.$darkThemeClass} {

//     }
// }

// .MuiBox-root.org-setup-outline-logo-image {

//     &.#{var.$darkThemeClass} {

//     }
// }

.MuiTypography-root.org-setup-outline-brand-typo {
    text-transform: uppercase;
    
    @include utility.typoBase(var.$grey-900, 0.875rem, 700, null, 1.29, 8px, null);
    
    &:hover {
        cursor: pointer;
    }

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$primary-600));
    }
}
