@use 'var';
@use 'utility';
@use 'mixins';


// ################ AccordionDetailMsg ################


.MuiBox-root.accordion-detail-msg-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    width: 100%;

    &.#{var.$darkThemeClass} {

    }
}

.MuiTypography-root.accordion-detail-msg-title-typo {
    @include utility.typoBase(var.$grey-900, 0.875rem, 400, null, 1.56, null, null);

    @include utility.textEllipsis(70%);
    
    &.#{var.$darkThemeClass} {
        
    }
}

.MuiTypography-root.accordion-detail-msg-value-typo {
    @include utility.typoBase(var.$grey-600, 0.875rem, 400, null, 1.56, null, null);
    
    @include utility.textEllipsis(20%); // Should add upto 100% with .MuiTypography-root.accordion-detail-msg-title-typo
    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.drag-accordion-card-content-box {
    margin-top: 16px;

    div:first-of-type { // Removing margin-top from first child
        margin-top: 0;
    }
}


// ################ RowHeaderTypo ################

.MuiTypography-root.row-header-typo-msg-typo {
    @include utility.textEllipsis(12ch);
}