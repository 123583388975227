@use 'var';
@use 'utility';
@use 'mixins';


// ################ EntityDetailPopup ################

.MuiModal-root.entity-detail-popup-modal {
    align-items: center;
    display: flex;
    justify-content: center;
    outline: 0;
    
    & .MuiBackdrop-root {
        background-color: var.$grey-700;
    }
}

.MuiPaper-root.entity-detail-popup-paper {
    background-color: var.$primary-100;
    max-height: 100vh;
    overflow: auto;
    padding: 64px 72px;
    position: relative;
}

.MuiBox-root.entity-detail-popup-close-icon-box {
    margin-left: auto;
    padding-top: 16px;
    position: absolute;
    top: 0;
    right: 72px;
    width: fit-content;
}

.MuiSvgIcon-root.entity-detail-popup-close-icon {
    &:hover {
        cursor: pointer;
    }

    &.#{var.$darkThemeClass} {
        path {
            fill: var.$grey-500;
        }
    }
}


.MuiPaper-root.entity-detail-popup-header-paper {
    background-color: transparent;
}

.MuiTypography-root.entity-detail-popup-title-typo {
    margin-bottom: 8px;

    @include utility.typoBase(var.$grey-900, 1.5625rem, 500, null, 1.16, 12px, null);
}

.MuiPaper-root.entity-detail-popup-head-content-paper {
    align-items: flex-start;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
}

.MuiTypography-root.entity-detail-popup-head-content-typo {
    flex-grow: 1;
    margin-right: 8px;
    
    @include utility.typoBase(var.$grey-600, 1rem, 400, null, 1.56, null, null);
}

.MuiButton-root.entity-detail-popup-save-button {
    background-color: var.$primary-200;
    border-radius: 0;
    min-width: 190px;
    padding: 12px;
    text-transform: none;

    &:hover {
        background-color: var.$primary-200;
    }
    
    @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, null, null);
}


// ################ DARK THEME

.MuiModal-root.entity-detail-popup-modal.#{var.$darkThemeClass} {
    & .MuiBackdrop-root {
        @include utility.theme(('bgColor': var.$dark-100));
    }
}

.MuiPaper-root.entity-detail-popup-paper.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-900));
}

// .MuiPaper-root.entity-detail-popup-header-paper.#{var.$darkThemeClass} {

// }

.MuiTypography-root.entity-detail-popup-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

// .MuiPaper-root.entity-detail-popup-head-content-paper.#{var.$darkThemeClass} {

// }

.MuiTypography-root.entity-detail-popup-head-content-typo.#{var.$darkThemeClass} {
    @include utility.theme(('olor': var.$grey-400));
}

.MuiButton-root.entity-detail-popup-save-button.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$primary-600, 'color': var.$primary-900));
    
    &.Mui-disabled {
        @include utility.theme(('bgColor': var.$grey-800, 'color': var.$grey-600));
    }
}


// ################ RoomTablePopup ################

.MuiTypography-root.roomtable-popup-level-typo,
.MuiTypography-root.roomtable-popup-desc-typo,
.MuiTypography-root.roomtable-popup-name-typo,
.MuiTypography-root.sensor-popup-kpi-typo {
    @include utility.typoBase(var.$grey-800, 1.25rem, 500, null, 1.45, null, null);
}

// .MuiTypography-root.roomtable-popup-desc-typo {
//     @include utility.typoBase(var.$grey-800, 1.25rem, 500, null, 1.45, 32px, null);
// }

.MuiDivider-root.roomtable-popup-divider,
.MuiDivider-root.sensor-popup-divider {
    background-color: var.$grey-900;
    margin-top: 32px;
}

.MuiBox-root.roomtable-popup-level-entry-box {
    margin-top: 16px;
    
    &:first-of-type {
        margin-top: 0px;
    }
}

.MuiSvgIcon-root.roomtable-popup-level-entry-cancel-icon {
    display: block;
    margin-left: auto;

    &:hover {
        cursor: pointer;
    }
}

.MuiTextField-root.roomtable-popup-textfield {
    // color: var.$grey-800;
    // margin-top: 16px;
    
    // & input {
    //     padding: 16px 15px;
    // }

    // & .MuiInputLabel-root {
    //     color: var.$grey-800;
    // }

    // & .MuiOutlinedInput-root {
    //     border-radius: 0;

    //     fieldset.MuiOutlinedInput-notchedOutline {
    //         border-color: var.$grey-800;
    //     }
    // }
    @include mixins.textfield;
}

.MuiButton-root.roomtable-popup-add-level-button {
    align-items: center;
    background-color: var.$primary-800;
    border-radius: 0;
    flex-direction: column;
    justify-content: center;
    margin-top: 32px;
    padding: 40px 20px;
    text-transform: none;
    
    &:hover {
        background-color: var.$primary-800;
    }
}

.MuiTextField-root.roomtable-popup-dimension-textfield {
    width: calc(50% - 8px);

    &:nth-of-type(even) {
        margin-right: 8px;
    }
    &:nth-of-type(odd) {
        margin-left: 8px;
    }
}

.MuiBox-root.roomtable-popup-levels-box {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.MuiSvgIcon-root.roomtable-popup-add-level-button-icon {
    path {
        fill: none;
    }
}

// ################ DARK THEME

.MuiTypography-root.roomtable-popup-level-typo.#{var.$darkThemeClass},
.MuiTypography-root.roomtable-popup-desc-typo.#{var.$darkThemeClass},
.MuiTypography-root.roomtable-popup-name-typo.#{var.$darkThemeClass},
.MuiTypography-root.sensor-popup-kpi-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiDivider-root.roomtable-popup-divider.#{var.$darkThemeClass},
.MuiDivider-root.sensor-popup-divider.#{var.$darkThemeClass} {
    background-color: var.$grey-700;
}

.MuiSvgIcon-root.roomtable-popup-level-entry-cancel-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$grey-500;
    }
}

.MuiTextField-root.roomtable-popup-textfield.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$grey-800));
        
    & .MuiInputLabel-root,
    & .MuiInputBase-root {
        @include utility.theme(('color': var.$grey-400));
    }

    & fieldset {
        border-color: var.$grey-500;
    }

    &:has(textarea) {
        @include utility.theme(('bgColor': var.$dark-100));
    }

    // Not needed because of :has selector
    // & textarea {
    //     @include utility.theme(('bgColor': var.$dark-100));
    // }
    
}


.MuiButton-root.roomtable-popup-add-level-button.#{var.$darkThemeClass} {
    background-color: var.$dark-100;
    color: var.$primary-600;
    
    border: 1px solid var.$primary-600;
    
    &:hover {
        background-color: var.$dark-100;
    }
}

// .MuiTextField-root.roomtable-popup-dimension-textfield.#{var.$darkThemeClass} {

// }

// .MuiBox-root.roomtable-popup-levels-box.#{var.$darkThemeClass} {

// }

.MuiSvgIcon-root.roomtable-popup-add-level-button-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$primary-600;
    }
}


// ################ AttachTablePopup ################


.MuiDivider-root.attachtable-popup-divider {
    margin-top: 32px;
}

.MuiBox-root.attachtable-popup-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    width: 100%;
}

.MuiTypography-root.attachtable-popup-notable-typo {
    text-align: center;
    
    @include utility.typoBase(var.$grey-400, 0.875rem, 500, null, 1.5, null, 288px);
}

.MuiButton-root.attachtable-popup-createtable-button {
    background-color: transparent;
    border: 1px solid var.$grey-400;
    border-radius: 0;
    padding: 10px 40px;
    text-transform: none;

    @include utility.typoBase(var.$grey-400, 0.875rem, 500, null, 1.5, 20px, fit-content);

    &:hover {
        background-color: transparent;
    }
}

.MuiBox-root.attachtable-popup-actiontitle-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.MuiButton-root.attachtable-popup-addnewtable-button {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    text-transform: none;

    @include utility.typoBase(var.$primary-600, 1rem, 500, null, 1.56);

    &:hover {
        background-color: transparent;
    }
}

.MuiTextField-root.attachtable-popup-textfield {
    border-radius: 0;
    margin-top: 12px;
}

.MuiTypography-root.attachtable-popup-tablename-typo,
.MuiTypography-root.attachtable-popup-levelcount-typo {
    margin-left: auto;
}

// ################ SensorPopup ################

.MuiTextField-root.sensor-popup-textfield {
    // color: var.$grey-800;
    // margin-top: 20px;

    // & .MuiInputBase-root {
    //     border-radius: 0;
    // }

    // & .MuiInputBase-input {
    //     padding: 16px 15px;
    // }
    
    // & textarea.MuiInputBase-input {
    //     padding: 0;
    // }

    // & .MuiInputLabel-root {
    //     color: var.$grey-800;
    //     font-size: 16px;
    //     line-height: 1.56;
    // }

    // & fieldset {
    //     border-color: var.$grey-800;
    // }
    @include mixins.textfield;
}

// ################ DARK THEME

.MuiTextField-root.sensor-popup-textfield.#{var.$darkThemeClass} {
    // @include utility.theme(('bgColor': var.$dark-100));
        
    // & .MuiInputLabel-root,
    // & .MuiInputBase-root {
    //     @include utility.theme(('color': var.$grey-400));
    // }

    // & fieldset {
    //     border-color: var.$grey-700;
    // }
    @include mixins.textfield-dark;
}

.MuiMenuItem-root.sensor-popup-menuitem.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$grey-500, 'color': var.$grey-200));
    
    &:hover {
        background-color: var.$grey-500;
    }
    
    &.Mui-selected {
        @include utility.theme(('bgColor': var.$grey-500, 'color': var.$grey-200));        
    }

    &.sensor-popup-menuitem-focus {
        background-color: var.$grey-600;
    }
    
    &.Mui-disabled {
        background-color: var.$grey-900;
        color: var.$grey-400;
    }
}

// ################ LightEntryPopup ################

.MuiTextField-root.light-entry-popup-textfield {
    // color: var.$grey-600;
    // margin-top: 20px;

    // & .MuiInputBase-root {
    //     border-radius: 0;
    // }

    // & .MuiInputBase-input {
    //     padding: 16px 15px;
    // }
    
    // & textarea.MuiInputBase-input {
    //     padding: 0;
    // }

    // & .MuiInputLabel-root {
    //     color: var.$grey-600;
    //     font-size: 16px;
    //     line-height: 1.56;
    // }

    // & fieldset {
    //     border-color: var.$grey-600;
    // }
    @include mixins.textfield;

}

// ################ DARK THEME

.MuiTextField-root.light-entry-popup-textfield.#{var.$darkThemeClass} {
    // @include utility.theme(('bgColor': var.$dark-100));
        
    // & .MuiInputLabel-root,
    // & .MuiInputBase-root {
    //     @include utility.theme(('color': var.$grey-400));
    // }

    // & fieldset {
    //     border-color: var.$grey-700;
    // }

    @include mixins.textfield-dark;

}

// ################ AddLightPopup ################

.MuiTextField-root.add-light-popup-textfield {
    // color: var.$grey-600;
    // margin-top: 20px;

    // & .MuiInputBase-root {
    //     border-radius: 0;
    // }

    // & .MuiInputBase-input {
    //     padding: 16px 15px;
    // }
    
    // & textarea.MuiInputBase-input {
    //     padding: 0;
    // }

    // & .MuiInputLabel-root {
    //     color: var.$grey-600;
    //     font-size: 16px;
    //     line-height: 1.56;
    // }

    // & fieldset {
    //     border-color: var.$grey-600;
    // }
    @include mixins.textfield;
}

// ################ DARK THEME

.MuiTextField-root.add-light-popup-textfield.#{var.$darkThemeClass} {
    // @include utility.theme(('bgColor': var.$dark-100));
        
    // & .MuiInputLabel-root,
    // & .MuiInputBase-root {
    //     @include utility.theme(('color': var.$grey-400));
    // }

    // & fieldset {
    //     border-color: var.$grey-700;
    // }
    @include mixins.textfield-dark;

}

// ################ InviteUserPopup ################

// .MuiBox-root.invite-user-box {

// }

// .MuiSvgIcon-root.invite-user-replay-icon {

// }

.MuiTextField-root.invite-user-textfield {
    @include mixins.textfield;
}

// .MuiMenuItem-root.invite-user-role-menuitem {

// }

// .MuiMenuItem-root.invite-user-perm-menuitem {

// }


// ################ DARK THEME

.MuiTextField-root.invite-user-textfield.#{var.$darkThemeClass} {
    // @include utility.theme(('bgColor': var.$dark-100));
    
    // & .MuiInputLabel-root,
    // & .MuiInputBase-root {
    //     @include utility.theme(('color': var.$grey-400));
    // }
    
    // & fieldset {
    //     border-color: var.$grey-700;
    // }
    @include mixins.textfield-dark;

}

.MuiMenuItem-root.invite-user-role-menuitem.#{var.$darkThemeClass},
.MuiMenuItem-root.invite-user-perm-menuitem.#{var.$darkThemeClass} {
    @include mixins.menuitem-dark;
}


// ################ CreatePermissionsPopup ################

.MuiTextField-root.create-permission-textfield {
    @include mixins.textfield;
}

.MuiBox-root.create-permission-roles-list-box {
    border-bottom: 1px solid var.$primary-300;
    height: 360px;
    min-width: fit-content;
    margin-top: 24px;
    overflow: auto;
}

.MuiBox-root.create-permission-role-header-box {
        background-color: var.$primary-300;
        display: flex;
        left: 0;
        min-width: fit-content;
        position: sticky;
        top: 0;
        z-index: 1;
}

.MuiTypography-root.create-permission-role-header-typo {
    color: var.$primary-800;
    // flexBasis, ? - Apply flexBasis via classes
    padding-bottom: 8px;
    padding-top: 8px;
    text-align: center;
}

.MuiBox-root.create-permission-role-content-box {
    display: flex;
    flex-direction: column;
}

.MuiBox-root.create-permission-entry-box {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    text-align: center;
}

.MuiTypography-root.create-permission-slno-typo {
    flex-basis: 10%;
}

.MuiTypography-root.create-permission-name-typo {
    flex-basis: 15%;
}

.MuiCheckbox-root.create-permission-checkbox {
    padding: 16px 15px;
}

// ################ DARK THEME

.MuiTextField-root.create-permission-textfield.#{var.$darkThemeClass} {
    @include mixins.textfield-dark;
}

.MuiBox-root.create-permission-roles-list-box.#{var.$darkThemeClass} {
    border-color: var.$grey-700;
}

.MuiBox-root.create-permission-role-header-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-300));
}

.MuiTypography-root.create-permission-role-header-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-500));

}

.MuiBox-root.create-permission-role-content-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100));
}

// .MuiBox-root.create-permission-entry-box.#{var.$darkThemeClass} {
//     @include utility.theme(('bgColor': var.$dark-100));
// }

.MuiTypography-root.create-permission-slno-typo.#{var.$darkThemeClass},
.MuiTypography-root.create-permission-name-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-300));

}

.MuiCheckbox-root.create-permission-checkbox.#{var.$darkThemeClass} {
    
    .MuiSvgIcon-root path {
        fill: var.$grey-400;
    }
}
    
// ################ ConfirmationPopupWithLoading ################

// .MuiModal-root.confirmation-loading-popup-modal {
// }

.MuiBox-root.confirmation-loading-popup-wrapper-box {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

.MuiBox-root.confirmation-loading-popup-content-box {
    background-color: var.$primary-100;
    padding: 60px 72px;
    width: 100%;
}

// .MuiBox-root.confirmation-loading-popup-title-box {

// }

.MuiTypography-root.confirmation-loading-popup-title-typo {
    @include utility.typoBase(var.$primary-800, 1.5rem, 500, null, 1.56, null, null);
}

// .MuiBox-root.confirmation-loading-popup-message-box {

// }

.MuiTypography-root.confirmation-loading-popup-message-typo {
    color: var.$primary-900;
    margin-top: 16px;
}

.MuiBox-root.confirmation-loading-popup-action-buttons-box {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
}

.MuiButton-root.confirmation-loading-popup-decline-button {
    background-color: var.$grey-400;
    border-radius: 0;
    padding: 12px;
    text-transform: none;

    &:hover {
        background-color: var.$grey-400;
    }
    
    @include utility.typoBase(var.$grey-200, 1rem, 500, -0.05em, 1.25, null, var.$maxBtnWd);
}

.MuiButton-root.confirmation-loading-popup-proceed-button {
    background-color: var.$primary-700;
    border-radius: 0;
    margin-left: 16px;
    padding: 12px;
    text-transform: none;

    &:hover {
        background-color: var.$primary-700;
    }

    @include utility.typoBase(var.$primary-100, 1rem, 500, -0.05em, 1.25, null, var.$maxBtnWd);
}

// ################ DARK THEME

// .MuiBox-root.confirmation-loading-popup-wrapper-box.#{var.$darkThemeClass} {
// }

.MuiBox-root.confirmation-loading-popup-content-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100));
}

.MuiTypography-root.confirmation-loading-popup-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiTypography-root.confirmation-loading-popup-message-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

// .MuiBox-root.confirmation-loading-popup-action-buttons-box.#{var.$darkThemeClass} {

// }

.MuiButton-root.confirmation-loading-popup-decline-button.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$grey-700, 'color': var.$grey-200));
}

.MuiButton-root.confirmation-loading-popup-proceed-button.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$grey-400, 'color': var.$grey-700));
}


// ################ CreateStrainPopup ################

.MuiTextField-root.create-strain-popup-textfield {
    @include mixins.textfield;
}

// ################ DARK THEME

.MuiTextField-root.create-strain-popup-textfield.#{var.$darkThemeClass} {
    @include mixins.textfield-dark;
}


// ################ StrainEntryPopup ################

.MuiTextField-root.strain-entry-popup-textfield {
    @include mixins.textfield;
}

.MuiTypography-root.strain-entry-popup-typo {
    @include utility.typoBase(var.$grey-900, 1rem, 500, null, null, null, null);
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }

    margin-top: 20px;
}

.MuiTableCell-head.strain-entry-table-title{
    background-color: var.$primary-700;
    border: none;
    color: var.$main-000;
}

// ################ DARK THEME

.MuiTextField-root.strain-entry-popup-textfield.#{var.$darkThemeClass} {
    @include mixins.textfield-dark;
}

// .MuiMenuItem-root.create-metric-plan-popup-menuitem {}


// ################ CreateMetricPlanPopup ################

.MuiBox-root.create-metric-plan-popup-metric-and-target-type-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
        
    div:nth-of-type(1) {
        margin-right: 8px;
    }

    div:nth-of-type(2) {
        margin-left: 8px;
    }
}

.MuiTextField-root.create-metric-plan-popup-textfield {
    @include mixins.textfield;
}

.MuiMenuItem-root.create-metric-plan-popup-menuitem.#{var.$darkThemeClass} {
    @include mixins.menuitem-dark;
}

// ################ DARK THEME

.MuiTextField-root.create-metric-plan-popup-textfield.#{var.$darkThemeClass} {
    @include mixins.textfield-dark;
}


// ################ CreateMetricCyclePopup ################

.MuiTextField-root.create-metric-cycle-popup-textfield {
    @include mixins.textfield;
}

// ################ DARK THEME

.MuiTextField-root.create-metric-cycle-popup-textfield.#{var.$darkThemeClass} {
    @include mixins.textfield-dark;
}


// ################ CropEntryInDetailPagePopup ################

// Check full className if applying styles
// .MuiInputAdornment-root.crop-entry-in-detail-page-popup-input-adornment {

//     &.#{var.$darkThemeClass} {

//     }
// }

.MuiTextField-root.crop-entry-in-detail-page-popup-textfield {
    @include mixins.textfield-outlined;
}

.MuiDivider-root.crop-entry-in-detail-page-popup-divider {
    @include mixins.divider;
    
    margin-bottom: 16px;
    margin-top: 8px;
}

.MuiMenuItem-root.crop-entry-in-detail-page-popup-menuitem {
    
    &.#{var.$darkThemeClass} {
        @include mixins.menuitem-dark;
    }
}

.MuiBox-root.crop-entry-in-detail-page-popup-box {
    display: flex;
    flex-direction: column;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiBox-root.crop-entry-in-detail-page-popup-entry-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTypography-root.crop-entry-in-detail-page-popup-fixed-key-typo {
    @include utility.typoBase(var.$grey-900, 1rem, 500, null, null, null, null);
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiTypography-root.crop-entry-in-detail-page-popup-fixed-value-typo {
    @include utility.typoBase(var.$grey-500, 1rem, 400, null, null, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}


// ################ AddStrainTablePopup ################


.MuiTextField-root.add-strain-table-popup-textfield {
    @include mixins.textfield-outlined;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-100, 'color': var.$grey-200));
    }

    &.add-strain-table-popup-filledinput,
    &.add-strain-table-popup-dropdown {
        margin-top: 0;
        
        fieldset.MuiOutlinedInput-notchedOutline {
            border: none;
        }
    }
}

.MuiMenuItem-root.add-strain-table-popup-menuitem {
    &.#{var.$darkThemeClass} {
        @include mixins.menuitem-dark;
    }
}

.MuiBox-root.add-strain-table-popup-sel-strains-box {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: var.$maxFarmTableWd;
    margin-top: 40px;

    // &.#{var.$darkThemeClass} {

    // }
}


// ################ StrainDetailInProdInvPopup ################


.MuiBox-root.strain-detail-inprodinv-popup-box {
    background-color: var.$primary-100;
    padding: 65px 32px;
    position: relative;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-800));
    }

}

.MuiBox-root.strain-detail-inprodinv-popup-close-icon-box {
    padding-top: 16px;
    position: absolute;
    right: 32px;
    top: 0;
}

.MuiSvgIcon-root.strain-detail-inprodinv-popup-close-icon {
    &:hover {
        cursor: pointer;
    }

    &.#{var.$darkThemeClass} {
        path {
            fill: var.$grey-500;
        }
    }
}


.MuiTypography-root.strain-detail-inprodinv-popup-title-typo {
    @include utility.typoBase(var.$main-900, 1.125rem, 500, null, 1.5, 0, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$main-000));
    }
}

.MuiBox-root.strain-detail-inprodinv-popup-tables-box {
    
    .MuiBox-root.strain-detail-inprodinv-popup-entry-table-box {
        margin-top: 23px;

    }

    .MuiTypography-root.strain-detail-inprodinv-popup-entry-row-title-typo {

    }

    .MuiTypography-root.strain-detail-inprodinv-popup-entry-content-typo {
        border-color: var.$primary-400;
    }
    
    
    &.#{var.$darkThemeClass} {
        .MuiTypography-root.strain-detail-inprodinv-popup-entry-content-typo {
            border-color: initial;
        }
    }
}


// ################ MetricCyclePopup ################


.MuiBox-root.metric-cycle-popup-static-box {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTypography-root.metric-cycle-popup-static-key-typo {
    @include utility.typoBase(var.$grey-900, 1rem, 500, null, null, null, null);
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiTypography-root.metric-cycle-popup-static-value-typo {
    @include utility.typoBase(var.$grey-500, 1rem, 400, null, null, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

.MuiTextField-root.metric-cycle-popup-textfield {
    // @include mixins.textfield;
    @include mixins.textfield-outlined;
}


// ################ CompareRoomAndQualPlanPopup ################


.MuiDivider-root.compare-room-qualplan-divider {
    margin-top: 24px;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$grey-500));
    }
}

.MuiTypography-root.compare-room-qualplan-phase-typo {
    margin-top: 24px;
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
    
}

.MuiBox-root.compare-room-qualplan-common-box {
    margin-top: 24px;

    // &.#{var.$darkThemeClass} {}
}

.MuiTypography-root.compare-room-qualplan-common-typo {

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiBox-root.compare-room-qualplan-missing-box {
    margin-top: 16px;
    // &.#{var.$darkThemeClass} {}
}

.MuiTypography-root.compare-room-qualplan-missing-typo {

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiTypography-root.compare-room-qualplan-list-item-typo {
    margin-left: 16px;
    margin-top: 8px;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

// ################ Routines Popup ################
.MuiTextField-root.routines-popup-textfield {
    @include mixins.textfield-outlined;

    // &.#{var.$darkThemeClass} {
    //     @include utility.theme(('bgColor': var.$dark-500));
    // }

    margin-top: 0;
}

.MuiTypography-root.routines-popup-title-typo {
    font-size: 24px;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiTypography-root.routines-popup-typo {
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiBox-root.routines-popup-task {
    border: 1px solid var.$grey-600;
    padding: 20px;

    &.#{var.$darkThemeClass} {
        background-color: var.$dark-100;
    }
}

.MuiBox-root.routines-popup-toolbox-container {
    padding: 12px;

    &.#{var.$darkThemeClass} {
        background-color: var.$dark-100;
    }
}

.MuiBox-root.routines-popup-task-value-container{
    border: 1px solid var.$grey-500;
    padding: 12px 16px;

    &.#{var.$darkThemeClass} {
        background-color: var.$grey-800
    }

    &.selected {
        background-color: var.$primary-700;
    }
}

.MuiPaper-root.routines-popup-tasks-container {
    background: none;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    display: flex;
}

.MuiStack-root.routines-popup-assignee{
    padding: 5px;
    border: 1px solid var.$grey-500;

    &.#{var.$darkThemeClass} {
        background-color: var.$grey-800
    }

    &.selected {
        background-color: var.$primary-700;
    }
}

.MuiChip-root.routines-popup-status-chip{

    &.#{var.$darkThemeClass} {
        & .MuiChip-label {
            @include utility.theme(('color': var.$grey-200));
        }
        background-color: var.$grey-700;
    }
}

// ################ ################ ################ ################ ################ 


// ################ Popup ################
// ################ DARK THEME