// Define styles that will be inherited by all components
// Class values derived from constants.baseClasses

@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ ELEMENTS ~~~~~~~~~~~~~~~~ 
body {
    margin: 0;
}

*, ::after, ::before {
    box-sizing: border-box;
}

// ~~~~~~~~~~~~~~~~ CLASSES ~~~~~~~~~~~~~~~~ 
.MuiBox-root.wrapper-root,
.wrapper-root {
    background-color: var.$primary-100;
    display: flex;

    &.#{var.$darkThemeClass} {
        @include mixins.main-content-wrapper-dark;
    }
}

.farmsTabContent, .roomDetailTabsContent, .fadeInCards, .tabContent {
    animation-name: FadeInOut;
    animation-duration: 1000ms;
    transition-timing-function: ease-in-out; /* Not being applied currently */
}

@keyframes FadeInOut {
    0% { opacity: 0; } 
    100% { opacity: 1; }
}

// ~~~~~~~~~~~~~~~~ COMPONENTS ~~~~~~~~~~~~~~~~ 

// .MuiBox-root.base-wrapper {
//     display: flex;
//     min-width: fit-content;
//     transition: var.$baseTransition;
//     width: 100%;
// }

.MuiBox-root {
    font-family: 'Space Grotesk', sans-serif;
}

ul.MuiList-root {
    padding: 0;
}

.MuiDialog-container {
    @include mixins.custom-scroll;
}

.MuiTextField-root {
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }
}

// .MuiPaper-root.main-content-wrapper,
// .MuiBox-root.main-content-wrapper {
//     background-color: var.$primary-100;
//     flex-grow: 1;
//     max-height: 100vh;
//     overflow: auto;
//     padding: 60px 32px 0;
//     // transition: opacity var(--fade-timing-ms)ms cubic-bezier(0.4, 0, 0.2, 1), padding 225ms ease-in-out !important;
    
//     @include utility.transition(());
// }