body {
  margin: 0;
}

*, ::after, ::before {
  box-sizing: border-box;
}

.MuiBox-root.wrapper-root,
.wrapper-root {
  background-color: #F7FAEA;
  display: flex;
}
.MuiBox-root.wrapper-root.dark-theme,
.wrapper-root.dark-theme {
  background-color: #1A1B19;
}

.farmsTabContent, .roomDetailTabsContent, .fadeInCards, .tabContent {
  animation-name: FadeInOut;
  animation-duration: 1000ms;
  transition-timing-function: ease-in-out; /* Not being applied currently */
}

@keyframes FadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.MuiBox-root {
  font-family: "Space Grotesk", sans-serif;
}

ul.MuiList-root {
  padding: 0;
}

.MuiDialog-container::-webkit-scrollbar {
  width: 8px;
}
.MuiDialog-container::-webkit-scrollbar-track {
  box-shadow: none;
}
.MuiDialog-container::-webkit-scrollbar-thumb {
  background-color: #BDC0B2;
  border-radius: 8px;
}

.MuiTextField-root input[type=number]::-webkit-inner-spin-button,
.MuiTextField-root input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.MuiBox-root.alert-and-routine-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.MuiBox-root.alert-and-routine-box.dark-theme {
  background-color: #2E2F2D;
  color: #A1A591;
}

.MuiButton-root.no-entity-create-button {
  background-color: #819C24;
  border-radius: 0;
  box-shadow: none;
  padding-bottom: 16px;
  padding-top: 16px;
  text-transform: none;
  margin-top: 30px;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.25;
  width: 244px;
  animation: pulse 1s 5;
}
.MuiButton-root.no-entity-create-button:hover {
  background-color: #819C24;
}

@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
.MuiButton-root.no-entity-create-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.no-entity-create-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiBox-root.base-entity-card-box,
.MuiButton-root.add-entity-card-button {
  align-items: center;
  background-color: #E6F1C1;
  border: 1px solid #4E5D15;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  height: 214px;
  justify-content: center;
  padding: 40px 20px;
  text-transform: none;
}
.MuiBox-root.base-entity-card-box:hover,
.MuiButton-root.add-entity-card-button:hover {
  cursor: pointer;
  background-color: #E6F1C1;
}

.MuiSvgIcon-root.add-entity-card-icon {
  fill: none;
  height: 24px;
  width: 24px;
}
.MuiSvgIcon-root.add-entity-card-icon path {
  stroke: #4E5D15;
}

.MuiTypography-root.add-entity-card-typo {
  color: #4E5D15;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 16px;
}

.MuiBox-root.base-entity-card-box.dark-theme,
.MuiButton-root.add-entity-card-button.dark-theme {
  border: 1px solid #B1D23A;
  background-color: #303130;
}

.MuiSvgIcon-root.add-entity-card-icon.dark-theme {
  fill: none;
  color: #B1D23A;
}
.MuiSvgIcon-root.add-entity-card-icon.dark-theme path {
  stroke: #B1D23A;
}

.MuiTypography-root.add-entity-card-typo.dark-theme {
  color: #B1D23A;
}

.MuiBox-root.base-entity-card-box:hover {
  cursor: default;
}

.MuiBox-root.sensor-card-baseentitycard-box {
  position: relative;
}

.MuiBox-root.sensor-card-action-button-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;
}
.MuiBox-root.sensor-card-action-button-box.sensor-card-added-table {
  justify-content: center;
}

.MuiButton-root.sensor-card-attach-table-button {
  background-color: transparent;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  text-transform: none;
  color: #4E5D15;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: initial;
  width: initial;
}
.MuiButton-root.sensor-card-attach-table-button:hover {
  background-color: transparent;
}

.MuiButton-root.sensor-card-edit-details-button {
  background-color: transparent;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  text-transform: none;
  color: #4E5D15;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: initial;
  width: initial;
}
.MuiButton-root.sensor-card-edit-details-button:hover {
  background-color: transparent;
}

.MuiBox-root.sensor-card-info-box {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px auto 0;
}

.MuiSvgIcon-root.sensor-card-edit-icon,
.MuiSvgIcon-root.sensor-card-attach-table-icon {
  height: 16px;
  width: 16px;
}
.MuiSvgIcon-root.sensor-card-edit-icon path,
.MuiSvgIcon-root.sensor-card-attach-table-icon path {
  fill: none;
  stroke: #4E5D15;
}

.MuiSvgIcon-root.sensor-card-dot-separator-icon {
  height: 5px;
  margin-left: 5px;
  margin-right: 5px;
  width: 5px;
}
.MuiSvgIcon-root.sensor-card-dot-separator-icon circle {
  fill: #4E5D15;
}

.MuiTypography-root.sensor-card-types-typo,
.MuiTypography-root.sensor-card-room-typo,
.MuiTypography-root.sensor-card-mac-typo {
  text-align: center;
  color: #4E5D15;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: initial;
  width: initial;
}

.MuiTypography-root.sensor-card-types-typo {
  width: calc(100% - 40px);
}

.MuiTypography-root.sensor-card-title-typo {
  color: #4E5D15;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
  margin-top: initial;
  width: initial;
}

.MuiButton-root.sensor-card-del-button {
  min-width: fit-content;
  padding: 0;
  position: absolute;
  right: 8px;
  top: 16px;
}
.MuiButton-root.sensor-card-del-button:hover {
  background-color: transparent;
}
.MuiButton-root.sensor-card-del-button path {
  fill: #4E5D15;
}

.MuiBox-root.sensor-card-baseentitycard-box.dark-theme {
  border-color: transparent;
}

.MuiButton-root.sensor-card-attach-table-button.dark-theme {
  color: #B1D23A;
}

.MuiSvgIcon-root.sensor-card-edit-icon.dark-theme path {
  stroke: #A1A591;
}

.MuiButton-root.sensor-card-edit-details-button.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.sensor-card-types-typo.dark-theme,
.MuiTypography-root.sensor-card-room-typo.dark-theme,
.MuiTypography-root.sensor-card-mac-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.sensor-card-title-typo.dark-theme {
  color: #F7FAEA;
}

.MuiSvgIcon-root.sensor-card-dot-separator-icon.dark-theme circle {
  fill: #A1A591;
}

.MuiButton-root.sensor-card-del-button.dark-theme path {
  fill: #A1A591;
}

.MuiBox-root.roomtable-card-baseentitycard-box {
  position: relative;
}

.MuiTypography-root.roomtable-card-name-typo {
  color: #4E5D15;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
  margin-top: initial;
  width: initial;
}

.MuiBox-root.roomtable-card-info-box {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px auto 0;
}

.MuiTypography-root.roomtable-card-info-typo {
  text-align: center;
  color: #4E5D15;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: initial;
  width: initial;
}

.MuiSvgIcon-root.roomtable-card-dot-sep-icon {
  height: 5px;
  margin-left: 5px;
  margin-right: 5px;
  width: 5px;
}
.MuiSvgIcon-root.roomtable-card-dot-sep-icon circle {
  fill: #4E5D15;
}

.MuiSvgIcon-root.roomtable-card-edit-icon {
  height: 16px;
  width: 16px;
}
.MuiSvgIcon-root.roomtable-card-edit-icon path {
  fill: none;
  stroke: #4E5D15;
}

.MuiButton-root.roomtable-card-edit-button {
  background-color: transparent;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  text-transform: none;
  color: #4E5D15;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: auto;
  width: initial;
}
.MuiButton-root.roomtable-card-edit-button:hover {
  background-color: transparent;
}

.MuiButton-root.roomtable-card-del-button {
  min-width: fit-content;
  padding: 0;
  position: absolute;
  right: 8px;
  top: 16px;
}
.MuiButton-root.roomtable-card-del-button:hover {
  background-color: transparent;
}
.MuiButton-root.roomtable-card-del-button path {
  fill: #4E5D15;
}

.MuiBox-root.roomtable-card-baseentitycard-box.dark-theme {
  border-color: transparent;
}

.MuiTypography-root.roomtable-card-name-typo.dark-theme {
  color: #F7FAEA;
}

.MuiTypography-root.roomtable-card-info-typo.dark-theme {
  color: #A1A591;
}

.MuiSvgIcon-root.roomtable-card-dot-sep-icon.dark-theme circle {
  fill: #A1A591;
}

.MuiSvgIcon-root.roomtable-card-edit-icon.dark-theme path {
  stroke: #A1A591;
}

.MuiButton-root.roomtable-card-edit-button.dark-theme {
  color: #A1A591;
}

.MuiButton-root.roomtable-card-del-button.dark-theme path {
  fill: #A1A591;
}

.MuiBox-root.crop-spec-card-box {
  align-items: center;
  background-color: #C5DE6E;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  transition: background-color 800ms  ;
}

.MuiSvgIcon-root.crop-spec-card-icon {
  height: 24px;
  width: 24px;
}
.MuiSvgIcon-root.crop-spec-card-icon path {
  fill: #414437;
}

.MuiTypography-root.crop-spec-card-title-typo {
  max-width: 118px;
  text-align: center;
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.28;
  margin-top: 22px;
}

.MuiTypography-root.crop-spec-card-value-typo {
  text-align: center;
  text-transform: uppercase;
  color: #414437;
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 1.16;
  margin-top: 24px;
}

.MuiBox-root.crop-spec-card-box.dark-theme {
  background-color: #242523;
}

.MuiSvgIcon-root.crop-spec-card-icon.dark-theme path {
  fill: #BDC0B2;
}

.MuiTypography-root.crop-spec-card-title-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.crop-spec-card-value-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.crop-link-card-box {
  align-items: flex-start;
  background-color: #C5DE6E;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  transition: background-color 800ms  ;
}

.MuiBox-root.crop-link-card-title-box {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MuiTypography-root.crop-link-card-title-key-typo {
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.28;
}

.MuiTypography-root.crop-link-card-title-value-typo {
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.28;
  margin-top: 5px;
}

.MuiBox-root.crop-link-card-link {
  text-decoration: none;
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.28;
}

.MuiButton-root.crop-link-card-link-button {
  align-items: center;
  justify-content: center;
  padding: 0;
  text-transform: none;
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.28;
}
.MuiButton-root.crop-link-card-link-button:hover {
  background-color: transparent;
}

.MuiSvgIcon-root.crop-link-card-link-icon {
  height: 16px;
  vertical-align: top;
  width: 16px;
}
.MuiSvgIcon-root.crop-link-card-link-icon path {
  fill: none;
  stroke: #414437;
}

.MuiBox-root.crop-link-card-box.dark-theme {
  background-color: #242523;
}

.MuiTypography-root.crop-link-card-title-key-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.crop-link-card-title-value-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.crop-link-card-link.dark-theme {
  color: #819C24;
}

.MuiButton-root.crop-link-card-link-button.dark-theme {
  color: #819C24;
}

.MuiSvgIcon-root.crop-link-card-link-icon.dark-theme path {
  stroke: #819C24;
}

.MuiPaper-root.room-detail-info-card-paper {
  margin-bottom: 16px;
  padding: 25px 28px;
  width: 100%;
}

.MuiPaper-root.room-detail-info-card-title-paper {
  display: flex;
  justify-content: space-between;
}

.MuiTypography-root.room-detail-info-card-title-typo {
  color: #414437;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.16;
}

.MuiSvgIcon-root.room-detail-info-card-edit-icon {
  height: 24px;
  width: 24px;
}

.MuiButton-root.room-detail-info-card-edit-button {
  color: #414437;
  font-size: 16px;
  font-weight: 500;
  flex-shrink: 0;
  line-height: 1.56;
  padding: 0;
  text-transform: none;
}

.MuiTypography-root.room-detail-info-card-entry-typo {
  color: #414437;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.56;
}

.MuiPaper-root.room-detail-info-card-paper.dark-theme {
  background-color: #303130;
}

.MuiPaper-root.room-detail-info-card-title-paper.dark-theme {
  background-color: transparent;
}

.MuiTypography-root.room-detail-info-card-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiSvgIcon-root.room-detail-info-card-edit-icon.dark-theme path {
  fill: #B1D23A;
}

.MuiButton-root.room-detail-info-card-edit-button.dark-theme {
  color: #B1D23A;
}

.MuiTypography-root.room-detail-info-card-entry-typo.dark-theme {
  color: #BDC0B2;
}

.MuiPaper-root.room-detail-item-info-card-paper {
  background-color: #C5DE6E;
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 1;
  margin-bottom: 16px;
  padding: 46px 28px;
  width: 100%;
}
.MuiPaper-root.room-detail-item-info-card-paper:hover {
  cursor: pointer;
}

.MuiPaper-root.room-detail-item-info-card-paper.dark-theme {
  background-color: #A1A591;
}

.MuiBox-root.metrc-room-drag-card-box {
  background-color: #C5DE6E;
  border-bottom: 1px solid #819C24;
  display: flex;
  padding: 24px 12px 23px;
  width: 100%;
}
.MuiBox-root.metrc-room-drag-card-box:hover {
  cursor: pointer;
}

.MuiSvgIcon-root.metrc-room-drag-card-icon {
  height: 24px;
  width: 24px;
}
.MuiSvgIcon-root.metrc-room-drag-card-icon path {
  fill: none;
  stroke: #090A08;
}
.MuiSvgIcon-root.metrc-room-drag-card-icon.dark-theme path {
  fill: none;
}

.MuiTypography-root.metrc-room-drag-card-typo {
  color: #090A08;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.45;
  margin-left: 16px;
}

.MuiBox-root.metrc-room-drag-card-box.dark-theme {
  border-color: #1A1F07;
  background-color: #A1A591;
}

.MuiTypography-root.metrc-room-drag-card-typo.dark-theme {
  color: #1A1F07;
}

.MuiBox-root.external-card-box {
  background-color: #E6F1C1;
  border-bottom: 1px solid #4E5D15;
  display: flex;
  margin-bottom: 16px;
  margin-right: 16px;
}
.MuiBox-root.external-card-box:hover {
  cursor: pointer;
}

.MuiBox-root.external-card-box.dark-theme {
  border-bottom: none;
  background-color: #303130;
}

.MuiPaper-root.farm-card-paper {
  border-bottom: 1px solid;
  border-color: #4E5D15;
  flex-shrink: 0;
  padding: 36px 28px;
}
.MuiPaper-root.farm-card-paper:hover {
  cursor: pointer;
}

.MuiSvgIcon-root.farm-card-icon {
  height: 48px;
  width: 48px;
}

.MuiTypography-root.farm-card-name-typo {
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
  margin-top: 32px;
  width: 100%;
}

.MuiTypography-root.farm-card-count-typo {
  color: #4E5D15;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 8px;
}

.MuiSvgIcon-root.farm-card-room-icon {
  height: 16px;
  margin-right: 4px;
  vertical-align: middle;
  width: 16px;
}
.MuiSvgIcon-root.farm-card-room-icon path {
  fill: none;
  stroke: #4E5D15;
  stroke-width: 4;
}

.MuiPaper-root.farm-card-paper.dark-theme {
  border-color: #B1D23A;
  background-color: #303130;
}

.MuiSvgIcon-root.farm-card-icon.dark-theme path {
  fill: #B1D23A;
}

.MuiTypography-root.farm-card-name-typo.dark-theme {
  color: #B1D23A;
}

.MuiTypography-root.farm-card-count-typo.dark-theme {
  color: #B1D23A;
}

.MuiSvgIcon-root.farm-card-room-icon.dark-theme path {
  stroke: #B1D23A;
}

.MuiPaper-root.room-card-paper {
  border-bottom: 1px solid #4E5D15;
  flex-shrink: 0;
  padding: 36px 28px;
}
.MuiPaper-root.room-card-paper:hover {
  cursor: pointer;
}

.MuiSvgIcon-root.room-card-icon {
  height: 48px;
  width: 48px;
}
.MuiSvgIcon-root.room-card-icon path {
  fill: none;
  stroke: #4E5D15;
}

.MuiTypography-root.room-card-name-typo {
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
  margin-top: 32px;
  width: 100%;
}

.MuiTypography-root.room-card-mapping-typo {
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
  margin-top: 8px;
}

.MuiSvgIcon-root.room-card-mapping-icon {
  height: 24px;
  margin-right: 4px;
  vertical-align: middle;
  width: 24px;
}

.MuiPaper-root.room-card-paper.dark-theme {
  border-color: #B1D23A;
  background-color: #303130;
}

.MuiSvgIcon-root.room-card-icon.dark-theme path {
  stroke: #B1D23A;
}

.MuiTypography-root.room-card-name-typo.dark-theme {
  color: #B1D23A;
}

.MuiTypography-root.room-card-mapping-typo.dark-theme {
  color: #B1D23A;
}

.MuiSvgIcon-root.room-card-mapping-icon.dark-theme path {
  fill: #B1D23A;
}

.MuiPaper-root.farm-room-detail-card-paper {
  border-bottom: 1px solid #4E5D15;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 36px 28px;
}
.MuiPaper-root.farm-room-detail-card-paper:hover {
  cursor: pointer;
}

.MuiSvgIcon-root.farm-room-detail-card-icon {
  height: 48px;
  width: 48px;
}
.MuiSvgIcon-root.farm-room-detail-card-icon path {
  fill: none;
}

.MuiTypography-root.farm-room-detail-card-name-typo {
  color: #4E5D15;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
  margin-top: 32px;
}

.MuiTypography-root.farm-room-detail-card-mapping-typo,
.MuiTypography-root.farm-room-detail-card-size-typo,
.MuiTypography-root.farm-room-detail-card-lights-typo,
.MuiTypography-root.farm-room-detail-card-sensors-typo {
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
  margin-top: 4px;
}

.MuiTypography-root.farm-room-detail-card-size-typo {
  margin-top: 24px;
}

.MuiTypography-root.farm-room-detail-card-lights-typo,
.MuiTypography-root.farm-room-detail-card-sensors-typo {
  margin-top: 16px;
}

.MuiSvgIcon-root.farm-room-detail-card-mapping-icon,
.MuiSvgIcon-root.farm-room-detail-card-size-icon,
.MuiSvgIcon-root.farm-room-detail-card-lights-icon,
.MuiSvgIcon-root.farm-room-detail-card-sensors-icon {
  height: 24px;
  margin-right: 4px;
  vertical-align: middle;
  width: 24px;
}

.MuiPaper-root.farm-room-detail-card-paper.dark-theme {
  border: 1px solid #5D614F;
  background-color: #303130;
}

.MuiSvgIcon-root.farm-room-detail-card-icon.dark-theme path {
  stroke: #D9DBD3;
}

.MuiTypography-root.farm-room-detail-card-name-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.farm-room-detail-card-mapping-typo.dark-theme,
.MuiTypography-root.farm-room-detail-card-size-typo.dark-theme,
.MuiTypography-root.farm-room-detail-card-lights-typo.dark-theme,
.MuiTypography-root.farm-room-detail-card-sensors-typo.dark-theme {
  color: #A1A591;
}

.MuiSvgIcon-root.farm-room-detail-card-mapping-icon.dark-theme path,
.MuiSvgIcon-root.farm-room-detail-card-size-icon.dark-theme path,
.MuiSvgIcon-root.farm-room-detail-card-lights-icon.dark-theme path,
.MuiSvgIcon-root.farm-room-detail-card-sensors-icon.dark-theme path {
  fill: #A1A591;
}

.MuiBox-root.plan-card-box {
  background-color: #E6F1C1;
  border-bottom: 1px solid #4E5D15;
  height: 275px;
  padding: 36px 28px;
}
.MuiBox-root.plan-card-box:hover {
  cursor: pointer;
}

.MuiBox-root.plan-card-header-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.MuiSvgIcon-root.plan-card-icon {
  height: 48px;
  width: 48px;
}
.MuiSvgIcon-root.plan-card-icon path {
  fill: #4E5D15;
}

.MuiTypography-root.plan-card-label-typo {
  border: 1px solid #4E5D15;
  border-radius: 4px;
  padding: 4px 8px;
  text-transform: uppercase;
  color: #4E5D15;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiTypography-root.plan-card-label-typo.dark-theme {
  color: #B1D23A;
  border-color: #B1D23A;
}

.MuiTypography-root.plan-card-title-typo {
  color: #4E5D15;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
  margin-top: 32px;
}

.MuiTypography-root.plan-card-desc-typo {
  max-height: 36%;
  overflow: auto;
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 24px;
}

.MuiBox-root.plan-card-box.dark-theme {
  border-color: #B1D23A;
  background-color: #303130;
}

.MuiSvgIcon-root.plan-card-icon.dark-theme path {
  fill: #B1D23A;
}

.MuiTypography-root.plan-card-title-typo.dark-theme {
  color: #B1D23A;
}

.MuiTypography-root.plan-card-desc-typo.dark-theme {
  color: #B1D23A;
}

.MuiPaper-root.detail-info-card-paper {
  padding: 25px 28px;
  width: 100%;
}

.MuiPaper-root.detail-info-card-title-paper {
  display: flex;
  justify-content: space-between;
}

.MuiTypography-root.detail-info-card-title-typo {
  color: #414437;
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 1.16;
}

.MuiSvgIcon-root.detail-info-card-edit-icon {
  height: 24px;
  vertical-align: middle;
  width: 24px;
}

.MuiButton-root.detail-info-card-edit-button {
  padding: 0;
  text-transform: none;
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}

.MuiTypography-root.detail-info-card-entry-typo {
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}

.MuiPaper-root.detail-info-card-paper.dark-theme {
  background-color: #303130;
}

.MuiPaper-root.detail-info-card-title-paper.dark-theme {
  background-color: transparent;
}

.MuiTypography-root.detail-info-card-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiSvgIcon-root.detail-info-card-edit-icon.dark-theme path {
  fill: #B1D23A;
}

.MuiButton-root.detail-info-card-edit-button.dark-theme {
  color: #B1D23A;
}

.MuiTypography-root.detail-info-card-entry-typo.dark-theme {
  color: #BDC0B2;
}

.MuiAccordion-root.expand-card-accordion {
  background-color: #C5DE6E;
  border-bottom: 1px solid #819C24;
  padding: 24px 20px;
  transition: background-color 800ms  ;
}
.MuiAccordion-root.expand-card-accordion::before {
  content: none;
}
.MuiAccordion-root.expand-card-accordion.Mui-expanded {
  background-color: #F7FAEA;
  margin-bottom: 0;
  margin-top: 0;
}
.MuiAccordion-root.expand-card-accordion.dark-theme {
  border-color: #A1A591;
  background-color: #BDC0B2;
}
.MuiAccordion-root.expand-card-accordion.dark-theme.Mui-expanded {
  background-color: #F6F6F4;
}

.MuiAccordionSummary-root.expand-card-accordion-summary {
  min-height: 0;
  padding-left: 0;
  padding-right: 0;
}
.MuiAccordionSummary-root.expand-card-accordion-summary:hover {
  cursor: default;
}
.MuiAccordionSummary-root.expand-card-accordion-summary.Mui-expanded {
  min-height: 0;
}
.MuiAccordionSummary-root.expand-card-accordion-summary .MuiAccordionSummary-content {
  flex-direction: column;
  margin-bottom: 0;
  margin-top: 0;
}
.MuiAccordionSummary-root.expand-card-accordion-summary.dark-theme {
  background-color: transparent;
}

.MuiBox-root.expand-card-title-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.MuiTypography-root.expand-card-title-typo {
  color: #090A08;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.45;
  max-width: 16ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MuiSvgIcon-root.expand-card-title-crop-icon {
  height: 24px;
  margin-right: 18px;
  vertical-align: sub;
  width: 24px;
  margin-right: 8px;
}
.MuiSvgIcon-root.expand-card-title-crop-icon path {
  fill: #090A08;
}

.MuiTypography-root.expand-card-title-qp-typo {
  color: #090A08;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  max-width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MuiBox-root.expand-card-action-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
}
.MuiBox-root.expand-card-action-box.Mui-disabled {
  justify-content: flex-end;
}

.MuiSvgIcon-root.expand-card-action-button-icon {
  height: 20px;
  margin-left: 6px;
  width: 20px;
}
.MuiSvgIcon-root.expand-card-action-button-icon path {
  fill: #090A08;
}

.MuiSvgIcon-root.expand-card-see-more-icon {
  height: 24px;
  transform: none;
  width: 24px;
  transition: transform 800ms  ;
}
.MuiSvgIcon-root.expand-card-see-more-icon path {
  fill: #090A08;
}
.MuiSvgIcon-root.expand-card-see-more-icon.expand-card-expanded-state {
  transform: rotateZ(180deg);
}

.MuiButton-root.expand-card-action-button {
  align-items: center;
  border: none;
  min-width: 0;
  padding: 0;
  text-transform: none;
  color: #090A08;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiButton-root.expand-card-action-button.Mui-disabled .MuiSvgIcon-root.expand-card-action-button-icon path {
  fill: rgba(0, 0, 0, 0.2588235294);
}

.MuiButton-root.expand-card-action-see-close-button {
  align-items: center;
  border: none;
  min-width: 0;
  padding: 0;
  text-transform: none;
  color: #090A08;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiButton-root.expand-card-action-see-close-button .MuiButton-endIcon {
  margin-left: 7px;
}

.MuiBox-root.expand-card-detail-box {
  display: flex;
  border-bottom: 1px solid #D9DBD3;
  flex-direction: column;
  margin-top: 24px;
  padding-bottom: 16px;
}

.MuiBox-root.expand-card-detail-entry-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.MuiBox-root.expand-card-detail-entry-box:last-of-type {
  margin-bottom: 0;
}

.MuiTypography-root.expand-card-detail-entry-key-typo {
  color: #090A08;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}

.MuiTypography-root.expand-card-detail-entry-value-typo {
  color: #5D614F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}

.MuiBox-root.plans-detail-info-card-box {
  background-color: #E6F1C1;
  min-width: fit-content;
  padding: 24px 28px;
  width: 100%;
}

.MuiBox-root.plans-detail-info-card-header-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.MuiTypography-root.plans-detail-info-card-header-typo {
  color: #414437;
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 1.16;
  overflow-wrap: break-word;
  word-break: break-word;
}

.MuiSvgIcon-root.plans-detail-info-card-edit-icon {
  height: 24px;
  width: 24px;
}

.MuiButton-root.plans-detail-info-card-edit-button {
  background-color: transparent;
  text-transform: none;
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
  overflow-wrap: break-word;
  word-break: break-word;
}
.MuiButton-root.plans-detail-info-card-edit-button:hover {
  background-color: transparent;
}

.MuiBox-root.plans-detail-info-card-content-box {
  display: flex;
  flex-direction: column;
}

.MuiBox-root.plans-detail-info-card-entry-box {
  margin-top: 16px;
}

.MuiTypography-root.plans-detail-info-card-entry-key-typo {
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}

.MuiTypography-root.plans-detail-info-card-entry-value-typo {
  color: #6F745F;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
  overflow-wrap: break-word;
  word-break: break-word;
}

.MuiSvgIcon-root.plans-detail-info-card-entry-icon {
  height: 24px;
  margin-right: 10px;
  width: 24px;
  vertical-align: middle;
}

.MuiBox-root.plans-detail-info-card-box.dark-theme {
  background-color: #303130;
}

.MuiTypography-root.plans-detail-info-card-header-typo.dark-theme {
  color: #D9DBD3;
}

.MuiSvgIcon-root.plans-detail-info-card-edit-icon.dark-theme path {
  fill: #B1D23A;
}

.MuiButton-root.plans-detail-info-card-edit-button.dark-theme {
  color: #B1D23A;
}

.MuiTypography-root.plans-detail-info-card-entry-key-typo.dark-theme {
  color: #BDC0B2;
}

.MuiTypography-root.plans-detail-info-card-entry-value-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.drag-card-box {
  align-items: center;
  background-color: #C5DE6E;
  border-bottom: 1px solid #819C24;
  display: flex;
  padding: 24px 12px 23px;
  width: 100%;
}
.MuiBox-root.drag-card-box:hover {
  cursor: pointer;
}
.MuiBox-root.drag-card-box.dark-theme {
  border-color: #1A1F07;
  background-color: #A1A591;
}

.MuiSvgIcon-root.drag-card-icon {
  height: 24px;
  width: 24px;
}

.MuiTypography-root.drag-card-typo {
  margin-left: 16px;
  color: #090A08;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.45;
}

.MuiAccordion-root.detail-accordion-accordion {
  background-color: #C5DE6E;
  border-bottom: 1px solid #819C24;
  padding: 24px 20px;
  transition: background-color 800ms  ;
  padding-bottom: 28px;
  padding-top: 28px;
}
.MuiAccordion-root.detail-accordion-accordion::before {
  content: none;
}
.MuiAccordion-root.detail-accordion-accordion.Mui-expanded {
  background-color: #F7FAEA;
  margin-bottom: 0;
  margin-top: 0;
}
.MuiAccordion-root.detail-accordion-accordion.dark-theme {
  border-color: #A1A591;
  background-color: #BDC0B2;
}
.MuiAccordion-root.detail-accordion-accordion.dark-theme.Mui-expanded {
  background-color: #F6F6F4;
}

.MuiAccordionSummary-root.detail-accordion-accordion-summary {
  min-height: 0;
  padding-left: 0;
  padding-right: 0;
}
.MuiAccordionSummary-root.detail-accordion-accordion-summary:hover {
  cursor: default;
}
.MuiAccordionSummary-root.detail-accordion-accordion-summary.Mui-expanded {
  min-height: 0;
}
.MuiAccordionSummary-root.detail-accordion-accordion-summary .MuiAccordionSummary-content {
  flex-direction: column;
  margin-bottom: 0;
  margin-top: 0;
}
.MuiAccordionSummary-root.detail-accordion-accordion-summary.dark-theme {
  background-color: transparent;
}

.MuiBox-root.detail-accordion-title-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.MuiTypography-root.detail-accordion-title-typo {
  color: #090A08;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.45;
}

.MuiSvgIcon-root.detail-accordion-title-crop-icon {
  height: 24px;
  margin-right: 18px;
  vertical-align: sub;
  width: 24px;
}
.MuiSvgIcon-root.detail-accordion-title-crop-icon path {
  fill: #090A08;
}

.MuiTypography-root.detail-accordion-title-qp-typo {
  color: #090A08;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}

.MuiBox-root.detail-accordion-title-action-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
}
.MuiBox-root.detail-accordion-title-action-box.Mui-disabled {
  justify-content: flex-end;
}

.MuiSvgIcon-root.detail-accordion-action-button-icon {
  height: 20px;
  margin-left: 6px;
  width: 20px;
}
.MuiSvgIcon-root.detail-accordion-action-button-icon path {
  fill: #090A08;
}

.MuiSvgIcon-root.detail-accordion-see-more-icon {
  height: 24px;
  transform: none;
  width: 24px;
  transition: transform 800ms  ;
}
.MuiSvgIcon-root.detail-accordion-see-more-icon path {
  fill: #090A08;
}
.MuiSvgIcon-root.detail-accordion-see-more-icon.expanded {
  transform: rotateZ(180deg);
}

.MuiButton-root.detail-accordion-action-edit-button,
.MuiButton-root.detail-accordion-action-unlink-button {
  align-items: center;
  border: none;
  margin-right: 24px;
  min-width: 0;
  padding: 0;
  text-transform: none;
  color: #090A08;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiButton-root.detail-accordion-action-edit-button.Mui-disabled .MuiSvgIcon-root.expand-card-action-button-icon path,
.MuiButton-root.detail-accordion-action-unlink-button.Mui-disabled .MuiSvgIcon-root.expand-card-action-button-icon path {
  fill: rgba(0, 0, 0, 0.2588235294);
}

.MuiButton-root.detail-accordion-action-unlink-button {
  margin-right: auto;
}

.MuiButton-root.detail-accordion-action-see-close-button {
  align-items: center;
  border: none;
  min-width: 0;
  padding: 0;
  text-transform: none;
  color: #090A08;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiButton-root.detail-accordion-action-see-close-button .MuiButton-endIcon {
  margin-left: 7px;
}

.MuiBox-root.detail-accordion-detail-box {
  display: flex;
  border-bottom: 1px solid #D9DBD3;
  flex-direction: column;
  margin-top: 24px;
  padding-bottom: 16px;
}

.MuiBox-root.detail-accordion-detail-entry-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.MuiBox-root.detail-accordion-detail-entry-box:last-of-type {
  margin-bottom: 0;
}

.MuiTypography-root.detail-accordion-detail-entry-key-typo {
  color: #090A08;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}

.MuiTypography-root.detail-accordion-detail-entry-value-typo {
  color: #5D614F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}

.MuiBox-root.crop-run-card-box {
  background-color: #E6F1C1;
  border-bottom: 1px solid #4E5D15;
  height: 328px;
  padding: 36px 28px;
}
.MuiBox-root.crop-run-card-box:hover {
  cursor: pointer;
}
.MuiBox-root.crop-run-card-box.dark-theme {
  background-color: #303130;
  border-color: #B1D23A;
}

.MuiBox-root.crop-run-card-header-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.MuiTypography-root.crop-run-card-date-typo,
.MuiTypography-root.crop-run-card-status-typo {
  text-transform: uppercase;
  color: #4E5D15;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiTypography-root.crop-run-card-date-typo.dark-theme,
.MuiTypography-root.crop-run-card-status-typo.dark-theme {
  color: #B1D23A;
}

.MuiTypography-root.crop-run-card-status-typo {
  border: 1px solid #4E5D15;
  border-radius: 4px;
  padding: 4px 8px;
}
.MuiTypography-root.crop-run-card-status-typo.dark-theme {
  border-color: #B1D23A;
}

.MuiTypography-root.crop-run-card-title-typo {
  color: #4E5D15;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
  margin-top: 32px;
}
.MuiTypography-root.crop-run-card-title-typo.dark-theme {
  color: #B1D23A;
}

.MuiTypography-root.crop-run-card-desc-typo {
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 24px;
}
.MuiTypography-root.crop-run-card-desc-typo.dark-theme {
  color: #B1D23A;
}

.MuiBox-root.in-production-inv-card-box {
  background-color: #FCFF80;
  display: flex;
  flex-direction: column;
  padding: 24px 6px;
}
.MuiBox-root.in-production-inv-card-box.dark-theme {
  background-color: #A1A591;
}

.MuiTypography-root.in-production-inv-card-value-typo {
  color: #324001;
  font-size: 1.875rem;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 1.13;
}
.MuiTypography-root.in-production-inv-card-value-typo.dark-theme {
  color: #252720;
}

.MuiTypography-root.in-production-inv-card-title-typo {
  color: #324001;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 2px;
}
.MuiTypography-root.in-production-inv-card-title-typo.dark-theme {
  color: #252720;
}

.MuiBox-root.in-production-inv-card-footer-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.MuiTypography-root.in-production-inv-card-actuals-typo,
.MuiTypography-root.in-production-inv-card-exp-typo {
  color: #324001;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiTypography-root.in-production-inv-card-actuals-typo.dark-theme,
.MuiTypography-root.in-production-inv-card-exp-typo.dark-theme {
  color: #252720;
}

.MuiSvgIcon-root.in-production-inv-card-icon {
  fill: none;
  height: 24px;
  margin-right: 4px;
  vertical-align: text-bottom;
  width: 24px;
}
.MuiSvgIcon-root.in-production-inv-card-icon path {
  stroke: #324001;
}
.MuiSvgIcon-root.in-production-inv-card-icon.dark-theme path {
  stroke: #000000;
}

.MuiAccordion-root.drag-accordion-card-accordion {
  background-color: #C5DE6E;
  border-bottom: 1px solid #819C24;
  padding: 24px 20px;
  transition: background-color 800ms  ;
}
.MuiAccordion-root.drag-accordion-card-accordion::before {
  content: none;
}
.MuiAccordion-root.drag-accordion-card-accordion.Mui-expanded {
  background-color: #F7FAEA;
  margin-bottom: 0;
  margin-top: 0;
}
.MuiAccordion-root.drag-accordion-card-accordion.dark-theme {
  border-color: #A1A591;
  background-color: #BDC0B2;
}
.MuiAccordion-root.drag-accordion-card-accordion.dark-theme.Mui-expanded {
  background-color: #F6F6F4;
}

.MuiAccordionSummary-root.drag-accordion-card-accordion-summary {
  min-height: 0;
  padding-left: 0;
  padding-right: 0;
}
.MuiAccordionSummary-root.drag-accordion-card-accordion-summary:hover {
  cursor: default;
}
.MuiAccordionSummary-root.drag-accordion-card-accordion-summary.Mui-expanded {
  min-height: 0;
}
.MuiAccordionSummary-root.drag-accordion-card-accordion-summary .MuiAccordionSummary-content {
  flex-direction: column;
  margin-bottom: 0;
  margin-top: 0;
}
.MuiAccordionSummary-root.drag-accordion-card-accordion-summary.dark-theme {
  background-color: transparent;
}

.MuiBox-root.drag-accordion-card-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.MuiBox-root.drag-accordion-card-title-box {
  align-items: center;
  display: flex;
}
.MuiSvgIcon-root.drag-accordion-card-icon {
  height: 24px;
  margin-right: 18px;
  vertical-align: sub;
  width: 24px;
}
.MuiSvgIcon-root.drag-accordion-card-icon path {
  fill: #090A08;
}
.MuiSvgIcon-root.drag-accordion-card-icon path {
  fill: none;
}

.MuiTypography-root.drag-accordion-card-typo {
  color: #090A08;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MuiButton-root.drag-accordion-card-see-more-button {
  align-items: center;
  border: none;
  min-width: 0;
  padding: 0;
  text-transform: none;
  color: #090A08;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiButton-root.drag-accordion-card-see-more-button .MuiButton-endIcon {
  margin-left: 7px;
}
.MuiSvgIcon-root.drag-accordion-card-see-more-icon {
  height: 24px;
  transform: none;
  width: 24px;
  transition: transform 800ms  ;
}
.MuiSvgIcon-root.drag-accordion-card-see-more-icon path {
  fill: #090A08;
}
.MuiSvgIcon-root.drag-accordion-card-see-more-icon.drag-accordion-card-expanded {
  transform: rotateZ(180deg);
}
.MuiAccordion-root.strain-accordion-accordion {
  border: 1px solid #819C24;
  padding: 0;
  margin: 8px 0;
}
.MuiAccordion-root.strain-accordion-accordion::before {
  content: none;
}
.MuiAccordion-root.strain-accordion-accordion.dark-theme {
  border-color: #A1A591;
  color: #BDC0B2;
  background-color: #252720;
}

.MuiAccordionSummary-root.strain-accordion-accordion-summary .MuiAccordionSummary-content {
  justify-content: space-between;
}

.MuiSvgIcon-root.strain-accordion-expand-icon.dark-theme path {
  fill: #A1A591;
}

.MuiTableHead-root.strain-table-header {
  opacity: 0.8;
  background: linear-gradient(180deg, #6B9219 0%, #223102 100%);
}

.MuiTableCell-root.strain-table-title-cell {
  color: #FFFFFF;
}

.MuiTextField-root.strain-textfield {
  color: #252720;
  margin-top: 20px;
  margin-top: 0;
}
.MuiTextField-root.strain-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.strain-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.strain-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.strain-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.strain-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.strain-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.strain-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.strain-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.strain-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.strain-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.strain-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.strain-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}
.MuiTextField-root.strain-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.strain-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.strain-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.strain-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.strain-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.strain-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiBox-root.progress-ring-plot-area path {
  fill: #C5DE6E;
}
.MuiBox-root.progress-ring-plot-area path.progress-ring-completion-ring {
  fill: #819C24;
}
.MuiBox-root.progress-ring-plot-area text.progress-ring-title {
  fill: #4E5D15;
  text-anchor: middle;
  text-transform: uppercase;
  transform: translate(0, -48px);
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.035em;
  line-height: 1.58;
}
.MuiBox-root.progress-ring-plot-area text.progress-ring-value {
  fill: #4E5D15;
  text-anchor: middle;
  transform: translate(0, -8px);
  font-size: 2.6875rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.12;
}
.MuiBox-root.progress-ring-plot-area.dark-theme path {
  fill: #414437;
}
.MuiBox-root.progress-ring-plot-area.dark-theme path.progress-ring-completion-ring {
  fill: #B1D23A;
}
.MuiBox-root.progress-ring-plot-area.dark-theme text.progress-ring-title,
.MuiBox-root.progress-ring-plot-area.dark-theme text.progress-ring-value {
  fill: #B1D23A;
}

.MuiBox-root.timeline-chart-box {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 1220px;
  width: 100%;
}

.MuiBox-root.timeline-chart-title-box {
  color: #414437;
  background-color: #C5DE6E;
  padding: 8px;
  text-align: center;
  width: 100%;
}
.MuiBox-root.timeline-chart-title-box.dark-theme {
  background-color: #414437;
  color: #D9DBD3;
}

.MuiBox-root.timeline-chart-grid-entries-box {
  display: grid;
  gap: 2px;
  grid-template-columns: minmax(150px, auto) 1fr;
  margin-top: 2px;
  overflow: auto;
  width: 100%;
}

.MuiBox-root.timeline-chart-row-header-box {
  color: #6F745F;
  background-color: #E6F1C1;
  padding: 8px;
  position: sticky;
  left: 0;
}
.MuiBox-root.timeline-chart-row-header-box.dark-theme {
  background-color: #303130;
  color: #D9DBD3;
}

.MuiBox-root.timeline-chart-row-content-box {
  display: grid;
  gap: 2px;
}

.MuiBox-root.timeline-chart-value-box {
  color: #6F745F;
  background-color: #E6F1C1;
  text-align: center;
  padding: 8px;
}
.MuiBox-root.timeline-chart-value-box.dark-theme {
  background-color: #303130;
  color: #D9DBD3;
}

.MuiPaper-root.detail-and-del-btn-paper {
  background-color: transparent;
  display: flex;
  margin-left: auto;
  width: fit-content;
}

.MuiSvgIcon-root.detail-and-del-btn-detail-icon {
  height: 20px;
  width: 20px;
}
.MuiSvgIcon-root.detail-and-del-btn-detail-icon:hover {
  cursor: pointer;
}

.MuiSvgIcon-root.detail-and-del-btn-delete-icon {
  height: 20px;
  margin-left: 48px;
  margin-right: 2px;
  width: 20px;
}
.MuiSvgIcon-root.detail-and-del-btn-delete-icon:hover {
  cursor: pointer;
}

.MuiSvgIcon-root.detail-and-del-btn-delete-icon.dark-theme path,
.MuiSvgIcon-root.detail-and-del-btn-detail-icon.dark-theme path {
  fill: #BDC0B2;
}

.MuiBox-root.sub-phase-entry-box {
  align-items: center;
  display: flex;
  gap: 10px;
}

.MuiTypography-root.sub-phase-entry-typo {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: max-content;
}

.MuiSvgIcon-root.sub-phase-entry-text-info-icon path {
  fill: red;
}

.MuiTextField-root.sub-phase-entry-textfield {
  max-width: 35%;
  color: #252720;
  margin-top: 20px;
  margin: 0;
}
.MuiTextField-root.sub-phase-entry-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.sub-phase-entry-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.sub-phase-entry-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.sub-phase-entry-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.sub-phase-entry-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.sub-phase-entry-textfield.sub-phase-entry-disabled .MuiInputLabel-root {
  color: #A1A591;
}
.MuiTextField-root.sub-phase-entry-textfield.sub-phase-entry-disabled fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #A1A591;
}

.MuiCheckbox-root.sub-phase-entry-checkbox {
  margin-top: 20px;
}

.MuiTypography-root.sub-phase-entry-typo.dark-theme {
  color: #BDC0B2;
}

.MuiTextField-root.sub-phase-entry-textfield.dark-theme {
  background-color: #303130;
  margin: 0;
}
.MuiTextField-root.sub-phase-entry-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.sub-phase-entry-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.sub-phase-entry-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.sub-phase-entry-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.sub-phase-entry-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.sub-phase-entry-textfield.dark-theme.sub-phase-entry-disabled .MuiInputLabel-root {
  color: #5D614F;
}
.MuiTextField-root.sub-phase-entry-textfield.dark-theme.sub-phase-entry-disabled fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #5D614F;
}

.MuiCheckbox-root.sub-phase-entry-checkbox.dark-theme path {
  fill: #A1A591;
}

.MuiBox-root.misc-breadcrumb-box {
  align-items: center;
  display: flex;
  user-select: none;
}

.MuiBox-root.misc-breadcrumb-entry-box {
  text-decoration: none;
  color: #5D614F;
  font-size: 0.875rem;
  font-weight: -0.05em;
  letter-spacing: 1.29;
  width: initial;
}
.MuiBox-root.misc-breadcrumb-entry-box:hover {
  color: #5D614F;
  cursor: pointer;
  text-decoration: none;
}
.MuiBox-root.misc-breadcrumb-entry-box:last-of-type:hover {
  cursor: default;
}
.MuiBox-root.misc-breadcrumb-entry-box.dark-theme {
  color: #A1A591;
}
.MuiBox-root.misc-breadcrumb-entry-box.dark-theme:hover {
  color: #A1A591;
}

.MuiSvgIcon-root.misc-breadcrumb-sep-icon {
  height: 16px;
  margin: 0 4px;
  width: 16px;
}
.MuiSvgIcon-root.misc-breadcrumb-sep-icon.dark-theme path {
  stroke: #A1A591;
}

.MuiFab-root.prev-next-fab-fab {
  background-color: #819C24;
  bottom: 32px;
  padding: 0;
  position: absolute;
  right: 32px;
}
.MuiFab-root.prev-next-fab-fab.MuiFab-circular {
  height: 34px;
  min-height: 34px;
  width: 34px;
}
.MuiFab-root.prev-next-fab-fab path {
  fill: #1A1F07;
}
.MuiFab-root.prev-next-fab-fab:hover {
  background-color: #819C24;
}

.MuiBox-root.prev-next-fab-prev-step-box,
.MuiBox-root.prev-next-fab-next-step-box {
  height: 100%;
  width: 50%;
}
.MuiBox-root.prev-next-fab-prev-step-box.prev-next-fab-single-step,
.MuiBox-root.prev-next-fab-next-step-box.prev-next-fab-single-step {
  width: 100%;
}

.MuiSvgIcon-root.prev-next-fab-prev-step,
.MuiSvgIcon-root.prev-next-fab-next-step {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  height: 100%;
  opacity: 1;
  transform: rotate(90deg);
  width: 100%;
}
.MuiSvgIcon-root.prev-next-fab-prev-step:hover,
.MuiSvgIcon-root.prev-next-fab-next-step:hover {
  background-color: #BDD959;
}
.MuiSvgIcon-root.prev-next-fab-prev-step.prev-next-fab-single-step,
.MuiSvgIcon-root.prev-next-fab-next-step.prev-next-fab-single-step {
  border-radius: 50%;
}
.MuiSvgIcon-root.prev-next-fab-prev-step.prev-next-fab-disabled-step,
.MuiSvgIcon-root.prev-next-fab-next-step.prev-next-fab-disabled-step {
  background-color: #819C24;
  opacity: 0.4;
}
.MuiSvgIcon-root.prev-next-fab-prev-step.prev-next-fab-disabled-step:hover,
.MuiSvgIcon-root.prev-next-fab-next-step.prev-next-fab-disabled-step:hover {
  background-color: #819C24;
}

.MuiSvgIcon-root.prev-next-fab-next-step {
  transform: rotate(270deg);
}

.MuiBox-root.download-metrc-data-msg-box {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 472px;
  padding-top: 72px;
  width: 100%;
}

.MuiBox-root.download-metrc-data-msg-download-box {
  height: 48px;
  padding: 4px;
  width: 48px;
}

.MuiTypography-root.download-metrc-data-msg-title-typo {
  color: #090A08;
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.13;
  margin-top: 36px;
}

.MuiTypography-root.download-metrc-data-msg-time-typo {
  color: #090A08;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
  margin-top: 12px;
}

.MuiBox-root.download-metrc-data-msg-download-box.dark-theme svg.MuiCircularProgress-svg circle {
  stroke: #6F745F;
}

.MuiTypography-root.download-metrc-data-msg-title-typo.dark-theme {
  color: #BDC0B2;
}

.MuiTypography-root.download-metrc-data-msg-time-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.accordion-detail-msg-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  width: 100%;
}
.MuiTypography-root.accordion-detail-msg-title-typo {
  color: #090A08;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.56;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.MuiTypography-root.accordion-detail-msg-value-typo {
  color: #5D614F;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.56;
  max-width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.MuiBox-root.drag-accordion-card-content-box {
  margin-top: 16px;
}
.MuiBox-root.drag-accordion-card-content-box div:first-of-type {
  margin-top: 0;
}

.MuiTypography-root.row-header-typo-msg-typo {
  max-width: 12ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MuiModal-root.entity-detail-popup-modal {
  align-items: center;
  display: flex;
  justify-content: center;
  outline: 0;
}
.MuiModal-root.entity-detail-popup-modal .MuiBackdrop-root {
  background-color: #414437;
}

.MuiPaper-root.entity-detail-popup-paper {
  background-color: #F7FAEA;
  max-height: 100vh;
  overflow: auto;
  padding: 64px 72px;
  position: relative;
}

.MuiBox-root.entity-detail-popup-close-icon-box {
  margin-left: auto;
  padding-top: 16px;
  position: absolute;
  top: 0;
  right: 72px;
  width: fit-content;
}

.MuiSvgIcon-root.entity-detail-popup-close-icon:hover {
  cursor: pointer;
}
.MuiSvgIcon-root.entity-detail-popup-close-icon.dark-theme path {
  fill: #6F745F;
}

.MuiPaper-root.entity-detail-popup-header-paper {
  background-color: transparent;
}

.MuiTypography-root.entity-detail-popup-title-typo {
  margin-bottom: 8px;
  color: #090A08;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
  margin-top: 12px;
}

.MuiPaper-root.entity-detail-popup-head-content-paper {
  align-items: flex-start;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
}

.MuiTypography-root.entity-detail-popup-head-content-typo {
  flex-grow: 1;
  margin-right: 8px;
  color: #5D614F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}

.MuiButton-root.entity-detail-popup-save-button {
  background-color: #E6F1C1;
  border-radius: 0;
  min-width: 190px;
  padding: 12px;
  text-transform: none;
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiButton-root.entity-detail-popup-save-button:hover {
  background-color: #E6F1C1;
}

.MuiModal-root.entity-detail-popup-modal.dark-theme .MuiBackdrop-root {
  background-color: #303130;
}

.MuiPaper-root.entity-detail-popup-paper.dark-theme {
  background-color: #090A08;
}

.MuiTypography-root.entity-detail-popup-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiButton-root.entity-detail-popup-save-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.entity-detail-popup-save-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiTypography-root.roomtable-popup-level-typo,
.MuiTypography-root.roomtable-popup-desc-typo,
.MuiTypography-root.roomtable-popup-name-typo,
.MuiTypography-root.sensor-popup-kpi-typo {
  color: #252720;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.45;
}

.MuiDivider-root.roomtable-popup-divider,
.MuiDivider-root.sensor-popup-divider {
  background-color: #090A08;
  margin-top: 32px;
}

.MuiBox-root.roomtable-popup-level-entry-box {
  margin-top: 16px;
}
.MuiBox-root.roomtable-popup-level-entry-box:first-of-type {
  margin-top: 0px;
}

.MuiSvgIcon-root.roomtable-popup-level-entry-cancel-icon {
  display: block;
  margin-left: auto;
}
.MuiSvgIcon-root.roomtable-popup-level-entry-cancel-icon:hover {
  cursor: pointer;
}

.MuiTextField-root.roomtable-popup-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.roomtable-popup-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.roomtable-popup-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.roomtable-popup-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.roomtable-popup-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.roomtable-popup-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}

.MuiButton-root.roomtable-popup-add-level-button {
  align-items: center;
  background-color: #4E5D15;
  border-radius: 0;
  flex-direction: column;
  justify-content: center;
  margin-top: 32px;
  padding: 40px 20px;
  text-transform: none;
}
.MuiButton-root.roomtable-popup-add-level-button:hover {
  background-color: #4E5D15;
}

.MuiTextField-root.roomtable-popup-dimension-textfield {
  width: calc(50% - 8px);
}
.MuiTextField-root.roomtable-popup-dimension-textfield:nth-of-type(even) {
  margin-right: 8px;
}
.MuiTextField-root.roomtable-popup-dimension-textfield:nth-of-type(odd) {
  margin-left: 8px;
}

.MuiBox-root.roomtable-popup-levels-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.MuiSvgIcon-root.roomtable-popup-add-level-button-icon path {
  fill: none;
}

.MuiTypography-root.roomtable-popup-level-typo.dark-theme,
.MuiTypography-root.roomtable-popup-desc-typo.dark-theme,
.MuiTypography-root.roomtable-popup-name-typo.dark-theme,
.MuiTypography-root.sensor-popup-kpi-typo.dark-theme {
  color: #A1A591;
}

.MuiDivider-root.roomtable-popup-divider.dark-theme,
.MuiDivider-root.sensor-popup-divider.dark-theme {
  background-color: #414437;
}

.MuiSvgIcon-root.roomtable-popup-level-entry-cancel-icon.dark-theme path {
  fill: #6F745F;
}

.MuiTextField-root.roomtable-popup-textfield.dark-theme {
  background-color: #252720;
}
.MuiTextField-root.roomtable-popup-textfield.dark-theme .MuiInputLabel-root, .MuiTextField-root.roomtable-popup-textfield.dark-theme .MuiInputBase-root {
  color: #A1A591;
}
.MuiTextField-root.roomtable-popup-textfield.dark-theme fieldset {
  border-color: #6F745F;
}
.MuiTextField-root.roomtable-popup-textfield.dark-theme:has(textarea) {
  background-color: #303130;
}

.MuiButton-root.roomtable-popup-add-level-button.dark-theme {
  background-color: #303130;
  color: #B1D23A;
  border: 1px solid #B1D23A;
}
.MuiButton-root.roomtable-popup-add-level-button.dark-theme:hover {
  background-color: #303130;
}

.MuiSvgIcon-root.roomtable-popup-add-level-button-icon.dark-theme path {
  stroke: #B1D23A;
}

.MuiDivider-root.attachtable-popup-divider {
  margin-top: 32px;
}

.MuiBox-root.attachtable-popup-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
}

.MuiTypography-root.attachtable-popup-notable-typo {
  text-align: center;
  color: #A1A591;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  width: 288px;
}

.MuiButton-root.attachtable-popup-createtable-button {
  background-color: transparent;
  border: 1px solid #A1A591;
  border-radius: 0;
  padding: 10px 40px;
  text-transform: none;
  color: #A1A591;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 20px;
  width: fit-content;
}
.MuiButton-root.attachtable-popup-createtable-button:hover {
  background-color: transparent;
}

.MuiBox-root.attachtable-popup-actiontitle-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.MuiButton-root.attachtable-popup-addnewtable-button {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  text-transform: none;
  color: #B1D23A;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
  margin-top: initial;
  width: initial;
}
.MuiButton-root.attachtable-popup-addnewtable-button:hover {
  background-color: transparent;
}

.MuiTextField-root.attachtable-popup-textfield {
  border-radius: 0;
  margin-top: 12px;
}

.MuiTypography-root.attachtable-popup-tablename-typo,
.MuiTypography-root.attachtable-popup-levelcount-typo {
  margin-left: auto;
}

.MuiTextField-root.sensor-popup-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.sensor-popup-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.sensor-popup-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.sensor-popup-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.sensor-popup-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.sensor-popup-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}

.MuiTextField-root.sensor-popup-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.sensor-popup-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.sensor-popup-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.sensor-popup-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.sensor-popup-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.sensor-popup-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiMenuItem-root.sensor-popup-menuitem.dark-theme {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.sensor-popup-menuitem.dark-theme:hover {
  background-color: #6F745F;
}
.MuiMenuItem-root.sensor-popup-menuitem.dark-theme.Mui-selected {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.sensor-popup-menuitem.dark-theme.sensor-popup-menuitem-focus {
  background-color: #5D614F;
}
.MuiMenuItem-root.sensor-popup-menuitem.dark-theme.Mui-disabled {
  background-color: #090A08;
  color: #A1A591;
}

.MuiTextField-root.light-entry-popup-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.light-entry-popup-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.light-entry-popup-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.light-entry-popup-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.light-entry-popup-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.light-entry-popup-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}

.MuiTextField-root.light-entry-popup-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.light-entry-popup-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.light-entry-popup-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.light-entry-popup-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.light-entry-popup-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.light-entry-popup-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiTextField-root.add-light-popup-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.add-light-popup-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.add-light-popup-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.add-light-popup-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.add-light-popup-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.add-light-popup-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}

.MuiTextField-root.add-light-popup-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.add-light-popup-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.add-light-popup-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.add-light-popup-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.add-light-popup-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.add-light-popup-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiTextField-root.invite-user-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.invite-user-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.invite-user-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.invite-user-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.invite-user-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.invite-user-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}

.MuiTextField-root.invite-user-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.invite-user-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.invite-user-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.invite-user-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.invite-user-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.invite-user-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiMenuItem-root.invite-user-role-menuitem.dark-theme,
.MuiMenuItem-root.invite-user-perm-menuitem.dark-theme {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.invite-user-role-menuitem.dark-theme:hover,
.MuiMenuItem-root.invite-user-perm-menuitem.dark-theme:hover {
  background-color: #6F745F;
}
.MuiMenuItem-root.invite-user-role-menuitem.dark-theme.Mui-selected,
.MuiMenuItem-root.invite-user-perm-menuitem.dark-theme.Mui-selected {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.invite-user-role-menuitem.dark-theme.menuitem-focus-dark,
.MuiMenuItem-root.invite-user-perm-menuitem.dark-theme.menuitem-focus-dark {
  background-color: #5D614F;
}
.MuiMenuItem-root.invite-user-role-menuitem.dark-theme.Mui-disabled,
.MuiMenuItem-root.invite-user-perm-menuitem.dark-theme.Mui-disabled {
  opacity: 1;
  background-color: #414437;
  color: #6F745F;
}

.MuiTextField-root.create-permission-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.create-permission-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.create-permission-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.create-permission-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.create-permission-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.create-permission-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}

.MuiBox-root.create-permission-roles-list-box {
  border-bottom: 1px solid #D6E797;
  height: 360px;
  min-width: fit-content;
  margin-top: 24px;
  overflow: auto;
}

.MuiBox-root.create-permission-role-header-box {
  background-color: #D6E797;
  display: flex;
  left: 0;
  min-width: fit-content;
  position: sticky;
  top: 0;
  z-index: 1;
}

.MuiTypography-root.create-permission-role-header-typo {
  color: #4E5D15;
  padding-bottom: 8px;
  padding-top: 8px;
  text-align: center;
}

.MuiBox-root.create-permission-role-content-box {
  display: flex;
  flex-direction: column;
}

.MuiBox-root.create-permission-entry-box {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  text-align: center;
}

.MuiTypography-root.create-permission-slno-typo {
  flex-basis: 10%;
}

.MuiTypography-root.create-permission-name-typo {
  flex-basis: 15%;
}

.MuiCheckbox-root.create-permission-checkbox {
  padding: 16px 15px;
}

.MuiTextField-root.create-permission-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.create-permission-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.create-permission-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.create-permission-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.create-permission-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.create-permission-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiBox-root.create-permission-roles-list-box.dark-theme {
  border-color: #414437;
}

.MuiBox-root.create-permission-role-header-box.dark-theme {
  background-color: #2B2C2B;
}

.MuiTypography-root.create-permission-role-header-typo.dark-theme {
  color: #6F745F;
}

.MuiBox-root.create-permission-role-content-box.dark-theme {
  background-color: #303130;
}

.MuiTypography-root.create-permission-slno-typo.dark-theme,
.MuiTypography-root.create-permission-name-typo.dark-theme {
  color: #BDC0B2;
}

.MuiCheckbox-root.create-permission-checkbox.dark-theme .MuiSvgIcon-root path {
  fill: #A1A591;
}

.MuiBox-root.confirmation-loading-popup-wrapper-box {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.MuiBox-root.confirmation-loading-popup-content-box {
  background-color: #F7FAEA;
  padding: 60px 72px;
  width: 100%;
}

.MuiTypography-root.confirmation-loading-popup-title-typo {
  color: #4E5D15;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.56;
}

.MuiTypography-root.confirmation-loading-popup-message-typo {
  color: #1A1F07;
  margin-top: 16px;
}

.MuiBox-root.confirmation-loading-popup-action-buttons-box {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.MuiButton-root.confirmation-loading-popup-decline-button {
  background-color: #A1A591;
  border-radius: 0;
  padding: 12px;
  text-transform: none;
  color: #D9DBD3;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.25;
  width: 244px;
}
.MuiButton-root.confirmation-loading-popup-decline-button:hover {
  background-color: #A1A591;
}

.MuiButton-root.confirmation-loading-popup-proceed-button {
  background-color: #819C24;
  border-radius: 0;
  margin-left: 16px;
  padding: 12px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.25;
  width: 244px;
}
.MuiButton-root.confirmation-loading-popup-proceed-button:hover {
  background-color: #819C24;
}

.MuiBox-root.confirmation-loading-popup-content-box.dark-theme {
  background-color: #303130;
}

.MuiTypography-root.confirmation-loading-popup-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.confirmation-loading-popup-message-typo.dark-theme {
  color: #A1A591;
}

.MuiButton-root.confirmation-loading-popup-decline-button.dark-theme {
  background-color: #414437;
  color: #D9DBD3;
}

.MuiButton-root.confirmation-loading-popup-proceed-button.dark-theme {
  background-color: #A1A591;
  color: #414437;
}

.MuiTextField-root.create-strain-popup-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.create-strain-popup-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.create-strain-popup-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.create-strain-popup-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.create-strain-popup-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.create-strain-popup-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}

.MuiTextField-root.create-strain-popup-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.create-strain-popup-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.create-strain-popup-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.create-strain-popup-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.create-strain-popup-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.create-strain-popup-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiTextField-root.strain-entry-popup-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.strain-entry-popup-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.strain-entry-popup-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.strain-entry-popup-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.strain-entry-popup-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.strain-entry-popup-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}

.MuiTypography-root.strain-entry-popup-typo {
  color: #090A08;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 20px;
}
.MuiTypography-root.strain-entry-popup-typo.dark-theme {
  color: #A1A591;
}

.MuiTableCell-head.strain-entry-table-title {
  background-color: #819C24;
  border: none;
  color: #FFFFFF;
}

.MuiTextField-root.strain-entry-popup-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.strain-entry-popup-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.strain-entry-popup-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.strain-entry-popup-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.strain-entry-popup-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.strain-entry-popup-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiBox-root.create-metric-plan-popup-metric-and-target-type-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.MuiBox-root.create-metric-plan-popup-metric-and-target-type-box div:nth-of-type(1) {
  margin-right: 8px;
}
.MuiBox-root.create-metric-plan-popup-metric-and-target-type-box div:nth-of-type(2) {
  margin-left: 8px;
}

.MuiTextField-root.create-metric-plan-popup-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.create-metric-plan-popup-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.create-metric-plan-popup-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.create-metric-plan-popup-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.create-metric-plan-popup-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.create-metric-plan-popup-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}

.MuiMenuItem-root.create-metric-plan-popup-menuitem.dark-theme {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.create-metric-plan-popup-menuitem.dark-theme:hover {
  background-color: #6F745F;
}
.MuiMenuItem-root.create-metric-plan-popup-menuitem.dark-theme.Mui-selected {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.create-metric-plan-popup-menuitem.dark-theme.menuitem-focus-dark {
  background-color: #5D614F;
}
.MuiMenuItem-root.create-metric-plan-popup-menuitem.dark-theme.Mui-disabled {
  opacity: 1;
  background-color: #414437;
  color: #6F745F;
}

.MuiTextField-root.create-metric-plan-popup-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.create-metric-plan-popup-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.create-metric-plan-popup-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.create-metric-plan-popup-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.create-metric-plan-popup-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.create-metric-plan-popup-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiTextField-root.create-metric-cycle-popup-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.create-metric-cycle-popup-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.create-metric-cycle-popup-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.create-metric-cycle-popup-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.create-metric-cycle-popup-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.create-metric-cycle-popup-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}

.MuiTextField-root.create-metric-cycle-popup-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.create-metric-cycle-popup-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.create-metric-cycle-popup-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.create-metric-cycle-popup-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.create-metric-cycle-popup-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.create-metric-cycle-popup-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiTextField-root.crop-entry-in-detail-page-popup-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.crop-entry-in-detail-page-popup-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.crop-entry-in-detail-page-popup-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.crop-entry-in-detail-page-popup-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.crop-entry-in-detail-page-popup-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.crop-entry-in-detail-page-popup-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.crop-entry-in-detail-page-popup-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.crop-entry-in-detail-page-popup-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.crop-entry-in-detail-page-popup-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.crop-entry-in-detail-page-popup-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.crop-entry-in-detail-page-popup-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.crop-entry-in-detail-page-popup-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.crop-entry-in-detail-page-popup-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}

.MuiDivider-root.crop-entry-in-detail-page-popup-divider {
  background-color: #A1A591;
  margin-bottom: 16px;
  margin-top: 8px;
}
.MuiDivider-root.crop-entry-in-detail-page-popup-divider.dark-theme {
  background-color: #6F745F;
}

.MuiMenuItem-root.crop-entry-in-detail-page-popup-menuitem.dark-theme {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.crop-entry-in-detail-page-popup-menuitem.dark-theme:hover {
  background-color: #6F745F;
}
.MuiMenuItem-root.crop-entry-in-detail-page-popup-menuitem.dark-theme.Mui-selected {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.crop-entry-in-detail-page-popup-menuitem.dark-theme.menuitem-focus-dark {
  background-color: #5D614F;
}
.MuiMenuItem-root.crop-entry-in-detail-page-popup-menuitem.dark-theme.Mui-disabled {
  opacity: 1;
  background-color: #414437;
  color: #6F745F;
}

.MuiBox-root.crop-entry-in-detail-page-popup-box {
  display: flex;
  flex-direction: column;
}

.MuiBox-root.crop-entry-in-detail-page-popup-entry-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.MuiTypography-root.crop-entry-in-detail-page-popup-fixed-key-typo {
  color: #090A08;
  font-size: 1rem;
  font-weight: 500;
}
.MuiTypography-root.crop-entry-in-detail-page-popup-fixed-key-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.crop-entry-in-detail-page-popup-fixed-value-typo {
  color: #6F745F;
  font-size: 1rem;
  font-weight: 400;
}
.MuiTypography-root.crop-entry-in-detail-page-popup-fixed-value-typo.dark-theme {
  color: #BDC0B2;
}

.MuiTextField-root.add-strain-table-popup-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.add-strain-table-popup-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.add-strain-table-popup-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.add-strain-table-popup-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.add-strain-table-popup-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.add-strain-table-popup-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.add-strain-table-popup-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.add-strain-table-popup-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.add-strain-table-popup-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.add-strain-table-popup-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.add-strain-table-popup-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.add-strain-table-popup-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.add-strain-table-popup-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}
.MuiTextField-root.add-strain-table-popup-textfield.dark-theme {
  background-color: #303130;
  color: #D9DBD3;
}
.MuiTextField-root.add-strain-table-popup-textfield.add-strain-table-popup-filledinput, .MuiTextField-root.add-strain-table-popup-textfield.add-strain-table-popup-dropdown {
  margin-top: 0;
}
.MuiTextField-root.add-strain-table-popup-textfield.add-strain-table-popup-filledinput fieldset.MuiOutlinedInput-notchedOutline, .MuiTextField-root.add-strain-table-popup-textfield.add-strain-table-popup-dropdown fieldset.MuiOutlinedInput-notchedOutline {
  border: none;
}

.MuiMenuItem-root.add-strain-table-popup-menuitem.dark-theme {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.add-strain-table-popup-menuitem.dark-theme:hover {
  background-color: #6F745F;
}
.MuiMenuItem-root.add-strain-table-popup-menuitem.dark-theme.Mui-selected {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.add-strain-table-popup-menuitem.dark-theme.menuitem-focus-dark {
  background-color: #5D614F;
}
.MuiMenuItem-root.add-strain-table-popup-menuitem.dark-theme.Mui-disabled {
  opacity: 1;
  background-color: #414437;
  color: #6F745F;
}

.MuiBox-root.add-strain-table-popup-sel-strains-box {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 1420px;
  margin-top: 40px;
}

.MuiBox-root.strain-detail-inprodinv-popup-box {
  background-color: #F7FAEA;
  padding: 65px 32px;
  position: relative;
}
.MuiBox-root.strain-detail-inprodinv-popup-box.dark-theme {
  background-color: #151614;
}

.MuiBox-root.strain-detail-inprodinv-popup-close-icon-box {
  padding-top: 16px;
  position: absolute;
  right: 32px;
  top: 0;
}

.MuiSvgIcon-root.strain-detail-inprodinv-popup-close-icon:hover {
  cursor: pointer;
}
.MuiSvgIcon-root.strain-detail-inprodinv-popup-close-icon.dark-theme path {
  fill: #6F745F;
}

.MuiTypography-root.strain-detail-inprodinv-popup-title-typo {
  color: #324001;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 0;
}
.MuiTypography-root.strain-detail-inprodinv-popup-title-typo.dark-theme {
  color: #FFFFFF;
}

.MuiBox-root.strain-detail-inprodinv-popup-tables-box .MuiBox-root.strain-detail-inprodinv-popup-entry-table-box {
  margin-top: 23px;
}
.MuiBox-root.strain-detail-inprodinv-popup-tables-box .MuiTypography-root.strain-detail-inprodinv-popup-entry-content-typo {
  border-color: #C5DE6E;
}
.MuiBox-root.strain-detail-inprodinv-popup-tables-box.dark-theme .MuiTypography-root.strain-detail-inprodinv-popup-entry-content-typo {
  border-color: initial;
}

.MuiBox-root.metric-cycle-popup-static-box {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.MuiTypography-root.metric-cycle-popup-static-key-typo {
  color: #090A08;
  font-size: 1rem;
  font-weight: 500;
}
.MuiTypography-root.metric-cycle-popup-static-key-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.metric-cycle-popup-static-value-typo {
  color: #6F745F;
  font-size: 1rem;
  font-weight: 400;
}
.MuiTypography-root.metric-cycle-popup-static-value-typo.dark-theme {
  color: #BDC0B2;
}

.MuiTextField-root.metric-cycle-popup-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.metric-cycle-popup-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.metric-cycle-popup-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.metric-cycle-popup-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.metric-cycle-popup-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.metric-cycle-popup-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.metric-cycle-popup-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.metric-cycle-popup-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.metric-cycle-popup-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.metric-cycle-popup-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.metric-cycle-popup-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.metric-cycle-popup-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.metric-cycle-popup-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}

.MuiDivider-root.compare-room-qualplan-divider {
  margin-top: 24px;
}
.MuiDivider-root.compare-room-qualplan-divider.dark-theme {
  background-color: #6F745F;
}

.MuiTypography-root.compare-room-qualplan-phase-typo {
  margin-top: 24px;
}
.MuiTypography-root.compare-room-qualplan-phase-typo.dark-theme {
  color: #D9DBD3;
}

.MuiBox-root.compare-room-qualplan-common-box {
  margin-top: 24px;
}

.MuiTypography-root.compare-room-qualplan-common-typo.dark-theme {
  color: #D9DBD3;
}

.MuiBox-root.compare-room-qualplan-missing-box {
  margin-top: 16px;
}

.MuiTypography-root.compare-room-qualplan-missing-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.compare-room-qualplan-list-item-typo {
  margin-left: 16px;
  margin-top: 8px;
}
.MuiTypography-root.compare-room-qualplan-list-item-typo.dark-theme {
  color: #BDC0B2;
}

.MuiTextField-root.routines-popup-textfield {
  color: #252720;
  margin-top: 20px;
  margin-top: 0;
}
.MuiTextField-root.routines-popup-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.routines-popup-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.routines-popup-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.routines-popup-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.routines-popup-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.routines-popup-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.routines-popup-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.routines-popup-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.routines-popup-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.routines-popup-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.routines-popup-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.routines-popup-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}

.MuiTypography-root.routines-popup-title-typo {
  font-size: 24px;
}
.MuiTypography-root.routines-popup-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.routines-popup-typo.dark-theme {
  color: #D9DBD3;
}

.MuiBox-root.routines-popup-task {
  border: 1px solid #5D614F;
  padding: 20px;
}
.MuiBox-root.routines-popup-task.dark-theme {
  background-color: #303130;
}

.MuiBox-root.routines-popup-toolbox-container {
  padding: 12px;
}
.MuiBox-root.routines-popup-toolbox-container.dark-theme {
  background-color: #303130;
}

.MuiBox-root.routines-popup-task-value-container {
  border: 1px solid #6F745F;
  padding: 12px 16px;
}
.MuiBox-root.routines-popup-task-value-container.dark-theme {
  background-color: #252720;
}
.MuiBox-root.routines-popup-task-value-container.selected {
  background-color: #819C24;
}

.MuiPaper-root.routines-popup-tasks-container {
  background: none;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.MuiStack-root.routines-popup-assignee {
  padding: 5px;
  border: 1px solid #6F745F;
}
.MuiStack-root.routines-popup-assignee.dark-theme {
  background-color: #252720;
}
.MuiStack-root.routines-popup-assignee.selected {
  background-color: #819C24;
}

.MuiChip-root.routines-popup-status-chip.dark-theme {
  background-color: #414437;
}
.MuiChip-root.routines-popup-status-chip.dark-theme .MuiChip-label {
  color: #D9DBD3;
}

.MuiTableContainer-root.display-table-table-container {
  border-radius: 0;
}

.MuiTable-root.display-table-table {
  width: 100%;
}

.MuiTableRow-root.display-table-table-row td, .MuiTableRow-root.display-table-table-row th {
  border: 1px solid #A1A591;
}

.MuiTableCell-head.display-table-title-cell {
  background-color: #D6E797;
  border: 1px solid #A1A591;
  border-bottom: none;
  padding: 6px 15px;
  white-space: nowrap;
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}

.MuiTableCell-root.display-table-entry-cell {
  padding: 6px 15px;
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}

.MuiTableCell-root.display-table-no-entity-table-cell {
  text-align: center;
  color: #6F745F;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: -0.05em;
}

.MuiPaper-root.display-table-table-footer-paper {
  border-top: 1px solid #A1A591;
  display: flex;
  justify-content: flex-end;
  padding: 24px 41px;
  width: 100%;
}

.MuiButton-root.display-table-add-button {
  background-color: #4E5D15;
  border-radius: 0;
  padding: 12px 24px;
  text-transform: none;
  color: #E6F1C1;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiButton-root.display-table-add-button:hover {
  background-color: #4E5D15;
}

.MuiTableRow-root.display-table-table-row.dark-theme {
  background-color: #303130;
}

.MuiTableCell-head.display-table-title-cell.dark-theme {
  background-color: #1A1B19;
  color: #D9DBD3;
}

.MuiTableCell-root.display-table-entry-cell.dark-theme {
  color: #BDC0B2;
  background-color: #1A1B19;
}

.MuiTableCell-root.display-table-no-entity-table-cell.dark-theme {
  background-color: #303130;
  color: #BDC0B2;
}

.MuiPaper-root.display-table-table-footer-paper.dark-theme {
  border-color: #A1A591;
  background-color: #303130;
}

.MuiButton-root.display-table-add-button.dark-theme {
  border: 1px solid #B1D23A;
  background-color: transparent;
  color: #B1D23A;
}

.MuiTablePagination-root.display-table-pagination.dark-theme {
  color: #A1A591;
}

.MuiTableContainer-root.display-table-with-dropdown-table-container {
  background-color: #F7FAEA;
  border-radius: 0;
}
.MuiTableContainer-root.display-table-with-dropdown-table-container.dark-theme {
  background-color: #303130;
}

.MuiTable-root.display-table-with-dropdown-table {
  width: 100%;
}

.MuiTableRow-root.display-table-with-dropdown-table-row td, .MuiTableRow-root.display-table-with-dropdown-table-row th {
  border: 0;
}

.MuiTableCell-head.display-table-with-dropdown-title-cell {
  background-color: #D6E797;
  border-bottom: none;
  padding-bottom: 12px;
  padding-top: 12px;
  text-align: center;
  white-space: nowrap;
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiTableCell-head.display-table-with-dropdown-title-cell.dark-theme {
  background-color: #2B2C2B;
  color: #6F745F;
}

.MuiTableCell-root.display-table-with-dropdown-entry-cell {
  text-align: center;
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiTableCell-root.display-table-with-dropdown-entry-cell.dark-theme {
  color: #BDC0B2;
}

.MuiTableCell-root.display-table-with-dropdown-no-entity-table-cell {
  text-align: center;
  color: #6F745F;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: -0.05em;
}
.MuiTableCell-root.display-table-with-dropdown-no-entity-table-cell.dark-theme {
  background-color: #303130;
  color: #BDC0B2;
}

.MuiPaper-root.display-table-with-dropdown-table-footer-paper {
  border-top: 1px solid #A1A591;
  display: flex;
  justify-content: flex-end;
  padding: 24px 41px;
  width: 100%;
}
.MuiPaper-root.display-table-with-dropdown-table-footer-paper.dark-theme {
  background-color: #303130;
}

.MuiButton-root.display-table-with-dropdown-add-button {
  background-color: #4E5D15;
  border-radius: 0;
  padding: 12px 24px;
  text-transform: none;
  color: #E6F1C1;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiButton-root.display-table-with-dropdown-add-button:hover {
  background-color: #4E5D15;
}
.MuiButton-root.display-table-with-dropdown-add-button.dark-theme {
  border: 1px solid #B1D23A;
  background-color: transparent;
  color: #B1D23A;
}

.MuiBox-root.in-production-inv-table-box {
  margin-top: 27px;
}
.MuiBox-root.in-production-inv-table-title-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.MuiTypography-root.in-production-inv-table-title-typo {
  color: #F7FAEA;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiTypography-root.in-production-inv-table-title-typo.dark-theme {
  color: #FFFFFF;
}

.MuiButton-root.in-production-inv-table-button {
  border-radius: 0;
  padding: 0;
  text-transform: none;
  color: #D6E797;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiButton-root.in-production-inv-table-button.dark-theme {
  color: #B1D23A;
}

.MuiBox-root.in-production-inv-table-table-box {
  display: grid;
  margin-top: 14px;
}
.MuiTypography-root.in-production-inv-table-entry-row-title-typo {
  padding-bottom: 4px;
  padding-top: 4px;
  color: #496019;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiTypography-root.in-production-inv-table-entry-row-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.in-production-inv-table-entry-value-typo {
  overflow-wrap: anywhere;
  padding-bottom: 4px;
  padding-top: 4px;
  text-align: center;
  border: 1px solid #E9FFC6;
  color: #496019;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiTypography-root.in-production-inv-table-entry-value-typo.dark-theme {
  border-color: #6F745F;
  color: #A1A591;
}
.MuiTypography-root.in-production-inv-table-entry-value-typo.dark-theme.table-header {
  color: #D9DBD3;
}

.MuiBox-root.wizard-base-box {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 60px 32px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}
.MuiBox-root.wizard-base-box.dark-theme {
  background-color: #1A1B19;
}
.MuiBox-root.wizard-base-content-box {
  background-color: #E6F1C1;
  column-gap: 120px;
  display: grid;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
  padding: 56px 120px;
}
.MuiBox-root.wizard-base-content-box.dark-theme {
  background-color: #090A08;
}

.MuiBox-root.wizard-base-left-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 376px;
  width: 100%;
}
.MuiTypography-root.wizard-base-info-typo {
  margin-top: 48px;
  max-width: 268px;
  text-align: center;
  color: #4E5D15;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
  width: 100%;
}
.MuiTypography-root.wizard-base-info-typo.dark-theme {
  color: #B1D23A;
}

.MuiTypography-root.wizard-base-info-sub-typo {
  margin-top: 16px;
  text-align: center;
  color: #414437;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
}
.MuiTypography-root.wizard-base-info-sub-typo.dark-theme {
  color: #BDC0B2;
}

.MuiButton-root.wizard-base-action-button {
  border-color: #5D614F;
  border-radius: 0;
  text-transform: none;
  color: #5D614F;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 42px;
  width: 100%;
}
.MuiButton-root.wizard-base-action-button:hover {
  border-color: #5D614F;
}
.MuiButton-root.wizard-base-action-button.dark-theme {
  color: #D9DBD3;
  border-color: #D9DBD3;
}
.MuiButton-root.wizard-base-action-button.dark-theme:hover {
  background-color: #090A08;
}

.MuiBox-root.wizard-base-right-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 376px;
  width: fit-content;
}
.MuiTypography-root.wizard-base-todo-typo {
  max-width: 268px;
  color: #252720;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.45;
  width: 100%;
}
.MuiTypography-root.wizard-base-todo-typo.dark-theme {
  color: #BDC0B2;
}

.MuiBox-root.wizard-base-todo-list-box {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.MuiSvgIcon-root.wizard-base-step-connector-icon {
  margin-bottom: 6px;
  margin-top: 6px;
}
.MuiSvgIcon-root.wizard-base-step-connector-icon line {
  stroke: #A1A591;
}
.MuiSvgIcon-root.wizard-base-step-connector-icon.wizard-base-curr-step line {
  stroke: #819C24;
}
.MuiSvgIcon-root.wizard-base-step-connector-icon.dark-theme line {
  stroke: #A1A591;
}
.MuiSvgIcon-root.wizard-base-step-connector-icon.dark-theme.wizard-base-curr-step line {
  stroke: #B1D23A;
}

.MuiBox-root.wizard-base-todo-item-box {
  align-items: center;
  display: flex;
}
.MuiTypography-root.wizard-base-todo-step-typo {
  margin-left: 18px;
  color: #252720;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiTypography-root.wizard-base-todo-step-typo.wizard-base-curr-step {
  font-weight: 700;
}
.MuiTypography-root.wizard-base-todo-step-typo.dark-theme {
  color: #BDC0B2;
}

.MuiBox-root.wizard-farms-box {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 60px 32px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}
.MuiBox-root.wizard-farms-box.dark-theme {
  background-color: #1A1B19;
}
.MuiBox-root.wizard-farms-content-box {
  background-color: #E6F1C1;
  column-gap: 120px;
  display: grid;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
  padding: 56px 120px;
}
.MuiBox-root.wizard-farms-content-box.dark-theme {
  background-color: #090A08;
}

.MuiBox-root.wizard-farms-left-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 376px;
  width: 100%;
}
.MuiTypography-root.wizard-farms-info-typo {
  margin-top: 48px;
  max-width: 268px;
  text-align: center;
  color: #4E5D15;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
  width: 100%;
}
.MuiTypography-root.wizard-farms-info-typo.dark-theme {
  color: #B1D23A;
}

.MuiTypography-root.wizard-farms-info-sub-typo {
  margin-top: 16px;
  text-align: center;
  color: #414437;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
}
.MuiTypography-root.wizard-farms-info-sub-typo.dark-theme {
  color: #BDC0B2;
}

.MuiButton-root.wizard-farms-action-button {
  border-color: #5D614F;
  border-radius: 0;
  text-transform: none;
  color: #5D614F;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 42px;
  width: 100%;
}
.MuiButton-root.wizard-farms-action-button:hover {
  border-color: #5D614F;
}
.MuiButton-root.wizard-farms-action-button.dark-theme {
  color: #D9DBD3;
  border-color: #D9DBD3;
}
.MuiButton-root.wizard-farms-action-button.dark-theme:hover {
  background-color: #090A08;
}

.MuiBox-root.wizard-farms-right-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 376px;
  width: fit-content;
}
.MuiTypography-root.wizard-farms-todo-typo {
  max-width: 268px;
  color: #252720;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.45;
  width: 100%;
}
.MuiTypography-root.wizard-farms-todo-typo.dark-theme {
  color: #BDC0B2;
}

.MuiBox-root.wizard-farms-todo-list-box {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.MuiSvgIcon-root.wizard-farms-step-connector-icon {
  margin-bottom: 6px;
  margin-top: 6px;
}
.MuiSvgIcon-root.wizard-farms-step-connector-icon line {
  stroke: #A1A591;
}
.MuiSvgIcon-root.wizard-farms-step-connector-icon.wizard-farms-curr-step line {
  stroke: #819C24;
}
.MuiSvgIcon-root.wizard-farms-step-connector-icon.dark-theme line {
  stroke: #A1A591;
}
.MuiSvgIcon-root.wizard-farms-step-connector-icon.dark-theme.wizard-farms-curr-step line {
  stroke: #B1D23A;
}

.MuiBox-root.wizard-farms-todo-item-box {
  align-items: center;
  display: flex;
}
.MuiTypography-root.wizard-farms-todo-step-typo {
  margin-left: 18px;
  color: #252720;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiTypography-root.wizard-farms-todo-step-typo.wizard-farms-curr-step {
  font-weight: 700;
}
.MuiTypography-root.wizard-farms-todo-step-typo.dark-theme {
  color: #BDC0B2;
}

.MuiBox-root.wizard-plans-box {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 60px 32px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}
.MuiBox-root.wizard-plans-box.dark-theme {
  background-color: #1A1B19;
}
.MuiBox-root.wizard-plans-content-box {
  background-color: #E6F1C1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 120px;
}
.MuiBox-root.dash-mini-card-box {
  background-color: #E9FFC6;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  height: 200px;
  padding: 14px;
  width: 180px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out !important;
}
.MuiBox-root.dash-mini-card-box:last-of-type {
  margin-right: 0;
}

.MuiSvgIcon-root.dash-mini-card-other-icon {
  background-color: #444444;
  border-radius: 50%;
  height: 48px;
  width: 48px;
}

.MuiSvgIcon-root.dash-mini-card-icon {
  height: 48px;
  width: 48px;
}

.MuiButton-root.dash-mini-card-see-more-button {
  background-color: transparent;
  min-width: 0;
  padding: 0;
  text-transform: none;
  color: #6B9219;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  margin-top: auto;
  width: fit-content;
}
.MuiButton-root.dash-mini-card-see-more-button:hover {
  background-color: transparent;
  color: #6B9219;
}

.MuiTypography-root.dash-mini-card-title-typo {
  max-width: 152px;
  opacity: 0.8;
  white-space: break-spaces;
  color: #496019;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.005em;
  line-height: 1.28;
  margin-top: 8px;
}

.MuiTypography-root.dash-mini-card-desc-typo {
  max-width: 152px;
  opacity: 0.8;
  white-space: break-spaces;
  color: #496019;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.005em;
  line-height: 1.28;
}

.MuiTypography-root.dash-mini-card-value-typo {
  max-width: 152px;
  color: #413E2E;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.005em;
  line-height: 1.29;
  margin-top: 16px;
}

.MuiBox-root.dash-mini-card-box.dark-theme {
  background-color: #303130;
}

.MuiSvgIcon-root.dash-mini-card-icon.dark-theme {
  border-radius: 50%;
  fill: transparent;
  background-image: linear-gradient(180deg, #242523 0%, rgba(36, 37, 35, 0) 100%);
}
.MuiSvgIcon-root.dash-mini-card-icon.dark-theme path {
  fill: transparent;
  stroke: #BDC0B2;
}
.MuiSvgIcon-root.dash-mini-card-icon.dark-theme rect {
  fill: transparent;
}

.MuiButton-root.dash-mini-card-see-more-button.dark-theme {
  color: #B1D23A;
}

.MuiTypography-root.dash-mini-card-desc-typo.dark-theme,
.MuiTypography-root.dash-mini-card-title-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.dash-mini-card-value-typo.dark-theme {
  color: #D9DBD3;
}

.MuiBox-root.dash-mega-card-box {
  background-color: #E9FFC6;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  height: 302px;
  padding: 20px;
  position: relative;
  width: 100%;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out !important;
}
.MuiBox-root.dash-mega-card-box:last-of-type {
  margin-right: 0;
}

.MuiSvgIcon-root.dash-mega-card-other-icon {
  background-color: #444444;
  border-radius: 50%;
  height: 128px;
  width: 128px;
}

.MuiSvgIcon-root.dash-mega-card-icon {
  margin-left: auto;
}
.MuiSvgIcon-root.dash-mega-card-icon path {
  fill: #444444;
}

.MuiButton-root.dash-mega-card-see-more-button {
  background-color: transparent;
  min-width: 0;
  padding: 10px 0;
  text-transform: none;
  color: #6B9219;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.29;
  margin-top: auto;
  width: fit-content;
}
.MuiButton-root.dash-mega-card-see-more-button:hover {
  background-color: transparent;
  color: #6B9219;
}

.MuiTypography-root.dash-mega-card-title-typo {
  max-width: 222px;
  opacity: 0.8;
  white-space: break-spaces;
  color: #496019;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.28;
  margin-top: 8px;
}

.MuiTypography-root.dash-mega-card-desc-typo {
  max-width: 222px;
  opacity: 0.8;
  white-space: break-spaces;
  color: #496019;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1;
}

.MuiTypography-root.dash-mega-card-value-typo {
  max-width: 152px;
  opacity: 1;
  color: #031628;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.005em;
  line-height: 1.28;
  margin-top: auto;
}

.MuiBox-root.dash-mega-card-box.dark-theme {
  background-color: #303130;
}

.MuiSvgIcon-root.dash-mega-card-icon.dark-theme path {
  fill: #5D614F;
}

.MuiButton-root.dash-mega-card-see-more-button.dark-theme {
  color: #B1D23A;
}

.MuiTypography-root.dash-mega-card-desc-typo.dark-theme,
.MuiTypography-root.dash-mega-card-title-typo.dark-theme {
  opacity: 0.8;
  color: #A1A591;
}

.MuiTypography-root.dash-mega-card-value-typo.dark-theme {
  color: #BDC0B2;
}

.MuiDrawer-root.dashdrawer-drawer {
  flex-grow: 0;
  height: 100vh;
  position: relative;
}
.MuiDrawer-root.dashdrawer-drawer .MuiDrawer-paper {
  background-color: #252720;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 800ms  ;
}
.MuiDrawer-root.dashdrawer-drawer .MuiDrawer-paper.dashdrawer-closed {
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 800ms  ;
}

.MuiPaper-root.dashdrawer-logo-paper {
  background-color: transparent;
  margin-bottom: 56px;
  margin-top: 37px;
  padding-left: 16px;
  padding-right: 16px;
}

.MuiBox-root.dashdrawer-logo-box {
  height: 16px;
  margin-bottom: 8px;
  padding: 0;
  width: 16px;
}
.MuiBox-root.dashdrawer-logo-box:hover {
  cursor: pointer;
}

.MuiBox-root.dashdrawer-logo-image {
  height: 16px;
  vertical-align: top;
  width: 16px;
}

.MuiTypography-root.dashdrawer-logo-typo {
  margin-bottom: 4px;
  transition: color 800ms ease, font-size 400ms ease;
  color: #A1A591;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.035em;
  line-height: 1.58;
  width: fit-content;
}
.MuiTypography-root.dashdrawer-logo-typo:hover {
  cursor: pointer;
}
.MuiTypography-root.dashdrawer-logo-typo.dashdrawer-closed {
  margin-bottom: 0;
  color: #D6E797;
  font-size: 0.875rem;
}
.MuiTypography-root.dashdrawer-logo-typo.dashdrawer-closed:hover {
  cursor: default;
}

.MuiPaper-root.dashdrawer-menu-paper {
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
}

.MuiSvgIcon-root.dashdrawer-dash-icon {
  height: 20px;
  width: 20px;
}
.MuiSvgIcon-root.dashdrawer-dash-icon path {
  fill: #A1A591;
}
.MuiSvgIcon-root.dashdrawer-dash-icon.dashdrawer-tabselected path {
  fill: #D6E797;
}

.MuiListItem-root.dashdrawer-listitem {
  display: block;
  margin-bottom: 12px;
  width: 100%;
}

.MuiListItemButton-root.dashdrawer-listitembutton {
  padding: 8px 16px;
  height: 40px;
}
.MuiListItemButton-root.dashdrawer-listitembutton.Mui-selected {
  background-color: inherit;
  border-left: 1px solid #D6E797;
}
.MuiListItemButton-root.dashdrawer-listitembutton.Mui-selected:hover {
  background-color: inherit;
}

.MuiListItemIcon-root.dashdrawer-listitemicon {
  color: #A1A591;
  min-width: fit-content;
  padding-right: 10px;
}
.MuiListItemIcon-root.dashdrawer-listitemicon.dashdrawer-closed {
  padding-right: 0;
}
.MuiListItemIcon-root.dashdrawer-listitemicon.dashdrawer-tabselected {
  color: #D6E797;
}

.MuiListItemText-root.dashdrawer-listitemtext {
  margin-bottom: 0;
  margin-top: 0;
  opacity: 1;
  transition: opacity 225ms 100ms ease;
}
.MuiListItemText-root.dashdrawer-listitemtext .MuiListItemText-primary {
  color: #A1A591;
  font-weight: 500;
}
.MuiListItemText-root.dashdrawer-listitemtext.dashdrawer-tabselected .MuiListItemText-primary {
  color: #D6E797;
}
.MuiListItemText-root.dashdrawer-listitemtext.dashdrawer-closed {
  opacity: 0;
  transition: none;
}

.MuiPaper-root.dashdrawer-footer-paper {
  background-color: transparent;
  margin-top: auto;
}

.MuiDivider-root.dashdrawer-footer-divider {
  background-color: #414437;
}

.MuiPaper-root.dashdrawer-profile-paper {
  background-color: transparent;
  margin: 36px 16px 32px;
}
.MuiPaper-root.dashdrawer-profile-paper.dashdrawer-closed {
  margin-left: 0;
  margin-right: 0;
}

.MuiTypography-root.dashdrawer-login-typo {
  color: #A1A591;
  height: 22px;
  margin-bottom: 20px;
  opacity: 1;
  transition: opacity 225ms 100ms ease;
  color: #A1A591;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.035em;
  line-height: 1.58;
}
.MuiTypography-root.dashdrawer-login-typo.dashdrawer-closed {
  opacity: 0;
  transition: initial;
}

.MuiTypography-root.dashdrawer-role-typo {
  color: #A1A591;
  background-color: #A1A591;
  height: 19px;
  opacity: 1;
  padding-left: 12px;
  padding-right: 12px;
  text-transform: uppercase;
  transition: opacity 225ms 100ms ease;
  color: #252720;
  font-size: 0.75rem;
  letter-spacing: 0.035em;
  line-height: 1.58;
  width: fit-content;
}
.MuiTypography-root.dashdrawer-role-typo.dashdrawer-closed {
  opacity: 0;
  transition: initial;
}

.MuiAccordion-root.dashdrawer-accordion {
  background-color: transparent;
  margin-top: 12px;
  padding: 0;
}
.MuiAccordion-root.dashdrawer-accordion .MuiAccordionSummary-root.Mui-expanded {
  min-height: 0;
}
.MuiAccordion-root.dashdrawer-accordion .MuiAccordionSummary-content.Mui-expanded, .MuiAccordion-root.dashdrawer-accordion .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  margin-bottom: 40px;
  margin-top: 12px;
}
.MuiAccordion-root.dashdrawer-accordion.Mui-disabled, .MuiAccordion-root.dashdrawer-accordion .MuiButtonBase-root.Mui-disabled {
  background-color: transparent;
  opacity: 1;
}
.MuiAccordion-root.dashdrawer-accordion::before {
  content: "none";
}

.MuiAccordionSummary-root.dashdrawer-acc-summary {
  min-height: fit-content;
  padding: 0;
}
.MuiAccordionSummary-root.dashdrawer-acc-summary .MuiAccordionSummary-content {
  align-items: center;
  margin: 0;
}

.MuiAvatar-root.dashdrawer-avatar {
  color: #252720;
  font-size: 1.125rem;
  height: 32px;
  margin-left: 0;
  margin-right: 0;
  width: 32px;
}
.MuiAvatar-root.dashdrawer-avatar.dashdrawer-closed {
  margin-left: auto;
  margin-right: auto;
}

.MuiTypography-root.dashdrawer-avatar-typo {
  margin-left: 16px;
  opacity: 1;
  transition: opacity 225ms 100ms ease;
  color: #D6E797;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiTypography-root.dashdrawer-avatar-typo.dashdrawer-closed {
  opacity: 0;
  transition: initial;
}

.MuiAccordionDetails-root.dashdrawer-acc-details {
  padding: 0;
  opacity: 1;
  transition: opacity 10ms ease;
}
.MuiAccordionDetails-root.dashdrawer-acc-details.dashdrawer-closed {
  opacity: 0;
}

.MuiListItem-root.dashdrawer-listitem {
  display: block;
  height: 41px;
  margin-bottom: 12px;
}

.MuiListItemButton-root.dashdrawer-listbutton {
  padding: 0;
}
.MuiListItemButton-root.dashdrawer-listbutton .MuiListItemText-primary {
  color: #A1A591;
}
.MuiListItemButton-root.dashdrawer-listbutton.Mui-selected {
  background-color: inherit;
  color: #D6E797;
}
.MuiListItemButton-root.dashdrawer-listbutton.Mui-selected .MuiListItemText-primary {
  color: #D6E797;
}
.MuiListItemButton-root.dashdrawer-listbutton.Mui-selected:hover {
  background-color: inherit;
  color: #D6E797;
}

.MuiListItemIcon-root.dashdrawer-listicon {
  color: #A1A591;
  min-width: fit-content;
  margin-right: 10px;
}
.MuiListItemIcon-root.dashdrawer-listicon path {
  fill: #A1A591;
}
.MuiListItemIcon-root.dashdrawer-listicon.dashdrawer-tabselected path {
  fill: #D6E797;
}

.MuiListItemText-root.dashdrawer-listtext .MuiListItemText-primary {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}

.MuiDrawer-root.dashdrawer-drawer.dark-theme .MuiDrawer-paper {
  background-color: #2E2F2D;
}

.MuiTypography-root.dashdrawer-logo-typo.dark-theme {
  color: #B1D23A;
  font-weight: 700;
}
.MuiTypography-root.dashdrawer-logo-typo.dark-theme:hover {
  cursor: pointer;
}
.MuiTypography-root.dashdrawer-logo-typo.dark-theme.dashdrawer-closed {
  font-weight: 700;
}

.MuiListItemButton-root.dashdrawer-listitembutton.dark-theme.Mui-selected {
  border: none;
  background-color: #5D614F;
}

.MuiListItemIcon-root.dashdrawer-listitemicon.dark-theme {
  color: #D9DBD3;
}
.MuiListItemIcon-root.dashdrawer-listitemicon.dark-theme.dashdrawer-tabselected {
  color: #D9DBD3;
}

.MuiSvgIcon-root.dashdrawer-dash-icon.dark-theme.dashdrawer-tabselected path {
  fill: #D9DBD3;
}

.MuiListItemText-root.dashdrawer-listitemtext.dark-theme.dashdrawer-tabselected .MuiListItemText-primary {
  color: #D9DBD3;
}

.MuiBox-root.popper-box {
  max-width: 300;
  width: 100%;
  margin: "auto";
  position: "relative";
  background-color: #F7FAEA;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}
.MuiBox-root.popper-box.dark-theme {
  background-color: #1A1B19;
}

.MuiTypography-root.popper {
  margin-bottom: 2px;
  color: #090A08;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
  margin-top: 12px;
}

.routine-stepper {
  width: 100%;
  max-width: 800px;
  margin: auto;
  position: relative;
}
.routine-stepper .stepper-popper {
  z-index: 1300;
}
.routine-stepper .stepper-paper {
  padding: 16px;
  width: 300px;
  max-width: 100%;
}
.routine-stepper .stepper-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.routine-stepper .stepper-form .button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.routine-stepper .completion-message,
.routine-stepper .step-message {
  margin: 16px 0 8px;
  text-align: center;
}
.routine-stepper .button-container {
  display: flex;
  justify-content: center;
  padding-top: 16px;
}
.routine-stepper .button-container .back-button {
  margin-right: 8px;
}

.MuiTypography-root.popper-title-typo {
  margin-bottom: 8px;
  color: #090A08;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
  margin-top: 12px;
}

.MuiTypography-root.popper-title-typo.dark-theme {
  font-size: larger;
  color: #D9DBD3;
}

.MuiBox-root.popper-icon-box {
  margin-left: auto;
  padding-top: 16px;
  position: absolute;
  top: 0;
  right: 32px;
  width: fit-content;
}

.MuiBox-root.base-new-and-detail-box {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 15px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiBox-root.base-new-and-detail-header-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.MuiBox-root.base-new-and-detail-title-box {
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.MuiTypography-root.base-new-and-detail-title-typo {
  margin-bottom: 16px;
  color: #414437;
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.08;
  overflow-wrap: break-word;
  word-break: break-word;
}

.MuiSvgIcon-root.base-new-and-detail-breadcrumb-sep-icon {
  height: 16px;
  width: 16px;
}

.MuiBreadcrumbs-root.base-new-and-detail-breadcrumbs {
  line-height: 18px;
}
.MuiBreadcrumbs-root.base-new-and-detail-breadcrumbs .MuiBreadcrumbs-separator {
  margin-left: 4px;
  margin-right: 4px;
}

.MuiTypography-root.base-new-and-detail-breadcrumb-link-typo {
  color: #5D614F;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 1.13;
  overflow-wrap: break-word;
  word-break: break-word;
}
.MuiTypography-root.base-new-and-detail-breadcrumb-link-typo:hover {
  cursor: default;
}

.MuiTab-root.base-new-and-detail-tab {
  text-transform: none;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTab-root.base-new-and-detail-tab.Mui-selected {
  color: #414437;
  font-weight: 500;
}

.MuiCircularProgress-root.base-new-and-detail-circular-progress {
  color: #6F745F;
}

.MuiButton-root.base-new-and-detail-save-button {
  background-color: #819C24;
  border-radius: 0;
  box-shadow: none;
  padding-bottom: 16px;
  padding-top: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.25;
  width: 244px;
}
.MuiButton-root.base-new-and-detail-save-button:hover {
  background-color: #819C24;
}

.MuiTabs-root.base-new-and-detail-tabs .MuiTabs-indicator {
  background-color: #414437;
}

.MuiDivider-root.base-new-and-detail-divider {
  background-color: #A1A591;
}

.MuiBox-root.base-new-and-detail-box.dark-theme {
  background-color: #1A1B19;
}

.MuiTypography-root.base-new-and-detail-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiSvgIcon-root.base-new-and-detail-breadcrumb-sep-icon.dark-theme path {
  stroke: #A1A591;
}

.MuiBreadcrumbs-root.base-new-and-detail-breadcrumbs.dark-theme li span {
  color: #A1A591;
}

.MuiTypography-root.base-new-and-detail-breadcrumb-link-typo.dark-theme {
  color: #A1A591;
}

.MuiTab-root.base-new-and-detail-tab.dark-theme.Mui-selected {
  color: #D9DBD3;
}

.MuiButton-root.base-new-and-detail-save-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.base-new-and-detail-save-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiTabs-root.base-new-and-detail-tabs.dark-theme .MuiTabs-indicator {
  background-color: #D9DBD3;
}

.MuiDivider-root.base-new-and-detail-divider.dark-theme {
  background-color: #6F745F;
}

.MuiBox-root.crop-box {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiBox-root.crop-header-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.MuiBox-root.crop-stack-container {
  width: 650px;
}

.MuiBox-root.crop-header-title-box {
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.MuiTypography-root.crop-header-title-typo {
  margin-bottom: 16px;
  color: #414437;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.08;
}

.MuiSvgIcon-root.crop-header-breadcrumbs-sep-icon {
  height: 16px;
  width: 16px;
}

.MuiBreadcrumbs-root.crop-header-breadcrumbs {
  line-height: 18px;
}
.MuiBreadcrumbs-root.crop-header-breadcrumbs .MuiBreadcrumbs-separator {
  margin-left: 4px;
  margin-right: 4px;
}

.MuiTypography-root.crop-header-breadcrumbs-link-typo {
  color: #5D614F;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 1.125;
}
.MuiTypography-root.crop-header-breadcrumbs-link-typo:hover {
  cursor: default;
}

.MuiButton-root.crop-save-button {
  background-color: #819C24;
  border-radius: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.25;
  width: 244px;
}
.MuiButton-root.crop-save-button:hover {
  background-color: #819C24;
}

.MuiDivider-root.crop-divider {
  background-color: #A1A591;
}

.MuiTabs-root.crop-tabs {
  margin-top: 32px;
}

.MuiTab-root.crop-tab {
  text-transform: none;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTab-root.crop-tab.Mui-selected {
  color: #414437;
  font-weight: 500;
}

.MuiTextField-root.crop-textfield {
  color: #252720;
  margin-top: 20px;
  width: 650px;
}
.MuiTextField-root.crop-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.crop-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.crop-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.crop-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.crop-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.crop-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.crop-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.crop-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.crop-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.crop-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.crop-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.crop-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}

.MuiTextField-root.crop-overview-textfield {
  color: #252720;
  margin-top: 0;
}
.MuiTextField-root.crop-overview-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.crop-overview-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.crop-overview-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.crop-overview-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.crop-overview-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.crop-overview-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.crop-overview-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.crop-overview-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.crop-overview-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.crop-overview-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.crop-overview-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.crop-overview-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}

.MuiGrid-container.crop-overview-box {
  margin-top: 16px;
  background-color: transparent;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiBox-root.crop-overview-title-phase-box {
  background-color: #C5DE6E;
  margin: 32px auto 0;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}

.MuiBox-root.crop-overview-detail-box {
  background-color: #F6F6F4;
  column-gap: 3px;
  display: grid;
  grid-template-columns: minmax(250px, auto) 1fr;
  grid-template-rows: repeat(auto-fill, minmax(41px, auto));
  margin-top: 1px;
  overflow: auto;
  row-gap: 2px;
}

.MuiBox-root.crop-overview-grid-entry-title-box {
  align-items: center;
  background-color: #E6F1C1;
  display: flex;
  padding-left: 16px;
  position: sticky;
  left: 0;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiBox-root.crop-overview-grid-entry-title-box.metricplan-title-entry {
  background-color: #E6F1C1;
}
.MuiBox-root.crop-overview-grid-entry-title-box.metricplan-title-entry.timeline-chart-highlight-metricplan {
  background-color: #819C24;
}

.MuiBox-root.crop-overview-grid-entry-outer-box {
  background-color: transparent;
  column-gap: 3px;
  display: grid;
  grid-template-rows: minmax(41px, auto);
  transition: grid 800ms  ;
}

.MuiBox-root.crop-overview-grid-entry-box {
  align-items: center;
  background-color: #E6F1C1;
  display: flex;
  justify-content: center;
  min-width: 152px;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiBox-root.crop-overview-grid-entry-box.timeline-chart-subphase-entry {
  transition: background-color 800ms  ;
}
.MuiBox-root.crop-overview-grid-entry-box.timeline-chart-day-entry {
  transition: none;
}
.MuiBox-root.crop-overview-grid-entry-box.timeline-chart-expanded-grid-entry-subphase-box {
  background-color: #C5DE6E;
  color: #4E5D15;
}
.MuiBox-root.crop-overview-grid-entry-box.timeline-chart-week-header-selected {
  background-color: #E6F1C1;
  color: #4E5D15;
  justify-content: flex-start;
  transition: background-color 800ms  ;
}
.MuiBox-root.crop-overview-grid-entry-box.timeline-chart-expanded-grid-entry-day-box {
  background-color: white;
  column-gap: 1px;
  display: grid;
  grid-template-rows: minmax(41px, auto);
  transition: grid 800ms  ;
}

.MuiBox-root.timeline-chart-expanded-day-box {
  align-items: center;
  background-color: #E6F1C1;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiBox-root.timeline-chart-expanded-day-box.timeline-chart-today-day-entry {
  border: 1px solid #4E5D15;
  color: #4E5D15;
}

.MuiBox-root.timeline-chart-expanded-alert-routine-content-box,
.MuiBox-root.timeline-chart-expanded-week-content-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-left: 16px;
  margin-right: 16px;
  width: 100%;
}

.MuiBox-root.timeline-chart-expanded-alert-routine-content-typo-box {
  align-items: center;
  display: flex;
  color: #819C24;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}

.MuiSvgIcon-root.timeline-chart-expanded-alert-routine-content-icon {
  height: 20px;
  width: 20px;
}
.MuiSvgIcon-root.timeline-chart-expanded-alert-routine-content-icon path {
  fill: #819C24;
}

.MuiButton-root.timeline-chart-expanded-alert-routine-see-detail-button {
  text-transform: none;
  padding: 0;
  color: #252720;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiButton-root.timeline-chart-expanded-alert-routine-see-detail-button:hover {
  background-color: transparent;
}

.MuiBox-root.timeline-chart-expanded-metricplan-content-box {
  background-color: #F6F6F4;
  column-gap: 1px;
  display: grid;
  grid-template-rows: minmax(41px, auto);
  transition: grid 800ms  ;
}

.MuiBox-root.timeline-chart-expanded-metricplan-box {
  align-items: center;
  background-color: #E6F1C1;
  display: flex;
  justify-content: center;
}

.MuiBox-root.overview-footer-box {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  width: 100%;
}

.MuiBox-root.overview-footer-specs-content-box {
  display: flex;
  flex-basis: calc(50% - 8px);
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 8px;
}

.MuiTypography-root.overview-footer-specs-typo {
  color: #A1A591;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.45;
}

.MuiBox-root.overview-footer-specs-list-box {
  background-color: #E6F1C1;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin-top: 16px;
  padding: 12px;
}

.MuiBox-root.overview-footer-spec-card-box {
  flex-basis: calc(50% - 8px);
  flex-grow: 1;
  flex-shrink: 1;
}
.MuiBox-root.overview-footer-spec-card-box:first-of-type {
  margin-right: 4px;
}
.MuiBox-root.overview-footer-spec-card-box:last-of-type {
  margin-left: 4px;
}

.MuiBox-root.overview-footer-links-content-box {
  display: flex;
  flex-basis: calc(50% - 8px);
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 8px;
}

.MuiTypography-root.overview-footer-links-typo {
  color: #A1A591;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.45;
}

.MuiBox-root.overview-footer-links-list-box {
  display: flex;
  background-color: #E6F1C1;
  flex-direction: column;
  height: 100%;
  margin-top: 16px;
  padding: 12px;
}

.MuiBox-root.overview-footer-link-card-box {
  margin-top: 15px;
}
.MuiBox-root.overview-footer-link-card-box:first-of-type {
  margin-top: 0;
}

.MuiBox-root.crop-specifics-box {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  width: 100%;
}

.MuiTypography-root.crop-specifics-title-typo {
  color: #090A08;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.45;
}
.MuiTypography-root.crop-specifics-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.overview-key-typo {
  color: #090A08;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.45;
}
.MuiTypography-root.overview-key-typo.dark-theme {
  color: #F6F6F4;
}

.MuiAccordion-root.overview-accordion {
  background-color: transparent;
}
.MuiAccordion-root.overview-accordion:before {
  background-color: transparent;
}
.MuiAccordion-root.overview-accordion .MuiAccordionSummary-root {
  padding: 0;
}

.MuiDivider-root.overview-divider {
  height: 0;
  width: 100%;
  align-items: center;
}
.MuiDivider-root.overview-divider:before, .MuiDivider-root.overview-divider:after {
  border-color: #6F745F;
}
.MuiDivider-root.overview-divider:before {
  width: 0%;
}
.MuiDivider-root.overview-divider:after {
  width: 100%;
}
.MuiDivider-root.overview-divider .MuiDivider-wrapper {
  padding: 0;
}
.MuiDivider-root.overview-divider.dark-theme .MuiDivider-wrapper {
  color: #B1D23A;
}

.MuiAccordionDetails-root.overview-accordion-details {
  padding: 0;
}

.MuiSvgIcon-root.overview-down-arrow-icon.dark-theme {
  fill: #B1D23A;
}

.MuiBox-root.crop-phases-box {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.MuiPaper-root.crop-phases-strain-box {
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.MuiTab-root.crop-phases-strain-tab {
  text-transform: none;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTab-root.crop-phases-strain-tab.Mui-selected {
  color: #303130;
  background-color: #E6F1C1;
  font-weight: 500;
}

.MuiTab-root.crop-phases-phase-tab {
  text-transform: none;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTab-root.crop-phases-phase-tab.Mui-selected {
  color: #303130;
  background-color: #B1D23A;
  font-weight: 500;
}

.MuiTabs-root.crop-phases-strain-tab .MuiTabs-indicator {
  display: none;
}

.MuiTabs-root.crop-phases-phase-tab .MuiTabs-indicator {
  display: none;
}

.MuiTab-root.crop-phases-strain-tab.dark-theme {
  color: #A1A591;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTab-root.crop-phases-strain-tab.dark-theme.Mui-selected {
  color: #FFFFFF;
  background-color: #303130;
  font-weight: 500;
}

.MuiTab-root.crop-phases-phase-tab.dark-theme {
  color: #FFFFFF;
}
.MuiTab-root.crop-phases-phase-tab.dark-theme.Mui-selected {
  color: #303130;
}

.MuiTabs-root.crop-phases-phase-tab.dark-theme .MuiTabs-scrollButtons {
  color: #A1A591;
}

.MuiPaper-root.crop-phases-strain-box.dark-theme {
  background-color: #303130;
}

.MuiBox-root.crop-box.dark-theme {
  background-color: #1A1B19;
}

.MuiTypography-root.crop-header-title-typo.dark-theme {
  color: #BDC0B2;
}

.MuiSvgIcon-root.crop-header-breadcrumbs-sep-icon.dark-theme path {
  stroke: #6F745F;
}

.MuiBreadcrumbs-root.crop-header-breadcrumbs.dark-theme li span {
  color: #6F745F;
}

.MuiTypography-root.crop-header-breadcrumbs-link-typo.dark-theme {
  color: #6F745F;
}

.MuiDivider-root.crop-divider.dark-theme {
  background-color: #6F745F;
}

.MuiTab-root.crop-tab.dark-theme {
  color: #A1A591;
}
.MuiTab-root.crop-tab.dark-theme.Mui-selected {
  color: #D9DBD3;
}

.MuiButton-root.crop-save-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.crop-save-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiGrid-container.crop-overview-box.dark-theme {
  background-color: #151614;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiBox-root.crop-overview-detail-box.dark-theme {
  background-color: #151614;
}

.MuiBox-root.crop-overview-title-phase-box.dark-theme {
  border-color: #151614;
  background-color: #2E2F2D;
  color: #A1A591;
}

.MuiBox-root.crop-overview-grid-entry-title-box.dark-theme {
  border-color: #2E2F2D;
  background-color: #2E2F2D;
  color: #A1A591;
}

.MuiBox-root.crop-overview-grid-entry-outer-box.dark-theme {
  background-color: transparent;
}

.MuiBox-root.crop-overview-grid-entry-box.dark-theme {
  background-color: #2E2F2D;
  color: #A1A591;
  transition: background-color 800ms  ;
}
.MuiBox-root.crop-overview-grid-entry-box.dark-theme.timeline-chart-week-header-selected {
  background-color: #B1D23A;
  color: #4E5D15;
}
.MuiBox-root.crop-overview-grid-entry-box.dark-theme.timeline-chart-expanded-grid-entry-subphase-box {
  color: #B1D23A;
}
.MuiBox-root.crop-overview-grid-entry-box.dark-theme.timeline-chart-day-entry {
  transition: none;
}
.MuiBox-root.crop-overview-grid-entry-box.dark-theme.timeline-chart-expanded-grid-entry-day-box {
  background-color: #151614;
  transition: none;
}

.MuiBox-root.timeline-chart-expanded-week-alert-routine-box.dark-theme {
  background-color: transparent;
  color: #6F745F;
}

.MuiSvgIcon-root.timeline-chart-expanded-week-alert-icon.dark-theme circle {
  fill: #DE3636;
}

.MuiSvgIcon-root.timeline-chart-expanded-week-routine-icon.dark-theme circle {
  fill: #FCFF80;
}

.MuiSvgIcon-root.timeline-chart-expanded-alert-routine-content-icon.dark-theme path {
  fill: #F99696;
}

.MuiBox-root.timeline-chart-expanded-alert-routine-content-typo-box.dark-theme {
  color: #F99696;
}

.MuiButton-root.timeline-chart-expanded-alert-routine-see-detail-button.dark-theme {
  color: #A1A591;
}

.MuiBox-root.timeline-chart-expanded-day-box.dark-theme {
  background-color: #2E2F2D;
  color: #6F745F;
}
.MuiBox-root.timeline-chart-expanded-day-box.dark-theme.timeline-chart-today-day-entry {
  border: 1px solid #6F745F;
}

.MuiBox-root.timeline-chart-expanded-metricplan-content-box.dark-theme {
  background-color: #151614;
}

.MuiBox-root.timeline-chart-expanded-metricplan-box.dark-theme {
  background-color: #2E2F2D;
}

.MuiTypography-root.overview-footer-specs-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.overview-footer-specs-list-box.dark-theme {
  background-color: #303130;
}

.MuiTypography-root.overview-footer-links-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.overview-footer-links-list-box.dark-theme {
  background-color: #303130;
}

.MuiMenuItem-root.crop-specifics-growplan-menuitem.dark-theme,
.MuiMenuItem-root.crop-specifics-status-menuitem.dark-theme {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.crop-specifics-growplan-menuitem.dark-theme:hover,
.MuiMenuItem-root.crop-specifics-status-menuitem.dark-theme:hover {
  background-color: #6F745F;
}
.MuiMenuItem-root.crop-specifics-growplan-menuitem.dark-theme.Mui-selected,
.MuiMenuItem-root.crop-specifics-status-menuitem.dark-theme.Mui-selected {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.crop-specifics-growplan-menuitem.dark-theme.crop-specifics-status-menuitem-focus, .MuiMenuItem-root.crop-specifics-growplan-menuitem.dark-theme.crop-specifics-growplan-menuitem-focus,
.MuiMenuItem-root.crop-specifics-status-menuitem.dark-theme.crop-specifics-status-menuitem-focus,
.MuiMenuItem-root.crop-specifics-status-menuitem.dark-theme.crop-specifics-growplan-menuitem-focus {
  background-color: #5D614F;
}

.MuiButton-root.crops-save-button {
  background-color: #4E5D15;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.56;
  margin-top: 20px;
  width: 244px;
  color: #F7FAEA;
  letter-spacing: 0;
  line-height: 1.45;
  margin-top: 0;
}
.MuiButton-root.crops-save-button:hover {
  background-color: #4E5D15;
}
.MuiButton-root.crops-save-button.Mui-disabled {
  background-color: #A1A591;
  color: #D9DBD3;
}
.MuiButton-root.crops-save-button.dark-theme {
  background-color: #819C24;
  color: #F7FAEA;
}
.MuiButton-root.crops-save-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}
.MuiButton-root.crops-save-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.crops-save-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiBox-root.crops-cropplans-box,
.MuiBox-root.crops-cropruns-box,
.MuiBox-root.crops-crophistory-box {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 32px;
}
.MuiBox-root.crops-cropplans-box.no-entity-available,
.MuiBox-root.crops-cropruns-box.no-entity-available,
.MuiBox-root.crops-crophistory-box.no-entity-available {
  display: flex;
}

.MuiBox-root.crops-entity-card-box {
  height: fit-content;
  min-height: 350px;
}
.MuiBox-root.crops-entity-card-box p:nth-of-type(3) {
  margin-top: 12px;
}

.MuiTypography-root.crops-entity-card-desc-typo {
  height: 3em;
  max-height: 3em;
  overflow: auto;
  text-overflow: ellipsis;
  white-space: break-spaces;
}

.MuiTypography-root.crops-crops-card-extra-typo {
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 4px;
}
.MuiTypography-root.crops-crops-card-extra-typo.dark-theme {
  color: #B1D23A;
}

.MuiBox-root.crop-run-box {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 60px 32px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}
.MuiBox-root.crop-run-box.dark-theme {
  background-color: #1A1B19;
}

.MuiBox-root.crop-run-title-box {
  background-color: transparent;
  display: flex;
}

.MuiTypography-root.crop-run-title-typo {
  margin-bottom: 16px;
  color: #414437;
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.08;
  overflow-wrap: break-word;
  word-break: break-word;
}
.MuiTypography-root.crop-run-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTabs-root.crop-run-tabs {
  margin-top: 32px;
}
.MuiTabs-root.crop-run-tabs .MuiTabs-indicator {
  background-color: #414437;
}
.MuiTabs-root.crop-run-tabs.dark-theme .MuiTabs-indicator {
  background-color: #D9DBD3;
}

.MuiTab-root.crop-run-tab {
  text-transform: none;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTab-root.crop-run-tab.Mui-selected {
  color: #414437;
  font-weight: 500;
}
.MuiTab-root.crop-run-tab.dark-theme.Mui-selected {
  color: #D9DBD3;
}

.MuiDivider-root.crop-run-divider {
  background-color: #A1A591;
}
.MuiDivider-root.crop-run-divider.dark-theme {
  background-color: #6F745F;
}

.MuiBox-root.crop-run-content-box {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 32px;
}
.MuiBox-root.crop-run-content-box.no-entity-available {
  display: flex;
  justify-content: center;
}

.MuiBox-root.dashboard-wrapper-box {
  display: flex;
  min-width: fit-content;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out;
  width: 100%;
  background-color: #E7F2D5;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiCollapse-root.dashboard-middle-box-collapse {
  flex-grow: 1.5;
  flex-basis: 70%;
}
.MuiCollapse-root.dashboard-middle-box-collapse .MuiCollapse-wrapperInner {
  width: 100%;
}
.MuiCollapse-root.dashboard-middle-box-collapse.dashboard-projections-expanded {
  flex-basis: 0%;
}

.MuiBox-root.dashboard-middle-box {
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 32px;
}

.MuiBox-root.dashboard-inner-scroll-box {
  margin-right: 18px;
  margin-top: 23px;
}

.MuiBox-root.dashboard-greeting-box {
  align-items: center;
  display: flex;
}

.MuiSvgIcon-root.dashboard-bedtime-icon {
  color: #C5DE6E;
  height: 42px;
  width: 42px;
}

.MuiSvgIcon-root.dashboard-sunny-day-icon {
  height: 42px;
  width: 42px;
}
.MuiSvgIcon-root.dashboard-sunny-day-icon path {
  fill: #324001;
}

.MuiTypography-root.dashboard-greeting-title-typo {
  margin-left: 12px;
  color: #324001;
  font-size: 2.25rem;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1.076;
  margin-top: initial;
  width: initial;
}

.MuiTypography-root.dashboard-greeting-sub-title-typo {
  color: #496019;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: -0.015em;
  line-height: 1.25;
  margin-top: 12px;
  width: initial;
}

.MuiDivider-root.dashboard-divider {
  color: rgba(76, 110, 135, 0.1019607843);
  margin-top: 20px;
}

.MuiTypography-root.dashboard-date-info-typo {
  color: #496019;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: -0.015em;
  line-height: 1.25;
  margin-top: 12px;
  width: fit-content;
  font-style: italic;
  margin-left: auto;
}
.MuiTypography-root.dashboard-date-info-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.dashboard-mini-cards-frame-box {
  border: 4px solid #C5DE6E;
  margin-top: 36px;
  position: relative;
}

.MuiTypography-root.dashboard-mini-cards-frame-header-typo {
  background-color: #C5DE6E;
  top: -32px;
  color: #4E5D15;
  padding: 4px 12px;
  position: absolute;
  left: -4px;
}

.MuiButton-root.dashboard-mini-cards-frame-footer-button {
  background-color: #C5DE6E;
  border-radius: 0;
  bottom: 0;
  color: #4E5D15;
  padding: 4px 12px;
  padding-left: 16px;
  position: absolute;
  right: 0;
  text-transform: none;
}
.MuiButton-root.dashboard-mini-cards-frame-footer-button:hover {
  background-color: #C5DE6E;
}

.MuiBox-root.dashboard-mini-cards-box {
  display: flex;
}

.MuiBox-root.dashboard-mega-cards-frame-box {
  border: 4px solid #C5DE6E;
  margin-top: 36px;
  position: relative;
}

.MuiTypography-root.dashboard-mega-cards-frame-header-typo {
  background-color: #C5DE6E;
  top: -32px;
  color: #4E5D15;
  padding: 4px 12px;
  position: absolute;
  left: -4px;
}

.MuiButton-root.dashboard-mega-cards-frame-footer-button {
  background-color: #C5DE6E;
  border-radius: 0;
  bottom: 0;
  color: #4E5D15;
  padding: 4px 12px;
  padding-left: 16px;
  position: absolute;
  right: 0;
  text-transform: none;
}
.MuiButton-root.dashboard-mega-cards-frame-footer-button:hover {
  background-color: #C5DE6E;
}

.MuiBox-root.dashboard-mega-cards-box {
  display: grid;
  column-gap: 14px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(min-content, 302px);
  row-gap: 16px;
}

.MuiBox-root.dashboard-outer-end-box {
  display: flex;
  flex-basis: 30%;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100vh;
  min-width: 0;
}

.MuiBox-root.dashboard-outer-end-box-expanded {
  flex-basis: 100%;
  height: 100%;
}

.MuiBox-root.dashboard-end-box-base {
  min-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  background-color: #97BF41;
  background-image: none;
  transition: background-color 800ms ease, background-image 800ms ease;
}

.MuiBox-root.dashboard-end-box {
  display: inherit;
  padding-top: 0;
  width: 100%;
}

.MuiBox-root.dashboard-end-box-expanded {
  display: flex;
  max-width: 100%;
  width: 100%;
}

.MuiBox-root.dashboard-plant-box {
  position: relative;
}

.MuiSvgIcon-root.dashboard-smartgrow-icon {
  height: 100%;
  left: calc(50% - 75px);
  max-height: 150px;
  max-width: 150px;
  position: absolute;
  top: 60px;
  width: 100%;
}

.MuiSvgIcon-root.dashboard-plant-pot-icon {
  filter: drop-shadow(14px 32px 64px rgba(80, 88, 9, 0.41));
  height: 289px;
  position: absolute;
  transform: rotate(9.8deg) scale(1.1);
  left: 24px;
  width: 322px;
}

.MuiBox-root.dashboard-projections-info-box {
  margin-left: auto;
  margin-right: auto;
  max-width: inherit;
}

.MuiBox-root.dashboard-projections-info-box-expanded {
  margin-left: 51px;
  margin-right: auto;
  max-width: 379px;
}

.MuiTypography-root.dashboard-projections-typo {
  color: #324001;
  font-size: 2.25rem;
  font-weight: 500;
  letter-spacing: -0.055em;
  line-height: 1.066;
  margin-top: fit-content;
  width: initial;
}

.MuiBox-root.dashboard-projections-box {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.MuiSvgIcon-root.dashboard-see-detail-icon path {
  fill: #324001;
}

.MuiSvgIcon-root.dashboard-go-back-icon path {
  fill: none;
}

.MuiButton-root.dashboard-see-detail-button {
  border: 1px solid #324001;
  border-radius: 0;
  margin-bottom: 55px;
  padding: 10px;
  text-transform: none;
  color: #324001;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: -0.015em;
  line-height: 1.3;
  margin-top: 88px;
  width: 100%;
}

.MuiBox-root.dashboard-projections-entry-box {
  margin-top: 31px;
}

.MuiTypography-root.dashboard-projections-entry-value-typo {
  color: #324001;
  font-size: 2.25rem;
  font-weight: 500;
  letter-spacing: -0.015em;
  line-height: 1.28;
  margin-top: fit-content;
  width: initial;
}

.MuiTypography-root.dashboard-projections-entry-title-typo {
  color: #324001;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: -0.015em;
  line-height: 1.29;
  margin-top: 2px;
  width: fit-content;
}

.MuiBox-root.dashboard-inproductioninv-box {
  padding: 45px 42px;
  width: 100%;
}
.MuiBox-root.dashboard-inproductioninv-box.dark-theme {
  background-color: #1A1B19;
}

.MuiBox-root.dashboard-inproductioninv-logo-box {
  display: flex;
  flex-direction: column;
}
.MuiBox-root.dashboard-inproductioninv-logo-button-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.MuiSvgIcon-root.dashboard-inproductioninv-logo-icon {
  height: 75px;
  width: 77px;
}
.MuiSvgIcon-root.dashboard-inproductioninv-logo-icon rect {
  fill: #F7FAEA;
}
.MuiSvgIcon-root.dashboard-inproductioninv-logo-icon.dark-theme rect {
  fill: #252720;
}

.MuiSvgIcon-root.dashboard-inproductioninv-back-icon path {
  fill: none;
}
.MuiSvgIcon-root.dashboard-inproductioninv-back-icon.dark-theme path {
  stroke: #B1D23A;
}

.MuiButton-root.dashboard-inproductioninv-back-button {
  border: 1px solid #324001;
  border-radius: 0;
  padding: 10px;
  text-transform: none;
  color: #324001;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: -0.015em;
  line-height: 1.3;
}
.MuiButton-root.dashboard-inproductioninv-back-button.dark-theme {
  color: #B1D23A;
  border-color: #819C24;
}

.MuiTypography-root.dashboard-inproductioninv-title-typo {
  color: #F6F6F4;
  font-size: 1.875rem;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 1.13;
  margin-top: 19px;
  width: min-content;
}
.MuiTypography-root.dashboard-inproductioninv-title-typo.dark-theme {
  color: #F6F6F4;
}

.MuiBox-root.dashboard-inproductioninv-cards-box {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: minmax(154px, auto);
  margin-top: 19px;
}
.MuiBox-root.dashboard-inproductioninv-tables-box {
  margin-top: 15px;
}
.MuiBox-root.dashboard-inproductioninv-tables-box div:first-of-type {
  margin-top: 0;
}
.MuiBox-root.dashboard-wrapper-box.dark-theme {
  background-color: #1A1B19;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiSvgIcon-root.dashboard-sunny-day-icon.dark-theme path {
  fill: #BDC0B2;
}

.MuiSvgIcon-root.dashboard-bedtime-icon.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.dashboard-greeting-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.dashboard-greeting-sub-title-typo.dark-theme {
  color: #A1A591;
}

.MuiDivider-root.dashboard-divider.dark-theme {
  background-color: #414437;
}

.MuiBox-root.dashboard-mega-cards-frame-box.dark-theme,
.MuiBox-root.dashboard-mini-cards-frame-box.dark-theme {
  border-color: #BDC0B2;
}

.MuiButton-root.dashboard-mega-cards-frame-footer-button.dark-theme,
.MuiTypography-root.dashboard-mega-cards-frame-header-typo.dark-theme,
.MuiButton-root.dashboard-mini-cards-frame-footer-button.dark-theme,
.MuiTypography-root.dashboard-mini-cards-frame-header-typo.dark-theme {
  background-color: #BDC0B2;
  color: #242523;
}

.MuiBox-root.dashboard-end-box-base.dark-theme {
  background-color: transparent;
  background-image: linear-gradient(183.65deg, #1F201E 61.04%, #1B3C01 97%);
  transition: background-color 800ms ease, background-image 800ms ease;
}

.MuiBox-root.dashboard-end-box-expanded.dark-theme {
  background-image: none;
}

.MuiSvgIcon-root.dashboard-plant-pot-icon.dark-theme {
  filter: drop-shadow(14px 32px 64px rgba(42, 38, 38, 0.41));
}

.MuiTypography-root.dashboard-projections-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.dashboard-projections-entry-value-typo.dark-theme {
  color: #BDC0B2;
}

.MuiTypography-root.dashboard-projections-entry-title-typo.dark-theme {
  color: #6F745F;
}

.MuiSvgIcon-root.dashboard-go-back-icon.dark-theme path {
  stroke: #B1D23A;
}

.MuiSvgIcon-root.dashboard-see-detail-icon.dark-theme path {
  fill: #B1D23A;
}

.MuiButton-root.dashboard-see-detail-button.dark-theme {
  color: #B1D23A;
  border-color: #819C24;
}

.MuiTypography-root.external-detail-tab-title-typo {
  color: #090A08;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.25;
  margin-top: 60px;
}
.MuiTypography-root.external-detail-tab-title-typo.dark-theme {
  color: #BDC0B2;
}

.MuiTypography-root.external-detail-tab-content-typo {
  color: #414437;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  margin-top: 32px;
}
.MuiTypography-root.external-detail-tab-content-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.external-detail-tab-content-link-typo {
  display: block;
  color: #414437;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  margin-top: 32px;
}
.MuiTypography-root.external-detail-tab-content-link-typo:hover {
  cursor: pointer;
}
.MuiTypography-root.external-detail-tab-content-link-typo.dark-theme {
  color: #A1A591;
}

.MuiSvgIcon-root.farms-icon {
  height: 20px;
  width: 20px;
}
.MuiSvgIcon-root.farms-icon path {
  fill: #F7FAEA;
}

.MuiPaper-root.farms-paper {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 15px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiPaper-root.farms-org-paper {
  align-items: center;
  background-color: transparent;
  color: #414437;
  display: flex;
  font-weight: 500;
}

.MuiTypography-root.farms-org-title-typo {
  background-color: #D9DBD3;
  padding-left: 12px;
  padding-right: 12px;
  color: #5D614F;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
}

.MuiTypography-root.farms-org-name-typo {
  margin-left: 12px;
  color: #414437;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
}

.MuiTypography-root.farms-title-typo {
  color: #414437;
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.08;
  margin-top: 12px;
}

.MuiTypography-root.farms-key-typo {
  color: #6F745F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
  margin-top: 8px;
}

.MuiPaper-root.farms-key-refresh-add-paper {
  background-color: transparent;
  display: flex;
  margin-top: 8px;
}

.MuiInputBase-root.farms-key-select {
  border-radius: 0;
  padding-bottom: 12px;
  padding-top: 12px;
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  width: 603px;
}
.MuiInputBase-root.farms-key-select .MuiInputBase-input {
  padding: 0 12px;
}
.MuiInputBase-root.farms-key-select fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #BDC0B2;
}

.MuiButton-root.farms-refresh-button {
  background-color: #E6F1C1;
  border-radius: 0;
  flex-shrink: 0;
  margin-left: 16px;
  padding-bottom: 12px;
  padding-top: 12px;
  text-transform: none;
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
  width: 190px;
}
.MuiButton-root.farms-refresh-button:hover {
  background-color: #E6F1C1;
}
.MuiButton-root.farms-refresh-button .MuiSvgIcon-root path {
  fill: #4E5D15;
}

.MuiButton-root.farms-add-farm-button {
  background-color: #4E5D15;
  border-radius: 0;
  flex-shrink: 0;
  margin-left: 8px;
  padding: 12px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
  width: 190px;
}
.MuiButton-root.farms-add-farm-button:hover {
  background-color: #4E5D15;
}

.MuiTabs-root.farms-tabs {
  margin-top: 24px;
}
.MuiTabs-root.farms-tabs .MuiTabs-indicator {
  background-color: #414437;
}

.MuiTab-root.farms-tab {
  padding: 12px 10px;
  text-transform: none;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiTab-root.farms-tab.Mui-selected {
  color: #414437;
  font-weight: 500;
}

.MuiDivider-root.farms-divider {
  background-color: #A1A591;
}

.MuiTypography-root.farms-no-entity-typo {
  margin-left: auto;
  margin-right: auto;
  color: #6F745F;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: -0.05em;
  margin-top: 32px;
  width: fit-content;
}

.MuiPaper-root.farms-list-paper {
  background-color: transparent;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 40px;
  margin-top: 40px;
}

.MuiPaper-root.farms-table-list-paper {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 1420px;
}

.MuiPaper-root.farms-paper.dark-theme {
  background-color: #1A1B19;
}

.MuiTypography-root.farms-org-title-typo.dark-theme {
  border: 1px solid #414437;
  border-radius: 100px;
  background-color: #2B2C2B;
  color: #A1A591;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}

.MuiTypography-root.farms-org-name-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.farms-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.farms-key-typo.dark-theme {
  color: #A1A591;
}

.MuiInputBase-root.farms-key-select.dark-theme {
  color: #A1A591;
}
.MuiInputBase-root.farms-key-select.dark-theme fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #A1A591;
}
.MuiInputBase-root.farms-key-select.dark-theme .MuiSvgIcon-root path {
  fill: #A1A591;
}

.MuiMenuItem-root.farms-key-menuitem.dark-theme {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.farms-key-menuitem.dark-theme:hover {
  background-color: #6F745F;
}
.MuiMenuItem-root.farms-key-menuitem.dark-theme.Mui-selected {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.farms-key-menuitem.dark-theme.menuitem-focus-dark {
  background-color: #5D614F;
}
.MuiMenuItem-root.farms-key-menuitem.dark-theme.Mui-disabled {
  opacity: 1;
  background-color: #414437;
  color: #6F745F;
}

.MuiButton-root.farms-refresh-button.dark-theme {
  background-color: #303130;
  color: #819C24;
}
.MuiButton-root.farms-refresh-button.dark-theme .MuiSvgIcon-root path {
  fill: #819C24;
}

.MuiButton-root.farms-add-farm-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.farms-add-farm-button.dark-theme .MuiSvgIcon-root path {
  fill: #1A1F07;
}

.MuiTabs-root.farms-tabs.dark-theme .MuiTabs-indicator {
  background-color: #D9DBD3;
}

.MuiTab-root.farms-tab.dark-theme.Mui-selected {
  color: #D9DBD3;
}

.MuiDivider-root.farms-divider.dark-theme {
  background-color: #6F745F;
}

.MuiPaper-root.farm-detail-paper {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 15px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiBox-root.farm-detail-header-box {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.MuiTypography-root.farm-detail-name-typo {
  margin-bottom: 16px;
  color: #414437;
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.08;
}

.MuiTypography-root.farm-detail-breadcrumb-link-typo {
  color: #5D614F;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 1.29;
}
.MuiTypography-root.farm-detail-breadcrumb-link-typo:hover {
  cursor: default;
}

.MuiButton-root.farm-detail-create-room-button {
  background-color: #4E5D15;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.45;
  width: 244px;
}
.MuiButton-root.farm-detail-create-room-button:hover {
  background-color: #4E5D15;
}

.MuiTypography-root.farm-detail-rooms-typo {
  color: #414437;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
  margin-top: 40px;
}

.MuiPaper-root.farm-detail-rooms-paper {
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 24px;
  margin-top: 24px;
  width: 100%;
}
.MuiPaper-root.farm-detail-rooms-paper.farm-detail-noentity {
  display: flex;
}

.MuiTypography-root.farm-detail-no-room-typo {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #A1A591;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: -0.05em;
  width: fit-content;
}

.MuiSvgIcon-root.farm-detail-breadcrumb-sep-icon {
  height: 16px;
  width: 16px;
}

.MuiBreadcrumbs-root.farm-detail-breadcrumb {
  line-height: 18px;
}
.MuiBreadcrumbs-root.farm-detail-breadcrumb .MuiBreadcrumbs-separator {
  margin-left: 4px;
  margin-right: 4px;
}

.MuiPaper-root.farm-detail-paper.dark-theme {
  background-color: #1A1B19;
}

.MuiTypography-root.farm-detail-name-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.farm-detail-breadcrumb-link-typo.dark-theme {
  color: #A1A591;
}

.MuiButton-root.farm-detail-create-room-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.farm-detail-create-room-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiTypography-root.farm-detail-rooms-typo.dark-theme {
  color: #D9DBD3;
}

.MuiSvgIcon-root.farm-detail-breadcrumb-sep-icon.dark-theme path {
  stroke: #A1A591;
}

.MuiBreadcrumbs-root.farm-detail-breadcrumb.dark-theme li span {
  color: #A1A591;
}

.MuiPaper-root.growplan-paper {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 60px 32px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}
.MuiPaper-root.growplan-paper.dark-theme {
  background-color: #1A1B19;
}

.MuiPaper-root.growplan-header-paper {
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.MuiPaper-root.growplan-title-paper {
  background-color: transparent;
  width: 100%;
}

.MuiTypography-root.growplan-title-typo {
  margin-bottom: 16px;
  color: #414437;
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.08;
  overflow-wrap: break-word;
  word-break: break-word;
}
.MuiTypography-root.growplan-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.growplan-breadcrumb-link-typo {
  color: #5D614F;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 1.29;
}
.MuiTypography-root.growplan-breadcrumb-link-typo:hover {
  cursor: default;
}
.MuiTypography-root.growplan-breadcrumb-link-typo.dark-theme {
  color: #A1A591;
}

.MuiCircularProgress-root.growplan-progress {
  color: #6F745F;
}

.MuiButton-root.growplan-save-button {
  background-color: #819C24;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  width: 244px;
}
.MuiButton-root.growplan-save-button:hover {
  background-color: #819C24;
}
.MuiButton-root.growplan-save-button.Mui-disabled {
  background-color: #A1A591;
  color: #D9DBD3;
}
.MuiButton-root.growplan-save-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.growplan-save-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiTabs-root.growplan-tabs {
  margin-top: 32px;
}
.MuiTabs-root.growplan-tabs .MuiTabs-indicator {
  background-color: #414437;
}
.MuiTabs-root.growplan-tabs.dark-theme .MuiTabs-indicator {
  background-color: #D9DBD3;
}

.MuiTab-root.growplan-tab {
  text-transform: none;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTab-root.growplan-tab.Mui-selected {
  color: #414437;
  font-weight: 500;
}
.MuiTab-root.growplan-tab.dark-theme.Mui-selected {
  color: #D9DBD3;
}

.MuiDivider-root.growplan-divider {
  background-color: #A1A591;
}
.MuiDivider-root.growplan-divider.dark-theme {
  background-color: #6F745F;
}

.MuiBox-root.growplan-overview-box {
  display: grid;
  gap: 16px;
  grid-auto-rows: auto;
  grid-template-areas: "a c" "b c";
  grid-template-columns: repeat(2, 1fr);
  max-width: 1220px;
  margin-top: 36px;
  width: 100%;
}

.MuiSvgIcon-root.growplan-overview-inforoom-icon,
.MuiSvgIcon-root.growplan-overview-info-icon {
  height: 20px;
  margin-right: 10px;
  vertical-align: text-bottom;
  width: 20px;
}
.MuiSvgIcon-root.growplan-overview-inforoom-icon path,
.MuiSvgIcon-root.growplan-overview-info-icon path {
  fill: #4E5D15;
}
.MuiSvgIcon-root.growplan-overview-inforoom-icon.dark-theme path,
.MuiSvgIcon-root.growplan-overview-info-icon.dark-theme path {
  fill: #B1D23A;
}

.MuiSvgIcon-root.growplan-overview-inforoom-icon path {
  fill: none;
}
.MuiSvgIcon-root.growplan-overview-inforoom-icon.dark-theme path {
  fill: none;
  stroke: #B1D23A;
}

.MuiTypography-root.growplan-overview-info-typo {
  color: #6F745F;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
  margin-top: 4px;
}
.MuiTypography-root.growplan-overview-info-typo.dark-theme {
  color: #BDC0B2;
}

.MuiTypography-root.growplan-overview-sensor-count-typo {
  color: #6F745F;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 4px;
}
.MuiTypography-root.growplan-overview-sensor-count-typo.dark-theme {
  color: #A1A591;
}

.MuiSvgIcon-root.growplan-ext-link-icon,
.MuiSvgIcon-root.growplan-phaseplan-link-icon,
.MuiSvgIcon-root.growplan-qualityplan-link-icon {
  height: 20px;
  margin-left: 8px;
  vertical-align: sub;
  width: 20px;
}
.MuiSvgIcon-root.growplan-ext-link-icon path,
.MuiSvgIcon-root.growplan-phaseplan-link-icon path,
.MuiSvgIcon-root.growplan-qualityplan-link-icon path {
  fill: none;
}
.MuiSvgIcon-root.growplan-ext-link-icon:hover,
.MuiSvgIcon-root.growplan-phaseplan-link-icon:hover,
.MuiSvgIcon-root.growplan-qualityplan-link-icon:hover {
  cursor: pointer;
}
.MuiSvgIcon-root.growplan-ext-link-icon.dark-theme path,
.MuiSvgIcon-root.growplan-phaseplan-link-icon.dark-theme path,
.MuiSvgIcon-root.growplan-qualityplan-link-icon.dark-theme path {
  stroke: #6F745F;
}

.MuiTypography-root.growplan-overview-desc-typo {
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
  margin-top: 16px;
}
.MuiTypography-root.growplan-overview-desc-typo.dark-theme {
  color: #BDC0B2;
}

.MuiPaper-root.growplan-geninfo-paper {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  width: 100%;
}

.MuiBox-root.growplan-geninfo-plan-compatible-box {
  align-items: center;
  display: flex;
}

.MuiSvgIcon-root.growplan-geninfo-plan-compatible-error-icon {
  margin-left: 8px;
}
.MuiSvgIcon-root.growplan-geninfo-plan-compatible-error-icon:hover {
  cursor: pointer;
}
.MuiBox-root.growplan-sensor-count-box {
  font-size: 14px;
  margin-left: auto;
}

.MuiBox-root.growplan-room-menuitem-message-box {
  font-size: 0.875rem;
  font-style: italic;
  margin-left: auto;
}

.MuiMenuItem-root.growplan-room-menuitem.dark-theme,
.MuiMenuItem-root.growplan-phaseplan-menuitem.dark-theme,
.MuiMenuItem-root.growplan-medium-profile-menuitem.dark-theme,
.MuiMenuItem-root.growplan-qualityplan-menuitem.dark-theme {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.growplan-room-menuitem.dark-theme:hover,
.MuiMenuItem-root.growplan-phaseplan-menuitem.dark-theme:hover,
.MuiMenuItem-root.growplan-medium-profile-menuitem.dark-theme:hover,
.MuiMenuItem-root.growplan-qualityplan-menuitem.dark-theme:hover {
  background-color: #6F745F;
}
.MuiMenuItem-root.growplan-room-menuitem.dark-theme.Mui-selected,
.MuiMenuItem-root.growplan-phaseplan-menuitem.dark-theme.Mui-selected,
.MuiMenuItem-root.growplan-medium-profile-menuitem.dark-theme.Mui-selected,
.MuiMenuItem-root.growplan-qualityplan-menuitem.dark-theme.Mui-selected {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.growplan-room-menuitem.dark-theme.menuitem-focus-dark,
.MuiMenuItem-root.growplan-phaseplan-menuitem.dark-theme.menuitem-focus-dark,
.MuiMenuItem-root.growplan-medium-profile-menuitem.dark-theme.menuitem-focus-dark,
.MuiMenuItem-root.growplan-qualityplan-menuitem.dark-theme.menuitem-focus-dark {
  background-color: #5D614F;
}
.MuiMenuItem-root.growplan-room-menuitem.dark-theme.Mui-disabled,
.MuiMenuItem-root.growplan-phaseplan-menuitem.dark-theme.Mui-disabled,
.MuiMenuItem-root.growplan-medium-profile-menuitem.dark-theme.Mui-disabled,
.MuiMenuItem-root.growplan-qualityplan-menuitem.dark-theme.Mui-disabled {
  opacity: 1;
  background-color: #414437;
  color: #6F745F;
}

.MuiMenuItem-root.growplan-qualityplan-menuitem.growplan-disabled:hover {
  cursor: default;
}
.MuiMenuItem-root.growplan-qualityplan-menuitem.growplan-disabled:not(.dark-theme):hover {
  background-color: initial;
}

.MuiBox-root.growplan-qualityplan-menu-typo-box.growplan-disabled {
  opacity: 0.5;
}
.MuiBox-root.growplan-qualityplan-menu-typo-box.growplan-disabled:hover {
  cursor: default;
}
.MuiBox-root.growplan-qualityplan-menu-typo-box.growplan-disabled:not(.dark-theme):hover {
  background-color: initial;
}

.MuiTextField-root.growplan-geninfo-textfield {
  width: 650px;
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.growplan-geninfo-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.growplan-geninfo-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.growplan-geninfo-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.growplan-geninfo-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.growplan-geninfo-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.growplan-geninfo-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.growplan-geninfo-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.growplan-geninfo-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.growplan-geninfo-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.growplan-geninfo-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.growplan-geninfo-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.growplan-geninfo-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}

.MuiBox-root.growplan-phaseplan-entry-date-box {
  margin-left: auto;
}

.MuiBox-root.growplan-strains-box {
  margin-top: 32px;
  width: 100;
}

.MuiBox-root.growplan-strain-title-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.MuiTypography-root.growplan-geninfo-title-typo,
.MuiTypography-root.growplan-strain-title-typo {
  color: #090A08;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.45;
}
.MuiTypography-root.growplan-geninfo-title-typo.dark-theme,
.MuiTypography-root.growplan-strain-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiButton-root.growplan-strain-table-button {
  background-color: #4E5D15;
  border-radius: 0;
  padding-bottom: 14px;
  padding-top: 14px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.25;
  width: 244px;
}
.MuiButton-root.growplan-strain-table-button:hover {
  background-color: #4E5D15;
}
.MuiButton-root.growplan-strain-table-button:first-of-type {
  margin-right: 16px;
}
.MuiButton-root.growplan-strain-table-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.growplan-strain-table-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiPaper-root.growplan-strain-table-paper {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 36px;
  max-width: 1420px;
}

.MuiBox-root.growplan-crops-box {
  margin-top: 32px;
  width: 100;
}

.MuiBox-root.growplan-crops-title-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.MuiTypography-root.growplan-crops-title-typo {
  color: #414437;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 1.56;
}
.MuiTypography-root.growplan-crops-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiPaper-root.growplan-crops-table-paper {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 1420px;
  margin-top: 36px;
}

.MuiSvgIcon-root.growplan-breadcrumb-sep-icon {
  height: 16px;
  width: 16px;
}
.MuiSvgIcon-root.growplan-breadcrumb-sep-icon.dark-theme path {
  stroke: #A1A591;
}

.MuiBreadcrumbs-root.growplan-breadcrumbs {
  line-height: 18px;
}
.MuiBreadcrumbs-root.growplan-breadcrumbs .MuiBreadcrumbs-separator {
  margin-left: 4px;
  margin-right: 4px;
}
.MuiBreadcrumbs-root.growplan-breadcrumbs.dark-theme li span {
  color: #A1A591;
}

.MuiBox-root.invite-page-content-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 126px auto 0;
  padding-bottom: 56px;
  max-width: 544px;
}
.MuiSvgIcon-root.invite-page-user-icon {
  height: 48px;
  width: 48px;
}
.MuiSvgIcon-root.invite-page-user-icon.dark-theme path {
  fill: #D9DBD3;
}

.MuiTypography-root.invite-page-wel-typo {
  text-align: center;
  color: #090A08;
  font-size: 1.875rem;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 1.13;
  margin-top: 36px;
}
.MuiTypography-root.invite-page-wel-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.invite-page-wel-sub-typo {
  text-align: center;
  color: #414437;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 12px;
  width: 80%;
}
.MuiTypography-root.invite-page-wel-sub-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.invite-page-form-box {
  margin-top: 20px;
  width: 100%;
}
.MuiTextField-root.invite-page-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.invite-page-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.invite-page-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.invite-page-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.invite-page-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.invite-page-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.invite-page-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.invite-page-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.invite-page-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.invite-page-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.invite-page-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.invite-page-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.invite-page-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}

.MuiSvgIcon-root.invite-page-visibility-icon {
  height: 20px;
  width: 20px;
}
.MuiSvgIcon-root.invite-page-visibility-icon:hover {
  cursor: pointer;
}

.MuiBox-root.invite-page-instruction-box {
  margin-top: 20px;
  padding-left: 8px;
}
.MuiTypography-root.invite-page-instruction-typo {
  margin-left: 16px;
  color: #414437;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}
.MuiTypography-root.invite-page-instruction-typo.dark-theme {
  color: #A1A591;
}

.MuiButton-root.invite-page-submit-button {
  background-color: #4E5D15;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.56;
  margin-top: 20px;
  width: 244px;
  display: flex;
  text-transform: none;
  width: 100%;
}
.MuiButton-root.invite-page-submit-button:hover {
  background-color: #4E5D15;
}
.MuiButton-root.invite-page-submit-button.Mui-disabled {
  background-color: #A1A591;
  color: #D9DBD3;
}
.MuiButton-root.invite-page-submit-button.dark-theme {
  background-color: #819C24;
  color: #F7FAEA;
}
.MuiButton-root.invite-page-submit-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiBox-root.landing-page-box {
  background-color: #0F1300;
  min-height: 100vh;
  min-width: fit-content;
  width: 100%;
}

.MuiBox-root.landing-page-nav-box {
  align-items: center;
  backdrop-filter: blur(4px);
  background: linear-gradient(178.15deg, rgba(0, 18, 6, 0.5) 1.56%, rgba(255, 255, 255, 0.5) 465.89%, rgba(0, 18, 6, 0.5) 465.89%);
  display: flex;
  height: 70px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
}

.MuiBox-root.landing-page-content-box {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.MuiTypography-root.landing-page-nav-title-typo {
  margin-left: 36px;
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.29;
}

.MuiButton-root.landing-page-access-button,
.MuiButton-root.landing-page-nav-access-button {
  align-items: center;
  background-color: #ECF9DD;
  border-radius: 0;
  flex-grow: 0;
  justify-content: center;
  min-width: fit-content;
  margin-right: 36px;
  padding: 8px 16px;
  text-transform: none;
  white-space: nowrap;
  color: #4A4A4A;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 1.29;
}
.MuiButton-root.landing-page-access-button:hover,
.MuiButton-root.landing-page-nav-access-button:hover {
  background-color: #ECF9DD;
}

.MuiButton-root.landing-page-nav-access-button.landing-page-login-button {
  margin-left: auto;
  margin-right: 16px;
}

.MuiTypography-root.landing-page-headline-typo {
  text-align: center;
  color: #EFFADD;
  font-size: 4rem;
  font-weight: 500;
  letter-spacing: -0.055em;
  line-height: 0.92;
  margin-top: 127px;
}

.MuiTypography-root.landing-page-desc-typo {
  max-width: 648px;
  text-align: center;
  color: #EFFADD;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.35;
  margin-top: 24px;
}

.MuiButton-root.landing-page-access-button {
  font-size: 1rem;
  line-height: 1.25;
  min-width: fit-content;
  margin-top: 40px;
  padding: 14px 24px;
}

.MuiBox-root.landing-page-bg-image-box {
  margin-top: 161px;
}

.MuiBox-root.login-page-box {
  margin: 108px auto 0;
  width: 472px;
}
.MuiTypography-root.login-page-info-typo {
  color: #090A08;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTypography-root.login-page-info-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.login-page-action-typo {
  color: #090A08;
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.13;
  margin-top: 12px;
}
.MuiTypography-root.login-page-action-typo.dark-theme {
  color: #A1A591;
}

.MuiTextField-root.login-page-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.login-page-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.login-page-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.login-page-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.login-page-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.login-page-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.login-page-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.login-page-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.login-page-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.login-page-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.login-page-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.login-page-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.login-page-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}
.MuiButton-root.login-page-proceed-button {
  background-color: #4E5D15;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.56;
  margin-top: 20px;
  width: 244px;
  margin-bottom: 20px;
  width: 100%;
}
.MuiButton-root.login-page-proceed-button:hover {
  background-color: #4E5D15;
}
.MuiButton-root.login-page-proceed-button.Mui-disabled {
  background-color: #A1A591;
  color: #D9DBD3;
}
.MuiButton-root.login-page-proceed-button.dark-theme {
  background-color: #819C24;
  color: #F7FAEA;
}
.MuiButton-root.login-page-proceed-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiButton-root.specific-popup-proceed-button {
  background-color: #4E5D15;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.56;
  margin-top: 20px;
  width: 244px;
  margin-bottom: 20px;
  width: 100%;
}
.MuiButton-root.specific-popup-proceed-button:hover {
  background-color: #4E5D15;
}
.MuiButton-root.specific-popup-proceed-button.Mui-disabled {
  background-color: #A1A591;
  color: #D9DBD3;
}
.MuiButton-root.specific-popup-proceed-button.dark-theme {
  background-color: #819C24;
  color: #F7FAEA;
}
.MuiButton-root.specific-popup-proceed-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiSvgIcon-root.metric-plan-detail-icon {
  height: 32px;
  margin-right: 7px;
  width: 32px;
}
.MuiSvgIcon-root.metric-plan-detail-icon path {
  fill: #090A08;
}

.MuiBox-root.metric-plan-detail-box {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 15px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiBox-root.metric-plan-detail-header-box {
  align-items: flex-start;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out !important;
}

.MuiBox-root.metric-plan-detail-title-and-nav-box {
  display: flex;
  flex-direction: column;
}

.MuiTypography-root.metric-plan-detail-title-typo {
  margin-bottom: 16px;
  color: #414437;
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.08;
  overflow-wrap: break-word;
  word-break: break-word;
}

.MuiSvgIcon-root.metric-plan-detail-breadcrumb-sep-icon {
  height: 16px;
  width: 16px;
}

.MuiBreadcrumbs-root.metric-plan-detail-breadcrumbs {
  line-height: 18px;
}
.MuiBreadcrumbs-root.metric-plan-detail-breadcrumbs .MuiBreadcrumbs-separator {
  margin-left: 4px;
  margin-right: 4px;
}

.MuiTypography-root.metric-plan-detail-breadcrumb-link-typo {
  color: #5D614F;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 1.29;
}
.MuiTypography-root.metric-plan-detail-breadcrumb-link-typo:hover {
  cursor: default;
}

.MuiButton-root.metric-plan-detail-button {
  background-color: transparent;
  border: 1px solid #4E5D15;
  border-radius: 0;
  margin-right: 8px;
  padding-bottom: 16px;
  padding-top: 16px;
  text-align: center;
  text-transform: none;
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.25;
  width: 122px;
}
.MuiButton-root.metric-plan-detail-button:hover {
  background-color: transparent;
}
.MuiButton-root.metric-plan-detail-button:last-of-type {
  margin-right: 0;
}

.MuiSvgIcon-root.metric-plan-detail-button-icon {
  height: 20px;
  margin-right: 2px;
  width: 20px;
}

.MuiButton-root.metric-plan-detail-save-button {
  background-color: #819C24;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  width: 122px;
}
.MuiButton-root.metric-plan-detail-save-button:hover {
  background-color: #819C24;
}
.MuiButton-root.metric-plan-detail-save-button.Mui-disabled {
  background-color: #A1A591;
  color: #D9DBD3;
}

.MuiBox-root.metric-plan-detail-wizard-btn-box {
  display: flex;
}

.MuiBox-root.metric-plan-detail-wizard-back-btn-box {
  align-items: center;
  display: flex;
  margin-right: 16px;
}

.MuiButton-root.metric-plan-detail-wizard-back-button {
  background-color: #819C24;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  width: 244px;
  margin-left: 16px;
}
.MuiButton-root.metric-plan-detail-wizard-back-button:hover {
  background-color: #819C24;
}
.MuiButton-root.metric-plan-detail-wizard-back-button.Mui-disabled {
  background-color: #A1A591;
  color: #D9DBD3;
}
.MuiButton-root.metric-plan-detail-wizard-back-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.metric-plan-detail-wizard-back-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiButton-root.metric-plan-detail-save-metric-cycle-button {
  background-color: #819C24;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  width: 244px;
}
.MuiButton-root.metric-plan-detail-save-metric-cycle-button:hover {
  background-color: #819C24;
}
.MuiButton-root.metric-plan-detail-save-metric-cycle-button.Mui-disabled {
  background-color: #A1A591;
  color: #D9DBD3;
}
.MuiButton-root.metric-plan-detail-save-metric-cycle-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.metric-plan-detail-save-metric-cycle-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiTabs-root.metric-plan-detail-tabs {
  margin-top: 32px;
}
.MuiTabs-root.metric-plan-detail-tabs .MuiTabs-indicator {
  background-color: #414437;
}

.MuiTab-root.metric-plan-detail-tab {
  text-transform: none;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTab-root.metric-plan-detail-tab.Mui-selected {
  color: #414437;
  font-weight: 500;
}

.MuiDivider-root.metric-plan-detail-divider {
  background-color: #A1A591;
}

.MuiDivider-root.metric-plan-subphase-divider {
  height: 2px;
  background-color: #B1D23A;
  border-radius: 5px;
}

.MuiDivider-root.metric-run-run-divider {
  height: 8px;
  background-color: #A1A591;
  border-radius: 5px;
}

.MuiTextField-root.metric-plan-detail-textfield {
  width: 650px;
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.metric-plan-detail-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.metric-plan-detail-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.metric-plan-detail-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.metric-plan-detail-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.metric-plan-detail-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}

.MuiSvgIcon-root.metric-plan-detail-phaseplan-link-icon {
  height: 20px;
  margin-left: 4px;
  vertical-align: text-bottom;
  width: 20px;
}
.MuiSvgIcon-root.metric-plan-detail-phaseplan-link-icon path {
  fill: none;
}

.MuiBox-root.metric-plan-detail-phaseplan-link-box {
  text-decoration: none;
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.28;
}

.MuiBox-root.metric-plan-detail-overview-box {
  margin-top: 40px;
  display: grid;
  gap: 16px;
  grid-auto-rows: auto;
  grid-template-areas: "a c" "b c";
  grid-template-columns: repeat(2, 1fr);
  max-width: 1220px;
  margin-top: 36px;
  width: 100%;
}

.MuiBox-root.metric-plan-detail-card-box {
  background-color: #E6F1C1;
  min-width: fit-content;
  padding: 24px 28px;
  width: 100%;
}

.MuiBox-root.metric-plan-detail-card-header-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.MuiTypography-root.metric-plan-detail-card-header-typo {
  white-space: nowrap;
  color: #414437;
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 1.16;
}

.MuiSvgIcon-root.metric-plan-detail-attach-metric-cycle-edit-icon {
  height: 24px;
  width: 24px;
}

.MuiButton-root.metric-plan-detail-card-header-edit-button {
  background-color: transparent;
  text-transform: none;
  white-space: nowrap;
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiButton-root.metric-plan-detail-card-header-edit-button:hover {
  background-color: transparent;
}

.MuiBox-root.metric-plan-detail-content-box {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  overflow: auto;
}

.MuiBox-root.metric-plan-detail-gen-info-box {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  padding-bottom: 25px;
  width: 100%;
}

.MuiTypography-root.metric-plan-detail-tab-title-typo {
  color: #090A08;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.45;
}

.MuiTypography-root.metric-plan-detail-tab-sub-typo {
  margin-bottom: 20px;
  color: #5D614F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
  margin-top: 8px;
}

.MuiMenuItem-root.metric-plan-detail-menuitem.dark-theme {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.metric-plan-detail-menuitem.dark-theme:hover {
  background-color: #6F745F;
}
.MuiMenuItem-root.metric-plan-detail-menuitem.dark-theme.Mui-selected {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.metric-plan-detail-menuitem.dark-theme.menuitem-focus-dark {
  background-color: #5D614F;
}
.MuiMenuItem-root.metric-plan-detail-menuitem.dark-theme.Mui-disabled {
  opacity: 1;
  background-color: #414437;
  color: #6F745F;
}

.MuiBox-root.metric-plan-detail-cycle-table-box {
  display: flex;
  flex-direction: column;
  margin-top: 17.4285714286px;
  gap: 17.4285714286px;
}

.MuiTypography-root.metric-plan-detail-save-change-typo {
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
}
.MuiTypography-root.metric-plan-detail-save-change-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.metric-plan-detail-cycle-type-typo {
  margin-bottom: 12px;
  color: #414437;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
}

.MuiBox-root.metric-plan-detail-metric-cycle-table-box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 1420px;
  margin-bottom: 36px;
}

.MuiBox-root.metric-plan-detail-highcharts-box {
  margin-bottom: 62px;
  margin-left: auto;
  margin-right: auto;
}
.MuiBox-root.metric-plan-detail-highcharts-box.metric-plan-detail-no-chart {
  display: none;
}

.MuiSvgIcon-root.metric-plan-detail-icon.dark-theme path {
  fill: #B1D23A;
}

.MuiBox-root.metric-plan-detail-box.dark-theme {
  background-color: #1A1B19;
}

.MuiTypography-root.metric-plan-detail-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiSvgIcon-root.metric-plan-detail-breadcrumb-sep-icon.dark-theme path {
  stroke: #A1A591;
}

.MuiBreadcrumbs-root.metric-plan-detail-breadcrumbs.dark-theme li span {
  color: #A1A591;
}

.MuiTypography-root.metric-plan-detail-breadcrumb-link-typo.dark-theme {
  color: #A1A591;
}

.MuiButton-root.metric-plan-detail-button.dark-theme {
  border-color: #B1D23A;
  color: #B1D23A;
}

.MuiSvgIcon-root.metric-plan-detail-button-icon.dark-theme path {
  fill: #B1D23A;
}

.MuiButton-root.metric-plan-detail-save-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.metric-plan-detail-save-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiTabs-root.metric-plan-detail-tabs.dark-theme .MuiTabs-indicator {
  background-color: #D9DBD3;
}

.MuiTab-root.metric-plan-detail-tab.dark-theme.Mui-selected {
  color: #D9DBD3;
}

.MuiDivider-root.metric-plan-detail-divider.dark-theme {
  background-color: #6F745F;
}

.MuiTextField-root.metric-plan-detail-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.metric-plan-detail-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.metric-plan-detail-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.metric-plan-detail-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.metric-plan-detail-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.metric-plan-detail-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiBox-root.metric-plan-detail-card-box.dark-theme {
  background-color: #303130;
}

.MuiTypography-root.metric-plan-detail-card-header-typo.dark-theme {
  color: #D9DBD3;
}

.MuiSvgIcon-root.metric-plan-detail-attach-metric-cycle-edit-icon.dark-theme path {
  fill: #B1D23A;
}

.MuiButton-root.metric-plan-detail-card-header-edit-button.dark-theme {
  color: #B1D23A;
}

.MuiTypography-root.metric-plan-detail-tab-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.metric-plan-detail-tab-sub-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.metric-plan-detail-cycle-type-typo.dark-theme {
  color: #D9DBD3;
}

.MuiBox-root.metric-plan-detail-highcharts-box.dark-theme {
  background-color: transparent;
}
.MuiBox-root.metric-plan-detail-highcharts-box.dark-theme .highcharts-background {
  fill: #303130;
}
.MuiBox-root.metric-plan-detail-highcharts-box.dark-theme .highcharts-axis-labels text,
.MuiBox-root.metric-plan-detail-highcharts-box.dark-theme .highcharts-legend-item text {
  font-weight: 500 !important;
  fill: #BDC0B2 !important;
}

.command-center-chart {
  background-color: transparent;
}
.command-center-chart .highcharts-background {
  fill: transparent;
}

.command-center-view {
  color: #B1D23A;
}
.command-center-view .MuiTable-root.display-table-table {
  border-bottom: 1px solid #A1A591;
}

.MuiPaper-root.metric-plan-container {
  padding-top: 40px;
  background: transparent;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiCard-root.metric-plan-card-wrapper {
  display: flex;
  justify-content: space-between;
  border-radius: 0;
  border-bottom: 2px dotted #5D614F;
}

.MuiButton-root.metric-plan-card-btn {
  color: #B1D23A;
  padding: 0;
  stroke: #B1D23A;
  text-transform: none;
}
.MuiButton-root.metric-plan-card-btn.metric-plan-del-btn {
  color: #A1A591;
  stroke: #A1A591;
}

.MuiSvgIcon-root.metric-plan-link-icon {
  height: 16px;
  width: 16px;
}
.MuiSvgIcon-root.metric-plan-link-icon path {
  fill: none;
  stroke: inherit;
}
.MuiSvgIcon-root.metric-plan-link-icon:hover {
  cursor: pointer;
}

.MuiCardActions-root.metric-plan-card-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px;
}

.MuiBox-root.metric-plan-metricrun-box {
  display: grid;
}

.MuiChip-root.metric-run-index-chip {
  border-color: #B1D23A;
  color: #B1D23A;
  border-radius: 50%;
  height: 24px;
  width: 24px;
}
.MuiChip-root.metric-run-index-chip .MuiChip-label {
  padding: 0;
}

.MuiTextField-root.metric-base-textfield {
  color: #252720;
  margin-top: 0px;
}
.MuiTextField-root.metric-base-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.metric-base-textfield .MuiInputBase-input {
  padding: 4px 4px;
}
.MuiTextField-root.metric-base-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.metric-base-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 12px;
  line-height: 0;
}
.MuiTextField-root.metric-base-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.metric-base-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.metric-base-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.metric-base-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.metric-base-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.metric-base-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.metric-base-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.metric-base-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}

.MuiTextField-root.metric-run-day-textfield {
  max-width: 60px;
}

.MuiCardContent-root.metric-run-card-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
}

.MuiDivider-root.dim-linear-gradient-divider,
.MuiDivider-root.metric-run-night-divider,
.MuiDivider-root.metric-run-day-divider {
  height: 3px;
  border-radius: 5px;
}

.MuiDivider-root.metric-run-night-divider {
  background-color: #5A57FF;
}

.MuiDivider-root.metric-run-day-divider {
  background-color: #B1D23A;
}

.MuiPaper-root.metric-run-paper-wrapper {
  padding: 24px;
  border: 1px solid #252720;
  border-radius: 0;
}

.MuiCard-root.metric-run-card-wrapper {
  padding: 4px 12px;
}

.MuiSvgIcon-root.metric-run-dayparts-icon {
  height: 16px;
  width: 16px;
  fill: none;
}

.MuiTypography-root.metric-plan-subphase-typo {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.MuiCard-root.metric-plan-card-wrapper.dark-theme {
  background-color: #000000;
}

.MuiTypography-root.metric-plan-card-title-typo.dark-theme {
  color: #F6F6F4;
}

.MuiTypography-root.metric-plan-card-sub-header-typo.dark-theme,
.MuiTypography-root.metric-plan-subphase-typo.dark-theme {
  color: #A1A591;
}

.MuiTextField-root.metric-base-textfield.dark-theme {
  background-color: #303130;
  background: transparent;
}
.MuiTextField-root.metric-base-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.metric-base-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.metric-base-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.metric-base-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.metric-base-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.metric-base-textfield.dark-theme .MuiInputBase-root {
  border: 1px solid #B1D23A;
}
.MuiTextField-root.metric-base-textfield.dark-theme .MuiInputBase-root.Mui-disabled {
  border: none;
}
.MuiTextField-root.metric-base-textfield.dark-theme fieldset {
  border: none;
}

.MuiTypography-root.metric-run-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiPaper-root.metric-run-paper-wrapper.dark-theme {
  background-color: #1D1E1C;
}

.MuiCard-root.metric-run-card-wrapper.dark-theme {
  background: #000;
  border-bottom: 2px dotted #5D614F;
}

.MuiBox-root.organization-box {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 60px 32px 0;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiBox-root.organization-nav-title-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 54px;
}

.MuiBox-root.organization-title-box {
  align-items: center;
  display: flex;
}

.MuiTypography-root.organization-title-typo {
  background-color: #D9DBD3;
  padding: 4px 12px;
  text-transform: uppercase;
  color: #5D614F;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
}

.MuiTypography-root.organization-name-typo {
  margin-left: 12px;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
}

.MuiTabs-root.organization-tabs {
  margin-top: 40px;
}

.MuiTab-root.organization-tab {
  text-transform: none;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTab-root.organization-tab.Mui-selected {
  color: #414437;
  font-weight: 500;
}

.MuiDivider-root.organization-divider {
  background-color: #A1A591;
}

.MuiBox-root.organization-form-box {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.MuiBox-root.organization-input-section-box {
  max-width: 650px;
  margin-bottom: 32px;
}

.MuiTextField-root.organization-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.organization-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.organization-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.organization-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.organization-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.organization-textfield fieldset {
  border-color: #252720;
}

.MuiButton-root.organization-save-button {
  background-color: #819C24;
  border-radius: 0;
  margin-left: auto;
  padding-bottom: 16px;
  padding-top: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.25;
  width: 244px;
}
.MuiButton-root.organization-save-button:hover {
  background-color: #819C24;
}

.MuiBox-root.organization-table-box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 56px;
  max-width: 1420px;
}

.MuiBox-root.organization-perm-table-box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 56px;
  max-width: 1420px;
}

.MuiBox-root.organization-externals-table-box {
  display: grid;
  gap: 16px;
  margin-top: 56px;
  padding-bottom: 60px;
}

.MuiButton-root.organization-add-button {
  background-color: #4E5D15;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #D9DBD3;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.45;
  width: 244px;
}
.MuiButton-root.organization-add-button:hover {
  background-color: #4E5D15;
}

.MuiBox-root.organization-box.dark-theme {
  background-color: #1A1B19;
}

.MuiTypography-root.organization-title-typo.dark-theme {
  border: 1px solid #414437;
  border-radius: 100px;
  background-color: #2B2C2B;
  color: #A1A591;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}

.MuiTypography-root.organization-name-typo.dark-theme {
  color: #A1A591;
}

.MuiTab-root.organization-tab.dark-theme.Mui-selected {
  color: #D9DBD3;
}

.MuiDivider-root.organization-divider.dark-theme {
  background-color: #6F745F;
}

.MuiTextField-root.organization-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.organization-textfield.dark-theme .MuiInputLabel-root, .MuiTextField-root.organization-textfield.dark-theme .MuiInputBase-root {
  color: #A1A591;
}
.MuiTextField-root.organization-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiButton-root.organization-add-button.dark-theme,
.MuiButton-root.organization-save-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.organization-add-button.dark-theme.Mui-disabled,
.MuiButton-root.organization-save-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiBox-root.org-setup-outline-box {
  background-color: #F7FAEA;
  display: flex;
  min-height: 100vh;
  width: 100%;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}
.MuiBox-root.org-setup-outline-box.dark-theme {
  background-color: #1A1B19;
}

.MuiBox-root.org-setup-outline-content-box {
  flex-basis: 100%;
  padding-left: 32px;
  padding-top: 37px;
}
.MuiTypography-root.org-setup-outline-brand-typo {
  text-transform: uppercase;
  color: #090A08;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.29;
  margin-top: 8px;
}
.MuiTypography-root.org-setup-outline-brand-typo:hover {
  cursor: pointer;
}
.MuiTypography-root.org-setup-outline-brand-typo.dark-theme {
  color: #B1D23A;
}

.MuiBox-root.org-setup-box {
  display: flex;
  flex-direction: column;
  max-width: 472px;
  margin: 96px auto 32px;
}

.MuiTypography-root.org-setup-step-typo {
  text-transform: uppercase;
  color: #4E5D15;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.035em;
  line-height: 1.58;
}
.MuiTypography-root.org-setup-step-typo.dark-theme {
  color: #819C24;
}

.MuiBox-root.org-setup-progress-box {
  display: flex;
  height: 8px;
  margin-top: 12px;
}

.MuiBox-root.org-setup-progress-elem-box {
  background-color: #D9DBD3;
  border-radius: 100px;
  flex-grow: 1;
  height: 8px;
  margin-right: 4px;
  transition: background-color 800ms  ;
}
.MuiBox-root.org-setup-progress-elem-box.org-setup-completed-step {
  background-color: #819C24;
}
.MuiBox-root.org-setup-progress-elem-box:last-of-type {
  margin-right: 0;
}
.MuiBox-root.org-setup-progress-elem-box.dark-theme {
  background-color: #5D614F;
}
.MuiBox-root.org-setup-progress-elem-box.dark-theme.org-setup-completed-step {
  background-color: #819C24;
}

.MuiTypography-root.org-setup-detail-typo {
  color: #090A08;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
  margin-top: 52px;
}
.MuiTypography-root.org-setup-detail-typo.dark-theme {
  color: #6F745F;
}

.MuiTypography-root.org-setup-action-typo {
  color: #090A08;
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.13;
  margin-top: 12px;
}
.MuiTypography-root.org-setup-action-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.org-setup-form-box {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
}

.MuiSvgIcon-root.org-setup-whats-this-icon path {
  fill: none;
}

.MuiTextField-root.org-setup-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.org-setup-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.org-setup-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.org-setup-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.org-setup-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.org-setup-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.org-setup-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.org-setup-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.org-setup-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.org-setup-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.org-setup-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.org-setup-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.org-setup-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}

.MuiBox-root.org-setup-agree-box {
  align-items: center;
  display: flex;
  margin-top: 16px;
}

.MuiInputBase-root.org-setup-checkbox {
  border-color: #4E5D15;
  height: 16px;
  width: 16px;
}
.MuiInputBase-root.org-setup-checkbox input {
  accent-color: #4E5D15;
  height: 100%;
}
.MuiInputBase-root.org-setup-checkbox.dark-theme input {
  accent-color: #A1A591;
}

.MuiTypography-root.org-setup-agree-typo {
  margin-left: 16px;
  color: #090A08;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiTypography-root.org-setup-agree-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.org-setup-allow-info-box {
  display: flex;
  margin-top: 12px;
}

.MuiSvgIcon-root.org-setup-allow-info-icon {
  height: 20px;
  width: 20px;
}
.MuiSvgIcon-root.org-setup-allow-info-icon.dark-theme path {
  fill: #6F745F;
}

.MuiTypography-root.org-setup-allow-info-typo {
  margin-left: 4px;
  color: #5D614F;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}
.MuiTypography-root.org-setup-allow-info-typo.dark-theme {
  color: #6F745F;
}

.MuiMenuItem-root.org-setup-state-menuitem.dark-theme {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.org-setup-state-menuitem.dark-theme:hover {
  background-color: #6F745F;
}
.MuiMenuItem-root.org-setup-state-menuitem.dark-theme.Mui-selected {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.org-setup-state-menuitem.dark-theme.menuitem-focus-dark {
  background-color: #5D614F;
}
.MuiMenuItem-root.org-setup-state-menuitem.dark-theme.Mui-disabled {
  opacity: 1;
  background-color: #414437;
  color: #6F745F;
}

.MuiButton-root.org-setup-proceed-button {
  background-color: #4E5D15;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.56;
  margin-top: 20px;
  width: 244px;
  width: 100%;
}
.MuiButton-root.org-setup-proceed-button:hover {
  background-color: #4E5D15;
}
.MuiButton-root.org-setup-proceed-button.Mui-disabled {
  background-color: #A1A591;
  color: #D9DBD3;
}
.MuiButton-root.org-setup-proceed-button.dark-theme {
  background-color: #819C24;
  color: #F7FAEA;
}
.MuiButton-root.org-setup-proceed-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiBox-root.phase-plan-bar-box {
  display: flex;
  margin-top: 20px;
  width: 100%;
}

.MuiBox-root.phase-plan-bar-entry-box {
  align-items: center;
  background-color: #E6F1C1;
  border: 1px solid #D9DBD3;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  height: 36px;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
}

.MuiTypography-root.phase-plan-bar-day-typo {
  font-size: 0.875rem;
}

.MuiTypography-root.phase-plan-bar-day-typo,
.MuiTypography-root.phase-plan-bar-type-typo {
  color: #090A08;
}

.MuiBox-root.phase-plan-overview-box {
  display: grid;
  gap: 16px;
  grid-auto-rows: auto;
  grid-template-areas: "a c" "b c";
  grid-template-columns: repeat(2, 1fr);
  max-width: 1220px;
  margin-top: 36px;
  width: 100%;
}

.MuiTypography-root.phase-plan-overview-info-typo {
  color: #6F745F;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.56;
  margin-top: 4px;
  overflow-wrap: break-word;
  word-break: break-word;
}

.MuiSvgIcon-root.phase-plan-overview-info-icon {
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
  width: 20px;
}
.MuiSvgIcon-root.phase-plan-overview-info-icon path {
  fill: #4E5D15;
}

.MuiTextField-root.phase-plan-textfield {
  width: 650px;
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.phase-plan-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.phase-plan-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.phase-plan-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.phase-plan-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.phase-plan-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}

.MuiBox-root.phase-plan-gen-info-box {
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  width: 100%;
}

.MuiTypography-root.phase-plan-gen-info-title-typo {
  color: #090A08;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.45;
}

.MuiBox-root.phase-plan-sub-phase-box {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
}

.MuiBox-root.phase-plan-sub-phase-title-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
}

.MuiSvgIcon-root.phase-plan-add-sub-phase-icon.dark-theme path {
  fill: #1A1F07;
}

.MuiButton-root.phase-plan-add-sub-phase-button {
  background-color: #819C24;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  width: 244px;
}
.MuiButton-root.phase-plan-add-sub-phase-button:hover {
  background-color: #819C24;
}
.MuiButton-root.phase-plan-add-sub-phase-button.Mui-disabled {
  background-color: #A1A591;
  color: #D9DBD3;
}
.MuiButton-root.phase-plan-add-sub-phase-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.phase-plan-add-sub-phase-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiBox-root.phase-plan-bar-entry-box.dark-theme {
  border-color: #A1A591;
  background-color: #303130;
}

.MuiTypography-root.phase-plan-bar-type-typo.dark-theme,
.MuiTypography-root.phase-plan-bar-day-typo.dark-theme {
  color: #BDC0B2;
}

.MuiTypography-root.phase-plan-overview-info-typo.dark-theme {
  color: #A1A591;
}

.MuiSvgIcon-root.phase-plan-overview-info-icon.dark-theme path {
  fill: #B1D23A;
}

.MuiTextField-root.phase-plan-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.phase-plan-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.phase-plan-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.phase-plan-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.phase-plan-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.phase-plan-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiTypography-root.phase-plan-gen-info-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiBox-root.plans-box {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 15px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiBox-root.plans-header-box {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  min-height: 56px;
  width: 100%;
}

.MuiTypography-root.plans-title-typo {
  color: #414437;
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.08;
}

.MuiBox-root.plans-header-button-box {
  display: flex;
  align-items: center;
}

.MuiSvgIcon-root.plans-back-to-wizard-icon:hover {
  cursor: pointer;
}

.MuiButton-root.plans-create-button {
  background-color: #4E5D15;
  border-radius: 0;
  margin-left: 16px;
  padding: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.45;
  width: 244px;
}
.MuiButton-root.plans-create-button:hover {
  background-color: #4E5D15;
}

.MuiTabs-root.plans-tabs {
  margin-top: 40px;
}
.MuiTabs-root.plans-tabs .MuiTabs-indicator {
  background-color: #414437;
}

.MuiTab-root.plans-tab {
  text-transform: none;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTab-root.plans-tab.Mui-selected {
  color: #414437;
  font-weight: 500;
}

.MuiBox-root.plans-tab-box {
  margin-bottom: 32px;
  margin-top: 32px;
}

.MuiBox-root.plans-tab-card-box {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
}

.MuiTypography-root.plans-tab-title-typo {
  color: #414437;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
}

.MuiTypography-root.plans-tab-desc-typo {
  color: #5D614F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
  margin-top: 8px;
}

.MuiTypography-root.plans-site-dev-typo {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #A1A591;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 32px;
}

.MuiDivider-root.plans-divider {
  background-color: #A1A591;
}

.MuiBox-root.plans-metric-tab-box {
  margin-top: 8px;
}

.MuiTypography-root.plans-phase-plan-card-extra-detail-typo {
  margin-top: 8px;
}

.MuiTabs-root.plans-metric-filter-tabs {
  margin-bottom: 16px;
}
.MuiTabs-root.plans-metric-filter-tabs .MuiChip-root.plans-metric-chip-selected {
  background-color: #E6F1C1;
}
.MuiTabs-root.plans-metric-filter-tabs .MuiTab-root {
  min-width: fit-content;
  padding: 0px 4px;
  text-transform: none;
}
.MuiTabs-root.plans-metric-filter-tabs .MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}
.MuiTabs-root.plans-metric-filter-tabs .MuiTabs-indicator {
  display: none;
}
.MuiTabs-root.plans-metric-filter-tabs.dark-theme .MuiChip-root {
  color: #A1A591;
}
.MuiTabs-root.plans-metric-filter-tabs.dark-theme .MuiChip-root.plans-metric-chip-selected {
  background-color: #414437;
}
.MuiTabs-root.plans-metric-filter-tabs.dark-theme .MuiTabScrollButton-root {
  color: #A1A591;
}

.MuiBox-root.plans-box.dark-theme {
  background-color: #1A1B19;
}

.MuiTypography-root.plans-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiButton-root.plans-create-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.plans-create-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiTabs-root.plans-tabs.dark-theme .MuiTabs-indicator {
  background-color: #D9DBD3;
}

.MuiTab-root.plans-tab.dark-theme.Mui-selected {
  color: #D9DBD3;
}

.MuiTypography-root.plans-tab-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.plans-tab-desc-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.plans-site-dev-typo.dark-theme {
  color: #A1A591;
}

.MuiDivider-root.plans-divider.dark-theme {
  background-color: #6F745F;
}

.MuiBox-root.profile-box {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 60px 32px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}
.MuiBox-root.profile-box.dark-theme {
  background-color: #1A1B19;
}

.MuiTypography-root.profile-welcome-typo {
  color: #414437;
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.08;
  margin-top: 12px;
}
.MuiTypography-root.profile-welcome-typo.dark-theme {
  color: #D9DBD3;
}

.MuiBox-root.profile-form-box {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.MuiBox-root.profile-form-content-box {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 16px;
}
.MuiBox-root.profile-section-box {
  display: flex;
}
.MuiTextField-root.profile-textfield {
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.profile-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.profile-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.profile-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.profile-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.profile-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.profile-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.profile-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.profile-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.profile-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.profile-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.profile-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.profile-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}
.MuiTextField-root.profile-textfield.dark-theme input.Mui-disabled {
  -webkit-text-fill-color: #6F745F;
}

.MuiBox-root.profile-photo-section-box {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 244px;
  max-width: 244px;
  margin-left: auto;
}
.MuiBox-root.profile-photo-box {
  background-color: #D9DBD3;
  border-radius: 50%;
  height: 244px;
  width: 100%;
}
.MuiBox-root.profile-photo-box.dark-theme {
  background-color: #303130;
}

.MuiBox-root.profile-content-footer-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
}
.MuiTypography-root.profile-role-typo {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
}
.MuiTypography-root.profile-role-typo.dark-theme {
  color: #A1A591;
}

.MuiButton-root.profile-save-button {
  background-color: #819C24;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  width: 244px;
}
.MuiButton-root.profile-save-button:hover {
  background-color: #819C24;
}
.MuiButton-root.profile-save-button.Mui-disabled {
  background-color: #A1A591;
  color: #D9DBD3;
}
.MuiButton-root.profile-save-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.profile-save-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiBox-root.quality-plan-accordion-drag-card-box {
  align-items: flex-start;
  padding-left: 44px;
}

.MuiBox-root.quality-plan-box {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 60px 32px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}
.MuiBox-root.quality-plan-box.dark-theme {
  background-color: #1A1B19;
}

.MuiBox-root.quality-plan-header-box {
  align-items: flex-start;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
}

.MuiBox-root.quality-plan-header-title-box {
  display: flex;
  flex-direction: column;
}

.MuiTypography-root.quality-plan-action-typo {
  margin-bottom: 16px;
  color: #090A08;
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.13;
}
.MuiTypography-root.quality-plan-action-typo.dark-theme {
  color: #D9DBD3;
}

.MuiSvgIcon-root.quality-plan-breadcrumbs-sep-icon {
  height: 16px;
  width: 16px;
}
.MuiSvgIcon-root.quality-plan-breadcrumbs-sep-icon.dark-theme path {
  stroke: #A1A591;
}

.MuiBreadcrumbs-root.quality-plan-breadcrumbs {
  line-height: 18px;
}
.MuiBreadcrumbs-root.quality-plan-breadcrumbs .MuiBreadcrumbs-separator {
  margin-left: 4px;
  margin-right: 4px;
}
.MuiBreadcrumbs-root.quality-plan-breadcrumbs.dark-theme li span {
  color: #A1A591;
}

.MuiTypography-root.quality-plan-breadcrumb-link-typo {
  color: #5D614F;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 1.29;
}
.MuiTypography-root.quality-plan-breadcrumb-link-typo:hover {
  cursor: default;
}
.MuiTypography-root.quality-plan-breadcrumb-link-typo.dark-theme {
  color: #A1A591;
}

.MuiButton-root.quality-plan-save-button {
  background-color: #819C24;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  width: 244px;
}
.MuiButton-root.quality-plan-save-button:hover {
  background-color: #819C24;
}
.MuiButton-root.quality-plan-save-button.Mui-disabled {
  background-color: #A1A591;
  color: #D9DBD3;
}
.MuiButton-root.quality-plan-save-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.quality-plan-save-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiTabs-root.quality-plan-tabs {
  margin-top: 32px;
}
.MuiTabs-root.quality-plan-tabs .MuiTabs-indicator {
  background-color: #414437;
}
.MuiTabs-root.quality-plan-tabs.dark-theme .MuiTabs-indicator {
  background-color: #D9DBD3;
}

.MuiTab-root.quality-plan-tab {
  text-transform: none;
  color: #6F745F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTab-root.quality-plan-tab.Mui-selected {
  color: #414437;
  font-weight: 500;
}
.MuiTab-root.quality-plan-tab.dark-theme.Mui-selected {
  color: #D9DBD3;
}

.MuiDivider-root.quality-plan-divider {
  background-color: #A1A591;
}
.MuiDivider-root.quality-plan-divider.dark-theme {
  background-color: #6F745F;
}

.MuiBox-root.quality-plan-content-box {
  padding-top: 0;
}

.MuiTextField-root.quality-plan-tab-textfield {
  width: 650px;
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.quality-plan-tab-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.quality-plan-tab-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.quality-plan-tab-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.quality-plan-tab-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.quality-plan-tab-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.quality-plan-tab-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.quality-plan-tab-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.quality-plan-tab-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.quality-plan-tab-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.quality-plan-tab-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.quality-plan-tab-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.quality-plan-tab-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}

.MuiBox-root.quality-plan-overview-wrapper-box {
  margin-top: 8px;
}

.MuiBox-root.quality-plan-timeline-chart-box .MuiBox-root.timeline-chart-row-header-box.quality-plan-chart-value-hover:hover {
  cursor: pointer;
}
.MuiBox-root.quality-plan-timeline-chart-box .MuiBox-root.timeline-chart-row-header-box .MuiSvgIcon-root.quality-plan-chart-week-icon {
  margin-left: 8px;
  height: 20px;
  vertical-align: text-bottom;
  width: 20px;
}
.MuiBox-root.quality-plan-timeline-chart-box .MuiBox-root.timeline-chart-row-header-box .MuiSvgIcon-root.quality-plan-chart-week-icon.quality-plan-chart-expanded {
  transform: rotate(180deg);
}
.MuiBox-root.quality-plan-timeline-chart-box .MuiBox-root.timeline-chart-row-header-box .MuiSvgIcon-root.quality-plan-chart-week-icon.dark-theme path {
  fill: #D9DBD3;
}
.MuiBox-root.quality-plan-timeline-chart-box .MuiBox-root.timeline-chart-value-box {
  transition: background-color 800ms  ;
}
.MuiBox-root.quality-plan-timeline-chart-box .MuiBox-root.timeline-chart-value-box.quality-plan-chart-value-hover:hover {
  cursor: pointer;
}
.MuiBox-root.quality-plan-timeline-chart-box .MuiBox-root.timeline-chart-value-box.quality-plan-selected {
  background-color: #C5DE6E;
}
.MuiBox-root.quality-plan-timeline-chart-box .MuiBox-root.timeline-chart-value-box.quality-plan-selected.dark-theme {
  background-color: #B1D23A;
  color: #4E5D15;
}

.MuiBox-root.quality-plan-overview-box {
  display: grid;
  gap: 16px;
  grid-auto-rows: auto;
  grid-template-areas: "a c" "b c";
  grid-template-columns: repeat(2, 1fr);
  max-width: 1220px;
  margin-top: 36px;
  width: 100%;
}

.MuiSvgIcon-root.quality-plan-phaseplan-link-icon {
  height: 20px;
  margin-left: 4px;
  vertical-align: text-bottom;
  width: 20px;
}
.MuiSvgIcon-root.quality-plan-phaseplan-link-icon path {
  fill: none;
}

.MuiBox-root.quality-plan-phaseplan-link-box {
  text-decoration: none;
  color: #414437;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.28;
}

.MuiBox-root.quality-plan-gen-info-box {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.MuiTypography-root.quality-plan-tab-title-typo {
  color: #090A08;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.45;
  overflow-wrap: break-word;
  word-break: break-word;
}
.MuiTypography-root.quality-plan-tab-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.quality-plan-tab-sub-typo {
  margin-bottom: 12px;
  margin-top: 8px;
  color: #5D614F;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTypography-root.quality-plan-tab-sub-typo.dark-theme {
  color: #A1A591;
}

.MuiMenuItem-root.quality-plan-phaseplan-menuitem.dark-theme {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.quality-plan-phaseplan-menuitem.dark-theme:hover {
  background-color: #6F745F;
}
.MuiMenuItem-root.quality-plan-phaseplan-menuitem.dark-theme.Mui-selected {
  background-color: #6F745F;
  color: #D9DBD3;
}
.MuiMenuItem-root.quality-plan-phaseplan-menuitem.dark-theme.menuitem-focus-dark {
  background-color: #5D614F;
}
.MuiMenuItem-root.quality-plan-phaseplan-menuitem.dark-theme.Mui-disabled {
  opacity: 1;
  background-color: #414437;
  color: #6F745F;
}

.MuiTypography-root.quality-plan-attach-msg-typo {
  color: #090A08;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.45;
}
.MuiTypography-root.quality-plan-attach-msg-typo.dark-theme {
  color: #D9DBD3;
}

.MuiBox-root.quality-plan-drag-and-drop-box {
  display: flex;
  margin-top: 20px;
}

.MuiBox-root.quality-plan-drag-and-drop-end-box {
  flex-basis: 40%;
  height: 100%;
  max-width: 594px;
}

.MuiTypography-root.quality-plan-my-qp-typo {
  color: #414437;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
}
.MuiTypography-root.quality-plan-my-qp-typo.dark-theme {
  color: #BDC0B2;
}

.MuiTypography-root.quality-plan-my-qp-sub-typo {
  color: #414437;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 7px;
}
.MuiTypography-root.quality-plan-my-qp-sub-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.quality-plan-attached-metric-plans-box {
  background-color: #E6F1C1;
  height: 616px;
  margin-top: 16px;
  overflow: auto;
}
.MuiBox-root.quality-plan-attached-metric-plans-box.dark-theme {
  background-color: #F6F6F4;
}

.MuiTypography-root.quality-plan-sel-phase-typo {
  text-align: center;
  color: #414437;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.16;
  width: "100%";
}
.MuiTypography-root.quality-plan-sel-phase-typo.dark-theme {
  color: #BDC0B2;
}

.MuiBox-root.quality-plan-drag-and-drop-middle-box {
  align-items: center;
  display: flex;
  flex-basis: 10%;
  flex-direction: column;
  height: 616px;
  justify-content: center;
  margin-left: 64px;
  margin-right: 64px;
  max-width: 131px;
}

.MuiSvgIcon-root.quality-plan-drag-icon {
  height: 50px;
  width: 51px;
}
.MuiSvgIcon-root.quality-plan-drag-icon.dark-theme rect {
  fill: #303130;
}
.MuiSvgIcon-root.quality-plan-drag-icon.dark-theme path {
  fill: #D9DBD3;
}

.MuiTypography-root.quality-plan-drag-info-typo {
  text-align: center;
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 23px;
}
.MuiTypography-root.quality-plan-drag-info-typo.dark-theme {
  color: #BDC0B2;
}

.MuiBox-root.quality-plan-drag-and-drop-start-box {
  flex-basis: 40%;
  height: 100%;
  max-width: 594px;
}

.MuiTypography-root.quality-plan-created-mp-typo {
  color: #414437;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.16;
}
.MuiTypography-root.quality-plan-created-mp-typo.dark-theme {
  color: #BDC0B2;
}

.MuiTypography-root.quality-plan-created-mp-sub-typo {
  color: #414437;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 7px;
}
.MuiTypography-root.quality-plan-created-mp-sub-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.quality-plan-created-metric-plan-box {
  background-color: #E6F1C1;
  height: 616px;
  margin-top: 16px;
  overflow: auto;
}
.MuiBox-root.quality-plan-created-metric-plan-box.dark-theme {
  background-color: #F6F6F4;
}

.MuiTypography-root.room-detail-info-typo {
  color: #6F745F;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.56;
  min-height: 24.96px;
  margin-top: 4px;
}

.MuiSvgIcon-root.room-detail-info-icon {
  height: 20px;
  margin-right: 10px;
  vertical-align: text-bottom;
  width: 20px;
}

.MuiPaper-root.room-detail-info-icon-paper {
  align-items: center;
  background-color: #1A1F07;
  border-radius: 50%;
  display: flex;
  height: 56px;
  justify-content: center;
  width: 56px;
}

.MuiSvgIcon-root.room-detail-edit-icon {
  height: 20px;
  width: 20px;
  color: black;
}
.MuiSvgIcon-root.room-detail-edit-icon path {
  fill: none;
}

.MuiTypography-root.room-detail-item-info-typo {
  color: #1A1F07;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.16;
  margin-bottom: 24px;
  margin-top: 32px;
}

.MuiTypography-root.room-detail-item-info-info-typo {
  color: #1A1F07;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 4px;
}

.MuiSvgIcon-root.room-detail-item-info-icon {
  color: #C5DE6E;
  height: 24px;
  width: 24px;
}
.MuiSvgIcon-root.room-detail-item-info-icon path {
  fill: #C5DE6E;
}

.MuiPaper-root.room-detail-wrapper-paper {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 15px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiPaper-root.room-detail-header-paper {
  align-items: flex-start;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.MuiPaper-root.room-detail-title-paper {
  background-color: transparent;
  width: 100%;
}

.MuiTypography-root.room-detail-title-typo {
  color: #414437;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.08;
  margin-bottom: 16px;
}

.MuiSvgIcon-root.room-detail-breadcrumbs-sep-icon {
  height: 16px;
  width: 16px;
}

.MuiTypography-root.room-detail-detail-breadcrumb-typo {
  color: #5D614F;
  font-size: 14px;
  letter-spacing: -0.05em;
  line-height: 18px;
}
.MuiTypography-root.room-detail-detail-breadcrumb-typo:hover {
  cursor: default;
}

.MuiButton-root.room-detail-save-button {
  background-color: #819C24;
  border-radius: 0;
  padding-top: 14px;
  padding-bottom: 14px;
  text-transform: none;
  color: #D9DBD3;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05rem;
  line-height: 1.25;
  width: 244px;
}
.MuiButton-root.room-detail-save-button:hover {
  background-color: #819C24;
}

.MuiTabs-root.room-detail-tabs .room-detail-tabindicator {
  background-color: #414437;
}

.MuiTab-root.room-detail-tab {
  color: #6F745F;
  font-size: 16px;
  line-height: 1.56;
  text-transform: none;
}
.MuiTab-root.room-detail-tab.Mui-selected {
  color: #414437;
  font-weight: 500;
}
.MuiTab-root.room-detail-tab.room-detail-hide-tab {
  display: none;
}

.MuiDivider-root.room-detail-divider {
  background-color: #A1A591;
}

.MuiPaper-root.room-detail-detail-outer-paper {
  background-color: transparent;
  display: flex;
  margin-top: 40px;
  width: 100%;
}

.MuiPaper-root.room-detail-detail-left-paper {
  background-color: transparent;
  flex-basis: 50%;
  flex-grow: 1;
  margin-right: 14px;
}

.MuiPaper-root.room-detail-detail-right-paper {
  background-color: transparent;
  flex-basis: 50%;
  flex-grow: 1;
  margin-bottom: 16px;
}

.MuiPaper-root.room-detail-installation-specifics-card {
  margin-bottom: 0;
  height: 100%;
}

.MuiPaper-root.room-detail-light-and-table-paper {
  background-color: transparent;
  display: flex;
  margin-top: -1px;
}

.MuiBox-root.room-detail-detail-general-box {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.MuiBox-root.room-detail-drag-box {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  padding-bottom: 32px;
}

.MuiBox-root.room-detail-left-drag-box {
  display: flex;
  flex-basis: 40%;
  flex-direction: column;
}

.MuiSvgIcon-root.room-detail-card-icon {
  height: 24px;
  margin-right: 8px;
  width: 24px;
}
.MuiSvgIcon-root.room-detail-card-icon path {
  fill: none;
  stroke: #414437;
}

.MuiTypography-root.room-detail-api-title-typo {
  color: #414437;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.16;
}

.MuiTypography-root.room-detail-api-subtitle-typo {
  color: #414437;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 7px;
}

.MuiBox-root.room-detail-metrcroom-list-box {
  background-color: #E6F1C1;
  margin-top: 16px;
  height: 616px;
  overflow: auto;
}

.MuiBox-root.room-detail-middle-drag-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-basis: 10%;
  justify-content: center;
}

.MuiSvgIcon-root.room-detail-middle-drag-box-icon path {
  fill: none;
}

.MuiTypography-root.room-detail-drag-info-typo {
  color: #4E5D15;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.56;
  margin-top: 23px;
  text-align: center;
}

.MuiBox-root.room-detail-right-drag-box {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  min-height: 154px;
}

.MuiTypography-root.room-detail-tab-title-typo {
  color: #090A08;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.45;
}

.MuiTypography-root.room-detail-tab-sub-typo {
  color: #5D614F;
  font-size: 16px;
  line-height: 1.56;
  margin-bottom: 20px;
  margin-top: 8px;
}

.MuiTextField-root.room-detail-tab-textfield {
  position: relative;
  color: #252720;
  margin-top: 20px;
}
.MuiTextField-root.room-detail-tab-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.room-detail-tab-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.room-detail-tab-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.room-detail-tab-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.room-detail-tab-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}

.MuiBox-root.room-detail-no-metrcroom-box {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.MuiTypography-root.room-detail-no-metrcroom-typo {
  color: #6F745F;
  font-size: 20px;
  letter-spacing: -0.05em;
  text-align: center;
}

.MuiPaper-root.room-detail-detail-specifics-paper {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-bottom: 56px;
  width: 100%;
}

.room-detail-detail-sensors-paper {
  background-color: transparent;
  margin-top: 15px;
}

.MuiTypography-root.room-detail-filter-typo {
  color: #A1A591;
  font-size: 14px;
}

.MuiChip-root.room-detail-chip,
.MuiOutlinedInput-root.room-detail-outlined-input {
  font-size: 12px;
  font-weight: 500;
}

.MuiPaper-root.room-detail-detail-sensors-paper {
  background-color: transparent;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 32px;
}

.MuiButton-root.room-detail-sensorcard-button {
  background-color: #819C24;
  border-radius: 0;
  padding: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.45;
  width: 244px;
}
.MuiButton-root.room-detail-sensorcard-button:hover {
  background-color: #819C24;
}

.MuiBox-root.room-detail-tables-box {
  background-color: transparent;
  width: 100%;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 32px;
}
.MuiBox-root.room-detail-tables-box .MuiButton-root.can-clone-table {
  height: 104px;
}

.MuiPaper-root.room-detail-detail-lights-paper {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.MuiTypography-root.room-detail-lights-title-typo {
  color: #090A08;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.45;
}

.MuiTypography-root.room-detail-lights-sub-typo {
  color: #5D614F;
  font-size: 16px;
  line-height: 1.56;
  margin-top: 8px;
  margin-bottom: 32px;
}

.MuiBreadcrumbs-.room-detail-breadcrumbs {
  line-height: 18px;
}
.MuiBreadcrumbs-.room-detail-breadcrumbs .MuiBreadcrumbs-separator {
  margin-left: 4px;
  margin-right: 4px;
}

.MuiInputAdornment-root.room-detail-textfield-adornment .MuiTypography-root {
  color: #5D614F;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.56;
}

.MuiTypography-root.room-detail-info-typo.dark-theme {
  color: #A1A591;
}

.MuiSvgIcon-root.room-detail-info-icon.dark-theme path {
  fill: #A1A591;
}

.MuiPaper-root.room-detail-wrapper-paper.dark-theme {
  background-color: #1A1B19;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}

.MuiTypography-root.room-detail-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiBreadcrumbs-root.room-detail-breadcrumbs.dark-theme li span {
  color: #A1A591;
}

.MuiTabs-indicator.room-detail-tabindicator.dark-theme {
  background-color: #D9DBD3;
}

.MuiSvgIcon-root.room-detail-breadcrumbs-sep-icon.dark-theme path {
  stroke: #A1A591;
}

.MuiTypography-root.room-detail-detail-breadcrumb-typo.dark-theme {
  color: #A1A591;
}

.MuiButton-root.room-detail-save-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.room-detail-save-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiTab-root.room-detail-tab.dark-theme.Mui-disabled {
  color: #A1A591;
}
.MuiTab-root.room-detail-tab.dark-theme.Mui-selected {
  color: #D9DBD3;
}

.MuiDivider-root.room-detail-divider.dark-theme {
  background-color: #6F745F;
}

.MuiSvgIcon-root.room-detail-card-icon.dark-theme path {
  stroke: #A1A591;
}

.MuiTypography-root.room-detail-api-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.room-detail-api-subtitle-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.room-detail-metrcroom-list-box.dark-theme {
  background-color: #F6F6F4;
}

.MuiSvgIcon-root.room-detail-middle-drag-box-icon.dark-theme path {
  stroke: #A1A591;
}

.MuiTypography-root.room-detail-drag-info-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.room-detail-tab-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.room-detail-tab-sub-typo.dark-theme {
  color: #A1A591;
}

.MuiTextField-root.room-detail-tab-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.room-detail-tab-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.room-detail-tab-textfield.dark-theme .MuiInputBase-root,
.MuiTextField-root.room-detail-tab-textfield.dark-theme .MuiInputAdornment-root,
.MuiTextField-root.room-detail-tab-textfield.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.room-detail-tab-textfield.dark-theme fieldset {
  border-color: #414437;
}

.MuiTypography-root.room-detail-lights-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.room-detail-lights-sub-typo.dark-theme {
  color: #A1A591;
}

.MuiInputAdornment-root.room-detail-textfield-adornment.dark-theme .MuiTypography-root {
  color: #A1A591;
}

.MuiButton-root.room-detail-sensorcard-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.room-detail-sensorcard-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.MuiChip-filled.room-detail-chip.dark-theme {
  background-color: #E6F1C1;
}

.MuiChip-outlined.room-detail-chip.dark-theme {
  color: #A1A591;
}

.MuiOutlinedInput-root.room-detail-outlined-input.dark-theme {
  color: #A1A591;
  background-color: #303130;
}
.MuiOutlinedInput-root.room-detail-outlined-input.dark-theme .MuiInputLabel-root,
.MuiOutlinedInput-root.room-detail-outlined-input.dark-theme .MuiInputBase-root,
.MuiOutlinedInput-root.room-detail-outlined-input.dark-theme .MuiInputAdornment-root,
.MuiOutlinedInput-root.room-detail-outlined-input.dark-theme .Mui-disabled {
  color: #A1A591;
  -webkit-text-fill-color: #A1A591;
}
.MuiOutlinedInput-root.room-detail-outlined-input.dark-theme fieldset {
  border-color: #414437;
}

.MuiBox-root.setup-outline-page-box {
  background-color: #F7FAEA;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}
.MuiBox-root.setup-outline-page-box.setup-outline-page-hide-image {
  min-height: 100vh;
  grid-template-columns: repeat(1, 1fr);
}
.MuiBox-root.setup-outline-page-box.dark-theme {
  background-color: #1A1B19;
}

img.setup-outline-page-bg-image {
  float: left;
  height: 100vh;
  min-height: 100%;
  width: 100%;
}

.MuiBox-root.setup-outline-page-content-box {
  padding-top: 37px;
}

.MuiBox-root.setup-outline-page-logo-box.setup-outline-page-allow-add-farm {
  margin-left: auto;
  margin-right: auto;
  width: 472px;
}

.MuiTypography-root.setup-outline-page-brand-typo {
  color: #090A08;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.29;
  margin-top: 8px;
}
.MuiTypography-root.setup-outline-page-brand-typo:hover {
  cursor: pointer;
}
.MuiTypography-root.setup-outline-page-brand-typo.dark-theme {
  color: #B1D23A;
}

.MuiBox-root.settings-box {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 60px 32px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
}
.MuiBox-root.settings-box.dark-theme {
  background-color: #1A1B19;
}

.MuiTypography-root.settings-title-typo {
  color: #414437;
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.08;
  margin-top: 12px;
}
.MuiTypography-root.settings-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiTypography-root.settings-dev-typo {
  text-align: center;
  color: #A1A591;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 60px;
}

.MuiBox-root.signup-page-box {
  display: flex;
  flex-direction: column;
  margin-top: 108px;
  margin-left: auto;
  margin-right: auto;
  width: 472px;
}

.MuiTypography-root.signup-page-info-typo {
  color: #090A08;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
}
.MuiTypography-root.signup-page-info-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.signup-page-action-typo {
  color: #090A08;
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.13;
  margin-top: 12px;
}
.MuiTypography-root.signup-page-action-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.signup-page-name-box {
  column-gap: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.MuiTypography-root.signup-page-next-action-typo {
  margin-bottom: 4px;
  color: #252720;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.45;
  margin-top: 28px;
}
.MuiTypography-root.signup-page-next-action-typo.dark-theme {
  color: #A1A591;
}

.MuiButton-root.signup-page-resend-code-button {
  border-radius: 0;
  min-width: fit-content;
  padding: 16px 0;
  text-transform: none;
  white-space: nowrap;
  color: #4E5D15;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.MuiButton-root.signup-page-resend-code-button.dark-theme {
  color: #A1A591;
}
.MuiButton-root.signup-page-resend-code-button.dark-theme.Mui-disabled {
  opacity: 0.5;
}

.MuiSvgIcon-root.signup-page-show-password-icon {
  height: 20px;
  width: 20px;
}
.MuiSvgIcon-root.signup-page-show-password-icon:hover {
  cursor: pointer;
}

.MuiSvgIcon-root.signup-page-mail-icon {
  height: 20px;
  width: 20px;
}
.MuiSvgIcon-root.signup-page-mail-icon path {
  fill: none;
}
.MuiSvgIcon-root.signup-page-mail-icon.dark-theme path {
  stroke: #A1A591;
}

.MuiTextField-root.signup-page-textfield {
  color: #252720;
  margin-top: 20px;
  margin-top: 28px;
}
.MuiTextField-root.signup-page-textfield .MuiInputBase-root {
  border-radius: 0;
}
.MuiTextField-root.signup-page-textfield .MuiInputBase-input {
  padding: 16px 15px;
}
.MuiTextField-root.signup-page-textfield textarea.MuiInputBase-input {
  padding: 0;
}
.MuiTextField-root.signup-page-textfield .MuiInputLabel-root {
  color: #252720;
  font-size: 16px;
  line-height: 1.56;
}
.MuiTextField-root.signup-page-textfield fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #252720;
}
.MuiTextField-root.signup-page-textfield.dark-theme {
  background-color: #303130;
}
.MuiTextField-root.signup-page-textfield.dark-theme .MuiInputLabel-root,
.MuiTextField-root.signup-page-textfield.dark-theme .MuiInputBase-root {
  color: #D9DBD3;
}
.MuiTextField-root.signup-page-textfield.dark-theme .MuiInputLabel-root .Mui-disabled,
.MuiTextField-root.signup-page-textfield.dark-theme .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #A1A591;
}
.MuiTextField-root.signup-page-textfield.dark-theme fieldset {
  border-color: #414437;
}
.MuiTextField-root.signup-page-textfield.dark-theme .MuiSelect-icon path {
  fill: #6F745F;
}

.MuiButton-root.signup-page-proceed-button {
  background-color: #4E5D15;
  border-radius: 0;
  margin-bottom: 20px;
  padding: 16px;
  text-transform: none;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
  margin-top: 20px;
  width: 100%;
}
.MuiButton-root.signup-page-proceed-button:hover {
  background-color: #4E5D15;
}
.MuiButton-root.signup-page-proceed-button.dark-theme {
  background-color: #819C24;
  color: #F7FAEA;
}
.MuiButton-root.signup-page-proceed-button.dark-theme:hover {
  background-color: #819C24;
}
.MuiButton-root.signup-page-proceed-button.dark-theme.Mui-disabled {
  opacity: 0.5;
}

.MuiTypography-root.signup-page-enter-action-typo {
  color: #090A08;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
  margin-top: 16px;
}
.MuiTypography-root.signup-page-enter-action-typo.dark-theme {
  color: #A1A591;
}

.MuiTypography-root.signup-page-resend-action-typo {
  color: #414437;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.56;
  margin-top: 20px;
}
.MuiTypography-root.signup-page-resend-action-typo.dark-theme {
  color: #A1A591;
}

.MuiBox-root.signup-page-instructions-box {
  margin-top: 20px;
  padding-left: 8px;
}

.MuiTypography-root.signup-page-instruction-typo {
  margin-left: 16px;
  color: #414437;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}
.MuiTypography-root.signup-page-instruction-typo.dark-theme {
  color: #A1A591;
}

.MuiTimeline-root.entity-detail-timeline {
  padding: 0;
  padding-left: 10px;
}

.MuiTimelineItem-root.entity-detail-timeline-item:before {
  content: none;
}

.MuiTimelineDot-root.entity-detail-timeline-dot {
  position: relative;
}
.MuiTimelineDot-root.entity-detail-timeline-dot:before {
  width: 270%;
  height: 270%;
  content: "";
  left: -85%;
  top: -85%;
  position: absolute;
  border: 1px dashed #A1A591;
  border-radius: 50%;
}
.MuiTimelineDot-root.entity-detail-timeline-dot.entity-detail-level-completed-timeline-dot {
  background-color: #B1D23A;
}
.MuiTimelineDot-root.entity-detail-timeline-dot.entity-detail-level-completed-timeline-dot:before {
  width: 220%;
  height: 220%;
  content: "";
  left: -60%;
  top: -60%;
  position: absolute;
  border: 1px solid #B1D23A;
  border-radius: 50%;
}

.MuiTimelineDot-root.entity-detail-default-expanded-timeline-dot {
  background-color: #D9DBD3;
  position: relative;
}
.MuiTimelineDot-root.entity-detail-default-expanded-timeline-dot:before {
  width: 350%;
  height: 350%;
  content: "";
  left: -125%;
  top: -125%;
  position: absolute;
  border: 1px dashed #D9DBD3;
  border-radius: 50%;
}

.MuiTimelineConnector-root.entity-detail-timeline-connector {
  background: none;
  width: 1px;
  border: 1px dashed #6F745F;
}
.MuiTimelineConnector-root.entity-detail-timeline-connector.entity-detail-level-completed-timeline-connector {
  background-color: #B1D23A;
  border: none;
}

.MuiAccordionSummary-root.entity-detail-timeline-accordion-summary {
  background-color: #F7FAEA;
  padding: 0 16px;
}
.MuiAccordionSummary-root.entity-detail-timeline-accordion-summary.dark-theme {
  background-color: #414437;
  color: #BDC0B2;
}

.MuiAccordionDetails-root.entity-detail-timeline-accordion-detail.dark-theme {
  background-color: #090A08;
  color: #D9DBD3;
}

.MuiBox-root.command-center-root-container {
  background-color: #F7FAEA;
  flex-grow: 1;
  max-height: 100vh;
  overflow: auto;
  padding: 15px;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out, background-color 800ms   !important;
  padding: 0;
}

.MuiBox-root.command-center-header-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.MuiBox-root.command-center-title-box {
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.MuiTypography-root.command-center-title-typo {
  margin-bottom: 16px;
  color: #414437;
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.08;
  overflow-wrap: break-word;
  word-break: break-word;
}

.MuiButton-root.command-center-save-button {
  background-color: #819C24;
  border-radius: 0;
  box-shadow: none;
  padding-bottom: 16px;
  padding-top: 16px;
  text-transform: none;
  color: #F7FAEA;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.05em;
  line-height: 1.25;
  width: 244px;
}
.MuiButton-root.command-center-save-button:hover {
  background-color: #819C24;
}

.MuiCircularProgress-root.command-center-circular-progress {
  color: #6F745F;
}

.MuiSvgIcon-root.command-center-icon {
  height: 24px;
  width: 24px;
  fill: none;
}

.MuiPaper-root.command-center-modal-container {
  background-color: #F7FAEA;
  max-height: 100vh;
  overflow: auto;
  position: relative;
  width: 100%;
  max-width: none;
}

.MuiBox-root.command-center-root-container.dark-theme {
  background-color: #1A1B19;
}

.MuiTypography-root.command-center-title-typo.dark-theme {
  color: #D9DBD3;
}

.MuiButton-root.command-center-save-button.dark-theme {
  background-color: #B1D23A;
  color: #1A1F07;
}
.MuiButton-root.command-center-save-button.dark-theme.Mui-disabled {
  background-color: #252720;
  color: #5D614F;
}

.Mui-selected .command-center-icon path {
  stroke: #B1D23A;
}

.MuiPaper-root.command-center-modal-container.dark-theme {
  background-color: #090A08;
}

