@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.settings-box {
    @include mixins.content-wrapper;
}

.MuiTypography-root.settings-title-typo {
    @include utility.typoBase(var.$grey-700, 2.25rem, 700, -0.01em, 1.08, 12px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiTypography-root.settings-dev-typo {
    text-align: center;

    @include utility.typoBase(var.$grey-400, 1.5rem, 500, null, 1.5, 60px, null);

    // &.#{var.$darkThemeClass} {
    // }
}
