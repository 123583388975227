@use 'var';
@use 'utility';


// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.dash-mini-card-box {
    background-color: var.$main-100;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    height: 200px;
    // margin-right: 16px; // disabeld for frame view
    padding: 14px; // 20px in figma
    width: 180px;

    @include utility.transition(null);

    &:last-of-type {
        margin-right: 0;
    }
}

.MuiSvgIcon-root.dash-mini-card-other-icon {
    background-color: var.$dark-grey-100;
    border-radius: 50%;
    height: 48px;
    width: 48px;
}

.MuiSvgIcon-root.dash-mini-card-icon {
    height: 48px;
    width: 48px;
}

.MuiButton-root.dash-mini-card-see-more-button {
    background-color: transparent;
    min-width: 0;
    padding: 0;
    text-transform: none;

    @include utility.typoBase(var.$main-500, 0.875rem, 400, null, 1.29, auto, fit-content);

    &:hover {
        background-color: transparent;
        color: var.$main-500;
    }

}

.MuiTypography-root.dash-mini-card-title-typo {
    max-width: 152px; // 180px - 2 * 14px
    opacity: 0.8;
    white-space: break-spaces;

    // @include utility.typoBase(var.$main-700, 0.875rem, 400, 0.005em, 1.28, 8px, null);
    @include utility.typoBase(var.$main-700, 0.875rem, 400, 0.005em, 1.28, 8px, null);
}

.MuiTypography-root.dash-mini-card-desc-typo {
    max-width: 152px; // 180px - 2 * 14px
    opacity: 0.8;
    white-space: break-spaces;

    // @include utility.typoBase(var.$main-700, 0.875rem, 400, 0.005em, 1.28, null, null);
    @include utility.typoBase(var.$main-700, 0.875rem, 400, 0.005em, 1.28, null, null);
}

.MuiTypography-root.dash-mini-card-value-typo {
    max-width: 152px; // 180px - 2 * 14px

    @include utility.typoBase(var.$main-800, 1.5rem, 500, 0.005em, 1.29, 16px, null);
}


// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.dash-mini-card-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100));    
}

// .MuiSvgIcon-root.dash-mini-card-other-icon.#{var.$darkThemeClass} {
    
// }
    
.MuiSvgIcon-root.dash-mini-card-icon.#{var.$darkThemeClass} {
    border-radius: 50%;
    fill: transparent;
    @include utility.theme(('bgImage': var.$gradient-200));
    
    path {
        fill: transparent;
        stroke: var.$grey-300;
    }

    rect {
        fill: transparent;
    }
}

.MuiButton-root.dash-mini-card-see-more-button.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));
}

.MuiTypography-root.dash-mini-card-desc-typo.#{var.$darkThemeClass},
.MuiTypography-root.dash-mini-card-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiTypography-root.dash-mini-card-value-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}