@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~ 

.MuiSvgIcon-root.farms-icon {
    height: 20px;
    width: 20px;

    path {
        fill: var.$primary-100;
    }
}

.MuiPaper-root.farms-paper {
    @include mixins.main-content-wrapper;
}

.MuiPaper-root.farms-org-paper {
    align-items: center;
    background-color: transparent;
    color: var.$grey-700;
    display: flex;
    font-weight: 500;
}

.MuiTypography-root.farms-org-title-typo {
    background-color: var.$grey-200;
    padding-left: 12px;
    padding-right: 12px;

    @include utility.typoBase(var.$grey-600, 1.125rem, 500, null, 1.5, null, null);
}

.MuiTypography-root.farms-org-name-typo {
    margin-left: 12px;

    @include utility.typoBase(var.$grey-700, 1.125rem, 500, null, 1.5, null, null);
}

.MuiTypography-root.farms-title-typo {    
    @include utility.typoBase(var.$grey-700, 2.25rem, 700, -0.01em, 1.08, 12px, null);
}

.MuiTypography-root.farms-key-typo {
    @include utility.typoBase(var.$grey-500, 1rem, 400, null, 1.56, 8px, null);
}

.MuiPaper-root.farms-key-refresh-add-paper {
    background-color: transparent;
    display: flex;
    margin-top: 8px;
}

.MuiInputBase-root.farms-key-select {
    border-radius: 0;
    padding-bottom: 12px;
    padding-top: 12px;

    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.5, null, 603px);

    & .MuiInputBase-input {
        padding: 0 12px;
    }

    fieldset.MuiOutlinedInput-notchedOutline {
        border-color: var.$grey-300;
    }
}

.MuiButton-root.farms-refresh-button {
    background-color: var.$primary-200;
    border-radius: 0;
    flex-shrink: 0;
    margin-left: 16px;
    padding-bottom: 12px;
    padding-top: 12px;
    text-transform: none;

    &:hover {
        background-color: var.$primary-200;
    }

    & .MuiSvgIcon-root {
        path {
            fill: var.$primary-800;
        }
    }

    @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, null, 190px);

}

.MuiButton-root.farms-add-farm-button {
    background-color: var.$primary-800;
    border-radius: 0;
    flex-shrink: 0;
    margin-left: 8px;
    padding: 12px;
    text-transform: none;
        
    &:hover {
        background-color: var.$primary-800;
    }

    @include utility.typoBase(var.$primary-100, 1rem, 500, null, 1.56, null, 190px);

}

// .MuiMenuItem-root.farms-key-menuitem {

// }

.MuiTabs-root.farms-tabs {
    margin-top: 24px;

    & .MuiTabs-indicator {
        background-color: var.$grey-700;
    }
}

.MuiTab-root.farms-tab {
    padding: 12px 10px;
    text-transform: none;

    &.Mui-selected {
        color: var.$grey-700;
        font-weight: 500;
    }

    @include utility.typoBase(var.$grey-500, 1rem, 500, null, 1.56, null, null);
}

.MuiDivider-root.farms-divider {
    background-color: var.$grey-400;
}

.MuiTypography-root.farms-no-entity-typo {
    margin-left: auto;
    margin-right: auto;

    @include utility.typoBase(var.$grey-500, 1.5rem, 400, -0.05em, null, 32px, fit-content);
}

.MuiPaper-root.farms-list-paper {
    background-color: transparent;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 40px;
    margin-top: 40px;
}

.MuiPaper-root.farms-table-list-paper {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: var.$maxFarmTableWd;
    // margin-top: 40px;
    // padding-bottom: 32px;
}


// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~ 

// .MuiSvgIcon-root.farms-icon.#{var.$darkThemeClass} {
// Not using this style because the buttons have different color that should be set according to parent
// }

.MuiPaper-root.farms-paper.#{var.$darkThemeClass} {
    @include mixins.main-content-wrapper-dark;
}

// .MuiPaper-root.farms-org-paper.#{var.$darkThemeClass} {

// }

.MuiTypography-root.farms-org-title-typo.#{var.$darkThemeClass} {
    border: 1px solid var.$grey-700;
    border-radius: 100px;
    
    @include utility.theme(('bgColor': var.$dark-300));
    @include utility.typoBase(var.$grey-400, 1rem, 400, null, 1.56, null, null);
}

.MuiTypography-root.farms-org-name-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiTypography-root.farms-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiTypography-root.farms-key-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

// .MuiPaper-root.farms-key-refresh-add-paper.#{var.$darkThemeClass} {

// }

.MuiInputBase-root.farms-key-select.#{var.$darkThemeClass} {
    fieldset.MuiOutlinedInput-notchedOutline {
        border-color: var.$grey-400;
    }

    .MuiSvgIcon-root {
        path {
            fill: var.$grey-400;
        }
    }

    @include utility.theme(('color': var.$grey-400));
}

.MuiMenuItem-root.farms-key-menuitem.#{var.$darkThemeClass} {
    @include mixins.menuitem-dark;
}

.MuiButton-root.farms-refresh-button.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100, 'color': var.$primary-700));

    & .MuiSvgIcon-root {
        path {
            fill: var.$primary-700;
        }
    }

}

.MuiButton-root.farms-add-farm-button.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$primary-600, 'color': var.$primary-900));

    & .MuiSvgIcon-root {
        path {
            fill: var.$primary-900;
        }
    }

}

.MuiTabs-root.farms-tabs.#{var.$darkThemeClass} {
    & .MuiTabs-indicator {
        background-color: var.$grey-200;
    }
}

.MuiTab-root.farms-tab.#{var.$darkThemeClass} {
    &.Mui-selected {
        color: var.$grey-200;
    }
}

.MuiDivider-root.farms-divider.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$grey-500));
}

// .MuiTypography-root.farms-no-entity-typo.#{var.$darkThemeClass} {

// }

// .MuiPaper-root.farms-list-paper.#{var.$darkThemeClass} {

// }

// .MuiPaper-root.farms-table-list-paper.#{var.$darkThemeClass} {

// }
    