@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.landing-page-box {
    background-color: var.$lp-bg-100;
    min-height: 100vh;
    min-width: fit-content;
    width: 100%;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiBox-root.landing-page-nav-box {
    align-items: center;
    backdrop-filter: blur(4px);
    background: var.$lp-navBg-100;
    display: flex;
    height: 70px;
    justify-content: space-between;
    // max-width: var.$maxContentWd;
    position: fixed;
    top: 0;
    width: 100%;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiBox-root.landing-page-content-box {
    align-items: center;
    display: flex;
    flex-direction: column;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTypography-root.landing-page-nav-title-typo {
    margin-left: 36px;
    text-transform: uppercase;

    @include utility.typoBase(var.$main-000, 0.875rem, 700, null, 1.29, null, null);

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiButton-root.landing-page-access-button,
.MuiButton-root.landing-page-nav-access-button {
    align-items: center;
    background-color: var.$lp-btnBg-100;
    border-radius: 0;
    flex-grow: 0;
    justify-content: center;
    min-width: fit-content;
    margin-right: 36px;
    padding: 8px 16px;
    text-transform: none;
    white-space: nowrap;
    
    @include utility.typoBase(var.$lp-btn-100, 0.875rem, 500, -0.01em, 1.29, null, null);
    
    &:hover {
        background-color: var.$lp-btnBg-100;
    }

    // &.#{var.$darkThemeClass} {
  
    // }
}

.MuiButton-root.landing-page-nav-access-button {
    &.landing-page-login-button {
        margin-left: auto;
        margin-right: 16px;
    }
}
.MuiTypography-root.landing-page-headline-typo {
    text-align: center;

    @include utility.typoBase(var.$lp-txt-100, 4rem, 500, -0.055em, 0.92, 127px, null);

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTypography-root.landing-page-desc-typo {
    max-width: var.$maxTextWd-1;
    text-align: center;

    @include utility.typoBase(var.$lp-txt-100, 1rem, 500, null, 1.35, 24px, null);

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiButton-root.landing-page-access-button {
    font-size: 1rem;
    line-height: 1.25;
    min-width: fit-content;
    margin-top: 40px;
    padding: 14px 24px;
}

.MuiBox-root.landing-page-bg-image-box {
    margin-top: 161px;
    
    // 03-04-2023: TODO: Apply bg to the image without hiding it
    // background: #00CFFD;
    // filter: blur(255px);
    // transform: rotate(15deg);

    // &.#{var.$darkThemeClass} {

    // }
}
