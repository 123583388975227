@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~ 


.MuiPaper-root.farm-detail-paper {
    @include mixins.main-content-wrapper;
}

.MuiBox-root.farm-detail-header-box {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
}

// .MuiBox-root.farm-detail-title-box {

// }

.MuiTypography-root.farm-detail-name-typo {
    margin-bottom: 16px;

    @include utility.typoBase(var.$grey-700, 2.25rem, 700, -0.01em, 1.08, null, null);
}

.MuiTypography-root.farm-detail-breadcrumb-link-typo {

    &:hover {
        cursor: default;
    }

    @include utility.typoBase(var.$grey-600, 0.875rem, 400, -0.05em, 1.29, null, null);
}

.MuiButton-root.farm-detail-create-room-button {
    background-color: var.$primary-800;
    border-radius: 0;
    padding: 16px;
    text-transform: none;

    &:hover {
        background-color: var.$primary-800;
    }
    
    @include utility.typoBase(var.$primary-100, 1rem, 500, null, 1.45, null, var.$maxBtnWd);
}

.MuiTypography-root.farm-detail-rooms-typo {
    @include utility.typoBase(var.$grey-700, 1.5625rem, 500, null, 1.16, 40px, null);
}

.MuiPaper-root.farm-detail-rooms-paper {
    background-color: transparent;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-bottom: 24px;
    margin-top: 24px;
    width: 100%;

    &.farm-detail-noentity {
        display: flex;
    }
}

.MuiTypography-root.farm-detail-no-room-typo {
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include utility.typoBase(var.$grey-400, 1.5rem, 400, -0.05em, null, null, fit-content);
}

.MuiSvgIcon-root.farm-detail-breadcrumb-sep-icon {
    height: 16px;
    width: 16px;
}

.MuiBreadcrumbs-root.farm-detail-breadcrumb {
    line-height: 18px;
        
    & .MuiBreadcrumbs-separator {
        margin-left: 4px;
        margin-right: 4px;
    }
}



// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~ 


.MuiPaper-root.farm-detail-paper.#{var.$darkThemeClass} {
    @include mixins.main-content-wrapper-dark;
}

// .MuiBox-root.farm-detail-header-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.farm-detail-name-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiTypography-root.farm-detail-breadcrumb-link-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiButton-root.farm-detail-create-room-button.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$primary-600, 'color': var.$primary-900));
    
    &.Mui-disabled {
        @include utility.theme(('bgColor': var.$grey-800, 'color': var.$grey-600));
    }
}

.MuiTypography-root.farm-detail-rooms-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

// .MuiPaper-root.farm-detail-rooms-paper.#{var.$darkThemeClass} {

// }

// .MuiTypography-root.farm-detail-no-room-typo.#{var.$darkThemeClass} {

// }

.MuiSvgIcon-root.farm-detail-breadcrumb-sep-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$grey-400;
    }
}

.MuiBreadcrumbs-root.farm-detail-breadcrumb.#{var.$darkThemeClass} {
    li span {
        @include utility.theme(('color': var.$grey-400));
    }
}