@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.org-setup-box {
    display: flex;
    flex-direction: column;
    max-width: var.$maxSetupFormWd;
    margin: 96px auto 32px;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTypography-root.org-setup-step-typo {
    text-transform: uppercase;

    @include utility.typoBase(var.$primary-800, 0.75rem, 500, 0.035em, 1.58, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$primary-700));
    }
}

.MuiBox-root.org-setup-progress-box {
    display: flex;
    height: 8px;
    margin-top: 12px;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiBox-root.org-setup-progress-elem-box {
    background-color: var.$grey-200;
    border-radius: 100px;
    flex-grow: 1;
    height: 8px;
    margin-right: 4px;

    &.org-setup-completed-step {
        background-color: var.$primary-700;
    }
    
    &:last-of-type {
        margin-right: 0;
    }
    
    @include utility.transition((background-color), false, false);

    &.#{var.$darkThemeClass} {
        background-color: var.$grey-600;

        &.org-setup-completed-step {
            background-color: var.$primary-700;
        }    
    }
}

.MuiTypography-root.org-setup-detail-typo {
    @include utility.typoBase(var.$grey-900, 1rem, 400, null, 1.56, 52px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-500));
    }
}

.MuiTypography-root.org-setup-action-typo {
    @include utility.typoBase(var.$grey-900, 1.875rem, 700, -0.01em, 1.13, 12px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiBox-root.org-setup-form-box {
    display: flex;
    flex-direction: column;
    margin-top: 28px;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiSvgIcon-root.org-setup-whats-this-icon {
        
    path {
        fill: none;
    }

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTextField-root.org-setup-textfield {
    @include mixins.textfield-outlined;

    // &:last-of-type {
    //     margin-bottom: 0;
    // }

}

.MuiBox-root.org-setup-agree-box {
    align-items: center;
    display: flex;
    margin-top: 16px;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiInputBase-root.org-setup-checkbox {
    border-color: var.$primary-800;
    height: 16px;
    width: 16px;

    input {
        accent-color: var.$primary-800;
        height: 100%;
    }
    
    &.#{var.$darkThemeClass} {
        input {
            accent-color: var.$grey-400;
        }
    }
}

.MuiTypography-root.org-setup-agree-typo {
    margin-left: 16px;

    @include utility.typoBase(var.$grey-900, 0.875rem, 500, null, 1.5, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiBox-root.org-setup-allow-info-box {
    display: flex;
    margin-top: 12px;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiSvgIcon-root.org-setup-allow-info-icon {
    height: 20px;
    width: 20px;

    &.#{var.$darkThemeClass} {
        path {
            fill: var.$grey-500;
        }
    }
}

.MuiTypography-root.org-setup-allow-info-typo {
    margin-left: 4px;

    @include utility.typoBase(var.$grey-600, 0.875rem, 400, null, 1.5, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-500));
    }
}

.MuiMenuItem-root.org-setup-state-menuitem {
    &.#{var.$darkThemeClass} {
        @include mixins.menuitem-dark;
    }
}

.MuiButton-root.org-setup-proceed-button {
    @include mixins.save-button-contained-2;
    
    width: 100%;
}
