// Define styles specifically for Dashboard.jsx

@use 'sass:list';

@use 'var';
@use 'utility';

// Notes:
//  1. Assumming 1rem = 16px; If not true, we will set it expicilty in :root{} or html {}
//  2. Some common values for px to rem:
//          36px = 2.25rem; 12px = 0.75rem; 20px = 1.25rem; 
//  3. Add common styles via mixins; Can generalize by using browser defaults for all properties
//  4. ? - Should we use rem instead of em for letter-spacing values
//  5. ? - Should all px values be converted to rem or just the font-size

// ~~~~~~~~~~~~~~~~ MIXINS ~~~~~~~~~~~~~~~~ 

// @mixin boxBase() {
//     display: $display;
//     flex-basis: $flexBasis;
//     flex-shrink: $flexShrink;
//     height: $height;
//     margin-right: $mr;
//     margin-top: $mt;
//     overflow-y: $overflowY;
// }

// typoBase can be moved to utility
// @mixin typoBase($color: inherit, $fontSize: inherit, $fontWeight: inherit, $letterSpacing: inherit, $lineHeight: inherit, $mt: initial, $width: initial) {
//     color: $color;
//     font-size: $fontSize;
//     font-weight: $fontWeight;
//     letter-spacing: $letterSpacing;
//     line-height: $lineHeight;
//     margin-top: $mt;
//     width: $width;
// }

// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~ 
$miniFrameBorderWd: 4px;
$miniFrameTypoPX: 12px;
$miniFrameTypoPY: 4px;

$megaFrameBorderWd: 4px;
$megaFrameTypoPX: 12px;
$megaFrameTypoPY: 4px;

// NOTE: Since there are only two themes, it is implied that the absence of a theme class is light-theme

// NOTE: .MuiBox-root.light-theme is not specific to Dashboard.jsx; Consider using classes that are defined for this component
// .MuiBox-root.light-theme {
//     @include utility.theme(var.$main-200);

//     // @include utility.transition(list.join(background-color, width));
//     @include utility.transition(background-color);
// }

.MuiBox-root.dashboard-wrapper-box {
    display: flex;
    min-width: fit-content;
    transition: var.$baseTransition;
    width: 100%;

    @include utility.theme(('bgColor': var.$main-200));
    @include utility.transition(background-color); // .dashboard-wrapper-box is a direct child of Fade; So adding utility.transition 
}

.MuiCollapse-root.dashboard-middle-box-collapse {
    flex-grow: 1.5;
    flex-basis: 70%; // prev: 65%

    .MuiCollapse-wrapperInner {
        width: 100%;
    }

    &.dashboard-projections-expanded {
        flex-basis: 0%;
    }
}

.MuiBox-root.dashboard-middle-box {
    // flex-basis: 65%;
    // flex-shrink: 1;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 32px;
}

.MuiBox-root.dashboard-inner-scroll-box {
    margin-right: 18px;
    margin-top: 23px;
}

.MuiBox-root.dashboard-greeting-box {
    align-items: center;
    display: flex;
}

.MuiSvgIcon-root.dashboard-bedtime-icon {
    color: var.$primary-400;
    height: 42px; // prev: 60px
    width: 42px; // prev: 86px
}

.MuiSvgIcon-root.dashboard-sunny-day-icon {
    height: 42px; // prev: 60px
    width: 42px; // prev: 86px
    path {
        fill: var.$main-900;
    }
}

.MuiTypography-root.dashboard-greeting-title-typo {
    margin-left: 12px;
    
    // @include utility.typoBase(var.$main-900, 2.25rem, 500, -0.04em, 1.076, 12px);
    @include utility.typoBase(var.$main-900, 2.25rem, 500, -0.04em, 1.076);
    // @include utility.typoBase(null, 2.25rem, 500, -0.04em, 1.076, 12px); // Will work
}

.MuiTypography-root.dashboard-greeting-sub-title-typo {
    @include utility.typoBase(var.$main-700, 1rem, 400, -0.015em, 1.25, 12px);
}

.MuiDivider-root.dashboard-divider {
    color: var.$main-600-66;
    margin-top: 20px;
}

.MuiTypography-root.dashboard-date-info-typo {
    @include utility.typoBase(var.$main-700, 1rem, 400, -0.015em, 1.25, 12px, fit-content);

    font-style: italic;
    margin-left: auto;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiBox-root.dashboard-mini-cards-frame-box {
    border: $miniFrameBorderWd solid var.$primary-400;
    margin-top: 36px;
    position: relative;
}

.MuiTypography-root.dashboard-mini-cards-frame-header-typo {
    background-color: var.$primary-400;
    top: -32px; // height + 2 * $miniFrameTypoPY
    color: var.$primary-800;
    padding: $miniFrameTypoPY $miniFrameTypoPX;
    position: absolute;
    left: calc(-1 * $miniFrameBorderWd);
}

// .MuiTypography-root.dashboard-mini-cards-frame-footer-typo,
.MuiButton-root.dashboard-mini-cards-frame-footer-button {
    background-color: var.$primary-400;
    // bottom: -32px; // height + 2 * $miniFrameTypoPY
    // border-top-left-radius: calc(2 * $miniFrameBorderWd);
    border-radius: 0;
    bottom: 0;
    color: var.$primary-800;
    padding: $miniFrameTypoPY $miniFrameTypoPX;
    padding-left: calc($miniFrameTypoPX + $miniFrameBorderWd);
    position: absolute;
    // right: calc(-1 * $miniFrameBorderWd);
    right: 0;
    text-transform: none;
    
    &:hover {
        background-color: var.$primary-400;
    }
}

.MuiBox-root.dashboard-mini-cards-box {
    display: flex;
    // margin-top: 28px;
}

.MuiBox-root.dashboard-mega-cards-frame-box {
    border: $megaFrameBorderWd solid var.$primary-400;
    margin-top: 36px;
    position: relative;
}

.MuiTypography-root.dashboard-mega-cards-frame-header-typo {
    background-color: var.$primary-400;
    top: -32px; // height + 2 * $megaFrameTypoPY
    color: var.$primary-800;
    padding: $megaFrameTypoPY $megaFrameTypoPX;
    position: absolute;
    left: calc(-1 * $megaFrameBorderWd);
}

.MuiButton-root.dashboard-mega-cards-frame-footer-button {
    background-color: var.$primary-400;
    // bottom: -32px; // height + 2 * $megaFrameTypoPY
    // border-top-left-radius: calc(2 * $megaFrameBorderWd);
    border-radius: 0;
    bottom: 0;
    color: var.$primary-800;
    padding: $megaFrameTypoPY $megaFrameTypoPX;
    padding-left: calc($megaFrameTypoPX + $megaFrameBorderWd);
    position: absolute;
    // right: calc(-1 * $megaFrameBorderWd);
    right: 0;
    text-transform: none;
    
    &:hover {
        background-color: var.$primary-400;
    }
}

.MuiBox-root.dashboard-mega-cards-box {
    display: grid;
    column-gap: 14px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: minmax(min-content, 302px);
    // margin-top: 16px;
    row-gap: 16px;
}

.MuiBox-root.dashboard-outer-end-box {
    display: flex;
    flex-basis: 30%; // prev : 35%
    flex-grow: 1;
    flex-shrink: 1;
    height: 100vh;
    min-width: 0;
}

.MuiBox-root.dashboard-outer-end-box-expanded {
    flex-basis: 100%;
    // height: fit-content;
    height: 100%;
}

.MuiBox-root.dashboard-end-box-base {
    // flex-grow: 1; // previously
    // flex-shrink: 1; // previously
    // height: 100vh; // previously
    min-width: 0;
    overflow-x: hidden; // previously
    // overflow-x: auto; // after endBox
    overflow-y: auto;
    position: relative;
    // transition: width var.$baseTransitionTime ease; // Had no effect; So commented out

    @include utility.theme(('bgColor': var.$main-400));
    background-image: none;

    transition: background-color var.$baseTransitionTime ease, background-image var.$baseTransitionTime ease; // not using utility.transition because it is not the direct child of Fade
}

.MuiBox-root.dashboard-end-box {
    display: inherit;
    // flex-basis: 35%; // previously
    padding-top: 0;
    // width: inherit; // previously
    width: 100%; // after outerEndBox
}

.MuiBox-root.dashboard-end-box-expanded {
    display: flex;
    // flex-basis: 100%; // previously
    max-width: 100%;
    // padding-top: 69px;
    width: 100%;
}

.MuiBox-root.dashboard-plant-box {
    position: relative;
}

// .MuiBox-root.dashboard-plant-inner-box {
//     position: absolute;
//     max-width: 322px;
//     width: 100%;
// }
.MuiSvgIcon-root.dashboard-smartgrow-icon {
    height: 100%;
    left: calc(50% - 75px);
    max-height: 150px;
    max-width: 150px;
    position: absolute;
    top: 60px;
    width: 100%;
}

.MuiSvgIcon-root.dashboard-plant-pot-icon {
    filter: drop-shadow(14px 32px 64px rgba(80, 88, 9, 0.41));
    height: 289px;
    position: absolute;
    // transform: rotate(9.8deg); // values as per design
    transform: rotate(9.8deg) scale(1.1); // added extra scale property
    left: 24px;
    width: 322px;
}

.MuiBox-root.dashboard-projections-info-box {
    margin-left: auto;
    margin-right: auto;
    max-width: inherit;
}

.MuiBox-root.dashboard-projections-info-box-expanded {
    margin-left: 51px;
    margin-right: auto;
    max-width: 379px;
}

// .MuiBox-root.dashboard-projections-typo-box {} // only one entry using conditional styling; so left inline

.MuiTypography-root.dashboard-projections-typo {
    @include utility.typoBase(var.$main-900, 2.25rem, 500, -0.055em, 1.066, fit-content);
}

.MuiBox-root.dashboard-projections-box {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

.MuiSvgIcon-root.dashboard-see-detail-icon {
    path {
        fill: var.$main-900;
    }
}

.MuiSvgIcon-root.dashboard-go-back-icon {
    path {
        fill: none;
    }
}

.MuiButton-root.dashboard-see-detail-button {
    border: 1px solid var.$main-900;
    border-radius: 0;
    margin-bottom: 55px;
    padding: 10px;
    text-transform: none;

    @include utility.typoBase(var.$main-900, 1.25rem, 500, -0.015em, 1.3, 88px, 100%);
}

.MuiBox-root.dashboard-projections-entry-box {
    margin-top: 31px;
}

.MuiTypography-root.dashboard-projections-entry-value-typo {
    @include utility.typoBase(var.$main-900, 2.25rem, 500, -0.015em, 1.28, fit-content);
}

.MuiTypography-root.dashboard-projections-entry-title-typo {
    @include utility.typoBase(var.$main-900, 1.5rem, 400, -0.015em, 1.29, 2px, fit-content);
}


// ~~~~~~~~~~~~~~~~ InProdcutionInventory ~~~~~~~~~~~~~~~~ 

.MuiBox-root.dashboard-inproductioninv-box {
    padding: 45px 42px;
    width: 100%;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-700));
    }
}

.MuiBox-root.dashboard-inproductioninv-logo-box {
    display: flex;
    flex-direction: column;

    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.dashboard-inproductioninv-logo-button-box {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &.#{var.$darkThemeClass} {

    }
}

.MuiSvgIcon-root.dashboard-inproductioninv-logo-icon {
    height: 75px;
    width: 77px;

    rect {
        fill: var.$primary-100;
    }

    &.#{var.$darkThemeClass} {
        rect {
            fill: var.$grey-800;
        }
    }
}

.MuiSvgIcon-root.dashboard-inproductioninv-back-icon {
    path {
        fill: none;
    }

    &.#{var.$darkThemeClass} {
        path {
            stroke: var.$primary-600;
        }
    }
}

.MuiButton-root.dashboard-inproductioninv-back-button {
    border: 1px solid var.$main-900;
    border-radius: 0;
    padding: 10px;
    text-transform: none;

    @include utility.typoBase(var.$main-900, 1.25rem, 500, -0.015em, 1.3, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$primary-600));

        border-color: var.$primary-700;    
    }
}

.MuiTypography-root.dashboard-inproductioninv-title-typo {
    // Will probably need to change color for light theme
    @include utility.typoBase(var.$light-grey-200, 1.875rem, 500, -0.01em, 1.13, 19px, min-content);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$light-grey-200));
    }
}

.MuiBox-root.dashboard-inproductioninv-cards-box {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: minmax(154px, auto);
    margin-top: 19px;


    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.dashboard-inproductioninv-tables-box {
    margin-top: 15px;

    div:first-of-type {
        margin-top: 0;
    }

    &.#{var.$darkThemeClass} {

    }
}



// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~ 
    
// NOTE: 07022023: .MuiBox-root.dark-theme is not specific to Dashboard.jsx; Consider using classes that are defined for this component
// .MuiBox-root.dark-theme {
//     @include utility.theme(var.$dark-200);
//     @include utility.transition(background-color);
// }

// .#{var.$darkThemeClass}

.MuiBox-root.dashboard-wrapper-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-700));
    @include utility.transition(background-color);
}

.MuiSvgIcon-root.dashboard-sunny-day-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$grey-300;
    }
}

.MuiSvgIcon-root.dashboard-bedtime-icon.#{var.$darkThemeClass} {
    color: var.$grey-200;
}

.MuiTypography-root.dashboard-greeting-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiTypography-root.dashboard-greeting-sub-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiDivider-root.dashboard-divider.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$grey-700));
}

.MuiBox-root.dashboard-mega-cards-frame-box.#{var.$darkThemeClass},
.MuiBox-root.dashboard-mini-cards-frame-box.#{var.$darkThemeClass} {
    border-color: var.$grey-300;
}

.MuiButton-root.dashboard-mega-cards-frame-footer-button.#{var.$darkThemeClass},
.MuiTypography-root.dashboard-mega-cards-frame-header-typo.#{var.$darkThemeClass},
.MuiButton-root.dashboard-mini-cards-frame-footer-button.#{var.$darkThemeClass},
.MuiTypography-root.dashboard-mini-cards-frame-header-typo.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$grey-300, 'color': var.$dark-400));
}

.MuiBox-root.dashboard-end-box-base.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': transparent));
    
    background-image: var.$gradient-100;
    transition: background-color var.$baseTransitionTime ease, background-image var.$baseTransitionTime ease;
}

.MuiBox-root.dashboard-end-box-expanded.#{var.$darkThemeClass} {
    background-image: none;
}

.MuiSvgIcon-root.dashboard-plant-pot-icon.#{var.$darkThemeClass} {
    filter: drop-shadow(14px 32px 64px rgba(42, 38, 38, 0.41));
    // height: 389px;
    // left: 0;
    // top: 24px;
    // width: 417px;
}

.MuiTypography-root.dashboard-projections-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiTypography-root.dashboard-projections-entry-value-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-300));
}

.MuiTypography-root.dashboard-projections-entry-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-500));

}

.MuiSvgIcon-root.dashboard-go-back-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$primary-600;
    }
}

.MuiSvgIcon-root.dashboard-see-detail-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$primary-600;
    }
}

.MuiButton-root.dashboard-see-detail-button.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));

    border-color: var.$primary-700;
}

// .#{var.$darkThemeClass}
