@use 'var';
@use 'utility';
@use 'mixins';

// ################ DetailAndDelBtn ################

.MuiPaper-root.detail-and-del-btn-paper {
  background-color: transparent;
  display: flex;
  margin-left: auto;
  width: fit-content;
}

.MuiSvgIcon-root.detail-and-del-btn-detail-icon {
  height: 20px;
  width: 20px;

  &:hover {
    cursor: pointer;
  }
}

.MuiSvgIcon-root.detail-and-del-btn-delete-icon {
  height: 20px;
  margin-left: 48px;
  margin-right: 2px;
  width: 20px;

  &:hover {
    cursor: pointer;
  }
}

// ################ DARK THEME

// .MuiPaper-root.detail-and-del-btn-paper.#{var.$darkThemeClass} {

// }

.MuiSvgIcon-root.detail-and-del-btn-delete-icon.#{var.$darkThemeClass},
.MuiSvgIcon-root.detail-and-del-btn-detail-icon.#{var.$darkThemeClass} {
  path {
    fill: var.$grey-300;
  }
}

// ################ SubPhaseEntry ################

.MuiBox-root.sub-phase-entry-box {
  align-items: center;
  display: flex;
  gap: 10px;
}

.MuiTypography-root.sub-phase-entry-typo {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: max-content;
  // margin-top: 20px;
}

.MuiSvgIcon-root.sub-phase-entry-text-info-icon {
  path {
    fill: red;
  }
}

.MuiTextField-root.sub-phase-entry-textfield {
  max-width: 35%;
  @include mixins.textfield;

  margin: 0;

  &.sub-phase-entry-disabled {
    .MuiInputLabel-root {
      color: var.$grey-400;
    }

    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: var.$grey-400;
    }
  }
}

.MuiCheckbox-root.sub-phase-entry-checkbox {
  margin-top: 20px;
}

// ################ DARK THEME

// .MuiBox-root.sub-phase-entry-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.sub-phase-entry-typo.#{var.$darkThemeClass} {
  @include utility.theme(
    (
      'color': var.$grey-300,
    )
  );
}

// .MuiSvgIcon-root.sub-phase-entry-text-info-icon.#{var.$darkThemeClass} {
// }

.MuiTextField-root.sub-phase-entry-textfield.#{var.$darkThemeClass} {
  @include mixins.textfield-dark;

  margin: 0;

  &.sub-phase-entry-disabled {
    .MuiInputLabel-root {
      color: var.$grey-600;
    }

    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: var.$grey-600;
    }
  }
}

.MuiCheckbox-root.sub-phase-entry-checkbox.#{var.$darkThemeClass} {
  path {
    fill: var.$grey-400;
  }
}

// ################ Breadcrumbs ################

.MuiBox-root.misc-breadcrumb-box {
  align-items: center;
  display: flex;
  user-select: none;

  // &.#{var.$darkThemeClass} {

  // }
}

.MuiBox-root.misc-breadcrumb-entry-box {
  text-decoration: none;

  @include utility.typoBase(var.$grey-600, 0.875rem, -0.05em, 1.29, null, null);

  &:hover {
    color: var.$grey-600;
    cursor: pointer;
    text-decoration: none;
  }

  &:last-of-type:hover {
    cursor: default;
  }

  &.#{var.$darkThemeClass} {
    @include utility.theme(
      (
        'color': var.$grey-400,
      )
    );

    &:hover {
      @include utility.theme(
        (
          'color': var.$grey-400,
        )
      );
    }
  }
}

.MuiSvgIcon-root.misc-breadcrumb-sep-icon {
  height: 16px;
  margin: 0 4px;
  width: 16px;

  &.#{var.$darkThemeClass} {
    path {
      stroke: var.$grey-400;
    }
  }
}
