.command-center {
    padding: 0.29rem 0.1rem 0.6rem 0.1rem ; // 30px (1.875 * 16px = 30px)
    background-color: #000;
    color: #ffffff;
    // margin: 0 auto;
    display: flex;
    flex-direction: column;
    h1 {
      color: #ffffff;
      font-size: 1.56rem; // 25px (1.5625 * 16px = 25px)
      padding-left:20px ;
    }
  }
  
  
  
  
  .command-table {
    border-collapse: collapse;
    opacity: 1;
  
    th,
    td {
      color: #6F745F;
      border: 1px solid #4E5D15;
      white-space: nowrap;
      padding: 0.13rem 0.28rem; // 4px 7.5px (0.25 * 16px = 4px, 0.46875 * 16px = 7.5px)
      font-size: 0.676rem ; // 13.5px (0.84375 * 16px = 13.5px)
      text-overflow: ellipsis;
    }
  
    tbody {
      background-color: #000;
    }
  }
  
  .header-cell {
    color: #6F745F;
    border: 1px solid #4E5D15;
    background-color: #000;
    white-space: nowrap;
  }
  
  .body-cell {
    border: 1px solid #4E5D15;
    color: #6F745F;
    white-space: nowrap;
  }