@use 'mixins';
@use 'utility';
@use 'var';


// ################ PrevNextFab ################ 

.MuiFab-root.prev-next-fab-fab {
    // background-color: var.$grey-400;
    background-color: var.$primary-700;
    bottom: 32px;
    padding: 0;
    position: absolute;
    right: 32px;

    &.MuiFab-circular {
        height: 34px;
        min-height: 34px;
        width: 34px;
    }

    path {
        fill: var.$primary-900;
    }

    &:hover {
        // background-color: var.$grey-400;
        background-color: var.$primary-700;
    }

    // &.#{var.$darkThemeClass} {}
}

.MuiBox-root.prev-next-fab-prev-step-box,
.MuiBox-root.prev-next-fab-next-step-box {
    height: 100%;
    width: 50%;

    &.prev-next-fab-single-step {
        width: 100%;
    }

}

.MuiSvgIcon-root.prev-next-fab-prev-step,
.MuiSvgIcon-root.prev-next-fab-next-step {
    
    // background-color: var.$grey-900;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    height: 100%;
    opacity: 1;
    transform: rotate(90deg);
    width: 100%;
    
    &:hover {
        // background-color: var.$grey-300;
        background-color: var.$primary-500;
    }

    &.prev-next-fab-single-step {
        border-radius: 50%;
        // width: 100%;
    }

    &.prev-next-fab-disabled-step {
        // background-color: var.$grey-800;
        background-color: var.$primary-700;
        opacity: 0.4;

        &:hover {
            // background-color: var.$grey-800;
            background-color: var.$primary-700;
        }
    }

    // &.#{var.$darkThemeClass} {}
}

.MuiSvgIcon-root.prev-next-fab-next-step {
    
//     border-bottom-left-radius: 17px;
//     border-bottom-right-radius: 17px;
    transform: rotate(270deg);

//     &:hover {
//         background-color: var.$grey-300;
//     }

//     // &.#{var.$darkThemeClass} {}
}
