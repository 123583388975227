@use 'var';
@use 'utility';
@use 'mixins';


// ################ BaseChart ################


.MuiBox-root.base-chart-svg {

    &.#{var.$darkThemeClass} {}
}

.MuiBox-root.base-chart-inner-wrapper-g {

    &.#{var.$darkThemeClass} {}
}

.MuiBox-root.base-chart-plot-area-g {

    &.#{var.$darkThemeClass} {}
}

.MuiBox-root.base-chart-x-axis-g {

    &.#{var.$darkThemeClass} {}
}

.MuiBox-root.base-chart-y-axis-g {

    &.#{var.$darkThemeClass} {}
}


// ################ ProgressRing ################


.MuiBox-root.progress-ring-plot-area {
    path {
        fill : var.$primary-400;
    }

    path.progress-ring-completion-ring {
        fill: var.$primary-700;
    }
    
    text.progress-ring-title {
        fill: var.$primary-800;
        text-anchor: middle;
        text-transform: uppercase;
        transform: translate(0, -48px);

        @include utility.typoBase(null, 0.75rem, 500, 0.035em, 1.58, null, null);
    }

    text.progress-ring-value {
        fill: var.$primary-800;
        text-anchor: middle;
        transform: translate(0, -8px);

        @include utility.typoBase(null, 2.6875rem, 700, -0.01em, 1.12, null, null);

    }

    &.#{var.$darkThemeClass} {
        path {
            fill: var.$grey-700;
        }

        path.progress-ring-completion-ring {
            fill: var.$primary-600;
        }
    
        text.progress-ring-title,
        text.progress-ring-value {
            fill: var.$primary-600;
        }

    }

}


// ################ TimelineChart ################


.MuiBox-root.timeline-chart-box {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: var.$maxTableWd;
    width: 100%;
}

.MuiBox-root.timeline-chart-title-box {
    color: var.$grey-700;
    background-color: var.$primary-400;
    padding: 8px;
    text-align: center;
    width: 100%;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$grey-700, 'color': var.$grey-200));
    }
}

.MuiBox-root.timeline-chart-grid-entries-box {
    display: grid;
    gap: 2px;
    grid-template-columns: minmax(var.$timelineRowHeaderWd, auto) 1fr;
    margin-top: 2px;
    overflow: auto;
    width: 100%;

    // &.#{var.$darkThemeClass} {}
}

.MuiBox-root.timeline-chart-row-header-box {
    color: var.$grey-500;
    background-color: var.$primary-200;
    padding: 8px;
    position: sticky;
    left: 0;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-100, 'color': var.$grey-200));
    }
}

.MuiBox-root.timeline-chart-row-content-box {
    display: grid;
    gap: 2px;
    
    // &.#{var.$darkThemeClass} {}
}

.MuiBox-root.timeline-chart-value-box {
    color: var.$grey-500;
    background-color: var.$primary-200;
    text-align: center;
    padding: 8px;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-100, 'color': var.$grey-200));
    }
}
