@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 

.MuiButton-root.crops-save-button {
    @include mixins.save-button-contained-3;

    margin-top: 0;
}

.MuiBox-root.crops-cropplans-box,
.MuiBox-root.crops-cropruns-box,
.MuiBox-root.crops-crophistory-box {
    @include mixins.three-cards-grid;

    &.no-entity-available {
        display: flex;
    }
}

.MuiBox-root.crops-entity-card-box {
    height: fit-content;
    min-height: 350px;

    p:nth-of-type(3) { // 1st: Title, 2nd: Description, 3rd: First Extra Detail
        margin-top: 12px;
    }
    
}

.MuiTypography-root.crops-entity-card-desc-typo {
    // max-height: 1.5em; // Derived from line-height
    height: 3em; // Derived from line-height
    max-height: 3em; // Derived from line-height

    overflow: auto;
    text-overflow: ellipsis;
    white-space: break-spaces;
}

.MuiTypography-root.crops-crops-card-extra-typo {
    @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.5, 4px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$primary-600));
    }
}
