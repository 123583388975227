@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 


// .MuiBox-root.external-detail-box {

//     &.#{var.$darkThemeClass} {

//     }
// }

.MuiTypography-root.external-detail-tab-title-typo {
    @include utility.typoBase(var.$grey-900, 1.5rem, 500, null, 1.25, 60px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

.MuiTypography-root.external-detail-tab-content-typo {
    @include utility.typoBase(var.$grey-700, 1rem, 400, null, 1.25, 32px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiTypography-root.external-detail-tab-content-link-typo {
    display: block;

    @include utility.typoBase(var.$grey-700, 1rem, 400, null, 1.25, 32px, null);
    
    &:hover {
        cursor: pointer;
    }

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}
