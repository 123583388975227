@use 'var';
@use 'utility';
@use 'mixins';

$detailBoxM: 32px;
$gridColGap: 3px;
$gridExpandedDayColGap: 1px;
$gridEntryMinWd: 152px;
$gridRowGap: 2px;


// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.crop-box {
    background-color: var.$primary-100;
    // background-color: #FFFFFF;
    flex-grow: 1;
    max-height: 100vh;
    overflow: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;

    // transition: opacity var(--fade-timing-ms)ms cubic-bezier(0.4, 0, 0.2, 1), padding 225ms ease-in-out !important;
    @include utility.transition(background-color);
}

.MuiBox-root.crop-header-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.MuiBox-root.crop-stack-container {
  width:650px;
}


.MuiBox-root.crop-header-title-box {
    background-color: transparent;
    display: flex;
    flex-direction: column;
}

.MuiTypography-root.crop-header-title-typo {
    margin-bottom: 16px;

    @include utility.typoBase(var.$grey-700, 2rem, 700, -0.01em, 1.08, null, null);
}

.MuiSvgIcon-root.crop-header-breadcrumbs-sep-icon {
    height: 16px;
    width: 16px;
}

.MuiBreadcrumbs-root.crop-header-breadcrumbs {
    line-height: 18px;

    & .MuiBreadcrumbs-separator {
        margin-left: 4px;
        margin-right: 4px;
    }
}

.MuiTypography-root.crop-header-breadcrumbs-link-typo {
    @include utility.typoBase(var.$grey-600, 0.875rem, 400, -0.05em, 1.125, null, null);

    &:hover {
        cursor: default;
    }
}

.MuiButton-root.crop-save-button {
    background-color: var.$primary-700;
    border-radius: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    text-transform: none;

    @include utility.typoBase(var.$primary-100, 1rem, 500, -0.05em, 1.25, null, 244px);

    &:hover {
        background-color: var.$primary-700;
    }
}

.MuiDivider-root.crop-divider {
    background-color: var.$grey-400;
}

.MuiTabs-root.crop-tabs {
    margin-top: 32px;
}

.MuiTab-root.crop-tab {
    text-transform: none;

    @include utility.typoBase(var.$grey-500, 1rem, 400, null, 1.56, null, null);

    &.Mui-selected {
        color: var.$grey-700;
        font-weight: 500;
    }

}

.MuiTextField-root.crop-textfield {
    // To standardise with other detail pages
    // color: var.$grey-800;
    // margin-top: 20px;    
    
    // & .MuiInputBase-root {
    //     border-radius: 0;
    // }

    // & .MuiInputBase-input {
    //     padding: 16px 15px;
    // }

    // & textarea.MuiInputBase-input {
    //     padding: 0;
    // }
    
    // & .MuiInputLabel-root {
    //     color: var.$grey-800;
    // }
    
    // & fieldset.MuiOutlinedInput-notchedOutline {
    //     border-color: var.$grey-800;   
    // }

    @include mixins.textfield-outlined;

    width: var.$maxTextFieldWd;

}

.MuiTextField-root.crop-overview-textfield {
    @include mixins.textfield-outlined(0);
}

.MuiGrid-container.crop-overview-box {
    margin-top: 16px;
    background-color: transparent; // a/c to figma
    @include utility.transition(background-color);
}

.MuiBox-root.crop-overview-title-phase-box {
    background-color: var.$primary-400;
    // background-color: var.$primary-200; // prev to figma
    // border: 1px solid var.$grey-400;
    margin: $detailBoxM auto 0;

    padding-top: 8px;
    padding-bottom: 8px;

    text-align: center;

    // @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, null, null); // prev to figma
    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.56, null, null);
}

.MuiBox-root.crop-overview-detail-box {
    background-color: var.$light-grey-200; // a/c to figma
    column-gap: $gridColGap;
    display: grid;
    grid-template-columns: minmax(250px, auto) 1fr;
    grid-template-rows: repeat(auto-fill, minmax(41px, auto));
    margin-top: 1px;
    overflow: auto;
    row-gap: $gridRowGap;
}

.MuiBox-root.crop-overview-grid-entry-title-box {
    align-items: center;
    background-color: var.$primary-200; // prev to figma
    // background-color: white; // a/c to figma
    display: flex;
    padding-left: 16px;
    position: sticky;
    left: 0;
    
    &.metricplan-title-entry {
        // background-color: var.$light-grey-200;
        background-color: var.$primary-200;
        
        &.timeline-chart-highlight-metricplan {
            background-color: var.$primary-700;
        }
    }

    // @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, null, null); // prev to figma
    @include utility.typoBase(var.$grey-500, 1rem, 500, null, 1.56, null, null);
}

.MuiBox-root.crop-overview-grid-entry-outer-box {
    background-color: transparent;
    column-gap: $gridColGap;
    display: grid;
    grid-template-rows: minmax(41px, auto);

    @include utility.transition(grid, false, false);
}

.MuiBox-root.crop-overview-grid-entry-box {
    align-items: center;
    background-color: var.$primary-200; // prev to figma
    // background-color: white; // a/c to figma
    display: flex;
    justify-content: center;
    min-width: $gridEntryMinWd;

    // @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, null, null); // prev to figma
    @include utility.typoBase(var.$grey-500, 1rem, 500, null, 1.56, null, null); // a/c to figma

    &.timeline-chart-subphase-entry {
        // background-color: var.$primary-100; // a/c to figma
        // color: var.$grey-400;
        @include utility.transition(background-color, false, false);
    }

    &.timeline-chart-day-entry {
        transition: none;
    }

    &.timeline-chart-expanded-grid-entry-subphase-box {
        background-color: var.$primary-400; // a/c to figma
        color: var.$primary-800;
    }

    &.timeline-chart-week-header-selected {
        background-color: var.$primary-200; // a/c to figma
        color: var.$primary-800;
        justify-content: flex-start;

        @include utility.transition(background-color, false, false);
    }

    &.timeline-chart-expanded-grid-entry-day-box {
        background-color: white; // a/c to figma
        column-gap: $gridExpandedDayColGap;
        display: grid;
        grid-template-rows: minmax(41px, auto);
        
        @include utility.transition(grid, false, false);   
    }
}

// .MuiTypography-root.timeline-chart-subphase-entry-typo {

// }

.MuiBox-root.timeline-chart-expanded-day-box {
    align-items: center;
    background-color: var.$primary-200; // a/c to figma
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;

    // @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, null, null); // prev to figma
    @include utility.typoBase(var.$grey-500, 1rem, 500, null, 1.56, null, null); // a/c to figma

    &.timeline-chart-today-day-entry {
        border: 1px solid var.$primary-800;
        color: var.$primary-800;
    }

}

.MuiBox-root.timeline-chart-expanded-alert-routine-content-box,
.MuiBox-root.timeline-chart-expanded-week-content-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
}

.MuiBox-root.timeline-chart-expanded-alert-routine-content-typo-box {
    align-items: center;
    display: flex;

    // @include utility.typoBase(var.$primary-800, 0.875rem, 500, null, 1.5, null, null); // prev to figma
    @include utility.typoBase(var.$primary-700, 0.875rem, 500, null, 1.5, null, null); // self assumed
}

.MuiSvgIcon-root.timeline-chart-expanded-alert-routine-content-icon {
    height: 20px;
    width: 20px;

    path {
        // fill: var.$primary-800; // prev to figma
        fill: var.$primary-700; // self assumed
    }
}

.MuiButton-root.timeline-chart-expanded-alert-routine-see-detail-button {
    text-transform: none;
    padding: 0;

    &:hover {
        background-color: transparent;
    }

    // @include utility.typoBase(var.$primary-800, 0.875rem, 500, null, 1.5, null, null); // prev to figma
    @include utility.typoBase(var.$grey-800, 0.875rem, 500, null, 1.5, null, null); // self assumed
}

.MuiBox-root.timeline-chart-expanded-metricplan-content-box {
    background-color: var.$light-grey-200; // a/c to figma
    column-gap: $gridExpandedDayColGap;
    display: grid;
    grid-template-rows: minmax(41px, auto);
    
    @include utility.transition(grid, false, false);
}

.MuiBox-root.timeline-chart-expanded-metricplan-box {
    align-items: center;
    background-color: var.$primary-200; // a/c to figma
    display: flex;
    justify-content: center;
}

.MuiBox-root.overview-footer-box {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    width: 100%;
}

.MuiBox-root.overview-footer-specs-content-box {
    display: flex;
    flex-basis: calc(50% - 8px);
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 8px;
}

.MuiTypography-root.overview-footer-specs-typo {
    // @include utility.typoBase(var.$primary-800, 1.25rem, 500, null, 1.45, null, null); // prev to figma
    @include utility.typoBase(var.$grey-400, 1.25rem, 500, null, 1.45, null, null); // self assumed
}

.MuiBox-root.overview-footer-specs-list-box {
    background-color: var.$primary-200;
    // background-color: var.$light-grey-200; // self assumed
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin-top: 16px;
    padding: 12px;
}

.MuiBox-root.overview-footer-spec-card-box {
    flex-basis: calc(50% - 8px); // style only for two elements
    flex-grow: 1;
    flex-shrink: 1;
    // height: 100%;
    // width: 100%;

    &:first-of-type {
        margin-right: 4px;
    }

    &:last-of-type {
        margin-left: 4px;
    }

}

.MuiBox-root.overview-footer-links-content-box {
    display: flex;
    flex-basis: calc(50% - 8px);
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 8px;
}

.MuiTypography-root.overview-footer-links-typo {
    // @include utility.typoBase(var.$primary-800, 1.25rem, 500, null, 1.45, null, null); // prev to figma
    @include utility.typoBase(var.$grey-400, 1.25rem, 500, null, 1.45, null, null);
}

.MuiBox-root.overview-footer-links-list-box {
    display: flex;
    background-color: var.$primary-200;
    // background-color: var.$light-grey-200; // self assumed
    flex-direction: column;
    height: 100%;
    margin-top: 16px;
    padding: 12px;
}

.MuiBox-root.overview-footer-link-card-box {
    margin-top: 15px;

    &:first-of-type {
        margin-top: 0;
    }
}

.MuiBox-root.crop-specifics-box {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    width: 100%;
}

.MuiTypography-root.crop-specifics-title-typo {
    @include utility.typoBase(var.$grey-900, 1.5rem, 500, null, 1.45, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiTypography-root.overview-key-typo{
    @include utility.typoBase(var.$grey-900, 16px, 500, null, 1.45, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$light-grey-200));
    }
}

.MuiAccordion-root.overview-accordion {
    background-color: transparent;

    &:before{
        background-color: transparent;
    }

    & .MuiAccordionSummary-root{
        padding: 0
    }
}

.MuiDivider-root.overview-divider{
 
    &:before, &:after{
        border-color: var.$grey-500;
    }

    &:before {
        width: 0%;
    }

    &:after {
        width: 100%;
    }

    & .MuiDivider-wrapper {
        padding: 0;
    }

    &.#{var.$darkThemeClass} {
        & .MuiDivider-wrapper {
            color: var.$primary-600;
        }
    }

    height: 0;
    width: 100%;
    align-items: center;
}

.MuiAccordionDetails-root.overview-accordion-details{
    padding: 0;
}

.MuiSvgIcon-root.overview-down-arrow-icon.#{var.$darkThemeClass} {
    fill: var.$primary-600;
}

// ~~~~~~~~~~~~~~~~ CROP PHASES TAB ~~~~~~~~~~~~~~~~
.MuiBox-root.crop-phases-box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.MuiPaper-root.crop-phases-strain-box {
    padding: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.MuiTab-root.crop-phases-strain-tab{
    text-transform: none;

    @include utility.typoBase(var.$grey-500, 1rem, 400, null, 1.56, null, null);

    &.Mui-selected {
        @include utility.theme(('color': var.$dark-100));
        background-color: var.$primary-200;
        font-weight: 500;
    }
}

.MuiTab-root.crop-phases-phase-tab{
    text-transform: none;

    @include utility.typoBase(var.$grey-500, 1rem, 400, null, 1.56, null, null);

    &.Mui-selected {
        @include utility.theme(('color': var.$dark-100));
        background-color: var.$primary-600;
        font-weight: 500;
    }
}

.MuiTabs-root.crop-phases-strain-tab {
    & .MuiTabs-indicator {
        display: none;
    }
}

.MuiTabs-root.crop-phases-phase-tab {
    & .MuiTabs-indicator {
        display: none;
    }
}

// ~~~~~~~~~~~~~~~~ CROP PHASES TAB DARK THEME ~~~~~~~~~~~~~~~~
.MuiTab-root.crop-phases-strain-tab.#{var.$darkThemeClass} {

    @include utility.typoBase(var.$grey-400, 1rem, 400, null, 1.56, null, null);

    &.Mui-selected {
        @include utility.theme(('color': var.$main-000));
        background-color: var.$dark-100;
        font-weight: 500;
    }
}

.MuiTab-root.crop-phases-phase-tab.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$main-000));
    &.Mui-selected {
        @include utility.theme(('color': var.$dark-100));
    }
}

.MuiTabs-root.crop-phases-phase-tab.#{var.$darkThemeClass} {
    & .MuiTabs-scrollButtons {
        color: var.$grey-400;
    }
}

.MuiPaper-root.crop-phases-strain-box.#{var.$darkThemeClass} {
    background-color:  var.$dark-100;
}
// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~ 

.MuiBox-root.crop-box.#{var.$darkThemeClass} {
    // @include utility.theme(('bgColor': var.$dark-800)); // 10-04-2023 Used dark-700 in other detail pages; Can reset if doen on purpose
    @include mixins.main-content-wrapper-dark; // To standardise with other pages
}

.MuiTypography-root.crop-header-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-300));
}

.MuiSvgIcon-root.crop-header-breadcrumbs-sep-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$grey-500;
    }
}

.MuiBreadcrumbs-root.crop-header-breadcrumbs.#{var.$darkThemeClass} {
    li span {
        color: var.$grey-500;
    }
}

.MuiTypography-root.crop-header-breadcrumbs-link-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-500));
}

.MuiDivider-root.crop-divider.#{var.$darkThemeClass} {
    background-color: var.$grey-500;
}

.MuiTab-root.crop-tab.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));

    &.Mui-selected {
        color: var.$grey-200;
    }
}

.MuiButton-root.crop-save-button.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$primary-600, 'color': var.$primary-900));
    
    &.Mui-disabled {
        @include utility.theme(('bgColor': var.$grey-800, 'color': var.$grey-600));
    }
}

.MuiGrid-container.crop-overview-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-800)); // This style will be set on the parent bg 
    @include utility.transition(background-color);
}

.MuiBox-root.crop-overview-detail-box.#{var.$darkThemeClass} {
    background-color: var.$dark-800; // a/c to figma
}

.MuiBox-root.crop-overview-title-phase-box.#{var.$darkThemeClass} {
    border-color: var.$dark-800;
    @include utility.theme(('bgColor': var.$dark-200, 'color': var.$grey-400));
}

.MuiBox-root.crop-overview-grid-entry-title-box.#{var.$darkThemeClass} {
    border-color: var.$dark-200;
    @include utility.theme(('bgColor': var.$dark-200, 'color': var.$grey-400));
}

.MuiBox-root.crop-overview-grid-entry-outer-box.#{var.$darkThemeClass} {
    background-color: transparent;
}

.MuiBox-root.crop-overview-grid-entry-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-200, 'color': var.$grey-400));
    @include utility.transition(background-color, false, false);
    
    &.timeline-chart-week-header-selected {        
        @include utility.theme(('bgColor': var.$primary-600, 'color': var.$primary-800));
        // @include utility.transition(background-color, false, false);
    }
    
    &.timeline-chart-expanded-grid-entry-subphase-box {
        @include utility.theme(('color': var.$primary-600));
        // @include utility.transition(background-color, false, false);
    }

    &.timeline-chart-day-entry {
        transition: none;
    }

    &.timeline-chart-expanded-grid-entry-day-box {
        @include utility.theme(('bgColor': var.$dark-800));
        transition: none;
    }
}

// .MuiBox-root.timeline-chart-expanded-week-content-box.#{var.$darkThemeClass} {
//     @include utility.transition(background-color, false, false);
// }

.MuiBox-root.timeline-chart-expanded-week-alert-routine-box.#{var.$darkThemeClass} { 
    background-color: transparent;
    color: var.$grey-500;
}

.MuiSvgIcon-root.timeline-chart-expanded-week-alert-icon.#{var.$darkThemeClass} {
    circle {
        fill: var.$light-red-100;
    }
}

.MuiSvgIcon-root.timeline-chart-expanded-week-routine-icon.#{var.$darkThemeClass} {
    circle {
        fill: var.$light-yellow-100;
    }
}

.MuiSvgIcon-root.timeline-chart-expanded-alert-routine-content-icon.#{var.$darkThemeClass} { // Can also infer theme from parent for simpler code
    path {
        fill: var.$light-grey-100;
    }
}

.MuiBox-root.timeline-chart-expanded-alert-routine-content-typo-box.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$light-grey-100));
}

.MuiButton-root.timeline-chart-expanded-alert-routine-see-detail-button.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiBox-root.timeline-chart-expanded-day-box.#{var.$darkThemeClass} {
    background-color: var.$dark-200;

    @include utility.theme(('color': var.$grey-500));

    &.timeline-chart-today-day-entry {
        border: 1px solid var.$grey-500;
    }
}

.MuiBox-root.timeline-chart-expanded-metricplan-content-box.#{var.$darkThemeClass} {
    background-color: var.$dark-800;
}

.MuiBox-root.timeline-chart-expanded-metricplan-box.#{var.$darkThemeClass} {
    background-color: var.$dark-200;
}

// .MuiBox-root.overview-footer-box.#{var.$darkThemeClass} {

// }

// .MuiBox-root.overview-footer-specs-content-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.overview-footer-specs-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiBox-root.overview-footer-specs-list-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100));
}

// .MuiBox-root.overview-footer-links-content-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.overview-footer-links-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiBox-root.overview-footer-links-list-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100));
}

// 10-04-2023: Can uncomment if done on purpose
// .MuiTextField-root.crop-textfield.#{var.$darkThemeClass} {    
//     background-color: var.$grey-800;
//     // color: var.$grey-400;

//     & .MuiInputBase-input {
//         color: var.$grey-400;
//     }
    
//     & .MuiInputLabel-root {
//         color: var.$grey-400;
//     }
    
//     & fieldset.MuiOutlinedInput-notchedOutline {
//         border-color: var.$grey-500;   
//     }

//     & .MuiSvgIcon-root {
//         path {
//             fill: var.$grey-400;
//         }
//     }
// }

.MuiMenuItem-root.crop-specifics-growplan-menuitem.#{var.$darkThemeClass},
.MuiMenuItem-root.crop-specifics-status-menuitem.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$grey-500, 'color': var.$grey-200));
    
    &:hover {
        background-color: var.$grey-500;
    }
    
    &.Mui-selected {
        @include utility.theme(('bgColor': var.$grey-500, 'color': var.$grey-200));        
    }

    &.crop-specifics-status-menuitem-focus,
    &.crop-specifics-growplan-menuitem-focus {
        background-color: var.$grey-600;
    }
}
