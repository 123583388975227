@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 

.MuiBox-root.quality-plan-accordion-drag-card-box {
    align-items: flex-start;
    padding-left: 44px;
}

.MuiBox-root.quality-plan-box {
    // @include mixins.main-content-wrapper;
    @include mixins.content-wrapper;
}

.MuiBox-root.quality-plan-header-box {
    align-items: flex-start;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
}

.MuiBox-root.quality-plan-header-title-box {
    display: flex;
    flex-direction: column;
}

.MuiTypography-root.quality-plan-action-typo {
    margin-bottom: 16px;

    @include utility.typoBase(var.$grey-900, 1.875rem, 700, -0.01em, 1.13, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiSvgIcon-root.quality-plan-breadcrumbs-sep-icon {
    // height: 16px;
    // width: 16px;
    @include mixins.breadcrumb-sep-icon; // 30-03-2023: Has both light and dark theme styles
}

.MuiBreadcrumbs-root.quality-plan-breadcrumbs {
    // line-height: 18px;
        
    // .MuiBreadcrumbs-separator {
    //     margin-left: 4px;
    //     margin-right: 4px;
    // }

    @include mixins.breadcrumbs; // 30-03-2023: Has both light and dark theme styles
}

.MuiTypography-root.quality-plan-breadcrumb-link-typo {
    // @include utility.typoBase(var.$grey-600, 0.875rem, 400, -0.05em, 1.28, null, null);
        
    // &:hover {
    //     cursor: default;
    // }
    @include mixins.breadcrumb-link-typo;
}

.MuiButton-root.quality-plan-save-button {
    // background-color: var.$primary-700;
    // border-radius: 0;
    // padding: 16px;
    // text-transform: none;
        
    // &:hover {
    //     background-color: var.$primary-700;
    // }

    // &.Mui-disabled {
    //     background-color: var.$grey-400;
    //     color: var.$grey-200;
    // }

    // @include utility.typoBase(var.$primary-100, 1rem, 400, null, 1.25, null, var.$maxBtnWd);

    @include mixins.save-button-contained-1; // 30-03-2023: Has both light and dark theme styles
}

.MuiTabs-root.quality-plan-tabs {
    // margin-top: 32px;

    // .MuiTabs-indicator {
    //     background-color: var.$grey-700;
    // }

    @include mixins.tabs; // 30-03-2023: Has both light and dark theme styles
}

.MuiTab-root.quality-plan-tab {

    // text-transform: none;
    
    // &.Mui-selected {
    //     color: var.$grey-700;
    //     font-weight: 500;
    // }

    // @include utility.typoBase(var.$grey-500, 1rem, 400, null, 1.56, null, null);
    
    @include mixins.tab; // 30-03-2023: Has both light and dark theme styles
}

.MuiDivider-root.quality-plan-divider {
    // background-color: var.$grey-400;

    @include mixins.divider; // 30-03-2023: Has both light and dark theme styles
}

.MuiBox-root.quality-plan-content-box {
    // padding-bottom: 32px;
    padding-top: 0;
}

.MuiTextField-root.quality-plan-tab-textfield {
    width: var.$maxTextFieldWd;

    // @include mixins.textfield;

    @include mixins.textfield-outlined;
}

.MuiBox-root.quality-plan-overview-wrapper-box {
    margin-top: 8px;
}

.MuiBox-root.quality-plan-timeline-chart-box {

    & .MuiBox-root.timeline-chart-row-header-box {
        &.quality-plan-chart-value-hover:hover {
            cursor: pointer;
        }

        & .MuiSvgIcon-root.quality-plan-chart-week-icon {
            margin-left: 8px;
            height: 20px;
            vertical-align: text-bottom;
            width: 20px;

            &.quality-plan-chart-expanded {
                transform: rotate(180deg);
            }

            &.#{var.$darkThemeClass} {
                path {
                    fill: var.$grey-200;
                }
            }
        }
    }

    & .MuiBox-root.timeline-chart-value-box{
        @include utility.transition(background-color, false, false);

        &.quality-plan-chart-value-hover:hover {
            cursor: pointer;
        }
    
        &.quality-plan-selected {
            background-color: var.$primary-400;

            &.#{var.$darkThemeClass} {
                @include utility.theme(('bgColor': var.$primary-600, 'color': var.$primary-800));
            }
        }
    }
}

.MuiBox-root.quality-plan-overview-box {
    // margin-top: 8px;

    @include mixins.overview-cards-grid;
}

.MuiSvgIcon-root.quality-plan-phaseplan-link-icon {
    height: 20px;
    margin-left: 4px;
    vertical-align: text-bottom;
    width: 20px;

    path {
        fill: none;
    }
}

.MuiBox-root.quality-plan-phaseplan-link-box {
    text-decoration: none;

    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.28, null, null); // self assumed
}

// .MuiBox-root.quality-plan-overview-left-box {
//     display: flex;
//     flex-direction: column;
//     flex-basis: 50%;
//     margin-right: 16px;
//     max-width: 618px;
// }

// .MuiBox-root.quality-plan-overview-right-box {
//     display: flex;
//     flex-basis: 50%;
        
//     > .MuiPaper-root {
//         height: calc(100% - 16px);
//     }
// }

.MuiBox-root.quality-plan-gen-info-box {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.MuiTypography-root.quality-plan-tab-title-typo {
    @include utility.typoBase(var.$grey-900, 1.25rem, 500, null, 1.45, null, null, true);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiTypography-root.quality-plan-tab-sub-typo {
    margin-bottom: 12px;
    margin-top: 8px;

    @include utility.typoBase(var.$grey-600, 1rem, 400, null, 1.56, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

// .MuiBox-root.quality-plan-attach-metric-plans-box {
// }

.MuiMenuItem-root.quality-plan-phaseplan-menuitem {

    &.#{var.$darkThemeClass} {
        @include mixins.menuitem-dark;
    }
}

.MuiTypography-root.quality-plan-attach-msg-typo {
    @include utility.typoBase(var.$grey-900, 1.25rem, 500, null, 1.45, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiBox-root.quality-plan-drag-and-drop-box {
    display: flex;
    margin-top: 20px;
}

.MuiBox-root.quality-plan-drag-and-drop-end-box {
    flex-basis: 40%;
    // flex-shrink: 0;
    height: 100%;
    max-width: 594px;
}

.MuiTypography-root.quality-plan-my-qp-typo {
    @include utility.typoBase(var.$grey-700, 1.5625rem, 500, null, 1.16, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

.MuiTypography-root.quality-plan-my-qp-sub-typo {
    @include utility.typoBase(var.$grey-700, 0.875rem, 400, null, 1.5, 7px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiBox-root.quality-plan-attached-metric-plans-box {
    background-color: var.$primary-200;
    height: var.$maxCardContentBoxHt-2;
    margin-top: 16px;
    overflow: auto;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$light-grey-200));
    }
}

.MuiTypography-root.quality-plan-sel-phase-typo {
    text-align: center;
    @include utility.typoBase(var.$grey-700, 1.25rem, 500, null, 1.16, null, '100%');

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

.MuiBox-root.quality-plan-drag-and-drop-middle-box {
    align-items: center;
    display: flex;
    flex-basis: 10%;
    flex-direction: column;
    // flex-shrink: 0;
    height: var.$maxCardContentBoxHt-2;
    justify-content: center;
    margin-left: 64px;
    margin-right: 64px;
    max-width: 131px;
}

.MuiSvgIcon-root.quality-plan-drag-icon {
    height: 50px;
    width: 51px;

    &.#{var.$darkThemeClass} {
        rect {
            fill: var.$dark-100;
        }

        path {
            fill: var.$grey-200;
        }
    }
}

.MuiTypography-root.quality-plan-drag-info-typo {
    text-align: center;

    @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.5, 23px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

.MuiBox-root.quality-plan-drag-and-drop-start-box {
    flex-basis: 40%;
    // flex-shrink: 0;
    height: 100%;
    max-width: 594px;
}

.MuiTypography-root.quality-plan-created-mp-typo {
    @include utility.typoBase(var.$grey-700, 1.5625rem, 500, null, 1.16, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

.MuiTypography-root.quality-plan-created-mp-sub-typo {
    @include utility.typoBase(var.$grey-700, 0.875rem, 400, null, 1.5, 7px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }

}

.MuiBox-root.quality-plan-created-metric-plan-box {
    background-color: var.$primary-200;
    height: var.$maxCardContentBoxHt-2;
    margin-top: 16px;
    overflow: auto;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$light-grey-200));
    }
}
