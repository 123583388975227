@use 'var';
@use 'utility';
@use 'mixins';


// ################ LIGHT THEME ################ 


.MuiTypography-root.room-detail-info-typo {
    color: var.$grey-500;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.56;
    min-height: calc(1.56 * 16px);
    margin-top: 4px;
}

.MuiSvgIcon-root.room-detail-info-icon {
    height: 20px;
    margin-right: 10px; 
    vertical-align: text-bottom;
    width: 20px; 
    
    // & path {
    //     fill: var.$grey-500;
    // };
}

.MuiPaper-root.room-detail-info-icon-paper {
    align-items: center;
    background-color: var.$primary-900;
    border-radius: 50%;
    display: flex;
    height: 56px;
    justify-content: center;
    width: 56px;
}

.MuiSvgIcon-root.room-detail-edit-icon{
    height: 20px;
    width: 20px;
    color: black;
    
    & path {
        fill: none;
    };
}

.MuiTypography-root.room-detail-item-info-typo {
    color: var.$primary-900; 
    font-size: 25px; 
    font-weight: 500; 
    line-height: 1.16;
    margin-bottom: 24px;
    margin-top: 32px;
}

.MuiTypography-root.room-detail-item-info-info-typo {
    color: var.$primary-900;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 4px;
}

.MuiSvgIcon-root.room-detail-item-info-icon {
    color: var.$primary-400; 
    height: 24px; 
    width: 24px;

    & path {
        fill: var.$primary-400;
    }
}

.MuiPaper-root.room-detail-wrapper-paper {
    background-color: var.$primary-100;
    flex-grow: 1;
    max-height: 100vh;
    overflow: auto;
    padding: 15px;

    @include utility.transition(background-color);
}

.MuiPaper-root.room-detail-header-paper {
    align-items: flex-start;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.MuiPaper-root.room-detail-title-paper {
    background-color: transparent;
    width: 100%;
}

.MuiTypography-root.room-detail-title-typo {
    color: var.$grey-700;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 1.08;
    margin-bottom: 16px;
}

.MuiSvgIcon-root.room-detail-breadcrumbs-sep-icon {
    height: 16px;
    width: 16px;
}

.MuiTypography-root.room-detail-detail-breadcrumb-typo {
    color: var.$grey-600;
    font-size: 14px;
    letter-spacing: -0.05em;
    line-height: 18px;
    
    &:hover {
        cursor: default;
    }
}

.MuiButton-root.room-detail-save-button {
    background-color: var.$primary-700;
    border-radius: 0;
    padding-top: 14px;
    padding-bottom: 14px;
    text-transform: none;

    &:hover {
        background-color: var.$primary-700;
    }

    @include utility.typoBase(var.$grey-200, 1rem, 500, -0.05rem, 1.25, null, var.$maxBtnWd);
}

.MuiTabs-root.room-detail-tabs {

    & .room-detail-tabindicator { // Or could be &.TabIndicatorProps
        background-color: var.$grey-700;
    }
}

.MuiTab-root.room-detail-tab {
    color: var.$grey-500;
    font-size: 16px;
    line-height: 1.56;
    text-transform: none; 
    
    &.Mui-selected {
        color: var.$grey-700;
        font-weight: 500;
    };

    // @include utility.typoBase(var.$grey-500; 1rem; 400; null; 1.56; null; null);

    &.room-detail-hide-tab {
        display: none;
    }
}

.MuiDivider-root.room-detail-divider {
    background-color: var.$grey-400;
}

.MuiPaper-root.room-detail-detail-outer-paper {
    background-color: transparent;
    display: flex;
    margin-top: 40px;
    width: 100%;

}

.MuiPaper-root.room-detail-detail-left-paper {
    background-color: transparent;
    flex-basis: 50%;
    flex-grow: 1;
    margin-right: 14px;
}

.MuiPaper-root.room-detail-detail-right-paper {
    background-color: transparent;
    flex-basis: 50%;
    flex-grow: 1;
    margin-bottom: 16px;
}

.MuiPaper-root.room-detail-installation-specifics-card {
    margin-bottom: 0;
    height: 100%;
}

.MuiPaper-root.room-detail-light-and-table-paper {
    background-color: transparent;
    display: flex;
    margin-top: -1px;
}

.MuiBox-root.room-detail-detail-general-box {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
}

.MuiBox-root.room-detail-drag-box {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    padding-bottom: 32px;
}

.MuiBox-root.room-detail-left-drag-box {
    display: flex;
    flex-basis: 40%;
    flex-direction: column;
}

.MuiSvgIcon-root.room-detail-card-icon {
    height: 24px;
    margin-right: 8px;
    width: 24px;
    
    & path {
        fill: none;
        stroke: var.$grey-700;
    }
}

.MuiTypography-root.room-detail-api-title-typo {
    color: var.$grey-700;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.16;
}

.MuiTypography-root.room-detail-api-subtitle-typo {
    color: var.$grey-700;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 7px;
}

.MuiBox-root.room-detail-metrcroom-list-box {
    background-color: var.$primary-200;
    margin-top: 16px;
    height: calc(77 * 8px);
    overflow: auto;
}

.MuiBox-root.room-detail-middle-drag-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-basis: 10%;
    justify-content: center;
}

.MuiSvgIcon-root.room-detail-middle-drag-box-icon {
    & path {
        fill: none;
    }
}

.MuiTypography-root.room-detail-drag-info-typo {
    color: var.$primary-800;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.56;
    margin-top: 23px;
    text-align: center;
}

.MuiBox-root.room-detail-right-drag-box {
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
    min-height: 154px;
}

.MuiTypography-root.room-detail-tab-title-typo {
    color: var.$grey-900;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.45;
}

.MuiTypography-root.room-detail-tab-sub-typo {
    color: var.$grey-600;
    font-size: 16px;
    line-height: 1.56;
    margin-bottom: 20px;
    margin-top: 8px;
}

.MuiTextField-root.room-detail-tab-textfield {
    position: relative;
    // width: 650px;

    // color: var.$grey-600;
    // margin-top: 20px;

    // & .MuiInputBase-root {
    //     border-radius: 0;
    // }

    // & .MuiInputBase-input {
    //     padding: 16px 15px;
    // }
    
    // & textarea.MuiInputBase-input {
    //     padding: 0;
    // }

    // & .MuiInputLabel-root {
    //     color: var.$grey-600;
    //     font-size: 16px;
    //     line-height: 1.56;
    // }

    // & fieldset {
    //     border-color: var.$grey-600;
    // }
    @include mixins.textfield;
}

.MuiBox-root.room-detail-no-metrcroom-box {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
}

.MuiTypography-root.room-detail-no-metrcroom-typo {
    color: var.$grey-500;
    font-size: 20px;
    letter-spacing: -0.05em;
    text-align: center;
}

.MuiPaper-root.room-detail-detail-specifics-paper {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-bottom: 56px;
    width: 100%;
}

.room-detail-detail-sensors-paper{
    background-color: transparent;
    margin-top: 15px;
}

.MuiTypography-root.room-detail-filter-typo{
    color: var.$grey-400;
    font-size: 14px;
}

.MuiChip-root.room-detail-chip,
.MuiOutlinedInput-root.room-detail-outlined-input
{
    font-size: 12px;
    font-weight: 500;
}

.MuiPaper-root.room-detail-detail-sensors-paper {
    background-color: transparent;
    // display: flex;
    // flex-wrap: wrap;
    // margin-top: 56px;
    // width: 100%;

    @include mixins.three-cards-grid;
}

.MuiButton-root.room-detail-sensorcard-button {
    @include mixins.save-button-contianed-green-check;
}

.MuiBox-root.room-detail-tables-box {
    background-color: transparent;
    // display: flex;
    // flex-wrap: wrap;            
    // margin-top: 56px;
    width: 100%;
    & .MuiButton-root.can-clone-table{
        height: 104px;
    }

    @include mixins.three-cards-grid;
}

// .MuiButton-root.room-detail-addtablecard-button {
//     margin-bottom: 16px;
//     margin-right: 16px;  
// }

.MuiPaper-root.room-detail-detail-lights-paper {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
}

.MuiTypography-root.room-detail-lights-title-typo {
    color: var.$grey-900;
    font-size: 20px; // 20/1.1
    font-weight: 500;
    line-height: 1.45;
}

.MuiTypography-root.room-detail-lights-sub-typo {
    color: var.$grey-600;
    font-size: 16px; // 16/1.1
    line-height: 1.56;
    margin-top: 8px; // 8/1.1
    margin-bottom: 32px; // 32/1.1
}

.MuiBreadcrumbs-.room-detail-breadcrumbs {
    line-height: 18px;

    & .MuiBreadcrumbs-separator {
        margin-left: 4px;
        margin-right: 4px;
    }
}

.MuiInputAdornment-root.room-detail-textfield-adornment {
    & .MuiTypography-root {
        color: var.$grey-600;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.56;
    }
}

// ################ DARK THEME

.MuiTypography-root.room-detail-info-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiSvgIcon-root.room-detail-info-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$grey-400;
    }
}

// .MuiPaper-root.room-detail-info-icon-paper.#{var.$darkThemeClass} {

// }

// .MuiTypography-root.room-detail-item-info-typo.#{var.$darkThemeClass} {

// }

// .MuiTypography-root.room-detail-item-info-info-typo.#{var.$darkThemeClass} {

// }

// .MuiSvgIcon-root.room-detail-item-info-icon.#{var.$darkThemeClass} {

// }

.MuiPaper-root.room-detail-wrapper-paper.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-700));

    @include utility.transition(background-color);

}

// .MuiPaper-root.room-detail-header-paper.#{var.$darkThemeClass} {

// }

// .MuiPaper-root.room-detail-title-paper.#{var.$darkThemeClass} {

// }

.MuiTypography-root.room-detail-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiBreadcrumbs-root.room-detail-breadcrumbs.#{var.$darkThemeClass} {
    li span {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiTabs-indicator.room-detail-tabindicator.#{var.$darkThemeClass} {
    background-color: var.$grey-200;
}

.MuiSvgIcon-root.room-detail-breadcrumbs-sep-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$grey-400;
    }
}

.MuiTypography-root.room-detail-detail-breadcrumb-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiButton-root.room-detail-save-button.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$primary-600, 'color': var.$primary-900));
    
    &.Mui-disabled {
        @include utility.theme(('bgColor': var.$grey-800, 'color': var.$grey-600));
    }
    
}

// .MuiTabs-root.room-detail-tabs.#{var.$darkThemeClass} {

// }

.MuiTab-root.room-detail-tab.#{var.$darkThemeClass} {
    &.Mui-disabled {
        color: var.$grey-400;
    }
    
    &.Mui-selected {
        color: var.$grey-200;
    }
}

.MuiDivider-root.room-detail-divider.#{var.$darkThemeClass} {
    background-color: var.$grey-500;
}

// .MuiPaper-root.room-detail-detail-outer-paper.#{var.$darkThemeClass} {

// }

// .MuiPaper-root.room-detail-detail-left-paper.#{var.$darkThemeClass} {

// }

// .MuiPaper-root.room-detail-detail-right-paper.#{var.$darkThemeClass} {

// }

// .MuiPaper-root.room-detail-light-and-table-paper.#{var.$darkThemeClass} {

// }

// .MuiBox-root.room-detail-detail-general-box.#{var.$darkThemeClass} {

// }

// .MuiBox-root.room-detail-drag-box.#{var.$darkThemeClass} {

// }

// .MuiBox-root.room-detail-left-drag-box.#{var.$darkThemeClass} {

// }

.MuiSvgIcon-root.room-detail-card-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$grey-400;
    }
}

.MuiTypography-root.room-detail-api-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiTypography-root.room-detail-api-subtitle-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiBox-root.room-detail-metrcroom-list-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$light-grey-200));
}

// .MuiBox-root.room-detail-middle-drag-box.#{var.$darkThemeClass} {

// }

.MuiSvgIcon-root.room-detail-middle-drag-box-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$grey-400;
    }
}

.MuiTypography-root.room-detail-drag-info-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

// .MuiBox-root.room-detail-right-drag-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.room-detail-tab-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiTypography-root.room-detail-tab-sub-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiTextField-root.room-detail-tab-textfield.#{var.$darkThemeClass} {
    
    // @include utility.theme(('bgColor': var.$dark-100));
    
    // & .MuiInputLabel-root,
    // & .MuiInputBase-root {
    //     @include utility.theme(('color': var.$grey-400));
    // }
    
    // & fieldset {
    //     border-color: var.$grey-700;
    // }
    @include mixins.textfield-dark;
}

// .MuiBox-root.room-detail-no-metrcroom-box.#{var.$darkThemeClass} {

// }

// .MuiTypography-root.room-detail-no-metrcroom-typo.#{var.$darkThemeClass} {
    
// }

// .MuiPaper-root.room-detail-detail-specifics-paper.#{var.$darkThemeClass} {

// }

// .MuiPaper-root.room-detail-detail-sensors-paper.#{var.$darkThemeClass} {

// }

// .MuiButton-root.room-detail-sensorcard-button.#{var.$darkThemeClass} {

// }

// .MuiBox-root.room-detail-tables-box.#{var.$darkThemeClass} {

// }

// .MuiButton-root.room-detail-addtablecard-button.#{var.$darkThemeClass} {

// }

// .MuiPaper-root.room-detail-detail-lights-paper.#{var.$darkThemeClass} {

// }

.MuiTypography-root.room-detail-lights-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiTypography-root.room-detail-lights-sub-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

// .MuiBreadcrumbs-.room-detail-breadcrumbs.#{var.$darkThemeClass} {

// }

.MuiInputAdornment-root.room-detail-textfield-adornment.#{var.$darkThemeClass} {
    & .MuiTypography-root {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiButton-root.room-detail-sensorcard-button.#{var.$darkThemeClass} {
    @include mixins.save-button-contained-dark;
}

.MuiChip-filled.room-detail-chip.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$primary-200))
}

.MuiChip-outlined.room-detail-chip.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400))
}

.MuiOutlinedInput-root.room-detail-outlined-input.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
    @include mixins.textfield-dark;
}