// ~~~~~~~~~~~~~~~~ LIGHT THEME COLORS ~~~~~~~~~~~~~~~~ 

    // PRIMARY
$primary-100: #F7FAEA;
$primary-200: #E6F1C1;
$primary-300: #D6E797;
$primary-400: #C5DE6E;
$primary-500: #BDD959;
$primary-600: #B1D23A;
$primary-700: #819C24;
$primary-800: #4E5D15;
$primary-900: #1A1F07;

    // GREY
$grey-100: #1A1B19;
$grey-200: #D9DBD3;
$grey-300: #BDC0B2;
$grey-400: #A1A591;
$grey-500: #6F745F;
$grey-600: #5D614F;
$grey-700: #414437;
$grey-800: #252720;
$grey-900: #090A08;

    // DASHBOARD
$main-000: #FFFFFF; // white
$main-100: #E9FFC6; // cardBg
$main-200: #E7F2D5; // bg
$main-300: #AFE33F; // forecastKeyTitle
$main-400: #97BF41; // projectionBg
$main-500: #6B9219; // cardBtn
$main-600-66: #4C6E871A; // divider; 1A ~ 66% opacity
$main-700: #496019; // cardTitle, subTitle
$main-800: #413E2E; // cardValue
$main-900: #324001; // title
$main-1000: #070A01; // popupBg
$main-1000-90: #070A01E8; // popupBgWithOpacity; E8 ~ 90% opacity
$main-1100: #031628; // cardTitleMega

    // LANDING PAGE
$lp-bg-100: #0F1300;
$lp-btn-100: #4A4A4A;
$lp-btnBg-100: #ECF9DD;
$lp-txt-100: #EFFADD;
$lp-navBg-100: linear-gradient(178.15deg, rgba(0, 18, 6, 0.5) 1.56%, rgba(255, 255, 255, 0.5) 465.89%, rgba(0, 18, 6, 0.5) 465.89%);;

// ~~~~~~~~~~~~~~~~ DARK THEME COLORS ~~~~~~~~~~~~~~~~ 
    
    // DARK
$dark-100: #303130;
$dark-200: #2E2F2D;
$dark-300: #2B2C2B;
$dark-400: #242523;
$dark-400-00: #24252300;
$dark-500: #1F201E;
$dark-600: #1D1E1C;
$dark-700: #1A1B19;
$dark-800: #151614;
$dark-900: #090A08;

    // GRADIENTS
$gradient-100: linear-gradient(183.65deg, $dark-500 61.04%, #1B3C01 97%);
$gradient-200: linear-gradient(180deg, $dark-400 0%, rgba(36, 37, 35, 0) 100%);


// ~~~~~~~~~~~~~~~~ RANDOM COLORS ~~~~~~~~~~~~~~~~ 

$black-000: #000000;
$dark-grey-100: #444444;
$light-grey-100: #F99696;
$light-red-100: #DE3636;
$light-yellow-100: #FCFF80;
$light-grey-200: #F6F6F4;
$disabled-icon-100: #00000042;
$indigo: #5A57FF;


// BASE
$baseTransitionTime: 800ms;
$baseCollapseTime: 300ms;
$baseTransition: opacity $baseTransitionTime cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out; // opacity style for Fade component

// CLASS NAMES
$darkThemeClass: 'dark-theme';

// TRANSITIONS
$opacityTransition-100: opacity 225ms 100ms ease;
$opacityTransition-200: opacity 10ms ease;

// The width transition duration may be dependent on the actual width
$widthTransitionClosed-100: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
$widthTransitionClosedList-100: (width, 195ms, cubic-bezier(0.4, 0, 0.6, 1), 0ms);
$widthTransitionOpen-100: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
$widthTransitionOpenList-100: (width, 225ms, cubic-bezier(0.4, 0, 0.6, 1), 0ms);

// DIMENSIONS
$maxBtnWd: 244px;
$maxDragBoxWd: 500px;
$maxFarmTableWd: 1420px;
$maxInviteFormWd: 544px;
$maxTextFieldWd: 650px;
$maxContentWd: 1440px;
$maxTableWd: 1220px;
$maxTextWd-1: 648px;
$maxSetupFormWd: 472px;
$maxCardContentBoxHt-1: 504px; // 4 * 126px
$maxCardContentBoxHt-2: 616px; // 8 * 77px
$timelineRowHeaderWd: 150px;