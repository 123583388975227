@use 'var';
@use 'utility';


// NOTE: Mixins for accordion were created for styles applied while it is collapsed


// ################ MuiTextField-root ################


@mixin textfield {
    color: var.$grey-800;
    margin-top: 20px;
    
    .MuiInputBase-root {
        border-radius: 0;
    }
    
    .MuiInputBase-input {
        padding: 16px 15px;
    }
    
    textarea.MuiInputBase-input {
        padding: 0;
    }
    
    .MuiInputLabel-root {
        color: var.$grey-800;
        font-size: 16px;
        line-height: 1.56;
    }
    
    fieldset.MuiOutlinedInput-notchedOutline {
        border-color: var.$grey-800;
    }
}

@mixin textfield-dark {
    @include utility.theme(('bgColor': var.$dark-100));
    
    .MuiInputLabel-root,
    .MuiInputBase-root,
    .MuiInputAdornment-root,
    .Mui-disabled {
        @include utility.theme(('color': var.$grey-400));
        -webkit-text-fill-color: var.$grey-400;
    }
    
    fieldset {
        border-color: var.$grey-700;
    }    
}

@mixin textfield-outlined($margin-top: 20px, $padding: 16px 15px, $font-size: 16px, $line-height: 1.56) {
    color: var.$grey-800;
    margin-top: $margin-top;
    
    .MuiInputBase-root {
        border-radius: 0;
    }
    
    .MuiInputBase-input {
        padding: $padding;
    }
    
    textarea.MuiInputBase-input {
        padding: 0;
    }
    
    .MuiInputLabel-root {
        color: var.$grey-800;
        font-size: $font-size;
        line-height: $line-height;
    }
    
    fieldset.MuiOutlinedInput-notchedOutline {
        border-color: var.$grey-800;
    }

    // DARK THEME
    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-100));
    
        .MuiInputLabel-root,
        .MuiInputBase-root {
            @include utility.theme(('color': var.$grey-200));
            .Mui-disabled {
                -webkit-text-fill-color: var.$grey-400;
            }
        }
        
        fieldset {
            border-color: var.$grey-700;
        }

        .MuiSelect-icon path {
            fill: var.$grey-500;
        }
    }
}


// ################ MuiMenuItem-root ################


@mixin menuitem-dark {
    @include utility.theme(('bgColor': var.$grey-500, 'color': var.$grey-200));
    
    &:hover {
        background-color: var.$grey-500;
    }
    
    &.Mui-selected {
        @include utility.theme(('bgColor': var.$grey-500, 'color': var.$grey-200));        
    }
    
    &.menuitem-focus-dark {
        background-color: var.$grey-600;
    }

    &.Mui-disabled {
        opacity: 1;
        @include utility.theme(('bgColor': var.$grey-700, 'color': var.$grey-500));
    }
}


// ################ MuiPaper-root, MuiBox-root ################


@mixin main-content-wrapper {
    background-color: var.$primary-100;
    flex-grow: 1;
    max-height: 100vh;
    overflow: auto;
    padding: 15px;
    // transition: opacity var(--fade-timing-ms)ms cubic-bezier(0.4, 0, 0.2, 1), padding 225ms ease-in-out !important;
    
    @include utility.transition((background-color));
}

@mixin main-content-wrapper-dark {
    @include utility.theme(('bgColor': var.$dark-700));
}

@mixin content-wrapper {
    background-color: var.$primary-100;
    flex-grow: 1;
    max-height: 100vh;
    overflow: auto;
    padding: 60px 32px;
    // transition: opacity var(--fade-timing-ms)ms cubic-bezier(0.4, 0, 0.2, 1), padding 225ms ease-in-out !important;
    
    @include utility.transition((background-color));

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-700));
    }
}

@mixin overview-cards-grid {
    display: grid;
    gap: 16px;
    grid-auto-rows: auto;
    grid-template-areas: "a c"
                         "b c";
    grid-template-columns: repeat(2, 1fr);
    max-width: var.$maxTableWd;
    margin-top: 36px;
    width: 100%;
}

@mixin accordion-title-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@mixin three-cards-grid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 32px;
}

@mixin custom-scroll {
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #BDC0B2;
        border-radius: 8px;
    }
}

// ################ MuiButton-root ################


// Here, contained is reference to Mui button variant taxonomy

@mixin save-button-contianed-green-check { // 27-03-2023: TODO: Check if naming is correct; Do not use unless exact match since common pattern not found: ex:- primary-700 vs primary-800
    background-color: var.$primary-700;
    border-radius: 0;
    padding: 16px; 
    text-transform: none;
    
    &:hover {
        background-color: var.$primary-700;
    }

    @include utility.typoBase(var.$primary-100, 1rem, 500, -0.05em, 1.45, null, var.$maxBtnWd);
}

@mixin save-button-contained-dark {
    @include utility.theme(('bgColor': var.$primary-600, 'color': var.$primary-900));
    
    &.Mui-disabled {
        @include utility.theme(('bgColor': var.$grey-800, 'color': var.$grey-600));
    }
}

@mixin save-button-contained-1 {
    background-color: var.$primary-700;
    border-radius: 0;
    padding: 16px;
    text-transform: none;
        
    &:hover {
        background-color: var.$primary-700;
    }

    &.Mui-disabled {
        background-color: var.$grey-400;
        color: var.$grey-200;
    }

    @include utility.typoBase(var.$primary-100, 1rem, 400, null, 1.25, null, var.$maxBtnWd);

    // DARK THEME
    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$primary-600, 'color': var.$primary-900));
    
        &.Mui-disabled {
            @include utility.theme(('bgColor': var.$grey-800, 'color': var.$grey-600));
        }    
    }
}

@mixin save-button-contained-2 {
    background-color: var.$primary-800;
    border-radius: 0;
    padding: 16px;
    text-transform: none;
        
    &:hover {
        background-color: var.$primary-800;
    }

    &.Mui-disabled {
        background-color: var.$grey-400;
        color: var.$grey-200;
    }

    @include utility.typoBase(var.$main-000, 1rem, 500, -0.05em, 1.56, 20px, var.$maxBtnWd);

    // DARK THEME
    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$primary-700, 'color': var.$primary-100));
    
        &.Mui-disabled {
            @include utility.theme(('bgColor': var.$grey-800, 'color': var.$grey-600));
        }    
    }
}

@mixin save-button-contained-3 {
    @include save-button-contained-2;

    color: var.$primary-100;
    letter-spacing: 0;
    line-height: 1.45;

    &.#{var.$darkThemeClass} {
        @include save-button-contained-dark;
    }

}

// ################ MuiSvgIcon-root ################


@mixin breadcrumb-sep-icon {
    height: 16px;
    width: 16px;
    
    // Can also add &.#{var.$darkThemeClass} { property: value; } here - Pros and Cons ?
    // Testing the above approach:
    // DARK THEME
    &.#{var.$darkThemeClass} {
        path {
            stroke: var.$grey-400;
        }    
    }
}

// @mixin breadcrumb-sep-icon-dark {
//     path {
//         stroke: var.$grey-400;
//     }
// }

@mixin accordion-title-icon {
    height: 24px;
    margin-right: 18px;
    vertical-align: sub;
    width: 24px;

    path {
        fill: var.$grey-900;
    }
}

// ################ MuiBreadcrumbs-root ################


@mixin breadcrumbs {
    line-height: 18px;
        
    .MuiBreadcrumbs-separator {
        margin-left: 4px;
        margin-right: 4px;
    }

    // DARK THEME
    &.#{var.$darkThemeClass} {
        li span {
            @include utility.theme(('color': var.$grey-400));
        }        
    }
}

// @mixin breadcrumbs-dark {
//     li span {
//         @include utility.theme(('color': var.$grey-400));
//     }
// }


// ################ MuiTypography-root ################


@mixin breadcrumb-link-typo {
    @include utility.typoBase(var.$grey-600, 0.875rem, 400, -0.05em, 1.29, null, null);

    &:hover {
        cursor: default;
    }

    // DARK THEME
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

// @mixin breadcrumb-link-typo-dark {
//     @include utility.theme(('color': var.$grey-400));
// }

@mixin accordion-title-typo {
    @include utility.typoBase(var.$grey-900, 1.25rem, 500, null, 1.45, null, null);
}

// ################ MuiTabs-root ################


@mixin tabs {
    margin-top: 32px;

    .MuiTabs-indicator {
        background-color: var.$grey-700;
    }

    // DARK THEME
    &.#{var.$darkThemeClass} {
        .MuiTabs-indicator {
            background-color: var.$grey-200;
        }
    }
    
}

// @mixin tabs-dark {
//     .MuiTabs-indicator {
//         background-color: var.$grey-200;
//     }
// }


// ################ MuiTab-root ################


@mixin tab {
    text-transform: none;
        
    &.Mui-selected {
        color: var.$grey-700;
        font-weight: 500;
    }

    @include utility.typoBase(var.$grey-500, 1rem, 400, null, 1.56, null, null);


    // DARK THEME
    &.#{var.$darkThemeClass} {
        &.Mui-selected {
            color: var.$grey-200;
        }        
    }
}

// @mixin tab-dark {
//     &.Mui-selected {
//         color: var.$grey-200;
//     }
// }


// ################ MuiDivider-root ################


@mixin divider {
    background-color: var.$grey-400;

    // DARK THEME
    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$grey-500));
    }
}


// ################ MuiAccordion-root ################

@mixin accordion {
    background-color: var.$primary-400;
    border-bottom: 1px solid var.$primary-700;
    padding: 24px 20px;

    &::before {
        content: none;
    }

    &.Mui-expanded {
        background-color: var.$primary-100;
        margin-bottom: 0;
        margin-top: 0;
    }

    @include utility.transition(background-color, false, false);

    &.#{var.$darkThemeClass} {
        border-color: var.$grey-400;

        @include utility.theme(('bgColor': var.$grey-300));
        
        &.Mui-expanded {
            @include utility.theme(('bgColor': var.$light-grey-200));
        }    
    }
}


// ################ MuiAccordionSummary-root ################

@mixin accordion-summary {
    min-height: 0;
    padding-left: 0;
    padding-right: 0;
    
    &:hover {
        cursor: default;
    }

    &.Mui-expanded {
        min-height: 0;
    }

    & .MuiAccordionSummary-content {
        flex-direction: column;
        margin-bottom: 0;
        margin-top: 0;
    }

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': transparent));
    }
}


// ################ Mui-root ################


