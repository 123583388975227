@use 'var';
@use 'utility';
@use 'mixins';

// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~ 

.MuiBox-root.organization-box {
    background-color: var.$primary-100;
    flex-grow: 1;
    max-height: 100vh;
    overflow: auto;
    padding: 60px 32px 0;
    
    @include utility.transition(background-color);
}

.MuiBox-root.organization-nav-title-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 54px;
}

.MuiBox-root.organization-title-box {
    align-items: center;
    display: flex;
}

.MuiTypography-root.organization-title-typo {
    background-color: var.$grey-200;
    padding: 4px 12px;
    text-transform: uppercase;

    @include utility.typoBase(var.$grey-600, 1.125rem, 500, null, 1.5, null, null);
}

.MuiTypography-root.organization-name-typo {
    margin-left: 12px;
    
    @include utility.typoBase(null, 1.125rem, 500, null, 1.5, null, null);
}

// .MuiBox-root.organization-content-box {

// }

.MuiTabs-root.organization-tabs {
    margin-top: 40px;
}

.MuiTab-root.organization-tab {
    text-transform: none;
    
    &.Mui-selected {
        color: var.$grey-700;
        font-weight: 500;
    }

    @include utility.typoBase(var.$grey-500, 1rem, 400, null, 1.56, null, null);        
}

.MuiDivider-root.organization-divider {
    background-color: var.$grey-400;
}

.MuiBox-root.organization-form-box {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.MuiBox-root.organization-input-section-box {
    max-width: var.$maxTextFieldWd;
    margin-bottom: 32px;
}

.MuiTextField-root.organization-textfield {
    color: var.$grey-800;
    margin-top: 20px;

    & .MuiInputBase-root {
        border-radius: 0;
    }

    & .MuiInputBase-input {
        padding: 16px 15px;
    }
    
    & textarea.MuiInputBase-input {
        padding: 0;
    }

    & .MuiInputLabel-root {
        color: var.$grey-800;
        font-size: 16px;
        line-height: 1.56;
    }

    & fieldset {
        border-color: var.$grey-800;
    }
}

.MuiButton-root.organization-save-button {

    background-color: var.$primary-700;
    border-radius: 0;
    margin-left: auto;
    padding-bottom: 16px; 
    padding-top: 16px; 
    text-transform: none;
    
    &:hover {
        background-color: var.$primary-700;
    }

    @include utility.typoBase(var.$primary-100, 1rem, 500, -0.05em, 1.25, null, var.$maxBtnWd);
}

.MuiBox-root.organization-table-box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 56px;
    max-width: var.$maxFarmTableWd;
}

.MuiBox-root.organization-perm-table-box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 56px;
    max-width: var.$maxFarmTableWd;
}

.MuiBox-root.organization-externals-table-box {
    display: grid;
    gap: 16px;
    margin-top: 56px;
    padding-bottom: 60px;
}

.MuiButton-root.organization-add-button {

    background-color: var.$primary-800;
    border-radius: 0;
    padding: 16px;
    text-transform: none;
        
    &:hover {
        background-color: var.$primary-800;
    }

    @include utility.typoBase(var.$grey-200, 1rem, 500, -0.05em, 1.45, null, var.$maxBtnWd);
}


// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~ 

.MuiBox-root.organization-box.#{var.$darkThemeClass} {
    // @include utility.theme(('bgColor': var.$dark-700));
    
    // @include utility.transition(background-color);
    @include mixins.main-content-wrapper-dark;
}

// .MuiBox-root.organization-nav-title-box.#{var.$darkThemeClass} {
// }

// .MuiBox-root.organization-title-box.#{var.$darkThemeClass} {
// }

.MuiTypography-root.organization-title-typo.#{var.$darkThemeClass} {
    border: 1px solid var.$grey-700;
    border-radius: 100px;
    
    @include utility.theme(('bgColor': var.$dark-300));
    @include utility.typoBase(var.$grey-400, 1rem, 400, null, 1.56, null, null);
}

.MuiTypography-root.organization-name-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

// // .MuiBox-root.organization-content-box {}

// .MuiTabs-root.organization-tabs.#{var.$darkThemeClass} {
// }

.MuiTab-root.organization-tab.#{var.$darkThemeClass} {
    &.Mui-selected {
        color: var.$grey-200;
    }
}

.MuiDivider-root.organization-divider.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$grey-500));
}

// .MuiBox-root.organization-form-box.#{var.$darkThemeClass} {
// }

// .MuiBox-root.organization-input-section-box.#{var.$darkThemeClass} {
// }

.MuiTextField-root.organization-textfield.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100));
    
    & .MuiInputLabel-root,
    & .MuiInputBase-root {
        @include utility.theme(('color': var.$grey-400));
    }
    
    & fieldset {
        border-color: var.$grey-700;
    }
}

.MuiButton-root.organization-add-button.#{var.$darkThemeClass},
.MuiButton-root.organization-save-button.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$primary-600, 'color': var.$primary-900));
    
    &.Mui-disabled {
        @include utility.theme(('bgColor': var.$grey-800, 'color': var.$grey-600));
    }
}

// .MuiBox-root.organization-table-box.#{var.$darkThemeClass} {
// }

// .MuiBox-root.organization-perm-table-box.#{var.$darkThemeClass} {
// }

// .MuiBox-root.organization-externals-table-box.#{var.$darkThemeClass} {
// }

    