@layer customScroll, zeroScroll;

:root {
    /* colors */
    --primary-100: #F7FAEA;
    --primary-200: #E6F1C1;
    --primary-300: #D6E797;
    --primary-400: #C5DE6E;
    --primary-500: #BDD959;
    --primary-600: #B1D23A;
    --primary-700: #819C24;
    --primary-800: #4E5D15;
    --primary-900: #1A1F07;

    --grey-100: #F6F6F4;
    --grey-200: #D9DBD3;
    --grey-300: #BDC0B2;
    --grey-400: #A1A591;
    --grey-500: #6F745F;
    --grey-600: #5D614F;
    --grey-700: #414437;
    --grey-800: #252720;
    --grey-900: #090A08;
    
    --dashboard-bg: #E7F2D5;
    --dashboard-card-bg: #E9FFC6;
    --dashboard-card-btn: #6B9219;
    --dashboard-card-value: #413E2E;
    --dashboard-card-title: #496019;
    --dashboard-card-title-mega: #031628;
    --dashboard-divider: #4C6E871A;
    --dashboard-forecast-key-title: #AFE33F;
    --dashboard-projection-bg: #97BF41;
    --dashboard-popup-bg: #070A01;
    --dashboard-popup-bg-with-opacity: #070A01E8;
    --dashboard-title: #324001;
    --dashboard-subtitle: #496019;
    --dashboard-white: #FFFFFF;

    /* dimensions */
    --content-max-width: 1440px;
    --back-btn-wd: 237px;

    /* timing */
    --fade-timing-ms: 800ms;
}

.wrapper {
    margin: 0 auto;
    /* max-width: var(--content-max-width); */
}

.centeredFlexColumn {
    align-items: center;
    display: flex;
    flex-direction: column;
}

/* DashDrawer */
#dashdrawer-collapse {
    min-width: fit-content !important;
}

/* Dashboard */

/* 07022023: overwritten by .base-wrapper in basic.scss */
/* .MuiBox-root.dashboard-wrapper {
    display: flex;
    min-width: fit-content;
    width: 100%;
} */

/* 07022023: overwritten by .dashboard-middle-box-collapse in dashboard.scss */
/* .dashboard-middle-box-collapse {
    flex-grow: 1.5;
} */

/* 07022023: overwritten by .dashboard-middle-box-collapse in dashboard.scss */
/* .dashboard-middle-box-collapse .MuiCollapse-wrapperInner {
    width: 100%;
} */

/* 07022023: .dashboard-mega-cards-box */
/* .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 24px;
    column-gap: 14px;
    grid-template-rows: minmax(min-content, 302px);
} */

.dashboard-grid-0 {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.dashboard-grid-1 {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.dashboard-grid-2 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.dashboard-grid-3 {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

.MuiBox-root.fadeTransition,
.fadeTransition {
    transition: opacity var(--fade-timing-ms) cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out !important;
    /* transition: opacity var(--fade-timing-ms) cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out; */
}

.fadeTransitionNotImp {
    transition: opacity var(--fade-timing-ms) cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 225ms ease-in-out;   
}

/* ForecastPopup */
.MuiTypography-root.forecast-popup-desc {
    color: var(--dashboard-white);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
}

.MuiBox-root.forecast-popup-stat-box {
    align-items: baseline;
    display: flex;
}

.MuiTypography-root.forecast-popup-stat-key {
    color: var(--dashboard-forecast-key-title);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
}

.MuiTypography-root.forecast-popup-title {
    color: var(--dashboard-white);
    font-size: 48px;
    font-weight: 700;
    line-height: 1.27;
}

.MuiTypography-root.forecast-popup-stat-value {
    color: var(--dashboard-white);
    font-size: 24px;
    font-weight: 400;
    line-height: 1.29;
    margin-left: 16px;
}

/* Wrappers */
/* 24-03-2023: Added to mixins.scss; Can be removed after adding to each content wrapper in styles */
.MuiPaper-root.main-content-wrapper,
.MuiBox-root.main-content-wrapper {
    background-color: var(--primary-100);
    flex-grow: 1;
    max-height: 100vh;
    overflow: auto;
    padding: 60px 32px 0;
    transition: opacity var(--fade-timing-ms)ms cubic-bezier(0.4, 0, 0.2, 1), padding 225ms ease-in-out !important;
}

/* General Styles */

    /* Typography */
.card-title-typo {
    color: var(--primary-800);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.56;
}

    /* customScroll */
@layer customScroll {
    .customScroll::-webkit-scrollbar {
        width: 8px;
    }

    .customScroll::-webkit-scrollbar-track {
        box-shadow: none;
    }

    .customScroll::-webkit-scrollbar-thumb {
        background-color: #BDC0B2;
        border-radius: 8px;
    }
}

@layer zeroScroll {
    .zeroScrollWd,
    .zeroScrollWdWithHt {
        scrollbar-width: none;
    }
    
    .zeroScrollWdHt::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .zeroScrollWd::-webkit-scrollbar {
        /* Keep this style below customScroll; 25-05-2023: Or use layer */
        width: 0px;
    }
    
    .zeroScrollWdWithHt::-webkit-scrollbar {
        /* Keep this style below customScroll; 25-05-2023: Or use layer */
        width: 0px;
        height: 2px;
    }
}    
/* .transparentBgScroll::-webkit-scrollbar-thumb {
    background-color: transparent;
} */

    /* SGTransparentBGBtn */

.MuiButton-root.transparent-back-btn {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 0;
    color: white;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.015em;
    line-height: 1.3;
    padding: 10px;
    text-transform: none;
    /* width: var(--back-btn-wd); */
    width: 237px;
}

.MuiButton-root.transparent-back-btn:hover {
    background-color: transparent;
}

    /* Misc */
.MuiPaper-root.padding-bottom-60,
.MuiBox-root.padding-bottom-60 { /* Add more component specific classes if needed like: .MuiButton-root.padding-bottom-60 */
    padding-bottom: 60px;
}

    /* Temporary */
/* .MuiTypography-root.temp-tab-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25;
    color: var(--grey-900);
}

.MuiTypography-root.temp-tab-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    color: var(--grey-700);
} */

.highcharts-container {
    
    overflow-y: auto !important; 
}

