@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 


.MuiPaper-root.growplan-paper {
    @include mixins.content-wrapper;
}

.MuiPaper-root.growplan-header-paper {
    align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    width: 100%;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiPaper-root.growplan-title-paper {
    background-color: transparent;
    width: 100%;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTypography-root.growplan-title-typo {
    margin-bottom: 16px;

    @include utility.typoBase(var.$grey-700, 2.5rem, 700, -0.01em, 1.08, null, null, true);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiTypography-root.growplan-breadcrumb-link-typo {
    @include mixins.breadcrumb-link-typo;
}

.MuiCircularProgress-root.growplan-progress {
    color: var.$grey-500;
}

.MuiButton-root.growplan-save-button {
    @include mixins.save-button-contained-1;
}

.MuiTabs-root.growplan-tabs {
    @include mixins.tabs;
}

.MuiTab-root.growplan-tab {
    @include mixins.tab;
}

.MuiDivider-root.growplan-divider {
    @include mixins.divider;
}

.MuiBox-root.growplan-overview-box {
    @include mixins.overview-cards-grid;
}

.MuiSvgIcon-root.growplan-overview-inforoom-icon,
.MuiSvgIcon-root.growplan-overview-info-icon {
    height: 20px;
    margin-right: 10px;
    vertical-align: text-bottom;
    width: 20px;
        
    path {
        fill: var.$primary-800;
    }

    &.#{var.$darkThemeClass} {
        path {
            fill: var.$primary-600;
        }    
    }
}

.MuiSvgIcon-root.growplan-overview-inforoom-icon {
    path {
        fill: none;
    }

    &.#{var.$darkThemeClass} {
        path {
            fill: none;
            stroke: var.$primary-600;
        }
    }
}

.MuiTypography-root.growplan-overview-info-typo {
    @include utility.typoBase(var.$grey-500, 1rem, 500, null, 1.56, 4px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

.MuiTypography-root.growplan-overview-sensor-count-typo {
    @include utility.typoBase(var.$grey-500, 0.875rem, 400, null, 1.5, 4px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiSvgIcon-root.growplan-ext-link-icon,
.MuiSvgIcon-root.growplan-phaseplan-link-icon,
.MuiSvgIcon-root.growplan-qualityplan-link-icon {
    height: 20px;
    margin-left: 8px;
    vertical-align: sub;
    width: 20px;
        
    path {
        fill: none;
    }
        
    &:hover {
        cursor: pointer;
    }

    &.#{var.$darkThemeClass} {
        path {
            stroke: var.$grey-500;
        }
    }
}

// .MuiBox-root.growplan-overview-left-box {

// }

// .MuiBox-root.growplan-overview-left-inner-box {

// }

// .MuiBox-root.growplan-overview-right-box {

// }

.MuiTypography-root.growplan-overview-desc-typo {
    @include utility.typoBase(var.$primary-800, 1rem, 400, null, 1.56, 16px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

// .MuiSvgIcon-root.growplan-ext-link-icon {
// Added above with other similar style classes
// }

.MuiPaper-root.growplan-geninfo-paper {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    width: 100%;

    // &.#{var.$darkThemeClass} {

    // }
}

// .MuiTypography-root.growplan-geninfo-title-typo {
//     @include utility.typoBase(var.$grey-900, 1.5rem, 500, null, 1.45, null, null);

//     &.#{var.$darkThemeClass} {

//     }
// }

.MuiBox-root.growplan-geninfo-plan-compatible-box {
    align-items: center;
    display: flex;

    // &.#{var.$darkThemeClass} {}
}

.MuiSvgIcon-root.growplan-geninfo-plan-compatible-error-icon {
    margin-left: 8px;
    // margin-top: 20px;

    &:hover {
        cursor: pointer;
    }

    &.#{var.$darkThemeClass} {
        // path {
        //     color: var.$grey-300;
        // }
    }
}


.MuiBox-root.growplan-sensor-count-box {
    font-size: 14px;
    margin-left: auto;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiBox-root.growplan-room-menuitem-message-box {
    font-size: 0.875rem;
    font-style: italic;
    margin-left: auto;
}

.MuiMenuItem-root.growplan-room-menuitem,
.MuiMenuItem-root.growplan-phaseplan-menuitem,
.MuiMenuItem-root.growplan-medium-profile-menuitem,
.MuiMenuItem-root.growplan-qualityplan-menuitem {
    &.#{var.$darkThemeClass} {
        @include mixins.menuitem-dark;
    }
}


// .MuiButton-root.growplan-qualityplan-menu-button {
//     height: 100%;
//     width: 100%;
//     text-transform: none;
    
//     &.#{var.$darkThemeClass} {
//         @include mixins.menuitem-dark;
//     }

//     background-color: transparent;
// }


.MuiMenuItem-root.growplan-qualityplan-menuitem {

    &.growplan-disabled {
        &:hover {
            // background-color: initial;
            cursor: default;
        }
    }   
    
    &.growplan-disabled:not(.#{var.$darkThemeClass}) {
        &:hover {
            background-color: initial;
        }
    }

}

.MuiBox-root.growplan-qualityplan-menu-typo-box {
    &.#{var.$darkThemeClass} {}

    &.growplan-disabled {
        opacity: 0.5;

        &:hover {
            // background-color: initial;
            cursor: default;
        }
    }

    &.growplan-disabled:not(.#{var.$darkThemeClass}) {
        &:hover {
            background-color: initial;
        }
    }


}

.MuiTextField-root.growplan-geninfo-textfield {
    width: var.$maxTextFieldWd;

    @include mixins.textfield-outlined;
}

.MuiBox-root.growplan-phaseplan-entry-date-box {
    margin-left: auto;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiBox-root.growplan-strains-box {
    margin-top: 32px;
    width: 100;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiBox-root.growplan-strain-title-box {
    align-items: center;
    display: flex;
    justify-content: space-between;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTypography-root.growplan-geninfo-title-typo,
.MuiTypography-root.growplan-strain-title-typo {
    @include utility.typoBase(var.$grey-900, 1.5rem, 500, null, 1.45, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiButton-root.growplan-strain-table-button {
    // TODO: can be made into another variant of button
    background-color: var.$primary-800;
    border-radius: 0;
    padding-bottom: 14px;
    padding-top: 14px;
    text-transform: none;

    @include utility.typoBase(var.$primary-100, 1rem, 500, -0.05em, 1.25, null, var.$maxBtnWd);

    &:hover {
        background-color: var.$primary-800;
    }

    &:first-of-type {
        margin-right: 16px;
    }

    &.#{var.$darkThemeClass} {
        @include mixins.save-button-contained-dark;
    }
}

.MuiPaper-root.growplan-strain-table-paper {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 36px;
    max-width: var.$maxFarmTableWd;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiBox-root.growplan-crops-box {
    margin-top: 32px;
    width: 100;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiBox-root.growplan-crops-title-box {
    align-items: center;
    display: flex;
    justify-content: space-between;

    // &.#{var.$darkThemeClass} {

    // }
}

// .MuiTypography-root.growplan-crops-emptytitle-typo {
//         opacity: 0,
//         visibility: hidden;
//     },

//     &.#{var.$darkThemeClass} {

//     }
// }

.MuiTypography-root.growplan-crops-title-typo {
    @include utility.typoBase(var.$grey-700, 1.5rem, 500, -0.01em, 1.56, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiPaper-root.growplan-crops-table-paper {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: var.$maxFarmTableWd;
    margin-top: 36px;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiSvgIcon-root.growplan-breadcrumb-sep-icon {
    @include mixins.breadcrumb-sep-icon;
}

.MuiBreadcrumbs-root.growplan-breadcrumbs {
    @include mixins.breadcrumbs;
}