@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~ 

.MuiBox-root.plans-box {
    @include mixins.main-content-wrapper;

    @include utility.transition((background-color));
}

.MuiBox-root.plans-header-box {
    // align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    min-height: 56px;
    width: 100%;
}

.MuiTypography-root.plans-title-typo {    
    @include utility.typoBase(var.$grey-700, 2.25rem, 700, -0.01em, 1.08, null, null);
}

.MuiBox-root.plans-header-button-box {
    display: flex;
    align-items: center;
}

// .MuiBox-root.plans-back-to-wizard-icon-box {
//     margin-left: auto;
// }

.MuiSvgIcon-root.plans-back-to-wizard-icon {
    &:hover {
        cursor: pointer;
    }
}

.MuiButton-root.plans-create-button {
    background-color: var.$primary-800;
    border-radius: 0;
    margin-left: 16px;
    padding: 16px;
    text-transform: none;
    
    &:hover {
        // color: var.$primary-100;
        background-color: var.$primary-800;
    }
    
    @include utility.typoBase(var.$primary-100, 1rem, 500, null, 1.45, null, var.$maxBtnWd);
    }

// .MuiBox-root.plans-content-box {
    
// }

.MuiTabs-root.plans-tabs {
    margin-top: 40px;
    
    & .MuiTabs-indicator {
        background-color: var.$grey-700;
    }
}

.MuiTab-root.plans-tab {
    text-transform: none;
        
    &.Mui-selected {
        color: var.$grey-700;
        font-weight: 500;
    }

    @include utility.typoBase(var.$grey-500, 1rem, 400, null, 1.56, null, null);

}

.MuiBox-root.plans-tab-box {
    margin-bottom: 32px;
    margin-top: 32px;
}

.MuiBox-root.plans-tab-card-box {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
    // margin-top: 24px;
}

.MuiTypography-root.plans-tab-title-typo {
    @include utility.typoBase(var.$grey-700, 1.5625rem, 500, null, 1.16, null, null);
}

.MuiTypography-root.plans-tab-desc-typo {
    @include utility.typoBase(var.$grey-600, 1rem, 400, null, 1.56, 8px, null);
}

.MuiTypography-root.plans-site-dev-typo {
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include utility.typoBase(var.$grey-400, 1.5rem, 500, null, 1.5, 32px, null);
}

.MuiDivider-root.plans-divider {
    background-color: var.$grey-400;
}

// .MuiBox-root.plans-crop-plan-card {
    
// }

// .MuiBox-root.plans-grow-plan-card {
    
// }

// .MuiBox-root.plans-qual-plan-card {
    
// }

// .MuiBox-root.plans-metric-plan-card {
    
// }

// .MuiBox-root.plans-phase-plan-card {
    
// }

.MuiBox-root.plans-metric-tab-box {
    margin-top: 8px;
}

.MuiTypography-root.plans-phase-plan-card-extra-detail-typo {
    margin-top: 8px;
}

// .MuiBox-root.plans-metric-filter-box {
//     display: flex;
//     margin-bottom: 16px;
//     overflow: auto;
// }

.MuiTabs-root.plans-metric-filter-tabs {
    margin-bottom: 16px;

    & .MuiChip-root {
        &.plans-metric-chip-selected {
            background-color: var.$primary-200;
        }
    }

    & .MuiTab-root {
        min-width: fit-content;
        padding: 0px 4px;
        text-transform: none;
    }

    & .MuiTabs-scrollButtons.Mui-disabled {
        opacity: 0.3;
    }

    & .MuiTabs-indicator {
        display: none;
    }
    
    &.#{var.$darkThemeClass} {

        & .MuiChip-root {
            color: var.$grey-400;

            &.plans-metric-chip-selected {
                background-color: var.$grey-700;
            }
        }

        & .MuiTabScrollButton-root {
            color: var.$grey-400;
        }

    }
}


// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.plans-box.#{var.$darkThemeClass} {
    @include mixins.main-content-wrapper-dark;
}

// .MuiBox-root.plans-header-box.#{var.$darkThemeClass} {

// }
    
.MuiTypography-root.plans-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}
    
.MuiButton-root.plans-create-button.#{var.$darkThemeClass} {
    @include mixins.save-button-contained-dark;
}
    
.MuiTabs-root.plans-tabs.#{var.$darkThemeClass} {
    & .MuiTabs-indicator {
        background-color: var.$grey-200;
    }
}
    
.MuiTab-root.plans-tab.#{var.$darkThemeClass} {
    &.Mui-selected {
        color: var.$grey-200;
    }
}
    
// .MuiBox-root.plans-tab-box.#{var.$darkThemeClass} {

// }
    
// .MuiBox-root.plans-tab-card-box.#{var.$darkThemeClass} {

// }
    
.MuiTypography-root.plans-tab-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}
    
.MuiTypography-root.plans-tab-desc-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}
    
.MuiTypography-root.plans-site-dev-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}
    
.MuiDivider-root.plans-divider.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$grey-500));
}
