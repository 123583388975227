@use 'var';
@use 'utility';
@use 'mixins';


// ################ MIXINS ################

@mixin self-table-container {    
    background-color: var.$primary-100;
    border-radius: 0;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-100));
    }
}

@mixin self-table {
    width: 100%
}

@mixin self-table-row {
    td, th {
        border: 0;
    }

    // &.#{var.$darkThemeClass} {
    //     @include utility.theme(('bgColor': var.$dark-100));
    // }
}

@mixin self-table-head {
    background-color: var.$primary-300;
    border-bottom: none;
    padding-bottom: 12px;
    padding-top: 12px;
    text-align: center;
    white-space: nowrap;

    @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-300, 'color': var.$grey-500));
    }
}

@mixin self-table-entry-cell {
    // padding-bottom: 16px;
    // padding-top: 24px;
    text-align: center;

    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.56, null, null);
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

@mixin self-table-no-entity-cell {    
    text-align: center;
    
    @include utility.typoBase(var.$grey-500, 1.25rem, 400, -0.05em, null, null, null);
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-100, 'color': var.$grey-300));
    }
}

@mixin self-table-footer-paper {    
    border-top: 1px solid var.$grey-400;
    display: flex;
    justify-content: flex-end;
    padding: 24px 41px;
    width: 100%;

    &.#{var.$darkThemeClass} {
        // border-color: var.$grey-400; // redundant

        @include utility.theme(('bgColor': var.$dark-100));    
    }
}

@mixin self-add-button {
    background-color: var.$primary-800;
    border-radius: 0;
    padding: 12px 24px;
    text-transform: none;
    
    @include utility.typoBase(var.$primary-200, 1rem, 500, null, 1.56, null, null);

    &:hover {
        background-color: var.$primary-800;
    }

    &.#{var.$darkThemeClass} {
        border: 1px solid var.$primary-600;
    
        @include utility.theme(('bgColor': transparent, 'color': var.$primary-600));    
    }

}

// ################ DisplayTable ################

.MuiTableContainer-root.display-table-table-container {
    border-radius: 0;
}

.MuiTable-root.display-table-table {
    width: 100%
}

.MuiTableRow-root.display-table-table-row {
    & td, & th {
        border: 1px solid var.$grey-400;
    }
}

.MuiTableCell-head.display-table-title-cell {
    background-color: var.$primary-300;
    border: 1px solid var.$grey-400;
    border-bottom: none;
    padding: 6px 15px;
    white-space: nowrap;

    @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, null, null);
}

.MuiTableCell-root.display-table-entry-cell {
    padding: 6px 15px;

    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.56, null, null);
}

.MuiTableCell-root.display-table-no-entity-table-cell {
    text-align: center;

    @include utility.typoBase(var.$grey-500, 1.25rem, 400, -0.05em, null, null, null);
}

.MuiPaper-root.display-table-table-footer-paper {
    border-top: 1px solid var.$grey-400;
    display: flex;
    justify-content: flex-end;
    padding: 24px 41px;
    width: 100%;
}

.MuiButton-root.display-table-add-button {
    background-color: var.$primary-800;
    border-radius: 0;
    padding: 12px 24px;
    text-transform: none;
    
    @include utility.typoBase(var.$primary-200, 1rem, 500, null, 1.56, null, null);

    &:hover {
        background-color: var.$primary-800;
    }
}

// ################ DARK THEME

// .MuiTableContainer-root.display-table-table-container.#{var.$darkThemeClass} {

// }

// .MuiTable-root.display-table-table.#{var.$darkThemeClass} {

// }

.MuiTableRow-root.display-table-table-row.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100));
}

.MuiTableCell-head.display-table-title-cell.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-700, 'color': var.$grey-200));
}

.MuiTableCell-root.display-table-entry-cell.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-300));
    @include utility.theme(('bgColor': var.$dark-700));
}

.MuiTableCell-root.display-table-no-entity-table-cell.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100, 'color': var.$grey-300));
}

.MuiPaper-root.display-table-table-footer-paper.#{var.$darkThemeClass} {
    border-color: var.$grey-400;

    @include utility.theme(('bgColor': var.$dark-100));
}

.MuiButton-root.display-table-add-button.#{var.$darkThemeClass} {
    border: 1px solid var.$primary-600;
    
    @include utility.theme(('bgColor': transparent, 'color': var.$primary-600));
}

.MuiTablePagination-root.display-table-pagination.#{var.$darkThemeClass} {
    color: var.$grey-400;
}

// ################ DisplayTableWithDropdown ################


.MuiTableContainer-root.display-table-with-dropdown-table-container {
    @include self-table-container;
    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTable-root.display-table-with-dropdown-table {
    @include self-table;
    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTableRow-root.display-table-with-dropdown-table-row {
    @include self-table-row;
    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTableCell-head.display-table-with-dropdown-title-cell {
    @include self-table-head;
    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTableCell-root.display-table-with-dropdown-entry-cell {
    @include self-table-entry-cell;
    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTableCell-root.display-table-with-dropdown-no-entity-table-cell {
    @include self-table-no-entity-cell;
    // &.#{var.$darkThemeClass} {

    // }
}

.MuiPaper-root.display-table-with-dropdown-table-footer-paper {
    @include self-table-footer-paper;
    // &.#{var.$darkThemeClass} {

    // }
}

.MuiButton-root.display-table-with-dropdown-add-button {
    @include self-add-button;
    // &.#{var.$darkThemeClass} {

    // }
}


// ################ InProdInvTable ################

.MuiBox-root.in-production-inv-table-box {
    margin-top: 27px;

    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.in-production-inv-table-title-box {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &.#{var.$darkThemeClass} {

    }
}

.MuiTypography-root.in-production-inv-table-title-typo {
    @include utility.typoBase(var.$primary-100, 1.125rem, 500, null, 1.5, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$main-000));
    }
}

.MuiButton-root.in-production-inv-table-button {
    border-radius: 0;
    padding: 0;
    text-transform: none;

    @include utility.typoBase(var.$primary-300, 1.125rem, 500, null, 1.5, null, null);
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$primary-600));
    }
}

.MuiBox-root.in-production-inv-table-table-box {
    // align-items: center;
    // display: flex;
    display: grid;
    // grid-template-columns: minmax(125px, fit-content) 1fr;
    // gap: 2px;
    margin-top: 14px;
    
    &.#{var.$darkThemeClass} {
        // @include utility.theme(('bgColor': var.$grey-500));
    }
}

.MuiTypography-root.in-production-inv-table-entry-row-title-typo {
    padding-bottom: 4px;
    padding-top: 4px;

    @include utility.typoBase(var.$main-700, 0.875rem, 500, null, 1.5, null, null );
    
    &.#{var.$darkThemeClass} {
        // @include utility.theme(('bgColor': var.$dark-700, 'color': var.$grey-200));
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiTypography-root.in-production-inv-table-entry-value-typo {
    overflow-wrap: anywhere;
    padding-bottom: 4px;
    padding-top: 4px;
    text-align: center;
    
    border: 1px solid var.$main-100;
    @include utility.typoBase(var.$main-700, 0.875rem, 500, null, 1.5, null, null );
    
    &.#{var.$darkThemeClass} {
        border-color: var.$grey-500;
        // @include utility.theme(('bgColor': var.$dark-700, 'color': var.$grey-400));
        @include utility.theme(('color': var.$grey-400));

        &.table-header {
            color: var.$grey-200;
        }
    }


}


// .MuiBox-root.in-production-inv-table-row-headers-box {

//     display: grid;
//     grid-template-columns: 1fr;

//     &.#{var.$darkThemeClass} {

//     }
// }

// .MuiBox-root.in-production-inv-table-entries-box {

//     &.#{var.$darkThemeClass} {

//     }
// }

// .MuiBox-root.in-production-inv-table-entry-box {

//     &.#{var.$darkThemeClass} {

//     }
// }

// .MuiTypography-root.in-production-inv-table-entry-header-typo {

//     &.#{var.$darkThemeClass} {

//     }
// }

// .MuiTypography-root.in-production-inv-table-entry-value-typo {

//     &.#{var.$darkThemeClass} {

//     }
// }
