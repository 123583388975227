@use 'var';
@use 'utility';
@use 'mixins';

// ################ STEPPER ################

.MuiBox-root.popper-box {
    max-width: 300;
    width: 100%;
    margin: 'auto';
    position: 'relative';
    background-color: var.$primary-100;
    
    @include utility.transition((background-color));

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-700));
    }
}

// 

.MuiTypography-root.popper {
  margin-bottom: 2px;

  @include utility.typoBase(var.$grey-900, 1.5625rem, 500, null, 1.16, 12px, null);
}

.routine-stepper {
    width: 100%;
    max-width: 800px;
    margin: auto;
    position: relative;
  
    .stepper-popper {
      z-index: 1300;
    }
  
    .stepper-paper {
      padding: 16px;
      width: 300px;
      max-width: 100%;
    }
  
    .stepper-form {
      display: flex;
      flex-direction: column;
      gap: 16px;
  
      .button-group {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
      }
    }
  
    .completion-message,
    .step-message {
      margin: 16px 0 8px;
      text-align: center;
    }
  
    .button-container {
      display: flex;
      justify-content: center;
      padding-top: 16px;
  
      .back-button {
        margin-right: 8px;
      }
    }
  }

  // ################ POPPER ################

  .MuiTypography-root.popper-title-typo {
    margin-bottom: 8px;
  
    @include utility.typoBase(var.$grey-900, 1.5625rem, 500, null, 1.16, 12px, null);
  }
  
  .MuiTypography-root.popper-title-typo.#{var.$darkThemeClass} {
    font-size:larger;
  
    @include utility.theme(('color': var.$grey-200));
  }

  .MuiBox-root.popper-icon-box {
    margin-left: auto;
    padding-top: 16px;
    position: absolute;
    top: 0;
    right: 32px;
    width: fit-content;
}