@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.crop-run-box {
    @include mixins.content-wrapper;
}

.MuiBox-root.crop-run-title-box {
    background-color: transparent;
    display: flex;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTypography-root.crop-run-title-typo {
    margin-bottom: 16px;

    @include utility.typoBase(var.$grey-700, 2.5rem, 700, -0.01em, 1.08, null, null, true);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiTabs-root.crop-run-tabs {
    @include mixins.tabs;
}

.MuiTab-root.crop-run-tab {
    @include mixins.tab;
}

.MuiDivider-root.crop-run-divider {
    @include mixins.divider;
}

.MuiBox-root.crop-run-content-box {
    @include mixins.three-cards-grid;
    
    &.no-entity-available {
        display: flex;
        justify-content: center;
    }
}
