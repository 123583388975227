@use 'var';
@use 'utility';
@use 'mixins';

// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~ 

.MuiBox-root.alert-and-routine-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~ 

.MuiBox-root.alert-and-routine-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-200, 'color': var.$grey-400));
}


.MuiButton-root.no-entity-create-button {
    background-color: var.$primary-700;
    border-radius: 0;
    box-shadow: none;
    padding-bottom: 16px;
    padding-top: 16px;
    text-transform: none;
    margin-top: 30px;
    
    &:hover {
        background-color: var.$primary-700;
    }

    @include utility.typoBase(var.$primary-100, 1rem, 500, -0.05em, 1.25, null, var.$maxBtnWd);

    animation: pulse 1s 5;
}

@keyframes pulse {
    0% {
      opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
  }

.MuiButton-root.no-entity-create-button.#{var.$darkThemeClass} {
    @include mixins.save-button-contained-dark;
}