@use 'var';
@use 'utility';


// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.dash-mega-card-box {
    background-color: var.$main-100;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    height: 302px;
    padding: 20px;
    position: relative;
    width: 100%;

    @include utility.transition(null);

    &:last-of-type {
        margin-right: 0;
    }
}

.MuiSvgIcon-root.dash-mega-card-other-icon {
    background-color: var.$dark-grey-100;
    border-radius: 50%;
    height: 128px;
    width: 128px;
}

.MuiSvgIcon-root.dash-mega-card-icon {
    margin-left: auto;
    // position: absolute;
    path {
        fill: var.$dark-grey-100;
    }
}

.MuiButton-root.dash-mega-card-see-more-button {
    background-color: transparent;
    min-width: 0;
    padding: 10px 0;
    text-transform: none;

    @include utility.typoBase(var.$main-500, 1rem, 400, normal, 1.29, auto, fit-content);

    &:hover {
        background-color: transparent;
        color: var.$main-500;
    }
}

.MuiTypography-root.dash-mega-card-title-typo {
    max-width: 222px;
    opacity: 0.8;
    white-space: break-spaces;

    @include utility.typoBase(var.$main-700, 1rem, 400, normal, 1.28, 8px, null);
}

.MuiTypography-root.dash-mega-card-desc-typo {
    max-width: 222px; 
    opacity: 0.8;
    white-space: break-spaces;

    // @include utility.typoBase(var.$main-700, 0.875rem, 400, 0.005em, 1.28, null, null);
    @include utility.typoBase(var.$main-700, 1rem, 400, normal, 1, null, null);
}

.MuiTypography-root.dash-mega-card-value-typo {
    max-width: 152px;
    opacity: 1;

    @include utility.typoBase(var.$main-1100, 2rem, 500, 0.005em, 1.28, auto, null);
}


// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.dash-mega-card-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100));
    
}

// // .MuiSvgIcon-root.dash-mega-card-other-icon.#{var.$darkThemeClass} {
    
//     // }
    
.MuiSvgIcon-root.dash-mega-card-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$grey-600;
    }
}

.MuiButton-root.dash-mega-card-see-more-button.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));
}

.MuiTypography-root.dash-mega-card-desc-typo.#{var.$darkThemeClass},
.MuiTypography-root.dash-mega-card-title-typo.#{var.$darkThemeClass} {
    opacity: 0.8;
    @include utility.theme(('color': var.$grey-400));
}

.MuiTypography-root.dash-mega-card-value-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-300));
}