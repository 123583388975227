@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~ 


.MuiSvgIcon-root.metric-plan-detail-icon {
    height: 32px;
    margin-right: 7px;
    width: 32px;

    path {
        fill: var.$grey-900;
    }
}

.MuiBox-root.metric-plan-detail-box {
    @include mixins.main-content-wrapper;

    @include utility.transition((background-color, false, false));
}

.MuiBox-root.metric-plan-detail-header-box {
    align-items: flex-start;
    background-color: transparent;
    display: flex;
    justify-content: space-between;

    @include utility.transition(());
}

.MuiBox-root.metric-plan-detail-title-and-nav-box {
    display: flex;
    flex-direction: column;
}

.MuiTypography-root.metric-plan-detail-title-typo {
    margin-bottom: 16px;

    @include utility.typoBase(var.$grey-700, 2.25rem, 700, -0.01em, 1.08, null, null, true);
}

.MuiSvgIcon-root.metric-plan-detail-breadcrumb-sep-icon {
    height: 16px;
    width: 16px;
}

.MuiBreadcrumbs-root.metric-plan-detail-breadcrumbs {
    line-height: 18px;

    .MuiBreadcrumbs-separator {
        margin-left: 4px;
        margin-right: 4px;
    }
}

.MuiTypography-root.metric-plan-detail-breadcrumb-link-typo {
    @include utility.typoBase(var.$grey-600, 0.875rem, 400, -0.05em, 1.29, null, null);

    &:hover {
        cursor: default;
    }
}

.MuiButton-root.metric-plan-detail-button {
    background-color: transparent;
    border: 1px solid var.$primary-800;
    border-radius: 0;
    margin-right: 8px;
    padding-bottom: 16px;
    padding-top: 16px;
    text-align: center;
    text-transform: none;

    @include utility.typoBase(var.$primary-800, 1rem, 500, -0.05em, 1.25, null, calc(var.$maxBtnWd / 2));

    &:hover {
        background-color: transparent;
    }

    &:last-of-type {
        margin-right: 0;
    }
}

.MuiSvgIcon-root.metric-plan-detail-button-icon {
    height: 20px;
    margin-right: 2px;
    width: 20px;
}

// .MuiCircularProgress-root.metric-plan-detail-progress {

// }

.MuiButton-root.metric-plan-detail-save-button {
    background-color: var.$primary-700;
    border-radius: 0;
    padding: 16px;
    text-transform: none;

    &:hover {
        background-color: var.$primary-700;
    }

    &.Mui-disabled {
        background-color: var.$grey-400;
        color: var.$grey-200;
    }

    @include utility.typoBase(var.$primary-100, 1rem, 400, null, 1.25, null, calc(var.$maxBtnWd / 2));
}

.MuiBox-root.metric-plan-detail-wizard-btn-box {
    display: flex;
}

.MuiBox-root.metric-plan-detail-wizard-back-btn-box {
    align-items: center;
    display: flex;
    margin-right: 16px;
}

.MuiButton-root.metric-plan-detail-wizard-back-button {
    @include mixins.save-button-contained-1;
    margin-left: 16px;
}

.MuiButton-root.metric-plan-detail-save-metric-cycle-button {
    background-color: var.$primary-700;
    border-radius: 0;
    padding: 16px;
    text-transform: none;

    &:hover {
        background-color: var.$primary-700;
    }

    &.Mui-disabled {
        background-color: var.$grey-400;
        color: var.$grey-200;
    }

    @include utility.typoBase(var.$primary-100, 1rem, 400, null, 1.25, null, var.$maxBtnWd);

    &.#{var.$darkThemeClass} {
        @include mixins.save-button-contained-dark;
    }
}

.MuiTabs-root.metric-plan-detail-tabs {
    margin-top: 32px;

    .MuiTabs-indicator {
        background-color: var.$grey-700;
    }
}

.MuiTab-root.metric-plan-detail-tab {
    text-transform: none;

    &.Mui-selected {
        color: var.$grey-700;
        font-weight: 500;
    }

    @include utility.typoBase(var.$grey-500, 1rem, 400, null, 1.56, null, null);
}

.MuiDivider-root.metric-plan-detail-divider {
    @include utility.dividerBase(null, var.$grey-400, null)
}

.MuiDivider-root.metric-plan-subphase-divider {
    @include utility.dividerBase(2px, var.$primary-600, 5px)
}

.MuiDivider-root.metric-run-run-divider {
    @include utility.dividerBase(8px, var.$grey-400, 5px);
}

.MuiTextField-root.metric-plan-detail-textfield {
    width: var.$maxTextFieldWd;

    @include mixins.textfield;
}

.MuiSvgIcon-root.metric-plan-detail-phaseplan-link-icon {
    height: 20px;
    margin-left: 4px;
    vertical-align: text-bottom;
    width: 20px;

    path {
        fill: none;
    }

    // &.#{var.$darkThemeClass} {}
}

.MuiBox-root.metric-plan-detail-phaseplan-link-box {
    text-decoration: none;

    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.28, null, null); // self assumed

    // &.#{var.$darkThemeClass} {}
}


.MuiBox-root.metric-plan-detail-overview-box {
    // display: flex;
    // justify-content: space-between;
    margin-top: 40px;
    // padding-bottom: 32px;

    // display: grid;
    // gap: 16px;
    // grid-auto-rows: auto;
    // grid-template-areas: "a c"
    //                      "b c";
    // grid-template-columns: repeat(2, 1fr);
    // // margin-top: 36px;
    // max-width: var.$maxTableWd;
    // width: 100%;
    @include mixins.overview-cards-grid;
}

// .MuiBox-root.metric-plan-detail-left-box {
//     display: flex;
//     flex-direction: column;
//     flex-basis: 50%;
//     margin-right: 16px;
//     max-width: 618px; // Effectively: 602 because of margin-right;
// }

// .MuiBox-root.metric-plan-detail-right-box {
//     display: flex;
//     flex-basis: 50%;
//     max-width: 602px;
// }

.MuiBox-root.metric-plan-detail-card-box {
    background-color: var.$primary-200;
    min-width: fit-content; // apply here and/or set flexShrink: 0 on paren;
    padding: 24px 28px;
    width: 100%;
}

.MuiBox-root.metric-plan-detail-card-header-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.MuiTypography-root.metric-plan-detail-card-header-typo {
    white-space: nowrap;

    @include utility.typoBase(var.$grey-700, 1.5625rem, 700, null, 1.16, null, null);
}

.MuiSvgIcon-root.metric-plan-detail-attach-metric-cycle-edit-icon {
    height: 24px;
    width: 24px;
}

.MuiButton-root.metric-plan-detail-card-header-edit-button {
    background-color: transparent;
    text-transform: none;
    white-space: nowrap;

    &:hover {
        background-color: transparent;
    }

    @include utility.typoBase(var.$grey-700, 1rem, 500, null, 1.56, null, null);
}

.MuiBox-root.metric-plan-detail-content-box {
    display: flex;
    flex-direction: column;
    // height: var.$maxCardContentBoxHt-1;
    margin-top: 28px;
    overflow: auto;
}

.MuiBox-root.metric-plan-detail-gen-info-box {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    padding-bottom: 25px;
    width: 100%;
}

.MuiTypography-root.metric-plan-detail-tab-title-typo {
    @include utility.typoBase(var.$grey-900, 1.25rem, 500, null, 1.45, null, null);
}

.MuiTypography-root.metric-plan-detail-tab-sub-typo {
    margin-bottom: 20px;

    @include utility.typoBase(var.$grey-600, 1rem, 400, null, 1.56, 8px, null);
}

.MuiMenuItem-root.metric-plan-detail-menuitem {
    &.#{var.$darkThemeClass} {
        @include mixins.menuitem-dark;
    }
}

.MuiBox-root.metric-plan-detail-cycle-table-box {
    display: flex;
    flex-direction: column;
    margin-top: calc(var.$maxBtnWd / 14);
    gap: calc(var.$maxBtnWd / 14);
}

.MuiTypography-root.metric-plan-detail-save-change-typo {
    font-size: 1.2rem;
    text-align: center;
    width: 100%;

    &.#{var.$darkThemeClass} {
        color: var.$grey-200;
    }
}

.MuiTypography-root.metric-plan-detail-cycle-type-typo {
    margin-bottom: 12px;

    @include utility.typoBase(var.$grey-700, 1.125rem, 500, null, 1.5, null, null);
}

.MuiBox-root.metric-plan-detail-metric-cycle-table-box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: var.$maxFarmTableWd;
    margin-bottom: 36px;
}

.MuiBox-root.metric-plan-detail-highcharts-box {
    margin-bottom: 62px;
    margin-left: auto;
    margin-right: auto;

    &.metric-plan-detail-no-chart {
        display: none;
    }
}



// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~ 

.MuiSvgIcon-root.metric-plan-detail-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$primary-600;
    }
}

.MuiBox-root.metric-plan-detail-box.#{var.$darkThemeClass} {
    @include mixins.main-content-wrapper-dark;
}

// .MuiBox-root.metric-plan-detail-header-box.#{var.$darkThemeClass} {

// }

// .MuiBox-root.metric-plan-detail-title-and-nav-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.metric-plan-detail-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiSvgIcon-root.metric-plan-detail-breadcrumb-sep-icon.#{var.$darkThemeClass} {
    path {
        stroke: var.$grey-400;
    }
}

.MuiBreadcrumbs-root.metric-plan-detail-breadcrumbs.#{var.$darkThemeClass} {
    li span {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiTypography-root.metric-plan-detail-breadcrumb-link-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

.MuiButton-root.metric-plan-detail-button.#{var.$darkThemeClass} {
    border-color: var.$primary-600;

    @include utility.theme(('color': var.$primary-600));
}

.MuiSvgIcon-root.metric-plan-detail-button-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$primary-600;
    }
}

.MuiButton-root.metric-plan-detail-save-button.#{var.$darkThemeClass} {
    @include mixins.save-button-contained-dark;
}

.MuiTabs-root.metric-plan-detail-tabs.#{var.$darkThemeClass} {
    .MuiTabs-indicator {
        background-color: var.$grey-200;
    }
}

.MuiTab-root.metric-plan-detail-tab.#{var.$darkThemeClass} {
    &.Mui-selected {
        color: var.$grey-200;
    }
}

.MuiDivider-root.metric-plan-detail-divider.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$grey-500));
}

.MuiTextField-root.metric-plan-detail-textfield.#{var.$darkThemeClass} {
    @include mixins.textfield-dark;
}

// .MuiBox-root.metric-plan-detail-overview-box.#{var.$darkThemeClass} {

// }

// .MuiBox-root.metric-plan-detail-left-box.#{var.$darkThemeClass} {

// }

// .MuiBox-root.metric-plan-detail-right-box.#{var.$darkThemeClass} {

// }

.MuiBox-root.metric-plan-detail-card-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$dark-100));
}

// .MuiBox-root.metric-plan-detail-card-header-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.metric-plan-detail-card-header-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiSvgIcon-root.metric-plan-detail-attach-metric-cycle-edit-icon.#{var.$darkThemeClass} {
    path {
        fill: var.$primary-600;
    }
}

.MuiButton-root.metric-plan-detail-card-header-edit-button.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$primary-600));
}

// .MuiBox-root.metric-plan-detail-content-box.#{var.$darkThemeClass} {

// }

// .MuiBox-root.metric-plan-detail-gen-info-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.metric-plan-detail-tab-title-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

.MuiTypography-root.metric-plan-detail-tab-sub-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-400));
}

// .MuiBox-root.metric-plan-detail-cycle-table-box.#{var.$darkThemeClass} {

// }

.MuiTypography-root.metric-plan-detail-cycle-type-typo.#{var.$darkThemeClass} {
    @include utility.theme(('color': var.$grey-200));
}

// .MuiBox-root.metric-plan-detail-metric-cycle-table-box.#{var.$darkThemeClass} {

// }

.MuiBox-root.metric-plan-detail-highcharts-box.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': transparent));

    .highcharts-background {
        fill: var.$dark-100;
        // @include utility.theme(('bgColor': var.$dark-100));
    }

    .highcharts-axis-labels text,
    .highcharts-legend-item text {
        font-weight: 500 !important;
        fill: var.$grey-300 !important;
        // color: var.$grey-300;
    }

    // .highcharts-axis-labels text {

    // }

}
.command-center-chart{
    @include utility.theme(('bgColor': transparent));

    .highcharts-background {
        fill: transparent;
        // @include utility.theme(('bgColor': var.$dark-100));
    }
}
.command-center-view{
    @include utility.theme(('color': var.$primary-600));

    .MuiTable-root.display-table-table{
        border-bottom: 1px solid #A1A591; 
      }

}


// metric plan card css classes
// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~
.MuiPaper-root.metric-plan-container {
    padding-top: 40px;
    background: transparent;

    @include utility.transition(background-color);
}

.MuiCard-root.metric-plan-card-wrapper {
    display: flex;
    justify-content: space-between;
    border-radius: 0;
    border-bottom: 2px dotted var.$grey-600;
}

.MuiButton-root.metric-plan-card-btn {
    color: var.$primary-600;
    padding: 0;
    stroke: var.$primary-600;
    text-transform: none;

    &.metric-plan-del-btn {
        color: var.$grey-400;
        stroke: var.$grey-400;
    }
}

.MuiSvgIcon-root.metric-plan-link-icon {
    height: 16px;
    width: 16px;

    path {
        fill: none;
        stroke: inherit;
    }

    &:hover {
        cursor: pointer;
    }
}

.MuiCardActions-root.metric-plan-card-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 16px;
}

.MuiBox-root.metric-plan-metricrun-box {
    display: grid;
}

.MuiChip-root.metric-run-index-chip {
    border-color: var.$primary-600;
    color: var.$primary-600;
    border-radius: 50%;
    height: 24px;
    width: 24px;

    & .MuiChip-label {
        padding: 0;
    }
}

.MuiTextField-root.metric-base-textfield {
    @include mixins.textfield-outlined(0px, 4px 4px, 12px, 0);

    // fieldset {
    //     border: none;
    // }
    // background-color: transparent;
}

.MuiTextField-root.metric-run-day-textfield{
    max-width: 60px;
}

.MuiCardContent-root.metric-run-card-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;
}

.MuiDivider-root.dim-linear-gradient-divider,
.MuiDivider-root.metric-run-night-divider,
.MuiDivider-root.metric-run-day-divider {
    @include utility.dividerBase(3px, null, 5px);
}

.MuiDivider-root.metric-run-night-divider{
    background-color: var.$indigo;
}

.MuiDivider-root.metric-run-day-divider{
    background-color: var.$primary-600;
}


.MuiPaper-root.metric-run-paper-wrapper {
    padding: 24px;
    border: 1px solid var.$grey-800;
    border-radius: 0;
}

.MuiCard-root.metric-run-card-wrapper {
    padding: 4px 12px;
}

.MuiSvgIcon-root.metric-run-dayparts-icon{
    height: 16px;
    width: 16px;
    fill: none;
}

.MuiTypography-root.metric-plan-subphase-typo{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~
.MuiCard-root.metric-plan-card-wrapper.#{var.$darkThemeClass} {
    @include utility.theme(('bgColor': var.$black-000))
}

.MuiTypography-root.metric-plan-card-title-typo.#{var.$darkThemeClass} {
    color: var.$light-grey-200
}

.MuiTypography-root.metric-plan-card-sub-header-typo.#{var.$darkThemeClass},
.MuiTypography-root.metric-plan-subphase-typo.#{var.$darkThemeClass} {
    color: var.$grey-400;
}

.MuiTextField-root.metric-base-textfield.#{var.$darkThemeClass} {
    @include mixins.textfield-dark;

    & .MuiInputBase-root{
        border: 1px solid var.$primary-600;
    }
    
    & .MuiInputBase-root.Mui-disabled{
        border: none;
    }

    fieldset{
        border: none;
    }
    background: transparent;
}

.MuiTypography-root.metric-run-title-typo.#{var.$darkThemeClass} {
    color: var.$grey-200;
}

.MuiPaper-root.metric-run-paper-wrapper.#{var.$darkThemeClass} {
    background-color: var.$dark-600;
}

.MuiCard-root.metric-run-card-wrapper.#{var.$darkThemeClass} {
    background: #000;
    border-bottom: 2px dotted var.$grey-600;
}