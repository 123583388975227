@use 'var';
@use 'utility';
@use 'mixins';

$maxContentBoxWd: 376px;


// ~~~~~~~~~~~~~~~~ BaseWizard ~~~~~~~~~~~~~~~~ 


.MuiBox-root.wizard-base-box {
    @include mixins.content-wrapper;

    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.wizard-base-content-box {
    background-color: var.$primary-200;
    column-gap: 120px;
    display: grid;
    height: 100%;
    grid-template-columns: repeat(2, 1fr);
    padding: 56px 120px;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-900));
    }
}

.MuiBox-root.wizard-base-left-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: $maxContentBoxWd;
    width: 100%;

    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.wizard-base-ring-box {

    &.#{var.$darkThemeClass} {

    }
}

.MuiTypography-root.wizard-base-info-typo {
    margin-top: 48px;
    max-width: 268px;
    text-align: center;

    // @include utility.typoBase(var.$primary-800, 1.5625rem, 500, null, 1.16, null, 268px);
    @include utility.typoBase(var.$primary-800, 1.5625rem, 500, null, 1.16, null, 100%);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$primary-600));
    }
}

.MuiTypography-root.wizard-base-info-sub-typo {
    margin-top: 16px;
    // max-width: 376px;
    text-align: center;

    // @include utility.typoBase(var.$grey-700, 0.875rem, 400, null, 1.5, null, 376px);
    @include utility.typoBase(var.$grey-700, 0.875rem, 400, null, 1.5, null, 100%);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

.MuiButton-root.wizard-base-action-button {
    border-color: var.$grey-600;
    border-radius: 0;
    // max-width: $maxContentBoxWd;
    text-transform: none;

    @include utility.typoBase(var.$grey-600, 0.875rem, 500, null, 1.5, 42px, 100%);
    
    &:hover {
        border-color: var.$grey-600;
    }

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));

        border-color: var.$grey-200;
        
        &:hover {
            @include utility.theme(('bgColor': var.$dark-900));
        }
    }
}

.MuiBox-root.wizard-base-right-box {
    // align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: $maxContentBoxWd;
    // width: 100%;
    width: fit-content;

    &.#{var.$darkThemeClass} {

    }
}

.MuiTypography-root.wizard-base-todo-typo {
    max-width: 268px;

    @include utility.typoBase(var.$grey-800, 1.25rem, 500, null, 1.45, null, 100%);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

.MuiBox-root.wizard-base-todo-list-box {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    &.#{var.$darkThemeClass} {

    }
}

.MuiSvgIcon-root.wizard-base-step-connector-icon {
    margin-bottom: 6px;
    margin-top: 6px;

    line {
        stroke: var.$grey-400;
    }

    &.wizard-base-curr-step {
        line {
            stroke: var.$primary-700;
        }
    }

    &.#{var.$darkThemeClass} {
        line {
            stroke: var.$grey-400;
        }

        &.wizard-base-curr-step {
            line {
                stroke: var.$primary-600;
            }
        }
    }
}

.MuiBox-root.wizard-base-todo-item-box {
    align-items: center;
    display: flex;

    &.#{var.$darkThemeClass} {

    }
}

.MuiSvgIcon-root.wizard-base-step-status-icon {

    &.#{var.$darkThemeClass} {
        
    }
}

.MuiTypography-root.wizard-base-todo-step-typo {
    margin-left: 18px;

    @include utility.typoBase(var.$grey-800, 1rem, 500, null, 1.56, null, null);

    &.wizard-base-curr-step {
        font-weight: 700;
    }

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}


// ~~~~~~~~~~~~~~~~ FarmsWizard ~~~~~~~~~~~~~~~~ 


.MuiBox-root.wizard-farms-box {
    @include mixins.content-wrapper;

    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.wizard-farms-content-box {
    background-color: var.$primary-200;
    column-gap: 120px;
    display: grid;
    height: 100%;
    grid-template-columns: repeat(2, 1fr);
    padding: 56px 120px;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$dark-900));
    }
}

.MuiBox-root.wizard-farms-left-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: $maxContentBoxWd;
    width: 100%;

    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.wizard-farms-ring-box {

    &.#{var.$darkThemeClass} {

    }
}

.MuiTypography-root.wizard-farms-info-typo {
    margin-top: 48px;
    max-width: 268px;
    text-align: center;

    // @include utility.typoBase(var.$primary-800, 1.5625rem, 500, null, 1.16, null, 268px);
    @include utility.typoBase(var.$primary-800, 1.5625rem, 500, null, 1.16, null, 100%);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$primary-600));
    }
}

.MuiTypography-root.wizard-farms-info-sub-typo {
    margin-top: 16px;
    // max-width: 376px;
    text-align: center;

    // @include utility.typoBase(var.$grey-700, 0.875rem, 400, null, 1.5, null, 376px);
    @include utility.typoBase(var.$grey-700, 0.875rem, 400, null, 1.5, null, 100%);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

.MuiButton-root.wizard-farms-action-button {
    border-color: var.$grey-600;
    border-radius: 0;
    // max-width: $maxContentBoxWd;
    text-transform: none;

    @include utility.typoBase(var.$grey-600, 0.875rem, 500, null, 1.5, 42px, 100%);
    
    &:hover {
        border-color: var.$grey-600;
    }

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));

        border-color: var.$grey-200;
        
        &:hover {
            @include utility.theme(('bgColor': var.$dark-900));
        }
    }
}

.MuiBox-root.wizard-farms-right-box {
    // align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: $maxContentBoxWd;
    // width: 100%;
    width: fit-content;

    &.#{var.$darkThemeClass} {

    }
}

.MuiTypography-root.wizard-farms-todo-typo {
    max-width: 268px;

    @include utility.typoBase(var.$grey-800, 1.25rem, 500, null, 1.45, null, 100%);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}

.MuiBox-root.wizard-farms-todo-list-box {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    &.#{var.$darkThemeClass} {

    }
}

.MuiSvgIcon-root.wizard-farms-step-connector-icon {
    margin-bottom: 6px;
    margin-top: 6px;

    line {
        stroke: var.$grey-400;
    }

    &.wizard-farms-curr-step {
        line {
            stroke: var.$primary-700;
        }
    }

    &.#{var.$darkThemeClass} {
        line {
            stroke: var.$grey-400;
        }

        &.wizard-farms-curr-step {
            line {
                stroke: var.$primary-600;
            }
        }
    }
}

.MuiBox-root.wizard-farms-todo-item-box {
    align-items: center;
    display: flex;

    &.#{var.$darkThemeClass} {

    }
}

.MuiSvgIcon-root.wizard-farms-step-status-icon {

    &.#{var.$darkThemeClass} {
        
    }
}

.MuiTypography-root.wizard-farms-todo-step-typo {
    margin-left: 18px;

    @include utility.typoBase(var.$grey-800, 1rem, 500, null, 1.56, null, null);

    &.wizard-farms-curr-step {
        font-weight: 700;
    }

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-300));
    }
}


// ~~~~~~~~~~~~~~~~ PlansWizard ~~~~~~~~~~~~~~~~ 

.MuiBox-root.wizard-plans-box {
    @include mixins.content-wrapper;

    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.wizard-plans-content-box {
    background-color: var.$primary-200;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 120px;

    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.wizard-plans-left-box {
    
    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.wizard-plans-ring-box {

    &.#{var.$darkThemeClass} {

    }
}

.MuiBox-root.wizard-plans-right-box {

    &.#{var.$darkThemeClass} {

    }
}
