@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 


.MuiBox-root.signup-page-box {
    display: flex;
    flex-direction: column;
    // max-width: var.$maxSetupFormWd;
    // max-width: var.$maxSetupFormWd;
    margin-top: 108px;
    margin-left: auto;
    margin-right: auto;
    width: var.$maxSetupFormWd;

    // &.signup-page-basic-setup-complete {
    //     // margin-left: auto;
    //     // margin-right: auto;
    // }

    // &.signup-page-allow-farm-add {
    //     margin-left: auto;
    //     margin-right: auto;
    //     // width: var.$maxSetupFormWd;
    // }

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTypography-root.signup-page-info-typo {
    @include utility.typoBase(var.$grey-900, 1rem, 400, null, 1.56, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiTypography-root.signup-page-action-typo {
    @include utility.typoBase(var.$grey-900, 1.875rem, 700, -0.01em, 1.13, 12px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiBox-root.signup-page-name-box {
    column-gap: 16px;
    // display: flex;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // width: var.$maxSetupFormWd;
    // width: var.$maxSetupFormWd;

    // &.#{var.$darkThemeClass} {

    // }
}

// .MuiBox-root.signup-page-form-box {

//     &.#{var.$darkThemeClass} {

//     }
// }

.MuiTypography-root.signup-page-next-action-typo {
    margin-bottom: 4px;

    @include utility.typoBase(var.$grey-800, 1.25rem, 500, null, 1.45, 28px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}


.MuiButton-root.signup-page-resend-code-button {
    border-radius: 0;
    min-width: fit-content;
    padding: 16px 0;
    text-transform: none;
    white-space: nowrap;
    
    @include utility.typoBase(var.$primary-800, 1rem, 500, null, 1.56, null, null);
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));

        &.Mui-disabled {
            opacity: 0.5;
        }
    }
}

.MuiSvgIcon-root.signup-page-show-password-icon {
    height: 20px;
    width: 20px;
        
    &:hover {
        cursor: pointer;
    }

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiSvgIcon-root.signup-page-mail-icon {
    height: 20px;
    width: 20px;

    path {
        fill: none;
    }

    &.#{var.$darkThemeClass} {
        path {
            stroke: var.$grey-400;
        }
    }
}

.MuiTextField-root.signup-page-textfield {
    @include mixins.textfield-outlined;
    
    margin-top: 28px;
    // width: var.$maxSetupFormWd;
    // width: var.$maxSetupFormWd;

}

.MuiButton-root.signup-page-proceed-button {
    background-color: var.$primary-800;
    border-radius: 0;
    margin-bottom: 20px;
    padding: 16px;
    text-transform: none;

    // @include utility.typoBase(var.$main-000, 1rem, 500, null, 1.56, 20px, var.$maxSetupFormWd);
    @include utility.typoBase(var.$main-000, 1rem, 500, null, 1.56, 20px, 100%);
        
    &:hover {
        background-color: var.$primary-800;
    }

    &.#{var.$darkThemeClass} {
        @include utility.theme(('bgColor': var.$primary-700, 'color': var.$primary-100));

        &:hover {
            background-color: var.$primary-700;
        }

        &.Mui-disabled {
            opacity: 0.5;
        }
    }
}

.MuiTypography-root.signup-page-enter-action-typo {
    @include utility.typoBase(var.$grey-900, 1rem, 400, null, 1.56, 16px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiTypography-root.signup-page-resend-action-typo {
    @include utility.typoBase(var.$grey-700, 1rem, 400, null, 1.56, 20px, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiBox-root.signup-page-instructions-box {
    margin-top: 20px;
    padding-left: 8px;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiTypography-root.signup-page-instruction-typo {
    margin-left: 16px;

    @include utility.typoBase(var.$grey-700, 0.875rem, 400, null, 1.5, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}
