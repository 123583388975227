@use 'var';
@use 'utility';

// ~~~~~~~~~~~~~~~~ LIGHT THEME ~~~~~~~~~~~~~~~~

.MuiDrawer-root.dashdrawer-drawer {
    // <Drawer /> has a <Paper /> inside of it
    flex-grow: 0;
    height: 100vh;
    position: relative;

    .MuiDrawer-paper {
        background-color: var.$grey-800;
        // transition: background-color var.$baseTransitionTime;

        $drawerTransition: (var.$widthTransitionOpenList-100, (background-color));
        @include utility.transition($drawerTransition, false, flase);

        &.dashdrawer-closed {
            $drawerTransition: (var.$widthTransitionClosedList-100, (background-color));
            @include utility.transition($drawerTransition, false, false);
        }
    }
}

.MuiPaper-root.dashdrawer-logo-paper {
    background-color: transparent;
    margin-bottom: 56px;
    margin-top: 37px;
    padding-left: 16px;
    padding-right: 16px;
}

.MuiBox-root.dashdrawer-logo-box {
    height: 16px;
    margin-bottom: 8px;
    padding: 0;
    width: 16px;

    &:hover {
        cursor: pointer;
    }
}

.MuiBox-root.dashdrawer-logo-image {
    height: 16px;
    vertical-align: top;
    width: 16px;
}

.MuiTypography-root.dashdrawer-logo-typo {
    // color: var.$grey-400;
    margin-bottom: 4px;
    transition: color var.$baseTransitionTime ease, font-size 400ms ease;

    @include utility.typoBase(var.$grey-400, 0.75rem, 500, 0.035em, 1.58, null, fit-content);


    &:hover {
        cursor: pointer;
    }

    &.dashdrawer-closed {
        margin-bottom: 0;
        color: var.$primary-300;
        font-size: 0.875rem;    
        
        &:hover {
            cursor: default;
        }
    }
}

// .MuiTypography-root.dashdrawer-logo-typo.dashdrawer-closed {
//     margin-bottom: 0;
//     color: var.$primary-300;
//     font-size: 0.875rem;

//     &:hover {
//         cursor: default;
//     }
// }

.MuiPaper-root.dashdrawer-menu-paper {
    background-color: transparent;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
}

.MuiSvgIcon-root.dashdrawer-dash-icon {
    height: 20px;
    width: 20px;

    path {
        fill: var.$grey-400;
    }

    &.dashdrawer-tabselected path {
        fill: var.$primary-300;
    }
}

.MuiListItem-root.dashdrawer-listitem {
    display: block;
    margin-bottom: 12px;
    width: 100%;
    
    // &:last-of-type {
    //     margin-bottom: 0;
    // }
}

.MuiListItemButton-root.dashdrawer-listitembutton {
    padding: 8px 16px;
    height: 40px;

    &.Mui-selected {
        // color: var.$primary-300;
        background-color: inherit;
        border-left: 1px solid var.$primary-300;
    }

    &.Mui-selected:hover {
        background-color: inherit;
    }
}

.MuiListItemIcon-root.dashdrawer-listitemicon {
    color: var.$grey-400;
    min-width: fit-content;
    padding-right: 10px;

    &.dashdrawer-closed {
        padding-right: 0;
    }

    &.dashdrawer-tabselected {
        color: var.$primary-300;
    }
}

.MuiListItemText-root.dashdrawer-listitemtext {
    margin-bottom: 0;
    margin-top: 0;
    opacity: 1;
    transition: var.$opacityTransition-100;

    .MuiListItemText-primary {
        color: var.$grey-400;
        font-weight: 500;
    }

    &.dashdrawer-tabselected .MuiListItemText-primary {
        color: var.$primary-300;
    }

    &.dashdrawer-closed {
        opacity: 0;
        transition: none;
    }
}

.MuiPaper-root.dashdrawer-footer-paper {
    background-color: transparent;
    margin-top: auto;
}

.MuiDivider-root.dashdrawer-footer-divider {
    background-color: var.$grey-700;
}

.MuiPaper-root.dashdrawer-profile-paper {
    background-color: transparent;
    margin: 36px 16px 32px;

    &.dashdrawer-closed {
        margin-left: 0;
        margin-right: 0;
    }
}

.MuiTypography-root.dashdrawer-login-typo {
    color: var.$grey-400;
    height: 22px; // 10022023: Don't usually set height for any text element anymore; TODO: Style the text to take this setting out
    margin-bottom: 20px;
    opacity: 1;
    transition: var.$opacityTransition-100;

    @include utility.typoBase(var.$grey-400, 0.75rem, 500, 0.035em, 1.58, null, null);

    &.dashdrawer-closed {
        opacity: 0;
        transition: initial;
    }
}

.MuiTypography-root.dashdrawer-role-typo {
    color: var.$grey-400;
    background-color: var.$grey-400;
    height: 19px; // 10022023: TODO: Try to eliminate setting up text box height explicity; It should be an outcome;
    opacity: 1;
    padding-left: 12px;
    padding-right: 12px;
    text-transform: uppercase;
    transition: var.$opacityTransition-100;

    @include utility.typoBase(var.$grey-800, 0.75rem, null, 0.035em, 1.58, null, fit-content);

    &.dashdrawer-closed {
        opacity: 0;
        transition: initial;
    }
}

.MuiAccordion-root.dashdrawer-accordion {
    background-color: transparent;
    margin-top: 12px;
    padding: 0;

    .MuiAccordionSummary-root.Mui-expanded {
        min-height: 0;
    }
    
    .MuiAccordionSummary-content.Mui-expanded, .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        margin-bottom: 40px;
        margin-top: 12px;
    }

    &.Mui-disabled, .MuiButtonBase-root.Mui-disabled {
        background-color: transparent;
        opacity: 1;
    }

    &::before {
        content: 'none';
    }
}

.MuiAccordionSummary-root.dashdrawer-acc-summary {
    min-height: fit-content;
    padding: 0;
    
    .MuiAccordionSummary-content {
        align-items: center;
        margin: 0;
    }
}

.MuiAvatar-root.dashdrawer-avatar {
    color: var.$grey-800;
    font-size: 1.125rem;
    height: 32px;
    margin-left: 0;
    margin-right: 0;
    width: 32px;

    &.dashdrawer-closed {
        margin-left: auto;
        margin-right: auto;
    }
}

.MuiTypography-root.dashdrawer-avatar-typo {
    margin-left: 16px;
    opacity: 1;
    transition: var.$opacityTransition-100;

    @include utility.typoBase(var.$primary-300, 1rem, 500, null, 1.56, null, null);

    &.dashdrawer-closed {
        opacity: 0;
        transition: initial;
    }
}

.MuiAccordionDetails-root.dashdrawer-acc-details {
    padding: 0;
    opacity: 1;
    transition: var.$opacityTransition-200;

    &.dashdrawer-closed {
        opacity: 0;
    }
}

.MuiListItem-root.dashdrawer-listitem {
    display: block;
    height: 41px;
    margin-bottom: 12px;

    // &:last-child {
    //     margin-bottom: 0;
    // }
}

.MuiListItemButton-root.dashdrawer-listbutton {
    padding: 0;

    .MuiListItemText-primary {
        color: var.$grey-400;
    }

    &.Mui-selected {
        background-color: inherit;
        color: var.$primary-300;
        
        .MuiListItemText-primary {
            color: var.$primary-300;
        }    
        
        &:hover {
            background-color: inherit;
            color: var.$primary-300;
        }
    }
}

.MuiListItemIcon-root.dashdrawer-listicon {
    color: var.$grey-400;
    min-width: fit-content;
    margin-right: 10px;

    path {
        fill: var.$grey-400;
    }

    &.dashdrawer-tabselected path {
        fill: var.$primary-300;
    }
}

.MuiListItemText-root.dashdrawer-listtext {
    .MuiListItemText-primary {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.56;
    }
}


// ~~~~~~~~~~~~~~~~ DARK THEME ~~~~~~~~~~~~~~~~

.MuiDrawer-root.dashdrawer-drawer.#{var.$darkThemeClass} {
    .MuiDrawer-paper {
        @include utility.theme(('bgColor': var.$dark-200));
        // If a change to transition property is made, make sure to add the properties for width as in light theme
    }
}

.MuiTypography-root.dashdrawer-logo-typo.#{var.$darkThemeClass} {
    color: var.$primary-600;
    font-weight: 700;

    &:hover {
        cursor: pointer;
    }

    &.dashdrawer-closed {
        // color: var.$primary-600;
        font-weight: 700;
    }
}

.MuiListItemButton-root.dashdrawer-listitembutton.#{var.$darkThemeClass} {
    &.Mui-selected {
        // color: var.$primary-300;
        // background-color: var.$grey-600;
        border: none;

        @include utility.theme(('bgColor': var.$grey-600));
    }
}

.MuiListItemIcon-root.dashdrawer-listitemicon.#{var.$darkThemeClass} {    
    color: var.$grey-200;
    &.dashdrawer-tabselected {
        color: var.$grey-200;
    }
}

.MuiSvgIcon-root.dashdrawer-dash-icon.#{var.$darkThemeClass} {
    &.dashdrawer-tabselected path {
        fill: var.$grey-200;
    }
}

.MuiListItemText-root.dashdrawer-listitemtext.#{var.$darkThemeClass} {
    &.dashdrawer-tabselected .MuiListItemText-primary {
        color: var.$grey-200;
    }
}
