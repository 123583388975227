@use 'sass:map';
@use 'sass:string';

@use 'var';


// @mixin theme($bgColor) {
@mixin theme($themeMap) {
    background-color: map.get($themeMap, 'bgColor');
    background-image: map.get($themeMap, 'bgImage');
    color: map.get($themeMap, 'color');
}

@function getTransitions($propertyNamesList, $addImp, $addBase) {
    $transition: null;

    @if $addBase {
        $transition: var.$baseTransition + ', ';
    }

    @each $name, $duration, $timing, $delay in $propertyNamesList {
        @if not $duration {
            $duration: var.$baseTransitionTime;
        }

        $newTransition: null;
        
        @if $name {
            $newTransition: $name + " " + $duration + " " + $timing + " " + $delay + ", ";
        }
        
        $transition: $transition + $newTransition;
    }

    $formattedTransition: str-slice($transition, 0, -3);

    @if $addImp {
        @return unquote($formattedTransition + ' !important');
    }

    @return unquote($formattedTransition);
}

@mixin transition($propertyNamesList, $addImp: true, $addBase: true) {
    transition: getTransitions($propertyNamesList, $addImp, $addBase);
}

@mixin typoBase($color: inherit, $fontSize: inherit, $fontWeight: inherit, $letterSpacing: inherit, $lineHeight: inherit, $mt: initial, $width: initial, $wordBreakEnable: false) {
    // TODO: Implement using map line in theme()
    color: $color;
    font-size: $fontSize;
    font-weight: $fontWeight;
    letter-spacing: $letterSpacing;
    line-height: $lineHeight;
    margin-top: $mt;
    width: $width;

    @if $wordBreakEnable {
        overflow-wrap: break-word;
        word-break: break-word;    
    }
}

@mixin textEllipsis($maxWidth) {
    max-width: $maxWidth;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin dividerBase($height: inherit, $backgroundColor: inherit, $borderRadius: inherit) {
    height: $height;
    background-color: $backgroundColor;
    border-radius: $borderRadius;
}
