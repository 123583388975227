@use 'var';
@use 'utility';
@use 'mixins';


// ~~~~~~~~~~~~~~~~ LIGHT & DARK THEME ~~~~~~~~~~~~~~~~ 

.MuiBox-root.invite-page-content-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 126px auto 0;
    padding-bottom: 56px;
    max-width: var.$maxInviteFormWd;

    &.#{var.$darkThemeClass} {

    }
}

.MuiSvgIcon-root.invite-page-user-icon {
    height: 48px;
    width: 48px;

    &.#{var.$darkThemeClass} {
        path {
            fill: var.$grey-200;
        }
    }
}

.MuiTypography-root.invite-page-wel-typo {
    text-align: center;

    @include utility.typoBase(var.$grey-900, 1.875rem, 500, -0.01em, 1.13, 36px, null);
    
    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-200));
    }
}

.MuiTypography-root.invite-page-wel-sub-typo {
    text-align: center;
    
    @include utility.typoBase(var.$grey-700, 0.875rem, 400, null, 1.5, 12px, null);

    width: 80%;

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiBox-root.invite-page-form-box {
    margin-top: 20px;
    width: 100%;

    &.#{var.$darkThemeClass} {

    }
}

.MuiTextField-root.invite-page-textfield {
    @include mixins.textfield-outlined;

    // &.#{var.$darkThemeClass} {

    // }
}

.MuiSvgIcon-root.invite-page-visibility-icon {
    height: 20px;
    width: 20px;
        
    &:hover {
        cursor: pointer;
    }   
}

.MuiBox-root.invite-page-instruction-box {
    margin-top: 20px;
    padding-left: 8px;

    &.#{var.$darkThemeClass} {

    }
}

.MuiTypography-root.invite-page-instruction-typo {
    margin-left: 16px;

    @include utility.typoBase(var.$grey-700, 0.875rem, 400, null, 1.5, null, null);

    &.#{var.$darkThemeClass} {
        @include utility.theme(('color': var.$grey-400));
    }
}

.MuiButton-root.invite-page-submit-button {
    @include mixins.save-button-contained-2;
    
    display: flex;
    text-transform: none;
    width: 100%;
    
}
